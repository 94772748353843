.containVehiculeSelection {
  overflow: hidden;
  padding-top: 40px;
  @media (max-width: $screen-sm-max){
      margin-top: 0;
  }
  @media(max-width: $screen-xs-max) {
    margin-left: 0;
    margin-right: 0;
    margin-top: 1em;
    padding: 20px 0 40px;
  }
  .containVehiculeSelectionTitre {
    background: $grisFond;
    overflow: hidden;
    padding: 10px 11px 0 10px;
    position: relative;
    &:before{
      content: "";
      background-color: white;
      width: 20px;
      height: 20px;
      position: absolute;
      bottom: 10px;
      left: 11px;
      z-index: 1;
    }
    &:after{
      content: "";
      background-color: white;
      width: 20px;
      height: 20px;
      position: absolute;
      bottom: 10px;
      right: 12px;
      z-index: 1;
    }
    > .whiteCard {
      padding: 20px 20px 0;
      border-bottom: none;
      .vehiculeSelectionCadreTitreGris {
        background: $grisFond;
        box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
        padding: 10px 10px 20px;
        @media only screen {
          @media(max-width: $screen-xs-max) {
            padding: 5px;
          }
        }
      }
      .hideBottomSelectionCadreTitreGris {
        background-color: $grisFond;
        width: 100%;
        height: 10px;
        position: absolute;
        bottom: 0;
        left: 0;
          @media only screen {
              @media(max-width: $screen-sm-max) {
                  display: none;
              }
          }
      }
    }
  }
  .containVehiculeSelectionOffres {
    > div {
      background: $grisFond;
      padding: 0px 10px;
      margin-top: -10px;
        @media only screen {
            @media(max-width: $screen-sm-max) {
                margin-top: 0;
                padding: 0 0 10px;
            }
        }
      @media(max-width: $screen-xs-max) {
        margin-left: 0;
        margin-right: 0;
        >div{
          margin-left: 0;
          margin-right: 0;
        }
      }
      @media(max-width: $screen-sm-max) {
        .slick-prev, .slick-next{
          top: 30px;
          box-shadow: none;
          border: none;
          background: none;
        }
        .slick-prev{
          left: 25px;

        }
        .slick-next{
          right: 25px;
        }
      }
      .col-md-4{
        padding-bottom: 2em;
      }

      .whiteCard{
        border-top: none;
        padding: 10px 20px 0;
        @media(max-width: $screen-xs-max){
          padding: 10px 10px 0;
        }
          h6, .h6{
            padding: 0.2em 0 .6em
          }
        @media(min-width: 992px) and (max-width: 1300px) {
          > .row{
            min-height: 352px;
          }
          .honoredCarVignette{
            height: auto;
          }
          > div .honoredCarDescription{
            width: 100%;
            text-align: right;
          }
          > div .honoredCarPrice, > div .honoredCarOccasionPrice{
            width: 100%;
          }
        }
        @media(min-width: 768px) and (max-width: 992px){
          > .row {
            padding-bottom: 50px;
          }
        }
        .selectionVehiculeAnnonce{
          @media(max-width: $screen-xs-max){
            a{
              >.row{
                padding: 0 10px;
              }
            }

          }

          @media(max-width: $screen-xs-max) {
            margin-bottom: 10px;
            .honoredCarVignette{
              height: auto;
            }
          }
          &.vert:hover{
            p.selectionVehiculeDesc{
              color: $vert !important;
            }
          }
          &.bleu:hover{
            p.selectionVehiculeDesc{
              color: $bleu !important;
            }
          }
          &.jaune:hover{
            p.selectionVehiculeDesc{
              color: $jaune !important;
            }
          }
          &.rose:hover{
            p.selectionVehiculeDesc{
              color: $rose !important;
            }
          }
            .badge-ecoFirst span.eco{
                display: none;
            }
          &:hover{
            .badge-ecoFirst span.eco{
              display: inline-block;
            }
            span.ecoMinus{
              display: none;
            }
          }

        }
        p.selectionVehiculeDesc, p.selectionVehiculePrix{
          margin-bottom: 0.5em;
          white-space: nowrap;
          @media(max-width: $screen-sm-max){
              white-space: normal;
          }
          @media(max-width: $screen-xs-max) {
            margin-bottom: 0;
          }
        }
        p.selectionVehiculeDesc{
          color: $bleuEm;
        }
        p.selectionVehiculePrix{
          font-size: 1.1em;
          margin-bottom: 1.5em;
        }
      }

      .honoredCarvert, .honoredCarbleu,.honoredCarjaune{
        border-radius: 2px;
      }
      .honoredCarvert{
        border: 2px $vert solid;
      }
      .honoredCarbleu{
        border: 2px $bleu solid;
      }
      .honoredCarjaune{
        border: 2px $jaune solid;
      }

      .honoredCarVignette{
        height:50px;
      }

      .honoredCarDescription, .honoredCarPrice, .honoredCarOccasionPrice{
        padding:0;
      }
      .honoredCarPrice, .honoredCarOccasionPrice{
        text-align: right;
      }
      .honoredCarOccasionPrice {
        padding-top:5%;
      }

      @media(max-width: $screen-sm-max) {
        .honoredCarPrice {
          font-size: 0.9em;
        }
        .honoredCarOccasionPrice {
          padding-top:0;
        }
      }

    }
  }
    .noPaddingSelection {
        @media only screen {
            @media(max-width: $screen-xs-max) {
                padding: 0;
            }
        }
    }
  .financementIndispo {
    font-size: 0.8em;
  }

  .strikethrough {
    color: #acafb1;
    position: relative;
      &:before{
          content: '';
          display: inline-block;
          width: 100%;
          height: 1px;
          background-color: #E95A0B;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

      }
  }

  .honoredCarImg{
    position: relative;
    @media(max-width: $screen-xs-max){
      padding: 0;
      text-align: center;
    }
  }

  .badge-ecoFirst, .badge-eco {
    position: absolute;
    color: $bleuEm;
    border-radius: 1em;
    padding: 3px 10px;
    font-size: 10px;
    line-height: 10px;
    font-weight: bold;
    bottom: 5%;
      @media (max-width: $screen-sm-max){
          .ecoMinus{
              display: inline-block;
          }
      }
    @media (max-width: $screen-xs-max){
      border-radius: initial;
      bottom: 0;
    }
  }
  .badge-ecoFirst.vert, .badge-eco.vert{
    background-color: $vert;
  }
  .badge-ecoFirst.jaune, .badge-eco.jaune{
    background-color: $jaune;
  }
  .badge-ecoFirst {
    right: 15px;
    @media (max-width: $screen-xs-max){
      right: 0;
      span{
        display: none;
      }
    }
  }
  .badge-eco{
    right: 15px;
    @media (max-width: $screen-xs-max){
      right: 0;
    }
  }
    @media (max-width: $screen-sm-max){
        .whiteCard{
            .row{
                .selectionVehiculeAnnonce{
                    &:first-child{
                        .badge-ecoFirst{
                            span.eco{
                                display: inline-block;
                            }
                            span.ecoMinus{
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }

}


