
/* Tab Navigation */
.nav-tabs {
  margin: 0;
  margin-top: 20px;

  padding: 0;
  border: 0;
}

.nav-tabs > li > a {
  border-radius: 0;
  box-shadow: inset 0 -8px 7px -9px rgba(0, 0, 0, .4);
  background-color: $blanc;
  border-color: transparent;
  color: $gris1;
}

.nav-tabs > li > a:hover {
  background-color: $blanc;
  border-color: transparent;
  color: $bleuEm;
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
  background: #fff;
  //box-shadow: inset 0 0 0 0 rgba(0, 0, 0, .4), -2px -3px 5px -2px rgba(0, 0, 0, .4);
  box-shadow: 0px 14px 0px 0px white, inset 0 0 0 0 rgba(0, 0, 0, 0.4), 0px 0px 10px -1px rgba(0, 0, 0, 0.4),;
  color: $bleuEm;
}

/* Tab Content */
.tab-pane {
  background: #fff;
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.3);
  border-radius: 0;
  padding: 10px;
}

.nav-tabs.nav-justified > .active > a, .nav-tabs.nav-justified > .active > a:focus{
  border-right : 1px solid $gris3;
  background-color: $blanc;
}

.nav-tabs-justified {
@media (min-width: $screen-sm-min) {
  > li > a {
    //border-bottom: 1px solid $nav-tabs-justified-link-border-color;
    border-radius: 0;
    background-color: transparent !important;
  }
  > .active > a,
  > .active > a:hover,
  > .active > a:focus {
    //border-bottom-color: $nav-tabs-justified-active-link-border-color;
    background-color: $blanc !important;
  }
}
  @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
    .big-1{
      font-size: 0.9em;
    }
  }
}


.tabs-below{
  ul{
    @include liststyle();
  }
  .nav-tabs {
    margin: 0;
    margin-top: 0px;

    padding: 0;
    border: 0;
  }
  .nav-tabs > li{
    &.floatRight{
      float: right;
    }
    &.gris1{
      a{
        color: $blanc !important;
        background-color: $gris1;
        &.small{
          font-size: smaller;
          padding: 0.5em;
          font-weight: 500;
        }
      }
    }
    &.bleuEm{
      a{
        color: $blanc;
        background-color: $bleuEm;
        font-weight: 500;
        &.hiddenOnLoad{
          display: none;
          position: absolute;
        }
      }
    }
  }

  .nav-tabs > li > a {
    border-radius: 0;
    box-shadow: inset 0 8px 7px -9px rgba(0, 0, 0, .4), 0 3px 6px rgba(0,0,0,0.15);
    background-color: $blanc;
    border-color: transparent;
    color: $gris1;
    border-width: 0px;
  }

  .nav-tabs > li.active > a,
  .nav-tabs > li.active > a:hover {
    box-shadow: 0px -10px 0px 0px white, inset 0 0 0 0 rgba(0, 0, 0, 0.4), 0px 0px 10px -1px rgba(0, 0, 0, 0.4);
    border-width: 0px;
    background-color: $blanc;
    color: $bleuEm;
  }

  .nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus{
    border-top-color: transparent;
  }


}
