.containtelAside {
  text-align: center;
  margin-top: 2em;
  .whiteCard {
    padding: 0.5em;
    p {
      margin-bottom: 0.25em;
    }
    .input-group {
      margin-bottom: 1em;
    }
    .telAside {
      font-weight: 700;
      font-size: 2em;
      color: $orange;
      text-align: center;
      &:hover{
        color: darken($orange, 10%);
      }
    }
  }
  .icon-rappel{
    width: 150px;
    height: 150px;
    margin-bottom: -25px;
  }

  &.containtelAsidePromo {
    p.big-3 {
      font-size: 1.1em;

      @media(max-width: $screen-lg-min) {
        font-size: 1.3em;
      }
    }

    .icon-rappel {
      @media(max-width: $screen-lg-min) {
        $width-icon-rappel: 45px;
        width: $width-icon-rappel;
        height: $width-icon-rappel;
        margin-top: -15px;
      }
    }
  }
}
