.containFormulaireContact {
  .containCoordonneesFormulaireContact {
    @include box-shadow(inset 6px 0px 6px -6px rgba(0, 0, 0, 0.3));
    @media only screen {
      @media(max-width: $screen-xs-max) {
        @include shadowBoxInset(0.3);
        padding-top: 0.5em;
        text-align: center;
      }
    }

  }
  .btnPlanAccesFormulaire {
    margin-top: 1em;
  }

  .radioLabel {
    margin-bottom: 1em;
  }

  #containSujetContact {
    display: none;
    .containMessageContact {
      display: none;
      textarea {
        width: 100%;
      }
    }
  }
  .containEnvoiMessageContact, .containCopieMailContact, .containCaptchaContact {
    display: none;
  }
  #containNumeroCommandeContact {
    display: none;
  }
  #containCoordonneesContact {
    display: none;
  }

  #map {
    padding: 0;
    @media only screen and (max-width: 767px) {
      height: 250px;
    }
  }
}
