#toast-container{
  z-index: 200;
  > div{
    opacity: 1;
    @include shadowBox(0.6);
    margin: 5px 0 0px;
  }
  > .toast-info, > .toast-success, > .toast-error, > .toast-warning {
    background-image: none !important;
  }
  .toastBleuEm {
    background-color: $bleuEm;
  }
  .toast {
    border-radius: 0;
    //@include border-radius(0); //since webpack
    @include shadowBox(0.3);
    opacity: 1;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    filter: alpha(opacity=0);
    padding: 15px 15px 15px 15px;
    background-color: $bleuEm;


    &.toast-success {
      background-color: $vert;
    }
    &.toast-error {
      background-color: $orange;
    }
    &.toast-info {
      background-color: $bleuEm;
    }
    &.toast-warning {
      background-color: $jaune;
    }

    &:hover {
      @include shadowBox(0.4);
    }

    .toast-message {
      @include shadowText(0.3);
      .btn {
        @include shadowBox(0.3);
        margin-top: 1em;
      }
    }
  }
  &.toast-top-full-width, &.toast-bottom-full-width{
    > div{
      width: 100%;
    }

  }
}

@media all and (max-width: 240px) {
  #toast-container > div {
    padding: 8px;
  }
}



@media all and (min-width: 241px) and (max-width: 480px) {
  #toast-container > div {
    padding: 8px;
  }
}

@media all and (min-width: 481px) and (max-width: 768px) {
  #toast-container > div {
    padding: 15px;
  }
}

@media(max-width: $screen-xs-max){
  #toast-container {
    right: 0 !important;
    bottom: 0 !important;
    padding: 0 !important;
    width: 100% !important;
  }

  .toast{
    width: 100% !important;
  }
}

#toast-container{
  bottom: 0 !important;
}
