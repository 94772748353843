@import "aside-fiche/tel-aside";

.containtelAside {
  margin-top: 0;
}


.promoPageRule {
  background: $blanc;

  .topPromo {
    background: $blanc;
  }

  .logoPromo {
    margin-top: 6px;
  }

  .bgBleuEm {
    background: $bleuEm;
  }

  .accroche {
    @media(max-width: $screen-lg-min) {
      width: 100%;
    }
  }
  .descPromo {
    color: #000;
    margin-bottom: 20px;
    padding: 0 20px;

    @media(max-width: $screen-lg-min) {
      font-size: 0.9em;
      padding: 0;
    }
  }

  .scrollToTop {
    cursor: pointer;
  }

  p {
    font-family: Verdana, Arial, Helvetica, sans-serif;
    font-size: 10px;
    color: #818181;
    margin: 20px;
    padding: 0px;
    line-height: 15px;

    @media(max-width: $screen-lg-min) {
      margin: 20px 0;
    }
  }
}

.containerCallback {
  @media(max-width: $screen-lg-min) {
    margin: 15px 0;
  }

    .fixedCallback {
    position: fixed;
    width: 295px;
    z-index: 2;

    @media(max-width: $screen-lg-min) {
      position: relative;
      width: 100%;
    }

    &.fixedShareArticle {
      position: absolute;
      bottom: 40px;
      top: inherit;
    }

    &.fixedTopShareArticle {
      position: absolute;
      top: inherit;
    }
  }
}
img.w-100{
    width: 100%;
    display: block;
}
.page-offer{
    h1{
        font-size: 2.8rem;
        text-transform: initial;
        margin-bottom: 0;
    }
    .number-offer{
        font-size: 1.2rem;
        font-weight: 300;
    }
    .offer{
        position: relative;
        @media (max-width: $screen-sm-max){
            display: flex;
            width: 100%;
            flex-direction: column-reverse;
            margin: 0;
        }
        @media (max-width: $screen-xs-max){
            justify-content: center;
            margin: 0;
        }
    }
    .list-offer{
        column-count: 2;
        column-gap: 1rem;
        @media (max-width: $screen-xs-max){
            column-count: 1;
        }
        .container-offer{
            width: 100%;
            break-inside: avoid;
            margin-bottom: 1.5rem;
            padding-top: 1rem;
            display: inline-grid;
            page-break-inside: avoid;
        }
    }
    .bloc-offer{
        padding-right: 25px;
        @media (max-width: $screen-lg-max){
            order: 2;
            padding: 0 15px;
        }
        @media (max-width: $screen-sm-max){
            padding: 0;
        }
    }
    .bloc-callBack{
        right: 0;
        position: absolute;
        transition: top 0.3s linear;
        @media (max-width: $screen-md-max){
            right: initial;
            margin-bottom: 20px;
            position: relative;
            order: 1;
        }
        >.container-fluid{
            @media (max-width: $screen-sm-max){
                padding: 0;
            }
        }
        .containtelAsidePromo{
            padding-top: 1rem;
        }
    }
    .d-block{
        display: block;
    }
    .card-offer{
        text-align: center;
        h2{
            font-size: 1.8rem;
            background-color: $bleuEm;
            color: #fff;
            padding: 10px 20px;
            margin-top: 0;
            margin-bottom: 10px;
        }
    }
    .btn-offer{
        background-color: $jaune;
        padding: 10px 40px;
        display: inline-block;
        text-align: center;
        color: #fff;
        border-radius: 10px;
        font-size: 1.4rem;
        &:hover{
            opacity: 0.7;
        }
    }
    .text {
        padding: 0 15px;
        text-align: left;
        p{
            color: $gris1;
            font-size: 1.3rem;
        }
    }
    .container-img{
        border-radius: 5px 5px 0 0;
        overflow: hidden;
        img{
            width: 100%;
        }
    }
    .card-offer{
        padding: 0;
        border-radius: 5px;
    }
    .container-btn{
        margin: 20px 0;
        width: 100%;
    }
    .col-md-6{
        &:first-child{
            padding: 0 20px 0 0;
            @media (max-width: $screen-sm-max){
                padding: 0;
            }
            .whiteCard:first-child{
                margin-bottom: 30px;
            }
        }
        &:nth-of-type(2){
            padding: 0 0 0 20px;
            @media (max-width: $screen-sm-max){
                padding: 0;
            }
            .whiteCard:first-child{
                margin-bottom: 30px;
            }
        }
    }
}
.offer-event{
    .container-img{
        position: relative;
        &:before{
            content: '';
            display: inline-block;
            position: absolute;
            z-index: 1;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: rgba($bleuEm,0.5);
        }
        .banner{
            position: absolute;
            z-index: 2;
            background: #e0055a;
            color: #ffffff;
            top: 33px;
            right: -55px;
            transform: rotate(47deg);
            font-size: 0.875em;
            padding: 0.5rem 5rem;
        }
    }
}
