.jqvmap-label{
  z-index: 9999;
  background-color: $bleuEm;
  font-size: 1em;
  padding: 0.5em;
  border-radius: 10px;
}

.libelleRegionChoisie{
  background-color: #22286d;
  color: #ffffff;
  border-radius: 0.5em;
  font-weight: bold;
  font-size: 1.5em;
  padding: 0.5em;
  display: inline-block;

  @media(max-width: $screen-md-max) {
    margin-bottom: 30px;
  }
}

#vmap{
  height: 350px;
  margin: auto;
  @media only screen {
    @media(max-width: $screen-sm-max) {
      height: 300px;
    }
  }
}

/* Suppresion Map choix agence sur iPad */
@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)  {
  #vmap{
    display: none;
  }
}