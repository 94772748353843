$domFace : url('../../../../../resources/front/images/reprise/formulaire/dommages-face.png');
$domFaceGauche : url('../../../../../resources/front/images/reprise/formulaire/dommages-face-gauche.png');
$domGauche : url('../../../../../resources/front/images/reprise/formulaire/dommages-gauche.png');
$domDosGauche : url('../../../../../resources/front/images/reprise/formulaire/dommages-dos-gauche.png');
$domDos : url('../../../../../resources/front/images/reprise/formulaire/dommages-dos.png');
$domFaceDroite : url('../../../../../resources/front/images/reprise/formulaire/dommages-face-droite.png');
$domDroite : url('../../../../../resources/front/images/reprise/formulaire/dommages-droite.png');
$domDosDroite : url('../../../../../resources/front/images/reprise/formulaire/dommages-dos-droite.png');


#reprise{
  .voiture-3d {
    height: 400px;
    margin-left: -45px;
    margin-top: -50px;
    width: 700px;
    height: 400px;
    margin-left: calc(50% - 375px);
    margin-top: -50px;
    padding: 0;
    width: 700px;
    images {
      max-width: 100%;
    }
    > div {
      top: 0px;
      width: 735px;
      height: 400px;
    }
    .popover {
      z-index: 1000;
      .popover-content {
        padding: 1em 1em 0.5em;
        width: auto;
        .btn {
          margin-bottom: 0.5em;
        }
      }
      input[type="radio"] {
        display: none;
      }
      ul.list-unstyled {
        display: inline;
        margin-bottom: 0;

        li label {
          padding-left: 20px;
        }
      }
    }
    @media only screen {
      @media(max-width: $screen-sm-max) {
        zoom: 0.9;
        .popover {
          zoom: 1.1;
        }
        @media(max-width: 625px) {
          zoom: 0.80;
          .popover {
            zoom: 1.2;
          }
          @media(max-width: 575px) {
            zoom: 0.70;
            .popover {
              zoom: 1.3;
            }
            @media(max-width: 500px) {
              zoom: 0.60;
              .popover {
                zoom: 1.4;
              }
              @media(max-width: 450px) {
                zoom: 0.50;
                .popover {
                  zoom: 1.5;
                }
                @media(max-width: 375px) {
                  zoom: 0.40;
                  .popover {
                    zoom: 1.6;
                  }
                  @media(max-width: 300px) {
                    zoom: 0.30;
                    .popover {
                      zoom: 1.7;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ul {
    margin-bottom: 16px;
    width: 100%;

    li {
      list-style: none;
      a {
        transition: 0s !important;
      }
      .filters {
        display: none;

      }
    }

  }
}

#result-dommages{
  margin-top: 2em;
  div {
    cursor: pointer;
    padding-bottom: 0.5em;
  }
  input[type="checkbox"], .popover input[type="radio"] {
    vertical-align: middle;
    margin-top: -2px;
    margin-right: 5px;
  }
}

.item a {
  outline: none !important;
}

.containTableauEtatReprise{
  .containTablesAspectGeneral, .containTablesAutrePoints {
    @include shadowBox(0.3);
    padding: 0;

    @media only screen {
      @media(max-width: $screen-md-max) {
        table {
          &:first-child {
            tfoot {
              display: none;
            }
          }
        }
      }
    }

    .tableGreyWhite {
      > tbody {
        > tr {
          > td, th {
            width: 20%;
            @media only screen {
              @media(max-width: $screen-sm-max) {
                width: 25%;
              }
            }
            &:first-child {
              width: 60%;
              @media only screen {
                @media(max-width: $screen-sm-max) {
                  width: 50%;
                }
              }
            }
          }
        }
      }
      > tfoot {
        tr {
          &:first-child {
            td {
              font-size: 2em;
              padding: 0.25em 0;
              i {
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}


/* Images de fond*/
#voiture-face {
  background-image: $domFace;
}

#voiture-face-gauche {
  background-image: $domFaceGauche;
}

#voiture-gauche {
  background-image: $domGauche;
}

#voiture-dos-gauche {
  background-image: $domDosGauche;
}

#voiture-dos {
  background-image: $domDos;
}

#voiture-face-droite {
  background-image: $domFaceDroite;
}

#voiture-droite {
  background-image: $domDroite;
}

#voiture-dos-droite {
  background-image: $domDosDroite;
}

/* VOITURE FACE */

/* CAPOT */
#voiture-face .capot {
  left: 221px;
  top: 177px;
  position: absolute;
}

#voiture-face .capot a, #voiture-face .capot.selected, #voiture-face ul li.capot {
  width: 290px;
  height: 58px;
  position: absolute;
  z-index: 1;
}

#voiture-face .capot a:hover {
  background: $domFace 0 -500px no-repeat;
}

#voiture-face .capot.selected-rep, #voiture-face .capot.selected-rep a:hover {
  background: $domFace -336px -500px no-repeat;
}

#voiture-face .capot.selected-remp, #voiture-face .capot.selected-remp a:hover {
  background: $domFace -671px -500px no-repeat;
}

/* PARECHOCAVANT */
#voiture-face .pare-choc-avant {
  left: 204px;
  top: 230px;
  position: absolute;
}

#voiture-face .pare-choc-avant a, #voiture-face .pare-choc-avant.selected, #voiture-face ul li.pare-choc-avant {
  width: 325px;
  height: 113px;
  position: absolute;
  z-index: 0;
}

#voiture-face .pare-choc-avant a:hover {
  background: $domFace -1px -610px no-repeat;
}

#voiture-face .pare-choc-avant.selected-rep, #voiture-face .pare-choc-avant.selected-rep a:hover {
  background: $domFace -337px -610px no-repeat;
}

#voiture-face .pare-choc-avant.selected-remp, #voiture-face .pare-choc-avant.selected-remp a:hover {
  background: $domFace -672px -610px no-repeat;
}

/* CALANDRE */
#voiture-face .calandre {
  left: 277px;
  top: 225px;
  position: absolute;
}

#voiture-face .calandre a, #voiture-face .calandre.selected, #voiture-face ul li.calandre {
  width: 178px;
  height: 48px;
  position: absolute;
  z-index: 2;
}

#voiture-face .calandre a:hover {
  background: $domFace -0px -560px no-repeat;
}

#voiture-face .calandre.selected-rep, #voiture-face .calandre.selected-rep a:hover {
  background: $domFace -336px -560px no-repeat;
}

#voiture-face .calandre.selected-remp, #voiture-face .calandre.selected-remp a:hover {
  background: $domFace -671px -560px no-repeat;
}

/* PAVILLON */
#voiture-face .pavillon {
  left: 276px;
  top: 103px;
  position: absolute;
}

#voiture-face .pavillon a, #voiture-face .pavillon.selected, #voiture-face ul li.pavillon {
  width: 180px;
  height: 20px;
  position: absolute;
  z-index: 2;
}

#voiture-face .pavillon a:hover {
  background: $domFace -0px -478px no-repeat;
}

#voiture-face .pavillon.selected-rep, #voiture-face .pavillon.selected-rep a:hover {
  background: $domFace -336px -478px no-repeat;
}

#voiture-face .pavillon.selected-remp, #voiture-face .pavillon.selected-remp a:hover {
  background: $domFace -671px -478px no-repeat;
}

/* PARE-BRISE */
#voiture-face .pare-brise {
  left: 243px;
  top: 113px;
  position: absolute;
}

#voiture-face .pare-brise a, #voiture-face .pare-brise.selected, #voiture-face ul li.pare-brise {
  width: 246px;
  height: 70px;
  position: absolute;
  z-index: 0;
}

#voiture-face .pare-brise a:hover {
  background: $domFace -0px -400px no-repeat;
}

#voiture-face .pare-brise.selected-rep, #voiture-face .pare-brise.selected-rep a:hover {
  background: $domFace -336px -400px no-repeat;
}

#voiture-face .pare-brise.selected-remp, #voiture-face .pare-brise.selected-remp a:hover {
  background: $domFace -671px -400px no-repeat;
}

/* ESSUIE GLACE AVANT */
#voiture-face .essuie-glace-avant {
  left: 254px;
  top: 175px;
  position: absolute;
}

#voiture-face .essuie-glace-avant a, #voiture-face .essuie-glace-avant.selected, #voiture-face ul li.essuie-glace-avant {
  width: 222px;
  height: 8px;
  position: absolute;
  z-index: 2;
}

#voiture-face .essuie-glace-avant a:hover {
  background: $domFace -0px -470px no-repeat;
}

#voiture-face .essuie-glace-avant.selected-rep, #voiture-face .essuie-glace-avant.selected-rep a:hover {
  background: $domFace -336px -470px no-repeat;
}

#voiture-face .essuie-glace-avant.selected-remp, #voiture-face .essuie-glace-avant.selected-remp a:hover {
  background: $domFace -671px -470px no-repeat;
}

/* ANTI BROUILLARD AVANT DROIT */
#voiture-face .anti-brouillard-avant-droit {
  left: 217px;
  top: 302px;
  position: absolute;
}

#voiture-face .anti-brouillard-avant-droit a, #voiture-face .anti-brouillard-avant-droit.selected, #voiture-face ul li.anti-brouillard-avant-droit {
  width: 24px;
  height: 24px;
  position: absolute;
  z-index: 2;
}

#voiture-face .anti-brouillard-avant-droit a:hover {
  background: $domFace -1px -753px no-repeat;
}

#voiture-face .anti-brouillard-avant-droit.selected-rep, #voiture-face .anti-brouillard-avant-droit.selected-rep a:hover {
  background: $domFace -337px -753px no-repeat;
}

#voiture-face .anti-brouillard-avant-droit.selected-remp, #voiture-face .anti-brouillard-avant-droit.selected-remp a:hover {
  background: $domFace -672px -753px no-repeat;
}

/* ANTI BROUILLARD AVANT GAUCHE */
#voiture-face .anti-brouillard-avant-gauche {
  left: 493px;
  top: 302px;
  position: absolute;
}

#voiture-face .anti-brouillard-avant-gauche a, #voiture-face .anti-brouillard-avant-gauche.selected, #voiture-face ul li.anti-brouillard-avant-gauche {
  width: 24px;
  height: 24px;
  position: absolute;
  z-index: 2;
}

#voiture-face .anti-brouillard-avant-gauche a:hover {
  background: $domFace -1px -753px no-repeat;
}

#voiture-face .anti-brouillard-avant-gauche.selected-rep, #voiture-face .anti-brouillard-avant-gauche.selected-rep a:hover {
  background: $domFace -337px -753px no-repeat;
}

#voiture-face .anti-brouillard-avant-gauche.selected-remp, #voiture-face .anti-brouillard-avant-gauche.selected-remp a:hover {
  background: $domFace -672px -753px no-repeat;
}

/* PHARE AVANT GAUCHE */
#voiture-face .phare-avant-gauche {
  left: 459px;
  top: 219px;
  position: absolute;
}

#voiture-face .phare-avant-gauche a, #voiture-face .phare-avant-gauche.selected, #voiture-face ul li.phare-avant-gauche {
  width: 58px;
  height: 51px;
  position: absolute;
  z-index: 1;
}

#voiture-face .phare-avant-gauche a:hover {
  background: $domFace -0px -855px no-repeat;
}

#voiture-face .phare-avant-gauche.selected-rep, #voiture-face .phare-avant-gauche.selected-rep a:hover {
  background: $domFace -336px -855px no-repeat;
}

#voiture-face .phare-avant-gauche.selected-remp, #voiture-face .phare-avant-gauche.selected-remp a:hover {
  background: $domFace -671px -855px no-repeat;
}

/* PHARE AVANT DROIT */
#voiture-face .phare-avant-droit {
  left: 215px;
  top: 219px;
  position: absolute;
}

#voiture-face .phare-avant-droit a, #voiture-face .phare-avant-droit.selected-rep, #voiture-face .phare-avant-droit.selected-remp, #voiture-face ul li.phare-avant-droit {
  width: 58px;
  height: 51px;
  position: absolute;
  z-index: 1;
}

#voiture-face .phare-avant-droit a:hover {
  background: $domFace -1px -804px no-repeat;
}

#voiture-face .phare-avant-droit.selected-rep, #voiture-face .phare-avant-droit.selected-rep a:hover {
  background: $domFace -337px -804px no-repeat;
}

#voiture-face .phare-avant-droit.selected-remp, #voiture-face .phare-avant-droit.selected-remp a:hover {
  background: $domFace -672px -804px no-repeat;
}

/* RETROVISEUR DROIT */
#voiture-face .retro-droit {
  left: 203px;
  top: 160px;
  position: absolute;
}

#voiture-face .retro-droit a, #voiture-face .retro-droit.selected, #voiture-face ul li.retro-droit {
  width: 36px;
  height: 26px;
  position: absolute;
  z-index: 1;
}

#voiture-face .retro-droit a:hover {
  background: $domFace -0px -908px no-repeat;
}

#voiture-face .retro-droit.selected-rep, #voiture-face .retro-droit.selected-rep a:hover {
  background: $domFace -336px -908px no-repeat;
}

#voiture-face .retro-droit.selected-remp, #voiture-face .retro-droit.selected-remp a:hover {
  background: $domFace -671px -908px no-repeat;
}

/* RETROVISEUR GAUCHE */
#voiture-face .retro-gauche {
  left: 493px;
  top: 160px;
  position: absolute;
}

#voiture-face .retro-gauche a, #voiture-face .retro-gauche.selected, #voiture-face ul li.retro-gauche {
  width: 37px;
  height: 26px;
  position: absolute;
  z-index: 1;
}

#voiture-face .retro-gauche a:hover {
  background: $domFace 1px -935px no-repeat;
}

#voiture-face .retro-gauche.selected-rep, #voiture-face .retro-gauche.selected-rep a:hover {
  background: $domFace -334px -935px no-repeat;
}

#voiture-face .retro-gauche.selected-remp, #voiture-face .retro-gauche.selected-remp a:hover {
  background: $domFace -669px -935px no-repeat;
}

/* PNEU AVANT DROIT */
#voiture-face .pneu-avant-droit {
  left: 203px;
  top: 315px;
  position: absolute;
}

#voiture-face .pneu-avant-droit a, #voiture-face .pneu-avant-droit.selected, #voiture-face ul li.pneu-avant-droit {
  width: 49px;
  height: 42px;
  position: absolute;
  z-index: 1;
}

#voiture-face .pneu-avant-droit a:hover {
  background: $domFace 1px -969px no-repeat;
}

#voiture-face .pneu-avant-droit.selected-rep, #voiture-face .pneu-avant-droit.selected-rep a:hover {
  background: $domFace -334px -969px no-repeat;
}

#voiture-face .pneu-avant-droit.selected-remp, #voiture-face .pneu-avant-droit.selected-remp a:hover {
  background: $domFace -669px -969px no-repeat;
}

/* PNEU AVANT GAUCHE */
#voiture-face .pneu-avant-gauche {
  left: 479px;
  top: 315px;
  position: absolute;
}

#voiture-face .pneu-avant-gauche a, #voiture-face .pneu-avant-gauche.selected, #voiture-face ul li.pneu-avant-gauche {
  width: 49px;
  height: 42px;
  position: absolute;
  z-index: 1;
}

#voiture-face .pneu-avant-gauche a:hover {
  background: $domFace 1px -1018px no-repeat;
}

#voiture-face .pneu-avant-gauche.selected-rep, #voiture-face .pneu-avant-gauche.selected-rep a:hover {
  background: $domFace -334px -1018px no-repeat;
}

#voiture-face .pneu-avant-gauche.selected-remp, #voiture-face .pneu-avant-gauche.selected-remp a:hover {
  background: $domFace -669px -1018px no-repeat;
}

/* AILE AVANT GAUCHE */
#voiture-face .aile-avant-gauche {
  left: 505px;
  top: 209px;
  position: absolute;
}

#voiture-face .aile-avant-gauche a, #voiture-face .aile-avant-gauche.selected, #voiture-face ul li.aile-avant-gauche {
  width: 23px;
  height: 39px;
  position: absolute;
  z-index: 1;
}

#voiture-face .aile-avant-gauche a:hover {
  background: $domFace -1px -1060px no-repeat;
}

#voiture-face .aile-avant-gauche.selected-rep, #voiture-face .aile-avant-gauche.selected-rep a:hover {
  background: $domFace -337px -1060px no-repeat;
}

#voiture-face .aile-avant-gauche.selected-remp, #voiture-face .aile-avant-gauche.selected-remp a:hover {
  background: $domFace -672px -1060px no-repeat;
}

/* AILE AVANT DROITE */
#voiture-face .aile-avant-droite {
  left: 205px;
  top: 209px;
  position: absolute;
}

#voiture-face .aile-avant-droite a, #voiture-face .aile-avant-droite.selected, #voiture-face ul li.aile-avant-droite {
  width: 23px;
  height: 39px;
  position: absolute;
  z-index: 1;
}

#voiture-face .aile-avant-droite a:hover {
  background: $domFace -1px -1102px no-repeat;
}

#voiture-face .aile-avant-droite.selected-rep, #voiture-face .aile-avant-droite.selected-rep a:hover {
  background: $domFace -337px -1102px no-repeat;
}

#voiture-face .aile-avant-droite.selected-remp, #voiture-face .aile-avant-droite.selected-remp a:hover {
  background: $domFace -672px -1102px no-repeat;
}

/* AILE ARRIERE GAUCHE */
#voiture-face .aile-arriere-gauche {
  left: 452px;
  top: 112px;
  position: absolute;
}

#voiture-face .aile-arriere-gauche a, #voiture-face .aile-arriere-gauche.selected, #voiture-face ul li.aile-arriere-gauche {
  width: 43px;
  height: 69px;
  position: absolute;
  z-index: 1;
}

#voiture-face .aile-arriere-gauche a:hover {
  background: $domFace 1px -1212px no-repeat;
}

#voiture-face .aile-arriere-gauche.selected-rep, #voiture-face .aile-arriere-gauche.selected-rep a:hover {
  background: $domFace -335px -1212px no-repeat;
}

#voiture-face .aile-arriere-gauche.selected-remp, #voiture-face .aile-arriere-gauche.selected-remp a:hover {
  background: $domFace -670px -1212px no-repeat;
}

/* AILE ARRIERE DROITE */
#voiture-face .aile-arriere-droite {
  left: 237px;
  top: 112px;
  position: absolute;
}

#voiture-face .aile-arriere-droite a, #voiture-face .aile-arriere-droite.selected, #voiture-face ul li.aile-arriere-droite {
  width: 43px;
  height: 69px;
  position: absolute;
  z-index: 1;
}

#voiture-face .aile-arriere-droite a:hover {
  background: $domFace 1px -1143px no-repeat;
}

#voiture-face .aile-arriere-droite.selected-rep, #voiture-face .aile-arriere-droite.selected-rep a:hover {
  background: $domFace -335px -1143px no-repeat;
}

#voiture-face .aile-arriere-droite.selected-remp, #voiture-face .aile-arriere-droite.selected-remp a:hover {
  background: $domFace -670px -1143px no-repeat;
}

/* PLAQUE IMMATRICULATION */
#voiture-face .plaque-immatriculation {
  left: 310px;
  top: 280px;
  position: absolute;
}

#voiture-face .plaque-immatriculation a {
  width: 110px;
  height: 25px;
  position: absolute;
  z-index: 1;
  cursor: default;
}

/* VOITURE FACE GAUCHE */

/* PARE CHOC AVANT */
#voiture-face-gauche .pare-choc-avant {
  left: 68px;
  top: 215px;
  position: absolute;
}

#voiture-face-gauche .pare-choc-avant a, #voiture-face-gauche .pare-choc-avant.selected, #voiture-face-gauche ul li.pare-choc-avant {
  width: 250px;
  height: 118px;
  position: absolute;
  z-index: 1;
}

#voiture-face-gauche .pare-choc-avant a:hover {
  background: $domFaceGauche -0px -402px no-repeat;
}

#voiture-face-gauche .pare-choc-avant.selected-rep, #voiture-face-gauche .pare-choc-avant.selected-rep a:hover {
  background: $domFaceGauche -336px -402px no-repeat;
}

#voiture-face-gauche .pare-choc-avant.selected-remp, #voiture-face-gauche .pare-choc-avant.selected-remp a:hover {
  background: $domFaceGauche -686px -402px no-repeat;
}

/* PNEU ARRIERE DROIT */
#voiture-face-gauche .pneu-arriere-gauche {
  left: 545px;
  top: 224px;
  position: absolute;
}

#voiture-face-gauche .pneu-arriere-gauche a, #voiture-face-gauche .pneu-arriere-gauche.selected, #voiture-face-gauche ul li.pneu-arriere-gauche {
  width: 68px;
  height: 85px;
  position: absolute;
  z-index: 2;
}

#voiture-face-gauche .pneu-arriere-gauche a:hover {
  background: $domFaceGauche -1px -637px no-repeat;
}

#voiture-face-gauche .pneu-arriere-gauche.selected-rep, #voiture-face-gauche .pneu-arriere-gauche.selected-rep a:hover {
  background: $domFaceGauche -336px -637px no-repeat;
}

#voiture-face-gauche .pneu-arriere-gauche.selected-remp, #voiture-face-gauche .pneu-arriere-gauche.selected-remp a:hover {
  background: $domFaceGauche -686px -637px no-repeat;
}

/* PNEU AVANT GAUCHE */
#voiture-face-gauche .pneu-avant-gauche {
  left: 285px;
  top: 248px;
  position: absolute;
}

#voiture-face-gauche .pneu-avant-gauche a, #voiture-face-gauche .pneu-avant-gauche.selected, #voiture-face-gauche ul li.pneu-avant-gauche {
  width: 95px;
  height: 112px;
  position: absolute;
  z-index: 4;
}

#voiture-face-gauche .pneu-avant-gauche a:hover {
  background: $domFaceGauche -1px -522px no-repeat;
}

#voiture-face-gauche .pneu-avant-gauche.selected-rep, #voiture-face-gauche .pneu-avant-gauche.selected-rep a:hover {
  background: $domFaceGauche -336px -522px no-repeat;
}

#voiture-face-gauche .pneu-avant-gauche.selected-remp, #voiture-face-gauche .pneu-avant-gauche.selected-remp a:hover {
  background: $domFaceGauche -686px -522px no-repeat;
}

/* JANTE ARRIERE DROIT */
#voiture-face-gauche .jante-arriere-gauche {
  left: 570px;
  top: 236px;
  position: absolute;
}

#voiture-face-gauche .jante-arriere-gauche a, #voiture-face-gauche .jante-arriere-gauche.selected, #voiture-face-gauche ul li.jante-arriere-gauche {
  width: 35px;
  height: 60px;
  position: absolute;
  z-index: 2;
}

#voiture-face-gauche .jante-arriere-gauche a:hover {
  background: $domFaceGauche -77px -649px no-repeat;
}

#voiture-face-gauche .jante-arriere-gauche.selected-rep, #voiture-face-gauche .jante-arriere-gauche.selected-rep a:hover {
  background: $domFaceGauche -412px -649px no-repeat;
}

#voiture-face-gauche .jante-arriere-gauche.selected-remp, #voiture-face-gauche .jante-arriere-gauche.selected-remp a:hover {
  background: $domFaceGauche -762px -649px no-repeat;
}

/* JANTE AVANT GAUCHE */
#voiture-face-gauche .jante-avant-gauche {
  left: 314px;
  top: 264px;
  position: absolute;
}

#voiture-face-gauche .jante-avant-gauche a, #voiture-face-gauche .jante-avant-gauche.selected, #voiture-face-gauche ul li.jante-avant-gauche {
  width: 54px;
  height: 79px;
  position: absolute;
  z-index: 4;
}

#voiture-face-gauche .jante-avant-gauche a:hover {
  background: $domFaceGauche -105px -538px no-repeat;
}

#voiture-face-gauche .jante-avant-gauche.selected-rep, #voiture-face-gauche .jante-avant-gauche.selected-rep a:hover {
  background: $domFaceGauche -442px -538px no-repeat;
}

#voiture-face-gauche .jante-avant-gauche.selected-remp, #voiture-face-gauche .jante-avant-gauche.selected-remp a:hover {
  background: $domFaceGauche -792px -538px no-repeat;
}

/* PNEU AVANT DROIT */
#voiture-face-gauche .pneu-avant-droit {
  left: 130px;
  top: 325px;
  position: absolute;
}

#voiture-face-gauche .pneu-avant-droit a, #voiture-face-gauche .pneu-avant-droit.selected, #voiture-face-gauche ul li.pneu-avant-droit {
  width: 23px;
  height: 8px;
  position: absolute;
  z-index: 2;
}

#voiture-face-gauche .pneu-avant-droit a:hover {
  background: $domFaceGauche -1px -726px no-repeat;
}

#voiture-face-gauche .pneu-avant-droit.selected-rep, #voiture-face-gauche .pneu-avant-droit.selected-rep a:hover {
  background: $domFaceGauche -337px -726px no-repeat;
}

#voiture-face-gauche .pneu-avant-droit.selected-remp, #voiture-face-gauche .pneu-avant-droit.selected-remp a:hover {
  background: $domFaceGauche -686px -726px no-repeat;
}

/* CALANDRE */
#voiture-face-gauche .calandre {
  left: 82px;
  top: 216px;
  position: absolute;
}

#voiture-face-gauche .calandre a, #voiture-face-gauche .calandre.selected, #voiture-face-gauche ul li.calandre {
  width: 114px;
  height: 49px;
  position: absolute;
  z-index: 2;
}

#voiture-face-gauche .calandre a:hover {
  background: $domFaceGauche -0px -734px no-repeat;
}

#voiture-face-gauche .calandre.selected-rep, #voiture-face-gauche .calandre.selected-rep a:hover {
  background: $domFaceGauche -336px -734px no-repeat;
}

#voiture-face-gauche .calandre.selected-remp, #voiture-face-gauche .calandre.selected-remp a:hover {
  background: $domFaceGauche -686px -734px no-repeat;
}

/* AILE AVANT GAUCHE */
#voiture-face-gauche .aile-avant-gauche {
  left: 283px;
  top: 192px;
  position: absolute;
}

#voiture-face-gauche .aile-avant-gauche a, #voiture-face-gauche .aile-avant-gauche.selected, #voiture-face-gauche ul li.aile-avant-gauche {
  width: 115px;
  height: 116px;
  position: absolute;
  z-index: 3;
}

#voiture-face-gauche .aile-avant-gauche a:hover {
  background: $domFaceGauche -1px -787px no-repeat;
}

#voiture-face-gauche .aile-avant-gauche.selected-rep, #voiture-face-gauche .aile-avant-gauche.selected-rep a:hover {
  background: $domFaceGauche -337px -787px no-repeat;
}

#voiture-face-gauche .aile-avant-gauche.selected-remp, #voiture-face-gauche .aile-avant-gauche.selected-remp a:hover {
  background: $domFaceGauche -685px -787px no-repeat;
}

/* SOUBASSEMENT GAUCHE */
#voiture-face-gauche .soubassement-gauche {
  left: 383px;
  top: 262px;
  position: absolute;
}

#voiture-face-gauche .soubassement-gauche a, #voiture-face-gauche .soubassement-gauche.selected, #voiture-face-gauche ul li.soubassement-gauche {
  width: 177px;
  height: 57px;
  position: absolute;
  z-index: 2;
}

#voiture-face-gauche .soubassement-gauche a:hover {
  background: $domFaceGauche -1px -906px no-repeat;
}

#voiture-face-gauche .soubassement-gauche.selected-rep, #voiture-face-gauche .soubassement-gauche.selected-rep a:hover {
  background: $domFaceGauche -336px -906px no-repeat;
}

#voiture-face-gauche .soubassement-gauche.selected-remp, #voiture-face-gauche .soubassement-gauche.selected-remp a:hover {
  background: $domFaceGauche -685px -906px no-repeat;
}

/* PHARE AVANT GAUCHE */
#voiture-face-gauche .phare-avant-gauche {
  left: 193px;
  top: 216px;
  position: absolute;
}

#voiture-face-gauche .phare-avant-gauche a, #voiture-face-gauche .phare-avant-gauche.selected, #voiture-face-gauche ul li.phare-avant-gauche {
  width: 95px;
  height: 45px;
  position: absolute;
  z-index: 3;
}

#voiture-face-gauche .phare-avant-gauche a:hover {
  background: $domFaceGauche -1px -966px no-repeat;
}

#voiture-face-gauche .phare-avant-gauche.selected-rep, #voiture-face-gauche .phare-avant-gauche.selected-rep a:hover {
  background: $domFaceGauche -336px -966px no-repeat;
}

#voiture-face-gauche .phare-avant-gauche.selected-remp, #voiture-face-gauche .phare-avant-gauche.selected-remp a:hover {
  background: $domFaceGauche -686px -966px no-repeat;
}

/* ANTI BROUILLARD AVANT GAUCHE */
#voiture-face-gauche .anti-brouillard-avant-gauche {
  left: 237px;
  top: 298px;
  position: absolute;
}

#voiture-face-gauche .anti-brouillard-avant-gauche a, #voiture-face-gauche .anti-brouillard-avant-gauche.selected, #voiture-face-gauche ul li.anti-brouillard-avant-gauche {
  width: 23px;
  height: 26px;
  position: absolute;
  z-index: 2;
}

#voiture-face-gauche .anti-brouillard-avant-gauche a:hover {
  background: $domFaceGauche -0px -1014px no-repeat;
}

#voiture-face-gauche .anti-brouillard-avant-gauche.selected-rep, #voiture-face-gauche .anti-brouillard-avant-gauche.selected-rep a:hover {
  background: $domFaceGauche -336px -1014px no-repeat;
}

#voiture-face-gauche .anti-brouillard-avant-gauche.selected-remp, #voiture-face-gauche .anti-brouillard-avant-gauche.selected-remp a:hover {
  background: $domFaceGauche -686px -1014px no-repeat;
}

/* CAPOT */
#voiture-face-gauche .capot {
  left: 85px;
  top: 176px;
  position: absolute;
}

#voiture-face-gauche .capot a, #voiture-face-gauche .capot.selected, #voiture-face-gauche ul li.capot {
  width: 300px;
  height: 56px;
  position: absolute;
  z-index: 1;
}

#voiture-face-gauche .capot a:hover {
  background: $domFaceGauche -0px -1044px no-repeat;
}

#voiture-face-gauche .capot.selected-rep, #voiture-face-gauche .capot.selected-rep a:hover {
  background: $domFaceGauche -336px -1044px no-repeat;
}

#voiture-face-gauche .capot.selected-remp, #voiture-face-gauche .capot.selected-remp a:hover {
  background: $domFaceGauche -685px -1044px no-repeat;
}

/* PARE BRISE */
#voiture-face-gauche .pare-brise {
  left: 190px;
  top: 114px;
  position: absolute;
}

#voiture-face-gauche .pare-brise a, #voiture-face-gauche .pare-brise.selected, #voiture-face-gauche ul li.pare-brise {
  width: 226px;
  height: 68px;
  position: absolute;
  z-index: 1;
}

#voiture-face-gauche .pare-brise a:hover {
  background: $domFaceGauche -5px -1103px no-repeat;
}

#voiture-face-gauche .pare-brise.selected-rep, #voiture-face-gauche .pare-brise.selected-rep a:hover {
  background: $domFaceGauche -340px -1103px no-repeat;
}

#voiture-face-gauche .pare-brise.selected-remp, #voiture-face-gauche .pare-brise.selected-remp a:hover {
  background: $domFaceGauche -691px -1103px no-repeat;
}

/* ESSUIE GLACE AVANT */
#voiture-face-gauche .essuie-glace-avant {
  left: 194px;
  top: 172px;
  position: absolute;
}

#voiture-face-gauche .essuie-glace-avant a, #voiture-face-gauche .essuie-glace-avant.selected, #voiture-face-gauche ul li.essuie-glace-avant {
  width: 160px;
  height: 10px;
  position: absolute;
  z-index: 1;
}

#voiture-face-gauche .essuie-glace-avant a:hover {
  background: $domFaceGauche -0px -1172px no-repeat;
}

#voiture-face-gauche .essuie-glace-avant.selected-rep, #voiture-face-gauche .essuie-glace-avant.selected-rep a:hover {
  background: $domFaceGauche -336px -1172px no-repeat;
}

#voiture-face-gauche .essuie-glace-avant.selected-remp, #voiture-face-gauche .essuie-glace-avant.selected-remp a:hover {
  background: $domFaceGauche -683px -1172px no-repeat;
}

/* RETROVISEUR GAUCHE */
#voiture-face-gauche .retro-gauche {
  left: 391px;
  top: 157px;
  position: absolute;
}

#voiture-face-gauche .retro-gauche a, #voiture-face-gauche .retro-gauche.selected, #voiture-face-gauche ul li.retro-gauche {
  width: 52px;
  height: 31px;
  position: absolute;
  z-index: 1;
}

#voiture-face-gauche .retro-gauche a:hover {
  background: $domFaceGauche -0px -1183px no-repeat;
}

#voiture-face-gauche .retro-gauche.selected-rep, #voiture-face-gauche .retro-gauche.selected-rep a:hover {
  background: $domFaceGauche -336px -1183px no-repeat;
}

#voiture-face-gauche .retro-gauche.selected-remp, #voiture-face-gauche .retro-gauche.selected-remp a:hover {
  background: $domFaceGauche -686px -1183px no-repeat;
}

/* RETROVISEUR DROIT */
#voiture-face-gauche .retro-droit {
  left: 200px;
  top: 156px;
  position: absolute;
}

#voiture-face-gauche .retro-droit a, #voiture-face-gauche .retro-droit.selected, #voiture-face-gauche ul li.retro-droit {
  width: 20px;
  height: 12px;
  position: absolute;
  z-index: 1;
}

#voiture-face-gauche .retro-droit a:hover {
  background: $domFaceGauche -0px -1217px no-repeat;
}

#voiture-face-gauche .retro-droit.selected-rep, #voiture-face-gauche .retro-droit.selected-rep a:hover {
  background: $domFaceGauche -335px -1217px no-repeat;
}

#voiture-face-gauche .retro-droit.selected-remp, #voiture-face-gauche .retro-droit.selected-remp a:hover {
  background: $domFaceGauche -685px -1217px no-repeat;
}

/* PAVILLON */
#voiture-face-gauche .pavillon {
  left: 276px;
  top: 102px;
  position: absolute;
}

#voiture-face-gauche .pavillon a, #voiture-face-gauche .pavillon.selected, #voiture-face-gauche ul li.pavillon {
  width: 196px;
  height: 18px;
  position: absolute;
  z-index: 1;
}

#voiture-face-gauche .pavillon a:hover {
  background: $domFaceGauche -5px -1234px no-repeat;
}

#voiture-face-gauche .pavillon.selected-rep, #voiture-face-gauche .pavillon.selected-rep a:hover {
  background: $domFaceGauche -341px -1234px no-repeat;
}

#voiture-face-gauche .pavillon.selected-remp, #voiture-face-gauche .pavillon.selected-remp a:hover {
  background: $domFaceGauche -691px -1234px no-repeat;
}

/* AILE ARRIERE GAUCHE */
#voiture-face-gauche .aile-arriere-gauche {
  left: 368px;
  top: 103px;
  position: absolute;
}

#voiture-face-gauche .aile-arriere-gauche a, #voiture-face-gauche .aile-arriere-gauche.selected, #voiture-face-gauche ul li.aile-arriere-gauche {
  width: 236px;
  height: 162px;
  position: absolute;
  z-index: 0;
}

#voiture-face-gauche .aile-arriere-gauche a:hover {
  background: $domFaceGauche -1px -1261px no-repeat;
}

#voiture-face-gauche .aile-arriere-gauche.selected-rep, #voiture-face-gauche .aile-arriere-gauche.selected-rep a:hover {
  background: $domFaceGauche -336px -1261px no-repeat;
}

#voiture-face-gauche .aile-arriere-gauche.selected-remp, #voiture-face-gauche .aile-arriere-gauche.selected-remp a:hover {
  background: $domFaceGauche -686px -1261px no-repeat;
}

/* PARE CHOC ARRIERE */
#voiture-face-gauche .pare-choc-arriere {
  left: 600px;
  top: 189px;
  position: absolute;
}

#voiture-face-gauche .pare-choc-arriere a, #voiture-face-gauche .pare-choc-arriere.selected, #voiture-face-gauche ul li.pare-choc-arriere {
  width: 12px;
  height: 50px;
  position: absolute;
  z-index: 1;
}

#voiture-face-gauche .pare-choc-arriere a:hover {
  background: $domFaceGauche -1px -1427px no-repeat;
}

#voiture-face-gauche .pare-choc-arriere.selected-rep, #voiture-face-gauche .pare-choc-arriere.selected-rep a:hover {
  background: $domFaceGauche -336px -1427px no-repeat;
}

#voiture-face-gauche .pare-choc-arriere.selected-remp, #voiture-face-gauche .pare-choc-arriere.selected-remp a:hover {
  background: $domFaceGauche -686px -1427px no-repeat;
}

/* PORTE AVANT GAUCHE */
#voiture-face-gauche .porte-avant-gauche {
  left: 383px;
  top: 108px;
  position: absolute;
}

#voiture-face-gauche .porte-avant-gauche a, #voiture-face-gauche .porte-avant-gauche.selected, #voiture-face-gauche ul li.porte-avant-gauche {
  width: 116px;
  height: 197px;
  position: absolute;
  z-index: 0;
}

#voiture-face-gauche .porte-avant-gauche a:hover {
  background: $domFaceGauche -1px -1479px no-repeat;
}

#voiture-face-gauche .porte-avant-gauche.selected-rep, #voiture-face-gauche .porte-avant-gauche.selected-rep a:hover {
  background: $domFaceGauche -336px -1479px no-repeat;
}

#voiture-face-gauche .porte-avant-gauche.selected-remp, #voiture-face-gauche .porte-avant-gauche.selected-remp a:hover {
  background: $domFaceGauche -686px -1479px no-repeat;
}

/* PORTE ARRIERE GAUCHE */
#voiture-face-gauche .porte-arriere-gauche {
  left: 473px;
  top: 108px;
  position: absolute;
}

#voiture-face-gauche .porte-arriere-gauche a, #voiture-face-gauche .porte-arriere-gauche.selected, #voiture-face-gauche ul li.porte-arriere-gauche {
  width: 97px;
  height: 178px;
  position: absolute;
  z-index: 0;
}

#voiture-face-gauche .porte-arriere-gauche a:hover {
  background: $domFaceGauche -2px -1678px no-repeat;
}

#voiture-face-gauche .porte-arriere-gauche.selected-rep, #voiture-face-gauche .porte-arriere-gauche.selected-rep a:hover {
  background: $domFaceGauche -338px -1678px no-repeat;
}

#voiture-face-gauche .porte-arriere-gauche.selected-remp, #voiture-face-gauche .porte-arriere-gauche.selected-remp a:hover {
  background: $domFaceGauche -688px -1678px no-repeat;
}

/* VITRE AVANT GAUCHE */
#voiture-face-gauche .vitre-avant-gauche {
  left: 399px;
  top: 115px;
  position: absolute;
}

#voiture-face-gauche .vitre-avant-gauche a, #voiture-face-gauche .vitre-avant-gauche.selected, #voiture-face-gauche ul li.vitre-avant-gauche {
  width: 87px;
  height: 65px;
  position: absolute;
  z-index: 0;
}

#voiture-face-gauche .vitre-avant-gauche a:hover {
  background: $domFaceGauche 1px -1860px no-repeat;
}

#voiture-face-gauche .vitre-avant-gauche.selected-rep, #voiture-face-gauche .vitre-avant-gauche.selected-rep a:hover {
  background: $domFaceGauche -335px -1861px no-repeat;
}

#voiture-face-gauche .vitre-avant-gauche.selected-remp, #voiture-face-gauche .vitre-avant-gauche.selected-remp a:hover {
  background: $domFaceGauche -685px -1861px no-repeat;
}

/* VITRE ARRIERE GAUCHE */
#voiture-face-gauche .vitre-arriere-gauche {
  left: 481px;
  top: 114px;
  position: absolute;
}

#voiture-face-gauche .vitre-arriere-gauche a, #voiture-face-gauche .vitre-arriere-gauche.selected, #voiture-face-gauche ul li.vitre-arriere-gauche {
  width: 77px;
  height: 57px;
  position: absolute;
  z-index: 0;
}

#voiture-face-gauche .vitre-arriere-gauche a:hover {
  background: $domFaceGauche -0px -1931px no-repeat;
}

#voiture-face-gauche .vitre-arriere-gauche.selected-rep, #voiture-face-gauche .vitre-arriere-gauche.selected-rep a:hover {
  background: $domFaceGauche -336px -1931px no-repeat;
}

#voiture-face-gauche .vitre-arriere-gauche.selected-remp, #voiture-face-gauche .vitre-arriere-gauche.selected-remp a:hover {
  background: $domFaceGauche -686px -1931px no-repeat;
}

/* PHARE ARRIERE GAUCHE */
#voiture-face-gauche .phare-arriere-gauche {
  left: 591px;
  top: 166px;
  position: absolute;
}

#voiture-face-gauche .phare-arriere-gauche a, #voiture-face-gauche .phare-arriere-gauche.selected, #voiture-face-gauche ul li.phare-arriere-gauche {
  width: 16px;
  height: 26px;
  position: absolute;
  z-index: 0;
}

#voiture-face-gauche .phare-arriere-gauche a:hover {
  background: $domFaceGauche -0px -1990px no-repeat;
}

#voiture-face-gauche .phare-arriere-gauche.selected-rep, #voiture-face-gauche .phare-arriere-gauche.selected-rep a:hover {
  background: $domFaceGauche -336px -1990px no-repeat;
}

#voiture-face-gauche .phare-arriere-gauche.selected-remp, #voiture-face-gauche .phare-arriere-gauche.selected-remp a:hover {
  background: $domFaceGauche -686px -1990px no-repeat;
}

/* PLAQUE IMMATRICULATION */
#voiture-face-gauche .plaque-immatriculation {
  left: 90px;
  top: 267px;
  position: absolute;
}

#voiture-face-gauche .plaque-immatriculation a {
  width: 60px;
  height: 25px;
  position: absolute;
  z-index: 4;
  cursor: default;
}

/* VOITURE GAUCHE */

/* PARECHOCAVANT */
#voiture-gauche .pare-choc-avant {
  left: 40px;
  top: 217px;
  position: absolute;
}

#voiture-gauche .pare-choc-avant a, #voiture-gauche .pare-choc-avant.selected, #voiture-gauche ul li.pare-choc-avant {
  width: 77px;
  height: 93px;
  position: absolute;
  z-index: 1;
}

#voiture-gauche .pare-choc-avant a:hover {
  background: $domGauche -0px -393px no-repeat;
}

#voiture-gauche .pare-choc-avant.selected-rep, #voiture-gauche .pare-choc-avant.selected-rep a:hover {
  background: $domGauche -336px -393px no-repeat;
}

#voiture-gauche .pare-choc-avant.selected-remp, #voiture-gauche .pare-choc-avant.selected-remp a:hover {
  background: $domGauche -673px -393px no-repeat;
}

/* AILE AVANT GAUCHE */
#voiture-gauche .aile-avant-gauche {
  left: 90px;
  top: 188px;
  position: absolute;
}

#voiture-gauche .aile-avant-gauche a, #voiture-gauche .aile-avant-gauche.selected, #voiture-gauche ul li.aile-avant-gauche {
  width: 147px;
  height: 114px;
  position: absolute;
  z-index: 1;
}

#voiture-gauche .aile-avant-gauche a:hover {
  background: $domGauche -0px -489px no-repeat;
}

#voiture-gauche .aile-avant-gauche.selected-rep, #voiture-gauche .aile-avant-gauche.selected-rep a:hover {
  background: $domGauche -336px -489px no-repeat;
}

#voiture-gauche .aile-avant-gauche.selected-remp, #voiture-gauche .aile-avant-gauche.selected-remp a:hover {
  background: $domGauche -673px -489px no-repeat;
}

/* CAPOT */
#voiture-gauche .capot {
  left: 62px;
  top: 176px;
  position: absolute;
}

#voiture-gauche .capot a, #voiture-gauche .capot.selected, #voiture-gauche ul li.capot {
  width: 168px;
  height: 44px;
  position: absolute;
  z-index: 2;
}

#voiture-gauche .capot a:hover {
  background: $domGauche -1px -608px no-repeat;
}

#voiture-gauche .capot.selected-rep, #voiture-gauche .capot.selected-rep a:hover {
  background: $domGauche -337px -608px no-repeat;
}

#voiture-gauche .capot.selected-remp, #voiture-gauche .capot.selected-remp a:hover {
  background: $domGauche -674px -608px no-repeat;
}

/* PNEU AVANT GAUCHE */
#voiture-gauche .pneu-avant-gauche {
  left: 105px;
  top: 242px;
  position: absolute;
}

#voiture-gauche .pneu-avant-gauche a, #voiture-gauche .pneu-avant-gauche.selected, #voiture-gauche ul li.pneu-avant-gauche {
  width: 101px;
  height: 102px;
  position: absolute;
  z-index: 2;
}

#voiture-gauche .pneu-avant-gauche a:hover {
  background: $domGauche -0px -655px no-repeat;
}

#voiture-gauche .pneu-avant-gauche.selected-rep, #voiture-gauche .pneu-avant-gauche.selected-rep a:hover {
  background: $domGauche -336px -655px no-repeat;
}

#voiture-gauche .pneu-avant-gauche.selected-remp, #voiture-gauche .pneu-avant-gauche.selected-remp a:hover {
  background: $domGauche -673px -655px no-repeat;
}

/* JANTE AVANT GAUCHE */
#voiture-gauche .jante-avant-gauche {
  left: 120px;
  top: 257px;
  position: absolute;
}

#voiture-gauche .jante-avant-gauche a, #voiture-gauche .jante-avant-gauche.selected, #voiture-gauche ul li.jante-avant-gauche {
  width: 71px;
  height: 71px;
  position: absolute;
  z-index: 2;
}

#voiture-gauche .jante-avant-gauche a:hover {
  background: $domGauche -107px -670px no-repeat;
}

#voiture-gauche .jante-avant-gauche.selected-rep, #voiture-gauche .jante-avant-gauche.selected-rep a:hover {
  background: $domGauche -443px -670px no-repeat;
}

#voiture-gauche .jante-avant-gauche.selected-remp, #voiture-gauche .jante-avant-gauche.selected-remp a:hover {
  background: $domGauche -780px -670px no-repeat;
}

/* PHARE AVANT GAUCHE */
#voiture-gauche .phare-avant-gauche {
  left: 48px;
  top: 210px;
  position: absolute;
}

#voiture-gauche .phare-avant-gauche a, #voiture-gauche .phare-avant-gauche.selected, #voiture-gauche ul li.phare-avant-gauche {
  width: 58px;
  height: 30px;
  position: absolute;
  z-index: 2;
}

#voiture-gauche .phare-avant-gauche a:hover {
  background: $domGauche 1px -761px no-repeat;
}

#voiture-gauche .phare-avant-gauche.selected-rep, #voiture-gauche .phare-avant-gauche.selected-rep a:hover {
  background: $domGauche -335px -761px no-repeat;
}

#voiture-gauche .phare-avant-gauche.selected-remp, #voiture-gauche .phare-avant-gauche.selected-remp a:hover {
  background: $domGauche -672px -761px no-repeat;
}

/* ANTI BROUILLARD AVANT GAUCHE*/
#voiture-gauche .anti-brouillard-avant-gauche {
  left: 54px;
  top: 275px;
  position: absolute;
}

#voiture-gauche .anti-brouillard-avant-gauche a, #voiture-gauche .anti-brouillard-avant-gauche.selected, #voiture-gauche ul li.anti-brouillard-avant-gauche {
  width: 12px;
  height: 25px;
  position: absolute;
  z-index: 2;
}

#voiture-gauche .anti-brouillard-avant-gauche a:hover {
  background: $domGauche 0px -795px no-repeat;
}

#voiture-gauche .anti-brouillard-avant-gauche.selected-rep, #voiture-gauche .anti-brouillard-avant-gauche.selected-rep a:hover {
  background: $domGauche -335px -795px no-repeat;
}

#voiture-gauche .anti-brouillard-avant-gauche.selected-remp, #voiture-gauche .anti-brouillard-avant-gauche.selected-remp a:hover {
  background: $domGauche -672px -795px no-repeat;
}

/* AILE ARRIERE GAUCHE */
#voiture-gauche .aile-arriere-gauche {
  left: 202px;
  top: 106px;
  position: absolute;
}

#voiture-gauche .aile-arriere-gauche a, #voiture-gauche .aile-arriere-gauche.selected, #voiture-gauche ul li.aile-arriere-gauche {
  width: 465px;
  height: 171px;
  position: absolute;
  z-index: 0;
}

#voiture-gauche .aile-arriere-gauche a:hover {
  background: $domGauche -0px -841px no-repeat;
}

#voiture-gauche .aile-arriere-gauche.selected-rep, #voiture-gauche .aile-arriere-gauche.selected-rep a:hover {
  background: $domGauche -0px -1019px no-repeat;
}

#voiture-gauche .aile-arriere-gauche.selected-remp, #voiture-gauche .aile-arriere-gauche.selected-remp a:hover {
  background: $domGauche -501px -1019px no-repeat;
}

/* PARE BRISE */
#voiture-gauche .pare-brise {
  left: 202px;
  top: 120px;
  position: absolute;
}

#voiture-gauche .pare-brise a, #voiture-gauche .pare-brise.selected, #voiture-gauche ul li.pare-brise {
  width: 120px;
  height: 60px;
  position: absolute;
  z-index: 1;
}

#voiture-gauche .pare-brise a:hover {
  background: $domGauche -0px -1196px no-repeat;
}

#voiture-gauche .pare-brise.selected-rep, #voiture-gauche .pare-brise.selected-rep a:hover {
  background: $domGauche -335px -1196px no-repeat;
}

#voiture-gauche .pare-brise.selected-remp, #voiture-gauche .pare-brise.selected-remp a:hover {
  background: $domGauche -672px -1196px no-repeat;
}

/* PORTE AVANT GAUCHE */
#voiture-gauche .porte-avant-gauche {
  left: 222px;
  top: 116px;
  position: absolute;
}

#voiture-gauche .porte-avant-gauche a, #voiture-gauche .porte-avant-gauche.selected, #voiture-gauche ul li.porte-avant-gauche {
  width: 197px;
  height: 185px;
  position: absolute;
  z-index: 0;
}

#voiture-gauche .porte-avant-gauche a:hover {
  background: $domGauche -0px -1259px no-repeat;
}

#voiture-gauche .porte-avant-gauche.selected-rep, #voiture-gauche .porte-avant-gauche.selected-rep a:hover {
  background: $domGauche -336px -1259px no-repeat;
}

#voiture-gauche .porte-avant-gauche.selected-remp, #voiture-gauche .porte-avant-gauche.selected-remp a:hover {
  background: $domGauche -673px -1259px no-repeat;
}

/* PORTE ARRIERE GAUCHE */
#voiture-gauche .porte-arriere-gauche {
  left: 401px;
  top: 116px;
  position: absolute;
}

#voiture-gauche .porte-arriere-gauche a, #voiture-gauche .porte-arriere-gauche.selected, #voiture-gauche ul li.porte-arriere-gauche {
  width: 167px;
  height: 181px;
  position: absolute;
  z-index: 0;
}

#voiture-gauche .porte-arriere-gauche a:hover {
  background: $domGauche -1px -1450px no-repeat;
}

#voiture-gauche .porte-arriere-gauche.selected-rep, #voiture-gauche .porte-arriere-gauche.selected-rep a:hover {
  background: $domGauche -337px -1450px no-repeat;
}

#voiture-gauche .porte-arriere-gauche.selected-remp, #voiture-gauche .porte-arriere-gauche.selected-remp a:hover {
  background: $domGauche -674px -1450px no-repeat;
}

/* VITRE AVANT GAUCHE */
#voiture-gauche .vitre-avant-gauche {
  left: 272px;
  top: 121px;
  position: absolute;
}

#voiture-gauche .vitre-avant-gauche a, #voiture-gauche .vitre-avant-gauche.selected, #voiture-gauche ul li.vitre-avant-gauche {
  width: 138px;
  height: 64px;
  position: absolute;
  z-index: 1;
}

#voiture-gauche .vitre-avant-gauche a:hover {
  background: $domGauche -1px -1635px no-repeat;
}

#voiture-gauche .vitre-avant-gauche.selected-rep, #voiture-gauche .vitre-avant-gauche.selected-rep a:hover {
  background: $domGauche -337px -1635px no-repeat;
}

#voiture-gauche .vitre-avant-gauche.selected-remp, #voiture-gauche .vitre-avant-gauche.selected-remp a:hover {
  background: $domGauche -674px -1635px no-repeat;
}

/* VITRE ARRIERE GAUCHE */
#voiture-gauche .vitre-arriere-gauche {
  left: 420px;
  top: 121px;
  position: absolute;
}

#voiture-gauche .vitre-arriere-gauche a, #voiture-gauche .vitre-arriere-gauche.selected, #voiture-gauche ul li.vitre-arriere-gauche {
  width: 141px;
  height: 55px;
  position: absolute;
  z-index: 1;
}

#voiture-gauche .vitre-arriere-gauche a:hover {
  background: $domGauche -0px -1701px no-repeat;
}

#voiture-gauche .vitre-arriere-gauche.selected-rep, #voiture-gauche .vitre-arriere-gauche.selected-rep a:hover {
  background: $domGauche -336px -1701px no-repeat;
}

#voiture-gauche .vitre-arriere-gauche.selected-remp, #voiture-gauche .vitre-arriere-gauche.selected-remp a:hover {
  background: $domGauche -673px -1701px no-repeat;
}

/* RETRO GAUCHE */
#voiture-gauche .retro-gauche {
  left: 246px;
  top: 160px;
  position: absolute;
}

#voiture-gauche .retro-gauche a, #voiture-gauche .retro-gauche.selected, #voiture-gauche ul li.retro-gauche {
  width: 32px;
  height: 30px;
  position: absolute;
  z-index: 2;
}

#voiture-gauche .retro-gauche a:hover {
  background: $domGauche -1px -1759px no-repeat;
}

#voiture-gauche .retro-gauche.selected-rep, #voiture-gauche .retro-gauche.selected-rep a:hover {
  background: $domGauche -337px -1759px no-repeat;
}

#voiture-gauche .retro-gauche.selected-remp, #voiture-gauche .retro-gauche.selected-remp a:hover {
  background: $domGauche -674px -1759px no-repeat;
}

/* SOUBASSEMENT GAUCHE */
#voiture-gauche .soubassement-gauche {
  left: 216px;
  top: 276px;
  position: absolute;
}

#voiture-gauche .soubassement-gauche a, #voiture-gauche .soubassement-gauche.selected, #voiture-gauche ul li.soubassement-gauche {
  width: 315px;
  height: 37px;
  position: absolute;
  z-index: 2;
}

#voiture-gauche .soubassement-gauche a:hover {
  background: $domGauche 0px -1794px no-repeat;
}

#voiture-gauche .soubassement-gauche.selected-rep, #voiture-gauche .soubassement-gauche.selected-rep a:hover {
  background: $domGauche -336px -1794px no-repeat;
}

#voiture-gauche .soubassement-gauche.selected-remp, #voiture-gauche .soubassement-gauche.selected-remp a:hover {
  background: $domGauche -673px -1794px no-repeat;
}

/* PARE CHOC ARRIERE */
#voiture-gauche .pare-choc-arriere {
  left: 624px;
  top: 200px;
  position: absolute;
}

#voiture-gauche .pare-choc-arriere a, #voiture-gauche .pare-choc-arriere.selected, #voiture-gauche ul li.pare-choc-arriere {
  width: 69px;
  height: 99px;
  position: absolute;
  z-index: 1;
}

#voiture-gauche .pare-choc-arriere a:hover {
  background: $domGauche -1px -1837px no-repeat;
}

#voiture-gauche .pare-choc-arriere.selected-rep, #voiture-gauche .pare-choc-arriere.selected-rep a:hover {
  background: $domGauche -336px -1837px no-repeat;
}

#voiture-gauche .pare-choc-arriere.selected-remp, #voiture-gauche .pare-choc-arriere.selected-remp a:hover {
  background: $domGauche -673px -1837px no-repeat;
}

/* PNEU ARRIERE GAUCHE */
#voiture-gauche .pneu-arriere-gauche {
  left: 539px;
  top: 242px;
  position: absolute;
}

#voiture-gauche .pneu-arriere-gauche a, #voiture-gauche .pneu-arriere-gauche.selected, #voiture-gauche ul li.pneu-arriere-gauche {
  width: 101px;
  height: 101px;
  position: absolute;
  z-index: 1;
}

#voiture-gauche .pneu-arriere-gauche a:hover {
  background: $domGauche -1px -1941px no-repeat;
}

#voiture-gauche .pneu-arriere-gauche.selected-rep, #voiture-gauche .pneu-arriere-gauche.selected-rep a:hover {
  background: $domGauche -336px -1941px no-repeat;
}

#voiture-gauche .pneu-arriere-gauche.selected-remp, #voiture-gauche .pneu-arriere-gauche.selected-remp a:hover {
  background: $domGauche -673px -1941px no-repeat;
}

/* JANTE ARRIERE GAUCHE */
#voiture-gauche .jante-arriere-gauche {
  left: 554px;
  top: 257px;
  position: absolute;
}

#voiture-gauche .jante-arriere-gauche a, #voiture-gauche .jante-arriere-gauche.selected, #voiture-gauche ul li.jante-arriere-gauche {
  width: 71px;
  height: 71px;
  position: absolute;
  z-index: 1;
}

#voiture-gauche .jante-arriere-gauche a:hover {
  background: $domGauche -112px -1956px no-repeat;
}

#voiture-gauche .jante-arriere-gauche.selected-rep, #voiture-gauche .jante-arriere-gauche.selected-rep a:hover {
  background: $domGauche -449px -1956px no-repeat;
}

#voiture-gauche .jante-arriere-gauche.selected-remp, #voiture-gauche .jante-arriere-gauche.selected-remp a:hover {
  background: $domGauche -786px -1956px no-repeat;
}

/* HAYON */
#voiture-gauche .hayon-malle {
  left: 600px;
  top: 117px;
  position: absolute;
}

#voiture-gauche .hayon-malle a, #voiture-gauche .hayon-malle.selected, #voiture-gauche ul li.hayon-malle {
  width: 82px;
  height: 75px;
  position: absolute;
  z-index: 1;
}

#voiture-gauche .hayon-malle a:hover {
  background: $domGauche -1px -2047px no-repeat;
}

#voiture-gauche .hayon-malle.selected-rep, #voiture-gauche .hayon-malle.selected-rep a:hover {
  background: $domGauche -336px -2047px no-repeat;
}

#voiture-gauche .hayon-malle.selected-remp, #voiture-gauche .hayon-malle.selected-remp a:hover {
  background: $domGauche -673px -2047px no-repeat;
}

/* VITRE HAYON */
#voiture-gauche .vitre-hayon {
  left: 625px;
  top: 128px;
  position: absolute;
}

#voiture-gauche .vitre-hayon a, #voiture-gauche .vitre-hayon.selected, #voiture-gauche ul li.vitre-hayon {
  width: 40px;
  height: 37px;
  position: absolute;
  z-index: 1;
}

#voiture-gauche .vitre-hayon a:hover {
  background: $domGauche -1px -2136px no-repeat;
}

#voiture-gauche .vitre-hayon.selected-rep, #voiture-gauche .vitre-hayon.selected-rep a:hover {
  background: $domGauche -337px -2136px no-repeat;
}

#voiture-gauche .vitre-hayon.selected-remp, #voiture-gauche .vitre-hayon.selected-remp a:hover {
  background: $domGauche -674px -2136px no-repeat;
}

/* PHARE ARRIERE GAUCHE */
#voiture-gauche .phare-arriere-gauche {
  left: 627px;
  top: 173px;
  position: absolute;
}

#voiture-gauche .phare-arriere-gauche a, #voiture-gauche .phare-arriere-gauche.selected, #voiture-gauche ul li.phare-arriere-gauche {
  width: 55px;
  height: 31px;
  position: absolute;
  z-index: 1;
}

#voiture-gauche .phare-arriere-gauche a:hover {
  background: $domGauche -1px -2178px no-repeat;
}

#voiture-gauche .phare-arriere-gauche.selected-rep, #voiture-gauche .phare-arriere-gauche.selected-rep a:hover {
  background: $domGauche -337px -2178px no-repeat;
}

#voiture-gauche .phare-arriere-gauche.selected-remp, #voiture-gauche .phare-arriere-gauche.selected-remp a:hover {
  background: $domGauche -674px -2178px no-repeat;
}

/* VOITURE DOS GAUCHE */

/* PARE CHOC ARRIERE */
#voiture-dos-gauche .pare-choc-arriere {
  left: 418px;
  top: 210px;
  position: absolute;
  cursor: pointer;
}

#voiture-dos-gauche .pare-choc-arriere a, #voiture-dos-gauche .pare-choc-arriere.selected-rep, #voiture-dos-gauche .pare-choc-arriere.selected-remp {
  width: 246px;
  height: 118px;
  position: absolute;
  z-index: 1;
}

#voiture-dos-gauche .pare-choc-arriere a:hover, #voiture-dos-gauche .pare-choc-arriere a.hover2 {
  background: $domDosGauche -1px -410px no-repeat;
}

#voiture-dos-gauche .pare-choc-arriere.selected-rep, #voiture-dos-gauche .pare-choc-arriere.selected-rep a:hover, #voiture-dos-gauche .pare-choc-arriere.selected-rep a.hover2 {
  background: $domDosGauche -333px -410px no-repeat;
}

#voiture-dos-gauche .pare-choc-arriere.selected-remp, #voiture-dos-gauche .pare-choc-arriere.selected-remp a:hover, #voiture-dos-gauche .pare-choc-arriere.selected-remp a.hover2 {
  background: $domDosGauche -674px -410px no-repeat;
}

/* PARE CHOC ARRIERE BIS */
#voiture-dos-gauche .pare-choc-arriere.bis {
  left: 436px;
  top: 217px;
  position: absolute;
}

#voiture-dos-gauche .pare-choc-arriere.bis a {
  width: 215px;
  height: 112px;
  position: absolute;
  z-index: 1;
}

#voiture-dos-gauche .pare-choc-arriere.bis a:hover {
  background: none;
}

/* HAYON */
#voiture-dos-gauche .hayon-malle {
  left: 444px;
  top: 114px;
  position: absolute;
}

#voiture-dos-gauche .hayon-malle a, #voiture-dos-gauche .hayon-malle.selected, #voiture-dos-gauche ul li.hayon-malle {
  width: 207px;
  height: 138px;
  position: absolute;
  z-index: 1;
}

#voiture-dos-gauche .hayon-malle a:hover {
  background: $domDosGauche -1px -532px no-repeat;
}

#voiture-dos-gauche .hayon-malle.selected-rep, #voiture-dos-gauche .hayon-malle.selected-rep a:hover {
  background: $domDosGauche -332px -532px no-repeat;
}

#voiture-dos-gauche .hayon-malle.selected-remp, #voiture-dos-gauche .hayon-malle.selected-remp a:hover {
  background: $domDosGauche -674px -532px no-repeat;
}

/* AILE ARRIERE GAUCHE */
#voiture-dos-gauche .aile-arriere-gauche {
  left: 220px;
  top: 110px;
  position: absolute;
}

#voiture-dos-gauche .aile-arriere-gauche a, #voiture-dos-gauche .aile-arriere-gauche.selected-rep, #voiture-dos-gauche .aile-arriere-gauche.selected-remp {
  width: 280px;
  height: 182px;
  position: absolute;
  z-index: 0;
}

#voiture-dos-gauche .aile-arriere-gauche a:hover, #voiture-dos-gauche .aile-arriere-gauche a.hover1 {
  background: $domDosGauche -10px -676px no-repeat;
}

#voiture-dos-gauche .aile-arriere-gauche.selected-rep, #voiture-dos-gauche .aile-arriere-gauche.selected-rep a:hover, #voiture-dos-gauche .aile-arriere-gauche.selected-rep a.hover1 {
  background: $domDosGauche -341px -676px no-repeat;
}

#voiture-dos-gauche .aile-arriere-gauche.selected-remp, #voiture-dos-gauche .aile-arriere-gauche.selected-remp a:hover, #voiture-dos-gauche .aile-arriere-gauche.selected-remp a.hover1 {
  background: $domDosGauche -683px -676px no-repeat;
}

/* AILE ARRIERE GAUCHE BIS */
#voiture-dos-gauche .aile-arriere-gauche.bis {
  left: 380px;
  top: 170px;
  position: absolute;
}

#voiture-dos-gauche .aile-arriere-gauche.bis a {
  width: 55px;
  height: 80px;
  position: absolute;
  z-index: 5;
}

#voiture-dos-gauche .aile-arriere-gauche.bis a:hover {
  background: none;
}

/* PAVILLON */
#voiture-dos-gauche .pavillon {
  left: 318px;
  top: 102px;
  position: absolute;
}

#voiture-dos-gauche .pavillon a, #voiture-dos-gauche .pavillon.selected, #voiture-dos-gauche ul li.pavillon {
  width: 260px;
  height: 18px;
  position: absolute;
  z-index: 0;
}

#voiture-dos-gauche .pavillon a:hover {
  background: $domDosGauche -1px -863px no-repeat;
}

#voiture-dos-gauche .pavillon.selected-rep, #voiture-dos-gauche .pavillon.selected-rep a:hover {
  background: $domDosGauche -333px -863px no-repeat;
}

#voiture-dos-gauche .pavillon.selected-remp, #voiture-dos-gauche .pavillon.selected-remp a:hover {
  background: $domDosGauche -675px -863px no-repeat;
}

/* VITRE HAYON */
#voiture-dos-gauche .vitre-hayon {
  left: 476px;
  top: 132px;
  position: absolute;
}

#voiture-dos-gauche .vitre-hayon a, #voiture-dos-gauche .vitre-hayon.selected, #voiture-dos-gauche ul li.vitre-hayon {
  width: 160px;
  height: 45px;
  position: absolute;
  z-index: 1;
}

#voiture-dos-gauche .vitre-hayon a:hover {
  background: $domDosGauche -2px -885px no-repeat;
}

#voiture-dos-gauche .vitre-hayon.selected-rep, #voiture-dos-gauche .vitre-hayon.selected-rep a:hover {
  background: $domDosGauche -333px -885px no-repeat;
}

#voiture-dos-gauche .vitre-hayon.selected-remp, #voiture-dos-gauche .vitre-hayon.selected-remp a:hover {
  background: $domDosGauche -675px -885px no-repeat;
}

/* ESSUIE GLACE HAYON */
#voiture-dos-gauche .essuie-glace-hayon {
  left: 582px;
  top: 167px;
  position: absolute;
}

#voiture-dos-gauche .essuie-glace-hayon a, #voiture-dos-gauche .essuie-glace-hayon.selected, #voiture-dos-gauche ul li.essuie-glace-hayon {
  width: 34px;
  height: 10px;
  position: absolute;
  z-index: 1;
}

#voiture-dos-gauche .essuie-glace-hayon a:hover {
  background: $domDosGauche -2px -933px no-repeat;
}

#voiture-dos-gauche .essuie-glace-hayon.selected-rep, #voiture-dos-gauche .essuie-glace-hayon.selected-rep a:hover {
  background: $domDosGauche -333px -933px no-repeat;
}

#voiture-dos-gauche .essuie-glace-hayon.selected-remp, #voiture-dos-gauche .essuie-glace-hayon.selected-remp a:hover {
  background: $domDosGauche -675px -933px no-repeat;
}

/* ECLAIRAGE ARRIERE HAYON */
#voiture-dos-gauche .eclairage-arriere-hayon {
  left: 540px;
  top: 124px;
  position: absolute;
}

#voiture-dos-gauche .eclairage-arriere-hayon a, #voiture-dos-gauche .eclairage-arriere-hayon.selected, #voiture-dos-gauche ul li.eclairage-arriere-hayon {
  width: 12px;
  height: 5px;
  position: absolute;
  z-index: 1;
}

#voiture-dos-gauche .eclairage-arriere-hayon a:hover {
  background: $domDosGauche -1px -947px no-repeat;
}

#voiture-dos-gauche .eclairage-arriere-hayon.selected-rep, #voiture-dos-gauche .eclairage-arriere-hayon.selected-rep a:hover {
  background: $domDosGauche -333px -947px no-repeat;
}

#voiture-dos-gauche .eclairage-arriere-hayon.selected-remp, #voiture-dos-gauche .eclairage-arriere-hayon.selected-remp a:hover {
  background: $domDosGauche -675px -947px no-repeat;
}

/* PHARE ARRIERE DROIT */
#voiture-dos-gauche .phare-arriere-droit {
  left: 642px;
  top: 180px;
  position: absolute;
}

#voiture-dos-gauche .phare-arriere-droit a, #voiture-dos-gauche .phare-arriere-droit.selected, #voiture-dos-gauche ul li.phare-arriere-droit {
  width: 13px;
  height: 30px;
  position: absolute;
  z-index: 1;
}

#voiture-dos-gauche .phare-arriere-droit a:hover {
  background: $domDosGauche -0px -956px no-repeat;
}

#voiture-dos-gauche .phare-arriere-droit.selected-rep, #voiture-dos-gauche .phare-arriere-droit.selected-rep a:hover {
  background: $domDosGauche -332px -956px no-repeat;
}

#voiture-dos-gauche .phare-arriere-droit.selected-remp, #voiture-dos-gauche .phare-arriere-droit.selected-remp a:hover {
  background: $domDosGauche -674px -956px no-repeat;
}

/* PHARE ARRIERE GAUCHE */
#voiture-dos-gauche .phare-arriere-gauche {
  left: 437px;
  top: 183px;
  position: absolute;
}

#voiture-dos-gauche .phare-arriere-gauche a, #voiture-dos-gauche .phare-arriere-gauche.selected, #voiture-dos-gauche ul li.phare-arriere-gauche {
  width: 107px;
  height: 36px;
  position: absolute;
  z-index: 1;
}

#voiture-dos-gauche .phare-arriere-gauche a:hover {
  background: $domDosGauche -0px -990px no-repeat;
}

#voiture-dos-gauche .phare-arriere-gauche.selected-rep, #voiture-dos-gauche .phare-arriere-gauche.selected-rep a:hover {
  background: $domDosGauche -333px -990px no-repeat;
}

#voiture-dos-gauche .phare-arriere-gauche.selected-remp, #voiture-dos-gauche .phare-arriere-gauche.selected-remp a:hover {
  background: $domDosGauche -675px -990px no-repeat;
}

/* PNEU ARRIERE DROIT */
#voiture-dos-gauche .pneu-arriere-droit {
  left: 553px;
  top: 307px;
  position: absolute;
}

#voiture-dos-gauche .pneu-arriere-droit a, #voiture-dos-gauche .pneu-arriere-droit.selected, #voiture-dos-gauche ul li.pneu-arriere-droit {
  width: 81px;
  height: 30px;
  position: absolute;
  z-index: 1;
}

#voiture-dos-gauche .pneu-arriere-droit a:hover {
  background: $domDosGauche -2px -1030px no-repeat;
}

#voiture-dos-gauche .pneu-arriere-droit.selected-rep, #voiture-dos-gauche .pneu-arriere-droit.selected-rep a:hover {
  background: $domDosGauche -334px -1030px no-repeat;
}

#voiture-dos-gauche .pneu-arriere-droit.selected-remp, #voiture-dos-gauche .pneu-arriere-droit.selected-remp a:hover {
  background: $domDosGauche -676px -1030px no-repeat;
}

/* PNEU ARRIERE GAUCHE */
#voiture-dos-gauche .pneu-arriere-gauche {
  left: 360px;
  top: 255px;
  position: absolute;
}

#voiture-dos-gauche .pneu-arriere-gauche a, #voiture-dos-gauche .pneu-arriere-gauche.selected, #voiture-dos-gauche ul li.pneu-arriere-gauche {
  width: 96px;
  height: 112px;
  position: absolute;
  z-index: 3;
}

#voiture-dos-gauche .pneu-arriere-gauche a:hover {
  background: $domDosGauche -0px -1065px no-repeat;
}

#voiture-dos-gauche .pneu-arriere-gauche.selected-rep, #voiture-dos-gauche .pneu-arriere-gauche.selected-rep a:hover {
  background: $domDosGauche -333px -1065px no-repeat;
}

#voiture-dos-gauche .pneu-arriere-gauche.selected-remp, #voiture-dos-gauche .pneu-arriere-gauche.selected-remp a:hover {
  background: $domDosGauche -675px -1065px no-repeat;
}

/* JANTE ARRIERE GAUCHE */
#voiture-dos-gauche .jante-arriere-gauche {
  left: 372px;
  top: 272px;
  position: absolute;
}

#voiture-dos-gauche .jante-arriere-gauche a, #voiture-dos-gauche .jante-arriere-gauche.selected, #voiture-dos-gauche ul li.jante-arriere-gauche {
  width: 53px;
  height: 78px;
  position: absolute;
  z-index: 3;
}

#voiture-dos-gauche .jante-arriere-gauche a:hover {
  background: $domDosGauche -103px -1082px no-repeat;
}

#voiture-dos-gauche .jante-arriere-gauche.selected-rep, #voiture-dos-gauche .jante-arriere-gauche.selected-rep a:hover {
  background: $domDosGauche -434px -1082px no-repeat;
}

#voiture-dos-gauche .jante-arriere-gauche.selected-remp, #voiture-dos-gauche .jante-arriere-gauche.selected-remp a:hover {
  background: $domDosGauche -776px -1082px no-repeat;
}

/* PORTE ARRIERE GAUCHE */
#voiture-dos-gauche .porte-arriere-gauche {
  left: 271px;
  top: 115px;
  position: absolute;
}

#voiture-dos-gauche .porte-arriere-gauche a, #voiture-dos-gauche .porte-arriere-gauche.selected, #voiture-dos-gauche ul li.porte-arriere-gauche {
  width: 146px;
  height: 192px;
  position: absolute;
  z-index: 2;
}

#voiture-dos-gauche .porte-arriere-gauche a:hover {
  background: $domDosGauche 0px -1202px no-repeat;
}

#voiture-dos-gauche .porte-arriere-gauche.selected-rep, #voiture-dos-gauche .porte-arriere-gauche.selected-rep a:hover {
  background: $domDosGauche -333px -1202px no-repeat;
}

#voiture-dos-gauche .porte-arriere-gauche.selected-remp, #voiture-dos-gauche .porte-arriere-gauche.selected-remp a:hover {
  background: $domDosGauche -675px -1202px no-repeat;
}

/* PORTE AVANT GAUCHE */
#voiture-dos-gauche .porte-avant-gauche {
  left: 178px;
  top: 116px;
  position: absolute;
}

#voiture-dos-gauche .porte-avant-gauche a, #voiture-dos-gauche .porte-avant-gauche.selected, #voiture-dos-gauche ul li.porte-avant-gauche {
  width: 140px;
  height: 184px;
  position: absolute;
  z-index: 1;
}

#voiture-dos-gauche .porte-avant-gauche a:hover {
  background: $domDosGauche -1px -1399px no-repeat;
}

#voiture-dos-gauche .porte-avant-gauche.selected-rep, #voiture-dos-gauche .porte-avant-gauche.selected-rep a:hover {
  background: $domDosGauche -334px -1399px no-repeat;
}

#voiture-dos-gauche .porte-avant-gauche.selected-remp, #voiture-dos-gauche .porte-avant-gauche.selected-remp a:hover {
  background: $domDosGauche -676px -1399px no-repeat;
}

/* VITRE ARRIERE GAUCHE */
#voiture-dos-gauche .vitre-arriere-gauche {
  left: 298px;
  top: 120px;
  position: absolute;
}

#voiture-dos-gauche .vitre-arriere-gauche a, #voiture-dos-gauche .vitre-arriere-gauche.selected, #voiture-dos-gauche ul li.vitre-arriere-gauche {
  width: 113px;
  height: 62px;
  position: absolute;
  z-index: 2;
}

#voiture-dos-gauche .vitre-arriere-gauche a:hover {
  background: $domDosGauche -1px -1590px no-repeat;
}

#voiture-dos-gauche .vitre-arriere-gauche.selected-rep, #voiture-dos-gauche .vitre-arriere-gauche.selected-rep a:hover {
  background: $domDosGauche -333px -1587px no-repeat;
}

#voiture-dos-gauche .vitre-arriere-gauche.selected-remp, #voiture-dos-gauche .vitre-arriere-gauche.selected-remp a:hover {
  background: $domDosGauche -675px -1587px no-repeat;
}

/* VITRE AVANT GAUCHE */
#voiture-dos-gauche .vitre-avant-gauche {
  left: 208px;
  top: 122px;
  position: absolute;
}

#voiture-dos-gauche .vitre-avant-gauche a, #voiture-dos-gauche .vitre-avant-gauche.selected, #voiture-dos-gauche ul li.vitre-avant-gauche {
  width: 99px;
  height: 67px;
  position: absolute;
  z-index: 2;
}

#voiture-dos-gauche .vitre-avant-gauche a:hover {
  background: $domDosGauche -3px -1657px no-repeat;
}

#voiture-dos-gauche .vitre-avant-gauche.selected-rep, #voiture-dos-gauche .vitre-avant-gauche.selected-rep a:hover {
  background: $domDosGauche -334px -1657px no-repeat;
}

#voiture-dos-gauche .vitre-avant-gauche.selected-remp, #voiture-dos-gauche .vitre-avant-gauche.selected-remp a:hover {
  background: $domDosGauche -676px -1657px no-repeat;
}

/* SOUBASSEMENT GAUCHE */
#voiture-dos-gauche .soubassement-gauche {
  left: 179px;
  top: 290px;
  position: absolute;
}

#voiture-dos-gauche .soubassement-gauche a, #voiture-dos-gauche .soubassement-gauche.selected, #voiture-dos-gauche ul li.soubassement-gauche {
  width: 178px;
  height: 33px;
  position: absolute;
  z-index: 2;
}

#voiture-dos-gauche .soubassement-gauche a:hover {
  background: $domDosGauche -0px -1729px no-repeat;
}

#voiture-dos-gauche .soubassement-gauche.selected-rep, #voiture-dos-gauche .soubassement-gauche.selected-rep a:hover {
  background: $domDosGauche -332px -1729px no-repeat;
}

#voiture-dos-gauche .soubassement-gauche.selected-remp, #voiture-dos-gauche .soubassement-gauche.selected-remp a:hover {
  background: $domDosGauche -674px -1729px no-repeat;
}

/* RETRO GAUCHE */
#voiture-dos-gauche .retro-gauche {
  left: 183px;
  top: 165px;
  position: absolute;
}

#voiture-dos-gauche .retro-gauche a, #voiture-dos-gauche .retro-gauche.selected, #voiture-dos-gauche ul li.retro-gauche {
  width: 25px;
  height: 24px;
  position: absolute;
  z-index: 1;
}

#voiture-dos-gauche .retro-gauche a:hover {
  background: $domDosGauche -1px -1767px no-repeat;
}

#voiture-dos-gauche .retro-gauche.selected-rep, #voiture-dos-gauche .retro-gauche.selected-rep a:hover {
  background: $domDosGauche -333px -1767px no-repeat;
}

#voiture-dos-gauche .retro-gauche.selected-remp, #voiture-dos-gauche .retro-gauche.selected-remp a:hover {
  background: $domDosGauche -675px -1767px no-repeat;
}

/* AILE AVANT GAUCHE */
#voiture-dos-gauche .aile-avant-gauche {
  left: 129px;
  top: 195px;
  position: absolute;
}

#voiture-dos-gauche .aile-avant-gauche a, #voiture-dos-gauche .aile-avant-gauche.selected, #voiture-dos-gauche ul li.aile-avant-gauche {
  width: 58px;
  height: 95px;
  position: absolute;
  z-index: 0;
}

#voiture-dos-gauche .aile-avant-gauche a:hover {
  background: $domDosGauche -1px -1799px no-repeat;
}

#voiture-dos-gauche .aile-avant-gauche.selected-rep, #voiture-dos-gauche .aile-avant-gauche.selected-rep a:hover {
  background: $domDosGauche -335px -1799px no-repeat;
}

#voiture-dos-gauche .aile-avant-gauche.selected-remp, #voiture-dos-gauche .aile-avant-gauche.selected-remp a:hover {
  background: $domDosGauche -677px -1799px no-repeat;
}

/* CAPOT*/
#voiture-dos-gauche .capot {
  left: 138px;
  top: 185px;
  position: absolute;
}

#voiture-dos-gauche .capot a, #voiture-dos-gauche .capot.selected, #voiture-dos-gauche ul li.capot {
  width: 53px;
  height: 18px;
  position: absolute;
  z-index: 1;
}

#voiture-dos-gauche .capot a:hover {
  background: $domDosGauche -5px -1900px no-repeat;
}

#voiture-dos-gauche .capot.selected-rep, #voiture-dos-gauche .capot.selected-rep a:hover {
  background: $domDosGauche -335px -1900px no-repeat;
}

#voiture-dos-gauche .capot.selected-remp, #voiture-dos-gauche .capot.selected-remp a:hover {
  background: $domDosGauche -677px -1900px no-repeat;
}

/* PARE CHOC AVANT*/
#voiture-dos-gauche .pare-choc-avant {
  left: 121px;
  top: 221px;
  position: absolute;
}

#voiture-dos-gauche .pare-choc-avant a, #voiture-dos-gauche .pare-choc-avant.selected, #voiture-dos-gauche ul li.pare-choc-avant {
  width: 13px;
  height: 63px;
  position: absolute;
  z-index: 1;
}

#voiture-dos-gauche .pare-choc-avant a:hover {
  background: $domDosGauche -0px -1926px no-repeat;
}

#voiture-dos-gauche .pare-choc-avant.selected-rep, #voiture-dos-gauche .pare-choc-avant.selected-rep a:hover {
  background: $domDosGauche -333px -1926px no-repeat;
}

#voiture-dos-gauche .pare-choc-avant.selected-remp, #voiture-dos-gauche .pare-choc-avant.selected-remp a:hover {
  background: $domDosGauche -674px -1926px no-repeat;
}

/* PHARE AVANT GAUCHE*/
#voiture-dos-gauche .phare-avant-gauche {
  left: 122px;
  top: 203px;
  position: absolute;
}

#voiture-dos-gauche .phare-avant-gauche a, #voiture-dos-gauche .phare-avant-gauche.selected, #voiture-dos-gauche ul li.phare-avant-gauche {
  width: 20px;
  height: 28px;
  position: absolute;
  z-index: 1;
}

#voiture-dos-gauche .phare-avant-gauche a:hover {
  background: $domDosGauche -0px -1997px no-repeat;
}

#voiture-dos-gauche .phare-avant-gauche.selected-rep, #voiture-dos-gauche .phare-avant-gauche.selected-rep a:hover {
  background: $domDosGauche -333px -1997px no-repeat;
}

#voiture-dos-gauche .phare-avant-gauche.selected-remp, #voiture-dos-gauche .phare-avant-gauche.selected-remp a:hover {
  background: $domDosGauche -674px -1997px no-repeat;
}

/* PNEU AVANT GAUCHE*/
#voiture-dos-gauche .pneu-avant-gauche {
  left: 128px;
  top: 234px;
  position: absolute;
}

#voiture-dos-gauche .pneu-avant-gauche a, #voiture-dos-gauche .pneu-avant-gauche.selected, #voiture-dos-gauche ul li.pneu-avant-gauche {
  width: 63px;
  height: 84px;
  position: absolute;
  z-index: 0;
}

#voiture-dos-gauche .pneu-avant-gauche a:hover {
  background: $domDosGauche -2px -2032px no-repeat;
}

#voiture-dos-gauche .pneu-avant-gauche.selected-rep, #voiture-dos-gauche .pneu-avant-gauche.selected-rep a:hover {
  background: $domDosGauche -334px -2032px no-repeat;
}

#voiture-dos-gauche .pneu-avant-gauche.selected-remp, #voiture-dos-gauche .pneu-avant-gauche.selected-remp a:hover {
  background: $domDosGauche -676px -2032px no-repeat;
}

/* JANTE AVANT GAUCHE*/
#voiture-dos-gauche .jante-avant-gauche {
  left: 134px;
  top: 246px;
  position: absolute;
}

#voiture-dos-gauche .jante-avant-gauche a, #voiture-dos-gauche .jante-avant-gauche.selected, #voiture-dos-gauche ul li.jante-avant-gauche {
  width: 35px;
  height: 59px;
  position: absolute;
  z-index: 0;
}

#voiture-dos-gauche .jante-avant-gauche a:hover {
  background: $domDosGauche -68px -2044px no-repeat;
}

#voiture-dos-gauche .jante-avant-gauche.selected-rep, #voiture-dos-gauche .jante-avant-gauche.selected-rep a:hover {
  background: $domDosGauche -402px -2044px no-repeat;
}

#voiture-dos-gauche .jante-avant-gauche.selected-remp, #voiture-dos-gauche .jante-avant-gauche.selected-remp a:hover {
  background: $domDosGauche -744px -2044px no-repeat;
}

/* PLAQUE IMMATRICULATION */
#voiture-dos-gauche .plaque-immatriculation {
  left: 560px;
  top: 200px;
  position: absolute;
}

#voiture-dos-gauche .plaque-immatriculation a {
  width: 70px;
  height: 35px;
  position: absolute;
  z-index: 4;
  cursor: default;
}

/* VOITURE DOS */

/* PARE CHOC ARRIERE */
#voiture-dos .pare-choc-arriere {
  left: 202px;
  top: 211px;
  position: absolute;
}

#voiture-dos .pare-choc-arriere a, #voiture-dos .pare-choc-arriere.selected, #voiture-dos ul li.pare-choc-arriere {
  width: 327px;
  height: 112px;
  position: absolute;
  z-index: 0;
}

#voiture-dos .pare-choc-arriere a:hover, #voiture-dos .pare-choc-arriere a.hover2 {
  background: $domDos -1px -401px no-repeat;
}

#voiture-dos .pare-choc-arriere.selected-rep, #voiture-dos .pare-choc-arriere.selected-rep a:hover {
  background: $domDos -333px -401px no-repeat;
}

#voiture-dos .pare-choc-arriere.selected-remp, #voiture-dos .pare-choc-arriere.selected-remp a:hover {
  background: $domDos -665px -401px no-repeat;
}

/* HAYON */
#voiture-dos .hayon-malle {
  left: 238px;
  top: 114px;
  position: absolute;
}

#voiture-dos .hayon-malle a, #voiture-dos .hayon-malle.selected, #voiture-dos ul li.hayon-malle {
  width: 253px;
  height: 139px;
  position: absolute;
  z-index: 0;
}

#voiture-dos .hayon-malle a:hover {
  background: $domDos -1px -522px no-repeat;
}

#voiture-dos .hayon-malle.selected-rep, #voiture-dos .hayon-malle.selected-rep a:hover {
  background: $domDos -333px -522px no-repeat;
}

#voiture-dos .hayon-malle.selected-remp, #voiture-dos .hayon-malle.selected-remp a:hover {
  background: $domDos -665px -522px no-repeat;
}

/* VITRE HAYON */
#voiture-dos .vitre-hayon {
  left: 248px;
  top: 125px;
  position: absolute;
}

#voiture-dos .vitre-hayon a, #voiture-dos .vitre-hayon.selected, #voiture-dos ul li.vitre-hayon {
  width: 235px;
  height: 47px;
  position: absolute;
  z-index: 0;
}

#voiture-dos .vitre-hayon a:hover {
  background: $domDos -1px -669px no-repeat;
}

#voiture-dos .vitre-hayon.selected-rep, #voiture-dos .vitre-hayon.selected-rep a:hover {
  background: $domDos -333px -669px no-repeat;
}

#voiture-dos .vitre-hayon.selected-remp, #voiture-dos .vitre-hayon.selected-remp a:hover {
  background: $domDos -665px -669px no-repeat;
}

/* PAVILLON */
#voiture-dos .pavillon {
  left: 267px;
  top: 99px;
  position: absolute;
}

#voiture-dos .pavillon a, #voiture-dos .pavillon.selected, #voiture-dos ul li.pavillon {
  width: 197px;
  height: 21px;
  position: absolute;
  z-index: 0;
}

#voiture-dos .pavillon a:hover {
  background: $domDos -0px -723px no-repeat;
}

#voiture-dos .pavillon.selected-rep, #voiture-dos .pavillon.selected-rep a:hover {
  background: $domDos -332px -723px no-repeat;
}

#voiture-dos .pavillon.selected-remp, #voiture-dos .pavillon.selected-remp a:hover {
  background: $domDos -664px -723px no-repeat;
}

/* PNEU ARRIERE DROIT */
#voiture-dos .pneu-arriere-droit {
  left: 480px;
  top: 297px;
  position: absolute;
}

#voiture-dos .pneu-arriere-droit a, #voiture-dos .pneu-arriere-droit.selected, #voiture-dos ul li.pneu-arriere-droit {
  width: 47px;
  height: 60px;
  position: absolute;
  z-index: 0;
}

#voiture-dos .pneu-arriere-droit a:hover {
  background: $domDos -1px -756px no-repeat;
}

#voiture-dos .pneu-arriere-droit.selected-rep, #voiture-dos .pneu-arriere-droit.selected-rep a:hover {
  background: $domDos -333px -756px no-repeat;
}

#voiture-dos .pneu-arriere-droit.selected-remp, #voiture-dos .pneu-arriere-droit.selected-remp a:hover {
  background: $domDos -665px -756px no-repeat;
}

/* PNEU ARRIERE GAUCHE */
#voiture-dos .pneu-arriere-gauche {
  left: 204px;
  top: 297px;
  position: absolute;
}

#voiture-dos .pneu-arriere-gauche a, #voiture-dos .pneu-arriere-gauche.selected, #voiture-dos ul li.pneu-arriere-gauche {
  width: 47px;
  height: 60px;
  position: absolute;
  z-index: 0;
}

#voiture-dos .pneu-arriere-gauche a:hover {
  background: $domDos -1px -829px no-repeat;
}

#voiture-dos .pneu-arriere-gauche.selected-rep, #voiture-dos .pneu-arriere-gauche.selected-rep a:hover {
  background: $domDos -333px -829px no-repeat;
}

#voiture-dos .pneu-arriere-gauche.selected-remp, #voiture-dos .pneu-arriere-gauche.selected-remp a:hover {
  background: $domDos -665px -829px no-repeat;
}

/* PHARE ARRIERE GAUCHE */
#voiture-dos .phare-arriere-gauche {
  left: 214px;
  top: 175px;
  position: absolute;
}

#voiture-dos .phare-arriere-gauche a, #voiture-dos .phare-arriere-gauche.selected, #voiture-dos ul li.phare-arriere-gauche {
  width: 71px;
  height: 38px;
  position: absolute;
  z-index: 0;
}

#voiture-dos .phare-arriere-gauche a:hover {
  background: $domDos -1px -934px no-repeat;
}

#voiture-dos .phare-arriere-gauche.selected, #voiture-dos .phare-arriere-gauche.selected a:hover {
  background: $domDos -332px -934px no-repeat;
}

#voiture-dos .phare-arriere-gauche.selected-rep, #voiture-dos .phare-arriere-gauche.selected-rep a:hover {
  background: $domDos -332px -934px no-repeat;
}

#voiture-dos .phare-arriere-gauche.selected-remp, #voiture-dos .phare-arriere-gauche.selected-remp a:hover {
  background: $domDos -664px -934px no-repeat;
}

/* PHARE ARRIERE DROIT */
#voiture-dos .phare-arriere-droit {
  left: 446px;
  top: 175px;
  position: absolute;
}

#voiture-dos .phare-arriere-droit a, #voiture-dos .phare-arriere-droit.selected, #voiture-dos ul li.phare-arriere-droit {
  width: 71px;
  height: 38px;
  position: absolute;
  z-index: 0;
}

#voiture-dos .phare-arriere-droit a:hover {
  background: $domDos -0px -891px no-repeat;
}

#voiture-dos .phare-arriere-droit.selected-rep, #voiture-dos .phare-arriere-droit.selected-rep a:hover {
  background: $domDos -331px -891px no-repeat;
}

#voiture-dos .phare-arriere-droit.selected-remp, #voiture-dos .phare-arriere-droit.selected-remp a:hover {
  background: $domDos -663px -891px no-repeat;
}

/* ECLAIRAGE ARRIERE HAYON */
#voiture-dos .eclairage-arriere-hayon {
  left: 357px;
  top: 117px;
  position: absolute;
}

#voiture-dos .eclairage-arriere-hayon a, #voiture-dos .eclairage-arriere-hayon.selected, #voiture-dos ul li.eclairage-arriere-hayon {
  width: 17px;
  height: 7px;
  position: absolute;
  z-index: 0;
}

#voiture-dos .eclairage-arriere-hayon a:hover {
  background: $domDos -1px -971px no-repeat;
}

#voiture-dos .eclairage-arriere-hayon.selected-rep, #voiture-dos .eclairage-arriere-hayon.selected-rep a:hover {
  background: $domDos -332px -979px no-repeat;
}

#voiture-dos .eclairage-arriere-hayon.selected-remp, #voiture-dos .eclairage-arriere-hayon.selected-remp a:hover {
  background: $domDos -664px -979px no-repeat;
}

/* ESSUIE GLACE HAYON */
#voiture-dos .essuie-glace-hayon {
  left: 360px;
  top: 162px;
  position: absolute;
}

#voiture-dos .essuie-glace-hayon a, #voiture-dos .essuie-glace-hayon.selected, #voiture-dos ul li.essuie-glace-hayon {
  width: 72px;
  height: 10px;
  position: absolute;
  z-index: 0;
}

#voiture-dos .essuie-glace-hayon a:hover {
  background: $domDos -1px -987px no-repeat;
}

#voiture-dos .essuie-glace-hayon.selected-rep, #voiture-dos .essuie-glace-hayon.selected-rep a:hover {
  background: $domDos -333px -987px no-repeat;
}

#voiture-dos .essuie-glace-hayon.selected-remp, #voiture-dos .essuie-glace-hayon.selected-remp a:hover {
  background: $domDos -665px -987px no-repeat;
}

/* RETRO DROIT */
#voiture-dos .retro-droit {
  left: 485px;
  top: 149px;
  position: absolute;
}

#voiture-dos .retro-droit a, #voiture-dos .retro-droit.selected, #voiture-dos ul li.retro-droit {
  width: 16px;
  height: 17px;
  position: absolute;
  z-index: 1;
}

#voiture-dos .retro-droit a:hover {
  background: $domDos -1px -1001px no-repeat;
}

#voiture-dos .retro-droit.selected-rep, #voiture-dos .retro-droit.selected-rep a:hover {
  background: $domDos -332px -1001px no-repeat;
}

#voiture-dos .retro-droit.selected-remp, #voiture-dos .retro-droit.selected-remp a:hover {
  background: $domDos -664px -1001px no-repeat;
}

/* RETRO GAUCHE */
#voiture-dos .retro-gauche {
  left: 230px;
  top: 149px;
  position: absolute;
}

#voiture-dos .retro-gauche a, #voiture-dos .retro-gauche.selected, #voiture-dos ul li.retro-gauche {
  width: 16px;
  height: 17px;
  position: absolute;
  z-index: 1;
}

#voiture-dos .retro-gauche a:hover {
  background: $domDos -0px -1022px no-repeat;
}

#voiture-dos .retro-gauche.selected-rep, #voiture-dos .retro-gauche.selected-rep a:hover {
  background: $domDos -332px -1022px no-repeat;
}

#voiture-dos .retro-gauche.selected-remp, #voiture-dos .retro-gauche.selected-remp a:hover {
  background: $domDos -664px -1022px no-repeat;
}

/* AILE ARRIERE GAUCHE */
#voiture-dos .aile-arriere-gauche {
  left: 230px;
  top: 111px;
  position: absolute;
}

#voiture-dos .aile-arriere-gauche a, #voiture-dos .aile-arriere-gauche.selected, #voiture-dos ul li.aile-arriere-gauche {
  width: 38px;
  height: 70px;
  position: absolute;
  z-index: 0;
}

#voiture-dos .aile-arriere-gauche a:hover {
  background: $domDos -0px -1121px no-repeat;
}

#voiture-dos .aile-arriere-gauche.selected-rep, #voiture-dos .aile-arriere-gauche.selected-rep a:hover {
  background: $domDos -332px -1121px no-repeat;
}

#voiture-dos .aile-arriere-gauche.selected-remp, #voiture-dos .aile-arriere-gauche.selected-remp a:hover {
  background: $domDos -664px -1121px no-repeat;
}

/* AILE ARRIERE DROITE */
#voiture-dos .aile-arriere-droite {
  left: 463px;
  top: 111px;
  position: absolute;
}

#voiture-dos .aile-arriere-droite a, #voiture-dos .aile-arriere-droite.selected, #voiture-dos ul li.aile-arriere-droite {
  width: 38px;
  height: 70px;
  position: absolute;
  z-index: 0;
}

#voiture-dos .aile-arriere-droite a:hover {
  background: $domDos -2px -1045px no-repeat;
}

#voiture-dos .aile-arriere-droite.selected-rep, #voiture-dos .aile-arriere-droite.selected-rep a:hover {
  background: $domDos -333px -1045px no-repeat;
}

#voiture-dos .aile-arriere-droite.selected-remp, #voiture-dos .aile-arriere-droite.selected-remp a:hover {
  background: $domDos -665px -1045px no-repeat;
}

/* PLAQUE IMMATRICULATION */
#voiture-dos .plaque-immatriculation {
  left: 303px;
  top: 202px;
  position: absolute;
}

#voiture-dos .plaque-immatriculation a {
  width: 125px;
  height: 30px;
  position: absolute;
  z-index: 4;
  cursor: default;
}

/* VOITURE DOS DROITE */

/* PARE CHOC ARRIERE */
#voiture-dos-droite .pare-choc-arriere {
  left: 72px;
  top: 210px;
  position: absolute;
}

#voiture-dos-droite .pare-choc-arriere a, #voiture-dos-droite .pare-choc-arriere.selected-rep, #voiture-dos-droite .pare-choc-arriere.selected-remp {
  width: 246px;
  height: 118px;
  position: absolute;
  z-index: 0;
}

#voiture-dos-droite .pare-choc-arriere a:hover, #voiture-dos-droite .pare-choc-arriere a.hover2 {
  background: $domDosDroite -1px -410px no-repeat;
}

#voiture-dos-droite .pare-choc-arriere.selected-rep, #voiture-dos-droite .pare-choc-arriere.selected-rep a:hover, #voiture-dos-droite .pare-choc-arriere.selected-rep a.hover2 {
  background: $domDosDroite -333px -410px no-repeat;
}

#voiture-dos-droite .pare-choc-arriere.selected-remp, #voiture-dos-droite .pare-choc-arriere.selected-remp a:hover, #voiture-dos-droite .pare-choc-arriere.selected-remp a.hover2 {
  background: $domDosDroite -656px -410px no-repeat;
}

/* PARE CHOC ARRIERE BIS */
#voiture-dos-droite .pare-choc-arriere.bis {
  left: 75px;
  top: 217px;
  position: absolute;
}

#voiture-dos-droite .pare-choc-arriere.bis a {
  width: 240px;
  height: 112px;
  position: absolute;
  z-index: 6;
}

#voiture-dos-droite .pare-choc-arriere.bis a:hover {
  background: none;
}

/* HAYON */
#voiture-dos-droite .hayon-malle {
  left: 84px;
  top: 114px;
  position: absolute;
}

#voiture-dos-droite .hayon-malle a, #voiture-dos-droite .hayon-malle.selected, #voiture-dos-droite ul li.hayon-malle {
  width: 207px;
  height: 138px;
  position: absolute;
  z-index: 1;
}

#voiture-dos-droite .hayon-malle a:hover {
  background: $domDosDroite -1px -532px no-repeat;
}

#voiture-dos-droite .hayon-malle.selected-rep, #voiture-dos-droite .hayon-malle.selected-rep a:hover {
  background: $domDosDroite -333px -532px no-repeat;
}

#voiture-dos-droite .hayon-malle.selected-remp, #voiture-dos-droite .hayon-malle.selected-remp a:hover {
  background: $domDosDroite -656px -532px no-repeat;
}

/* AILE ARRIERE GAUCHE */
#voiture-dos-droite .aile-arriere-droite {
  left: 237px;
  top: 110px;
  position: absolute;
}

#voiture-dos-droite .aile-arriere-droite a, #voiture-dos-droite .aile-arriere-droite.selected-rep, #voiture-dos-droite .aile-arriere-droite.selected-remp {
  width: 280px;
  height: 182px;
  position: absolute;
  z-index: 0;
}

#voiture-dos-droite .aile-arriere-droite a:hover, #voiture-dos-droite .aile-arriere-droite a.hover1 {
  background: $domDosDroite -10px -676px no-repeat;
}

#voiture-dos-droite .aile-arriere-droite.selected-rep, #voiture-dos-droite .aile-arriere-droite.selected-rep a:hover, #voiture-dos-droite .aile-arriere-droite.selected-rep a.hover1 {
  background: $domDosDroite -341px -676px no-repeat;
}

#voiture-dos-droite .aile-arriere-droite.selected-remp, #voiture-dos-droite .aile-arriere-droite.selected-remp a:hover, #voiture-dos-droite .aile-arriere-droite.selected-remp a.hover1 {
  background: $domDosDroite -665px -676px no-repeat;
}

/* AILE ARRIERE GAUCHE BIS */
#voiture-dos-droite .aile-arriere-droite.bis {
  left: 300px;
  top: 170px;
  position: absolute;
}

#voiture-dos-droite .aile-arriere-droite.bis a {
  width: 55px;
  height: 80px;
  position: absolute;
  z-index: 5;
}

#voiture-dos-droite .aile-arriere-droite.bis a:hover {
  background: none;
}

/* PAVILLON */
#voiture-dos-droite .pavillon {
  left: 154px;
  top: 102px;
  position: absolute;
}

#voiture-dos-droite .pavillon a, #voiture-dos-droite .pavillon.selected, #voiture-dos-droite ul li.pavillon {
  width: 260px;
  height: 18px;
  position: absolute;
  z-index: 0;
}

#voiture-dos-droite .pavillon a:hover {
  background: $domDosDroite -1px -863px no-repeat;
}

#voiture-dos-droite .pavillon.selected-rep, #voiture-dos-droite .pavillon.selected-rep a:hover {
  background: $domDosDroite -333px -863px no-repeat;
}

#voiture-dos-droite .pavillon.selected-remp, #voiture-dos-droite .pavillon.selected-remp a:hover {
  background: $domDosDroite -656px -863px no-repeat;
}

/* VITRE HAYON */
#voiture-dos-droite .vitre-hayon {
  left: 99px;
  top: 132px;
  position: absolute;
}

#voiture-dos-droite .vitre-hayon a, #voiture-dos-droite .vitre-hayon.selected, #voiture-dos-droite ul li.vitre-hayon {
  width: 160px;
  height: 45px;
  position: absolute;
  z-index: 1;
}

#voiture-dos-droite .vitre-hayon a:hover {
  background: $domDosDroite -2px -885px no-repeat;
}

#voiture-dos-droite .vitre-hayon.selected-rep, #voiture-dos-droite .vitre-hayon.selected-rep a:hover {
  background: $domDosDroite -333px -885px no-repeat;
}

#voiture-dos-droite .vitre-hayon.selected-remp, #voiture-dos-droite .vitre-hayon.selected-remp a:hover {
  background: $domDosDroite -656px -885px no-repeat;
}

/* ESSUIE GLACE HAYON */
#voiture-dos-droite .essuie-glace-hayon {
  left: 121px;
  top: 167px;
  position: absolute;
}

#voiture-dos-droite .essuie-glace-hayon a, #voiture-dos-droite .essuie-glace-hayon.selected, #voiture-dos-droite ul li.essuie-glace-hayon {
  width: 34px;
  height: 10px;
  position: absolute;
  z-index: 1;
}

#voiture-dos-droite .essuie-glace-hayon a:hover {
  background: $domDosDroite -2px -933px no-repeat;
}

#voiture-dos-droite .essuie-glace-hayon.selected-rep, #voiture-dos-droite .essuie-glace-hayon.selected-rep a:hover {
  background: $domDosDroite -333px -933px no-repeat;
}

#voiture-dos-droite .essuie-glace-hayon.selected-remp, #voiture-dos-droite .essuie-glace-hayon.selected-remp a:hover {
  background: $domDosDroite -656px -933px no-repeat;
}

/* ECLAIRAGE ARRIERE HAYON */
#voiture-dos-droite .eclairage-arriere-hayon {
  left: 183px;
  top: 124px;
  position: absolute;
}

#voiture-dos-droite .eclairage-arriere-hayon a, #voiture-dos-droite .eclairage-arriere-hayon.selected, #voiture-dos-droite ul li.eclairage-arriere-hayon {
  width: 12px;
  height: 5px;
  position: absolute;
  z-index: 1;
}

#voiture-dos-droite .eclairage-arriere-hayon a:hover {
  background: $domDosDroite -1px -947px no-repeat;
}

#voiture-dos-droite .eclairage-arriere-hayon.selected-rep, #voiture-dos-droite .eclairage-arriere-hayon.selected-rep a:hover {
  background: $domDosDroite -333px -947px no-repeat;
}

#voiture-dos-droite .eclairage-arriere-hayon.selected-remp, #voiture-dos-droite .eclairage-arriere-hayon.selected-remp a:hover {
  background: $domDosDroite -656px -947px no-repeat;
}

/* PHARE ARRIERE DROIT */
#voiture-dos-droite .phare-arriere-gauche {
  left: 80px;
  top: 180px;
  position: absolute;
}

#voiture-dos-droite .phare-arriere-gauche a, #voiture-dos-droite .phare-arriere-gauche.selected, #voiture-dos-droite ul li.phare-arriere-gauche {
  width: 13px;
  height: 30px;
  position: absolute;
  z-index: 1;
}

#voiture-dos-droite .phare-arriere-gauche a:hover {
  background: $domDosDroite -0px -956px no-repeat;
}

#voiture-dos-droite .phare-arriere-gauche.selected-rep, #voiture-dos-droite .phare-arriere-gauche.selected-rep a:hover {
  background: $domDosDroite -332px -956px no-repeat;
}

#voiture-dos-droite .phare-arriere-gauche.selected-remp, #voiture-dos-droite .phare-arriere-gauche.selected-remp a:hover {
  background: $domDosDroite -655px -956px no-repeat;
}

/* PHARE ARRIERE GAUCHE */
#voiture-dos-droite .phare-arriere-droit {
  left: 191px;
  top: 183px;
  position: absolute;
}

#voiture-dos-droite .phare-arriere-droit a, #voiture-dos-droite .phare-arriere-droit.selected, #voiture-dos-droite ul li.phare-arriere-droit {
  width: 107px;
  height: 36px;
  position: absolute;
  z-index: 1;
}

#voiture-dos-droite .phare-arriere-droit a:hover {
  background: $domDosDroite -0px -990px no-repeat;
}

#voiture-dos-droite .phare-arriere-droit.selected-rep, #voiture-dos-droite .phare-arriere-droit.selected-rep a:hover {
  background: $domDosDroite -332px -990px no-repeat;
}

#voiture-dos-droite .phare-arriere-droit.selected-remp, #voiture-dos-droite .phare-arriere-droit.selected-remp a:hover {
  background: $domDosDroite -655px -990px no-repeat;
}

/* PNEU ARRIERE DROIT */
#voiture-dos-droite .pneu-arriere-gauche {
  left: 102px;
  top: 307px;
  position: absolute;
}

#voiture-dos-droite .pneu-arriere-gauche a, #voiture-dos-droite .pneu-arriere-gauche.selected, #voiture-dos-droite ul li.pneu-arriere-gauche {
  width: 81px;
  height: 30px;
  position: absolute;
  z-index: 1;
}

#voiture-dos-droite .pneu-arriere-gauche a:hover {
  background: $domDosDroite -2px -1030px no-repeat;
}

#voiture-dos-droite .pneu-arriere-gauche.selected-rep, #voiture-dos-droite .pneu-arriere-gauche.selected-rep a:hover {
  background: $domDosDroite -333px -1030px no-repeat;
}

#voiture-dos-droite .pneu-arriere-gauche.selected-remp, #voiture-dos-droite .pneu-arriere-gauche.selected-remp a:hover {
  background: $domDosDroite -656px -1030px no-repeat;
}

/* PNEU ARRIERE GAUCHE */
#voiture-dos-droite .pneu-arriere-droit {
  left: 277px;
  top: 255px;
  position: absolute;
}

#voiture-dos-droite .pneu-arriere-droit a, #voiture-dos-droite .pneu-arriere-droit.selected, #voiture-dos-droite ul li.pneu-arriere-droit {
  width: 96px;
  height: 112px;
  position: absolute;
  z-index: 3;
}

#voiture-dos-droite .pneu-arriere-droit a:hover {
  background: $domDosDroite -0px -1065px no-repeat;
}

#voiture-dos-droite .pneu-arriere-droit.selected-rep, #voiture-dos-droite .pneu-arriere-droit.selected-rep a:hover {
  background: $domDosDroite -331px -1065px no-repeat;
}

#voiture-dos-droite .pneu-arriere-droit.selected-remp, #voiture-dos-droite .pneu-arriere-droit.selected-remp a:hover {
  background: $domDosDroite -654px -1065px no-repeat;
}

/* PNEU ARRIERE GAUCHE */
#voiture-dos-droite .jante-arriere-droite {
  left: 312px;
  top: 272px;
  position: absolute;
}

#voiture-dos-droite .jante-arriere-droite a, #voiture-dos-droite .jante-arriere-droite.selected, #voiture-dos-droite ul li.jante-arriere-droite {
  width: 53px;
  height: 78px;
  position: absolute;
  z-index: 3;
}

#voiture-dos-droite .jante-arriere-droite a:hover {
  background: $domDosDroite -103px -1082px no-repeat;
}

#voiture-dos-droite .jante-arriere-droite.selected-rep, #voiture-dos-droite .jante-arriere-droite.selected-rep a:hover {
  background: $domDosDroite -435px -1082px no-repeat;
}

#voiture-dos-droite .jante-arriere-droite.selected-remp, #voiture-dos-droite .jante-arriere-droite.selected-remp a:hover {
  background: $domDosDroite -758px -1082px no-repeat;
}

/* PORTE ARRIERE GAUCHE */
#voiture-dos-droite .porte-arriere-droite {
  left: 318px;
  top: 115px;
  position: absolute;
}

#voiture-dos-droite .porte-arriere-droite a, #voiture-dos-droite .porte-arriere-droite.selected, #voiture-dos-droite ul li.porte-arriere-droite {
  width: 146px;
  height: 192px;
  position: absolute;
  z-index: 2;
}

#voiture-dos-droite .porte-arriere-droite a:hover {
  background: $domDosDroite 0px -1202px no-repeat;
}

#voiture-dos-droite .porte-arriere-droite.selected-rep, #voiture-dos-droite .porte-arriere-droite.selected-rep a:hover {
  background: $domDosDroite -331px -1202px no-repeat;
}

#voiture-dos-droite .porte-arriere-droite.selected-remp, #voiture-dos-droite .porte-arriere-droite.selected-remp a:hover {
  background: $domDosDroite -654px -1202px no-repeat;
}

/* PORTE AVANT GAUCHE */
#voiture-dos-droite .porte-avant-droite {
  left: 418px;
  top: 116px;
  position: absolute;
}

#voiture-dos-droite .porte-avant-droite a, #voiture-dos-droite .porte-avant-droite.selected, #voiture-dos-droite ul li.porte-avant-droite {
  width: 140px;
  height: 184px;
  position: absolute;
  z-index: 1;
}

#voiture-dos-droite .porte-avant-droite a:hover {
  background: $domDosDroite -1px -1399px no-repeat;
}

#voiture-dos-droite .porte-avant-droite.selected-rep, #voiture-dos-droite .porte-avant-droite.selected-rep a:hover {
  background: $domDosDroite -333px -1399px no-repeat;
}

#voiture-dos-droite .porte-avant-droite.selected-remp, #voiture-dos-droite .porte-avant-droite.selected-remp a:hover {
  background: $domDosDroite -656px -1399px no-repeat;
}

/* VITRE ARRIERE GAUCHE */
#voiture-dos-droite .vitre-arriere-droite {
  left: 324px;
  top: 120px;
  position: absolute;
}

#voiture-dos-droite .vitre-arriere-droite a, #voiture-dos-droite .vitre-arriere-droite.selected, #voiture-dos-droite ul li.vitre-arriere-droite {
  width: 113px;
  height: 62px;
  position: absolute;
  z-index: 2;
}

#voiture-dos-droite .vitre-arriere-droite a:hover {
  background: $domDosDroite -1px -1590px no-repeat;
}

#voiture-dos-droite .vitre-arriere-droite.selected-rep, #voiture-dos-droite .vitre-arriere-droite.selected-rep a:hover {
  background: $domDosDroite -333px -1587px no-repeat;
}

#voiture-dos-droite .vitre-arriere-droite.selected-remp, #voiture-dos-droite .vitre-arriere-droite.selected-remp a:hover {
  background: $domDosDroite -656px -1587px no-repeat;
}

/* VITRE AVANT GAUCHE */
#voiture-dos-droite .vitre-avant-droite {
  left: 430px;
  top: 122px;
  position: absolute;
}

#voiture-dos-droite .vitre-avant-droite a, #voiture-dos-droite .vitre-avant-droite.selected, #voiture-dos-droite ul li.vitre-avant-droite {
  width: 99px;
  height: 67px;
  position: absolute;
  z-index: 2;
}

#voiture-dos-droite .vitre-avant-droite a:hover {
  background: $domDosDroite -3px -1657px no-repeat;
}

#voiture-dos-droite .vitre-avant-droite.selected-rep, #voiture-dos-droite .vitre-avant-droite.selected-rep a:hover {
  background: $domDosDroite -334px -1657px no-repeat;
}

#voiture-dos-droite .vitre-avant-droite.selected-remp, #voiture-dos-droite .vitre-avant-droite.selected-remp a:hover {
  background: $domDosDroite -657px -1657px no-repeat;
}

/* SOUBASSEMENT GAUCHE */
#voiture-dos-droite .soubassement-droit {
  left: 378px;
  top: 291px;
  position: absolute;
}

#voiture-dos-droite .soubassement-droit a, #voiture-dos-droite .soubassement-droit.selected, #voiture-dos-droite ul li.soubassement-droit {
  width: 178px;
  height: 33px;
  position: absolute;
  z-index: 2;
}

#voiture-dos-droite .soubassement-droit a:hover {
  background: $domDosDroite -1px -1730px no-repeat;
}

#voiture-dos-droite .soubassement-droit.selected-rep, #voiture-dos-droite .soubassement-droit.selected-rep a:hover {
  background: $domDosDroite -333px -1730px no-repeat;
}

#voiture-dos-droite .soubassement-droit.selected-remp, #voiture-dos-droite .soubassement-droit.selected-remp a:hover {
  background: $domDosDroite -656px -1730px no-repeat;
}

/* RETRO GAUCHE */
#voiture-dos-droite .retro-droit {
  left: 528px;
  top: 165px;
  position: absolute;
}

#voiture-dos-droite .retro-droit a, #voiture-dos-droite .retro-droit.selected, #voiture-dos-droite ul li.retro-droit {
  width: 25px;
  height: 24px;
  position: absolute;
  z-index: 1;
}

#voiture-dos-droite .retro-droit a:hover {
  background: $domDosDroite -1px -1767px no-repeat;
}

#voiture-dos-droite .retro-droit.selected-rep, #voiture-dos-droite .retro-droit.selected-rep a:hover {
  background: $domDosDroite -333px -1767px no-repeat;
}

#voiture-dos-droite .retro-droit.selected-remp, #voiture-dos-droite .retro-droit.selected-remp a:hover {
  background: $domDosDroite -656px -1767px no-repeat;
}

/* AILE AVANT GAUCHE */
#voiture-dos-droite .aile-avant-droite {
  left: 547px;
  top: 195px;
  position: absolute;
}

#voiture-dos-droite .aile-avant-droite a, #voiture-dos-droite .aile-avant-droite.selected, #voiture-dos-droite ul li.aile-avant-droite {
  width: 58px;
  height: 95px;
  position: absolute;
  z-index: 0;
}

#voiture-dos-droite .aile-avant-droite a:hover {
  background: $domDosDroite -1px -1799px no-repeat;
}

#voiture-dos-droite .aile-avant-droite.selected-rep, #voiture-dos-droite .aile-avant-droite.selected-rep a:hover {
  background: $domDosDroite -333px -1799px no-repeat;
}

#voiture-dos-droite .aile-avant-droite.selected-remp, #voiture-dos-droite .aile-avant-droite.selected-remp a:hover {
  background: $domDosDroite -656px -1799px no-repeat;
}

/* CAPOT*/
#voiture-dos-droite .capot {
  left: 545px;
  top: 185px;
  position: absolute;
}

#voiture-dos-droite .capot a, #voiture-dos-droite .capot.selected, #voiture-dos-droite ul li.capot {
  width: 53px;
  height: 18px;
  position: absolute;
  z-index: 1;
}

#voiture-dos-droite .capot a:hover {
  background: $domDosDroite -3px -1900px no-repeat;
}

#voiture-dos-droite .capot.selected-rep, #voiture-dos-droite .capot.selected-rep a:hover {
  background: $domDosDroite -334px -1900px no-repeat;
}

#voiture-dos-droite .capot.selected-remp, #voiture-dos-droite .capot.selected-remp a:hover {
  background: $domDosDroite -657px -1900px no-repeat;
}

/* PARE CHOC AVANT*/
#voiture-dos-droite .pare-choc-avant {
  left: 602px;
  top: 221px;
  position: absolute;
}

#voiture-dos-droite .pare-choc-avant a, #voiture-dos-droite .pare-choc-avant.selected, #voiture-dos-droite ul li.pare-choc-avant {
  width: 13px;
  height: 63px;
  position: absolute;
  z-index: 1;
}

#voiture-dos-droite .pare-choc-avant a:hover {
  background: $domDosDroite -0px -1926px no-repeat;
}

#voiture-dos-droite .pare-choc-avant.selected-rep, #voiture-dos-droite .pare-choc-avant.selected-rep a:hover {
  background: $domDosDroite -332px -1926px no-repeat;
}

#voiture-dos-droite .pare-choc-avant.selected-remp, #voiture-dos-droite .pare-choc-avant.selected-remp a:hover {
  background: $domDosDroite -655px -1926px no-repeat;
}

/* PHARE AVANT GAUCHE*/
#voiture-dos-droite .phare-avant-droit {
  left: 592px;
  top: 203px;
  position: absolute;
}

#voiture-dos-droite .phare-avant-droit a, #voiture-dos-droite .phare-avant-droit.selected, #voiture-dos-droite ul li.phare-avant-droit {
  width: 20px;
  height: 28px;
  position: absolute;
  z-index: 1;
}

#voiture-dos-droite .phare-avant-droit a:hover {
  background: $domDosDroite -0px -1997px no-repeat;
}

#voiture-dos-droite .phare-avant-droit.selected-rep, #voiture-dos-droite .phare-avant-droit.selected-rep a:hover {
  background: $domDosDroite -331px -1997px no-repeat;
}

#voiture-dos-droite .phare-avant-droit.selected-remp, #voiture-dos-droite .phare-avant-droit.selected-remp a:hover {
  background: $domDosDroite -654px -1997px no-repeat;
}

/* PNEU AVANT GAUCHE*/
#voiture-dos-droite .pneu-avant-droit {
  left: 545px;
  top: 234px;
  position: absolute;
}

#voiture-dos-droite .pneu-avant-droit a, #voiture-dos-droite .pneu-avant-droit.selected, #voiture-dos-droite ul li.pneu-avant-droit {
  width: 63px;
  height: 84px;
  position: absolute;
  z-index: 0;
}

#voiture-dos-droite .pneu-avant-droit a:hover {
  background: $domDosDroite -2px -2032px no-repeat;
}

#voiture-dos-droite .pneu-avant-droit.selected-rep, #voiture-dos-droite .pneu-avant-droit.selected-rep a:hover {
  background: $domDosDroite -333px -2032px no-repeat;
}

#voiture-dos-droite .pneu-avant-droit.selected-remp, #voiture-dos-droite .pneu-avant-droit.selected-remp a:hover {
  background: $domDosDroite -656px -2032px no-repeat;
}

/* JANTE AVANT GAUCHE*/
#voiture-dos-droite .jante-avant-droite {
  left: 566px;
  top: 247px;
  position: absolute;
}

#voiture-dos-droite .jante-avant-droite a, #voiture-dos-droite .jante-avant-droite.selected, #voiture-dos-droite ul li.jante-avant-droite {
  width: 35px;
  height: 59px;
  position: absolute;
  z-index: 0;
}

#voiture-dos-droite .jante-avant-droite a:hover {
  background: $domDosDroite -68px -2044px no-repeat;
}

#voiture-dos-droite .jante-avant-droite.selected-rep, #voiture-dos-droite .jante-avant-droite.selected-rep a:hover {
  background: $domDosDroite -403px -2044px no-repeat;
}

#voiture-dos-droite .jante-avant-droite.selected-remp, #voiture-dos-droite .jante-avant-droite.selected-remp a:hover {
  background: $domDosDroite -726px -2044px no-repeat;
}

/* PLAQUE IMMATRICULATION */
#voiture-dos-droite .plaque-immatriculation {
  left: 105px;
  top: 200px;
  position: absolute;
}

#voiture-dos-droite .plaque-immatriculation a {
  width: 70px;
  height: 35px;
  position: absolute;
  z-index: 4;
  cursor: default;
}

/* VOITURE DROITE */

/* PARECHOCAVANT */
#voiture-droite .pare-choc-avant {
  left: 618px;
  top: 217px;
  position: absolute;
}

#voiture-droite .pare-choc-avant a, #voiture-droite .pare-choc-avant.selected, #voiture-droite ul li.pare-choc-avant {
  width: 77px;
  height: 93px;
  position: absolute;
  z-index: 1;
}

#voiture-droite .pare-choc-avant a:hover {
  background: $domDroite -2px -393px no-repeat;
}

#voiture-droite .pare-choc-avant.selected-rep, #voiture-droite .pare-choc-avant.selected-rep a:hover {
  background: $domDroite -338px -393px no-repeat;
}

#voiture-droite .pare-choc-avant.selected-remp, #voiture-droite .pare-choc-avant.selected-remp a:hover {
  background: $domDroite -672px -393px no-repeat;
}

/* AILE AVANT GAUCHE */
#voiture-droite .aile-avant-droite {
  left: 496px;
  top: 188px;
  position: absolute;
}

#voiture-droite .aile-avant-droite a, #voiture-droite .aile-avant-droite.selected, #voiture-droite ul li.aile-avant-droite {
  width: 147px;
  height: 114px;
  position: absolute;
  z-index: 1;
}

#voiture-droite .aile-avant-droite a:hover {
  background: $domDroite -0px -489px no-repeat;
}

#voiture-droite .aile-avant-droite.selected-rep, #voiture-droite .aile-avant-droite.selected-rep a:hover {
  background: $domDroite -335px -489px no-repeat;
}

#voiture-droite .aile-avant-droite.selected-remp, #voiture-droite .aile-avant-droite.selected-remp a:hover {
  background: $domDroite -669px -489px no-repeat;
}

/* CAPOT */
#voiture-droite .capot {
  left: 505px;
  top: 176px;
  position: absolute;
}

#voiture-droite .capot a, #voiture-droite .capot.selected, #voiture-droite ul li.capot {
  width: 168px;
  height: 44px;
  position: absolute;
  z-index: 2;
}

#voiture-droite .capot a:hover {
  background: $domDroite -1px -608px no-repeat;
}

#voiture-droite .capot.selected-rep, #voiture-droite .capot.selected-rep a:hover {
  background: $domDroite -337px -608px no-repeat;
}

#voiture-droite .capot.selected-remp, #voiture-droite .capot.selected-remp a:hover {
  background: $domDroite -671px -608px no-repeat;
}

/* PNEU AVANT GAUCHE */
#voiture-droite .pneu-avant-droit {
  left: 528px;
  top: 242px;
  position: absolute;
}

#voiture-droite .pneu-avant-droit a, #voiture-droite .pneu-avant-droit.selected, #voiture-droite ul li.pneu-avant-droit {
  width: 101px;
  height: 102px;
  position: absolute;
  z-index: 2;
}

#voiture-droite .pneu-avant-droit a:hover {
  background: $domDroite -0px -655px no-repeat;
}

#voiture-droite .pneu-avant-droit.selected-rep, #voiture-droite .pneu-avant-droit.selected-rep a:hover {
  background: $domDroite -334px -655px no-repeat;
}

#voiture-droite .pneu-avant-droit.selected-remp, #voiture-droite .pneu-avant-droit.selected-remp a:hover {
  background: $domDroite -668px -655px no-repeat;
}

/* JANTE AVANT GAUCHE */
#voiture-droite .jante-avant-droite {
  left: 544px;
  top: 257px;
  position: absolute;
}

#voiture-droite .jante-avant-droite a, #voiture-droite .jante-avant-droite.selected, #voiture-droite ul li.jante-avant-droite {
  width: 71px;
  height: 71px;
  position: absolute;
  z-index: 2;
}

#voiture-droite .jante-avant-droite a:hover {
  background: $domDroite -107px -670px no-repeat;
}

#voiture-droite .jante-avant-droite.selected-rep, #voiture-droite .jante-avant-droite.selected-rep a:hover {
  background: $domDroite -443px -670px no-repeat;
}

#voiture-droite .jante-avant-droite.selected-remp, #voiture-droite .jante-avant-droite.selected-remp a:hover {
  background: $domDroite -777px -670px no-repeat;
}

/* PHARE AVANT GAUCHE */
#voiture-droite .phare-avant-droit {
  left: 628px;
  top: 210px;
  position: absolute;
}

#voiture-droite .phare-avant-droit a, #voiture-droite .phare-avant-droit.selected, #voiture-droite ul li.phare-avant-droit {
  width: 58px;
  height: 30px;
  position: absolute;
  z-index: 2;
}

#voiture-droite .phare-avant-droit a:hover {
  background: $domDroite 0px -761px no-repeat;
}

#voiture-droite .phare-avant-droit.selected-rep, #voiture-droite .phare-avant-droit.selected-rep a:hover {
  background: $domDroite -335px -761px no-repeat;
}

#voiture-droite .phare-avant-droit.selected-remp, #voiture-droite .phare-avant-droit.selected-remp a:hover {
  background: $domDroite -669px -761px no-repeat;
}

/* ANTI BROUILLARD AVANT GAUCHE*/
#voiture-droite .anti-brouillard-avant-droit {
  left: 670px;
  top: 275px;
  position: absolute;
}

#voiture-droite .anti-brouillard-avant-droit a, #voiture-droite .anti-brouillard-avant-droit.selected, #voiture-droite ul li.anti-brouillard-avant-droit {
  width: 12px;
  height: 25px;
  position: absolute;
  z-index: 2;
}

#voiture-droite .anti-brouillard-avant-droit a:hover {
  background: $domDroite 0px -795px no-repeat;
}

#voiture-droite .anti-brouillard-avant-droit.selected-rep, #voiture-droite .anti-brouillard-avant-droit.selected-rep a:hover {
  background: $domDroite -334px -795px no-repeat;
}

#voiture-droite .anti-brouillard-avant-droit.selected-remp, #voiture-droite .anti-brouillard-avant-droit.selected-remp a:hover {
  background: $domDroite -668px -795px no-repeat;
}

/* AILE ARRIERE GAUCHE */
#voiture-droite .aile-arriere-droite {
  left: 66px;
  top: 106px;
  position: absolute;
}

#voiture-droite .aile-arriere-droite a, #voiture-droite .aile-arriere-droite.selected, #voiture-droite ul li.aile-arriere-droite {
  width: 465px;
  height: 171px;
  position: absolute;
  z-index: 0;
}

#voiture-droite .aile-arriere-droite a:hover {
  background: $domDroite -0px -841px no-repeat;
}

#voiture-droite .aile-arriere-droite.selected-rep, #voiture-droite .aile-arriere-droite.selected-rep a:hover {
  background: $domDroite -0px -1019px no-repeat;
}

#voiture-droite .aile-arriere-droite.selected-remp, #voiture-droite .aile-arriere-droite.selected-remp a:hover {
  background: $domDroite -559px -1019px no-repeat;
}

/* PARE BRISE */
#voiture-droite .pare-brise {
  left: 409px;
  top: 120px;
  position: absolute;
}

#voiture-droite .pare-brise a, #voiture-droite .pare-brise.selected, #voiture-droite ul li.pare-brise {
  width: 120px;
  height: 60px;
  position: absolute;
  z-index: 1;
}

#voiture-droite .pare-brise a:hover {
  background: $domDroite -0px -1196px no-repeat;
}

#voiture-droite .pare-brise.selected-rep, #voiture-droite .pare-brise.selected-rep a:hover {
  background: $domDroite -335px -1196px no-repeat;
}

#voiture-droite .pare-brise.selected-remp, #voiture-droite .pare-brise.selected-remp a:hover {
  background: $domDroite -669px -1196px no-repeat;
}

/* PORTE AVANT GAUCHE */
#voiture-droite .porte-avant-droite {
  left: 314px;
  top: 116px;
  position: absolute;
}

#voiture-droite .porte-avant-droite a, #voiture-droite .porte-avant-droite.selected, #voiture-droite ul li.porte-avant-droite {
  width: 197px;
  height: 185px;
  position: absolute;
  z-index: 0;
}

#voiture-droite .porte-avant-droite a:hover {
  background: $domDroite -0px -1259px no-repeat;
}

#voiture-droite .porte-avant-droite.selected-rep, #voiture-droite .porte-avant-droite.selected-rep a:hover {
  background: $domDroite -334px -1259px no-repeat;
}

#voiture-droite .porte-avant-droite.selected-remp, #voiture-droite .porte-avant-droite.selected-remp a:hover {
  background: $domDroite -668px -1259px no-repeat;
}

/* PORTE ARRIERE GAUCHE */
#voiture-droite .porte-arriere-droite {
  left: 166px;
  top: 116px;
  position: absolute;
}

#voiture-droite .porte-arriere-droite a, #voiture-droite .porte-arriere-droite.selected, #voiture-droite ul li.porte-arriere-droite {
  width: 167px;
  height: 181px;
  position: absolute;
  z-index: 0;
}

#voiture-droite .porte-arriere-droite a:hover {
  background: $domDroite -1px -1450px no-repeat;
}

#voiture-droite .porte-arriere-droite.selected-rep, #voiture-droite .porte-arriere-droite.selected-rep a:hover {
  background: $domDroite -336px -1450px no-repeat;
}

#voiture-droite .porte-arriere-droite.selected-remp, #voiture-droite .porte-arriere-droite.selected-remp a:hover {
  background: $domDroite -670px -1450px no-repeat;
}

/* VITRE AVANT GAUCHE */
#voiture-droite .vitre-avant-droite {
  left: 325px;
  top: 121px;
  position: absolute;
}

#voiture-droite .vitre-avant-droite a, #voiture-droite .vitre-avant-droite.selected, #voiture-droite ul li.vitre-avant-droite {
  width: 138px;
  height: 64px;
  position: absolute;
  z-index: 1;
}

#voiture-droite .vitre-avant-droite a:hover {
  background: $domDroite -1px -1635px no-repeat;
}

#voiture-droite .vitre-avant-droite.selected-rep, #voiture-droite .vitre-avant-droite.selected-rep a:hover {
  background: $domDroite -335px -1635px no-repeat;
}

#voiture-droite .vitre-avant-droite.selected-remp, #voiture-droite .vitre-avant-droite.selected-remp a:hover {
  background: $domDroite -669px -1635px no-repeat;
}

/* VITRE ARRIERE GAUCHE */
#voiture-droite .vitre-arriere-droite {
  left: 173px;
  top: 121px;
  position: absolute;
}

#voiture-droite .vitre-arriere-droite a, #voiture-droite .vitre-arriere-droite.selected, #voiture-droite ul li.vitre-arriere-droite {
  width: 141px;
  height: 55px;
  position: absolute;
  z-index: 1;
}

#voiture-droite .vitre-arriere-droite a:hover {
  background: $domDroite -0px -1701px no-repeat;
}

#voiture-droite .vitre-arriere-droite.selected-rep, #voiture-droite .vitre-arriere-droite.selected-rep a:hover {
  background: $domDroite -335px -1701px no-repeat;
}

#voiture-droite .vitre-arriere-droite.selected-remp, #voiture-droite .vitre-arriere-droite.selected-remp a:hover {
  background: $domDroite -669px -1701px no-repeat;
}

/* RETRO GAUCHE */
#voiture-droite .retro-droit {
  left: 458px;
  top: 160px;
  position: absolute;
}

#voiture-droite .retro-droit a, #voiture-droite .retro-droit.selected, #voiture-droite ul li.retro-droit {
  width: 32px;
  height: 30px;
  position: absolute;
  z-index: 2;
}

#voiture-droite .retro-droit a:hover {
  background: $domDroite -1px -1759px no-repeat;
}

#voiture-droite .retro-droit.selected-rep, #voiture-droite .retro-droit.selected-rep a:hover {
  background: $domDroite -337px -1759px no-repeat;
}

#voiture-droite .retro-droit.selected-remp, #voiture-droite .retro-droit.selected-remp a:hover {
  background: $domDroite -671px -1759px no-repeat;
}

/* SOUBASSEMENT GAUCHE */
#voiture-droite .soubassement-droit {
  left: 202px;
  top: 276px;
  position: absolute;
}

#voiture-droite .soubassement-droit a, #voiture-droite .soubassement-droit.selected, #voiture-droite ul li.soubassement-droit {
  width: 315px;
  height: 37px;
  position: absolute;
  z-index: 2;
}

#voiture-droite .soubassement-droit a:hover {
  background: $domDroite 0px -1794px no-repeat;
}

#voiture-droite .soubassement-droit.selected-rep, #voiture-droite .soubassement-droit.selected-rep a:hover {
  background: $domDroite -335px -1794px no-repeat;
}

#voiture-droite .soubassement-droit.selected-remp, #voiture-droite .soubassement-droit.selected-remp a:hover {
  background: $domDroite -669px -1794px no-repeat;
}

/* PARE CHOC ARRIERE */
#voiture-droite .pare-choc-arriere {
  left: 42px;
  top: 200px;
  position: absolute;
}

#voiture-droite .pare-choc-arriere a, #voiture-droite .pare-choc-arriere.selected, #voiture-droite ul li.pare-choc-arriere {
  width: 69px;
  height: 99px;
  position: absolute;
  z-index: 1;
}

#voiture-droite .pare-choc-arriere a:hover {
  background: $domDroite -1px -1837px no-repeat;
}

#voiture-droite .pare-choc-arriere.selected-rep, #voiture-droite .pare-choc-arriere.selected-rep a:hover {
  background: $domDroite -336px -1837px no-repeat;
}

#voiture-droite .pare-choc-arriere.selected-remp, #voiture-droite .pare-choc-arriere.selected-remp a:hover {
  background: $domDroite -670px -1837px no-repeat;
}

/* PNEU ARRIERE GAUCHE */
#voiture-droite .pneu-arriere-droit {
  left: 95px;
  top: 242px;
  position: absolute;
}

#voiture-droite .pneu-arriere-droit a, #voiture-droite .pneu-arriere-droit.selected, #voiture-droite ul li.pneu-arriere-droit {
  width: 101px;
  height: 101px;
  position: absolute;
  z-index: 1;
}

#voiture-droite .pneu-arriere-droit a:hover {
  background: $domDroite -1px -1941px no-repeat;
}

#voiture-droite .pneu-arriere-droit.selected-rep, #voiture-droite .pneu-arriere-droit.selected-rep a:hover {
  background: $domDroite -336px -1941px no-repeat;
}

#voiture-droite .pneu-arriere-droit.selected-remp, #voiture-droite .pneu-arriere-droit.selected-remp a:hover {
  background: $domDroite -670px -1941px no-repeat;
}

/* JANTE ARRIERE GAUCHE */
#voiture-droite .jante-arriere-droite {
  left: 110px;
  top: 257px;
  position: absolute;
}

#voiture-droite .jante-arriere-droite a, #voiture-droite .jante-arriere-droite.selected, #voiture-droite ul li.jante-arriere-droite {
  width: 71px;
  height: 71px;
  position: absolute;
  z-index: 1;
}

#voiture-droite .jante-arriere-droite a:hover {
  background: $domDroite -112px -1956px no-repeat;
}

#voiture-droite .jante-arriere-droite.selected-rep, #voiture-droite .jante-arriere-droite.selected-rep a:hover {
  background: $domDroite -449px -1956px no-repeat;
}

#voiture-droite .jante-arriere-droite.selected-remp, #voiture-droite .jante-arriere-droite.selected-remp a:hover {
  background: $domDroite -783px -1956px no-repeat;
}

/* HAYON */
#voiture-droite .hayon-malle {
  left: 53px;
  top: 117px;
  position: absolute;
}

#voiture-droite .hayon-malle a, #voiture-droite .hayon-malle.selected, #voiture-droite ul li.hayon-malle {
  width: 82px;
  height: 75px;
  position: absolute;
  z-index: 1;
}

#voiture-droite .hayon-malle a:hover {
  background: $domDroite -1px -2047px no-repeat;
}

#voiture-droite .hayon-malle.selected-rep, #voiture-droite .hayon-malle.selected-rep a:hover {
  background: $domDroite -336px -2047px no-repeat;
}

#voiture-droite .hayon-malle.selected-remp, #voiture-droite .hayon-malle.selected-remp a:hover {
  background: $domDroite -670px -2047px no-repeat;
}

/* VITRE HAYON */
#voiture-droite .vitre-hayon {
  left: 67px;
  top: 128px;
  position: absolute;
}

#voiture-droite .vitre-hayon a, #voiture-droite .vitre-hayon.selected, #voiture-droite ul li.vitre-hayon {
  width: 40px;
  height: 37px;
  position: absolute;
  z-index: 1;
}

#voiture-droite .vitre-hayon a:hover {
  background: $domDroite -1px -2136px no-repeat;
}

#voiture-droite .vitre-hayon.selected-rep, #voiture-droite .vitre-hayon.selected-rep a:hover {
  background: $domDroite -336px -2135px no-repeat;
}

#voiture-droite .vitre-hayon.selected-remp, #voiture-droite .vitre-hayon.selected-remp a:hover {
  background: $domDroite -670px -2135px no-repeat;
}

/* PHARE ARRIERE GAUCHE */
#voiture-droite .phare-arriere-droit {
  left: 53px;
  top: 173px;
  position: absolute;
}

#voiture-droite .phare-arriere-droit a, #voiture-droite .phare-arriere-droit.selected, #voiture-droite ul li.phare-arriere-droit {
  width: 55px;
  height: 31px;
  position: absolute;
  z-index: 1;
}

#voiture-droite .phare-arriere-droit a:hover {
  background: $domDroite -1px -2178px no-repeat;
}

#voiture-droite .phare-arriere-droit.selected-rep, #voiture-droite .phare-arriere-droit.selected-rep a:hover {
  background: $domDroite -335px -2178px no-repeat;
}

#voiture-droite .phare-arriere-droit.selected-remp, #voiture-droite .phare-arriere-droit.selected-remp a:hover {
  background: $domDroite -669px -2178px no-repeat;
}

/* VOITURE FACE DROITE */

/* PARE CHOC AVANT */
#voiture-face-droite .pare-choc-avant {
  left: 419px;
  top: 215px;
  position: absolute;
}

#voiture-face-droite .pare-choc-avant a, #voiture-face-droite .pare-choc-avant.selected, #voiture-face-droite ul li.pare-choc-avant {
  width: 250px;
  height: 118px;
  position: absolute;
  z-index: 1;
}

#voiture-face-droite .pare-choc-avant a:hover {
  background: $domFaceDroite -0px -402px no-repeat;
}

#voiture-face-droite .pare-choc-avant.selected-rep, #voiture-face-droite .pare-choc-avant.selected-rep a:hover {
  background: $domFaceDroite -334px -402px no-repeat;
}

#voiture-face-droite .pare-choc-avant.selected-remp, #voiture-face-droite .pare-choc-avant.selected-remp a:hover {
  background: $domFaceDroite -666px -402px no-repeat;
}

/* PNEU ARRIERE DROIT */
#voiture-face-droite .pneu-arriere-droit {
  left: 124px;
  top: 224px;
  position: absolute;
}

#voiture-face-droite .pneu-arriere-droit a, #voiture-face-droite .pneu-arriere-droit.selected, #voiture-face-droite ul li.pneu-arriere-droit {
  width: 68px;
  height: 85px;
  position: absolute;
  z-index: 2;
}

#voiture-face-droite .pneu-arriere-droit a:hover {
  background: $domFaceDroite -1px -637px no-repeat;
}

#voiture-face-droite .pneu-arriere-droit.selected-rep, #voiture-face-droite .pneu-arriere-droit.selected-rep a:hover {
  background: $domFaceDroite -336px -637px no-repeat;
}

#voiture-face-droite .pneu-arriere-droit.selected-remp, #voiture-face-droite .pneu-arriere-droit.selected-remp a:hover {
  background: $domFaceDroite -668px -637px no-repeat;
}

/* PNEU AVANT DROIT */
#voiture-face-droite .pneu-avant-droit {
  left: 357px;
  top: 248px;
  position: absolute;
}

#voiture-face-droite .pneu-avant-droit a, #voiture-face-droite .pneu-avant-droit.selected, #voiture-face-droite ul li.pneu-avant-droit {
  width: 95px;
  height: 112px;
  position: absolute;
  z-index: 4;
}

#voiture-face-droite .pneu-avant-droit a:hover {
  background: $domFaceDroite -1px -522px no-repeat;
}

#voiture-face-droite .pneu-avant-droit.selected-rep, #voiture-face-droite .pneu-avant-droit.selected-rep a:hover {
  background: $domFaceDroite -336px -522px no-repeat;
}

#voiture-face-droite .pneu-avant-droit.selected-remp, #voiture-face-droite .pneu-avant-droit.selected-remp a:hover {
  background: $domFaceDroite -668px -522px no-repeat;
}

/* JANTE ARRIERE DROITE */
#voiture-face-droite .jante-arriere-droite {
  left: 130px;
  top: 236px;
  position: absolute;
}

#voiture-face-droite .jante-arriere-droite a, #voiture-face-droite .jante-arriere-droite.selected, #voiture-face-droite ul li.jante-arriere-droite {
  width: 35px;
  height: 60px;
  position: absolute;
  z-index: 2;
}

#voiture-face-droite .jante-arriere-droite a:hover {
  background: $domFaceDroite -77px -649px no-repeat;
}

#voiture-face-droite .jante-arriere-droite.selected-rep, #voiture-face-droite .jante-arriere-droite.selected-rep a:hover {
  background: $domFaceDroite -412px -649px no-repeat;
}

#voiture-face-droite .jante-arriere-droite.selected-remp, #voiture-face-droite .jante-arriere-droite.selected-remp a:hover {
  background: $domFaceDroite -744px -649px no-repeat;
}

/* JANTE AVANT DROITE */
#voiture-face-droite .jante-avant-droite {
  left: 366px;
  top: 264px;
  position: absolute;
}

#voiture-face-droite .jante-avant-droite a, #voiture-face-droite .jante-avant-droite.selected, #voiture-face-droite ul li.jante-avant-droite {
  width: 54px;
  height: 79px;
  position: absolute;
  z-index: 4;
}

#voiture-face-droite .jante-avant-droite a:hover {
  background: $domFaceDroite -105px -538px no-repeat;
}

#voiture-face-droite .jante-avant-droite.selected-rep, #voiture-face-droite .jante-avant-droite.selected-rep a:hover {
  background: $domFaceDroite -442px -538px no-repeat;
}

#voiture-face-droite .jante-avant-droite.selected-remp, #voiture-face-droite .jante-avant-droite.selected-remp a:hover {
  background: $domFaceDroite -774px -538px no-repeat;
}

/* PNEU AVANT GAUCHE */
#voiture-face-droite .pneu-avant-gauche {
  left: 582px;
  top: 325px;
  position: absolute;
}

#voiture-face-droite .pneu-avant-gauche a, #voiture-face-droite .pneu-avant-gauche.selected, #voiture-face-droite ul li.pneu-avant-gauche {
  width: 23px;
  height: 8px;
  position: absolute;
  z-index: 2;
}

#voiture-face-droite .pneu-avant-gauche a:hover {
  background: $domFaceDroite -3px -726px no-repeat;
}

#voiture-face-droite .pneu-avant-gauche.selected-rep, #voiture-face-droite .pneu-avant-gauche.selected-rep a:hover {
  background: $domFaceDroite -337px -726px no-repeat;
}

#voiture-face-droite .pneu-avant-gauche.selected-remp, #voiture-face-droite .pneu-avant-gauche.selected-remp a:hover {
  background: $domFaceDroite -669px -726px no-repeat;
}

/* CALANDRE */
#voiture-face-droite .calandre {
  left: 539px;
  top: 216px;
  position: absolute;
}

#voiture-face-droite .calandre a, #voiture-face-droite .calandre.selected, #voiture-face-droite ul li.calandre {
  width: 114px;
  height: 49px;
  position: absolute;
  z-index: 2;
}

#voiture-face-droite .calandre a:hover {
  background: $domFaceDroite -0px -734px no-repeat;
}

#voiture-face-droite .calandre.selected-rep, #voiture-face-droite .calandre.selected-rep a:hover {
  background: $domFaceDroite -336px -734px no-repeat;
}

#voiture-face-droite .calandre.selected-remp, #voiture-face-droite .calandre.selected-remp a:hover {
  background: $domFaceDroite -668px -734px no-repeat;
}

/* AILE AVANT GAUCHE */
#voiture-face-droite .aile-avant-droite {
  left: 336px;
  top: 192px;
  position: absolute;
}

#voiture-face-droite .aile-avant-droite a, #voiture-face-droite .aile-avant-droite.selected, #voiture-face-droite ul li.aile-avant-droite {
  width: 115px;
  height: 116px;
  position: absolute;
  z-index: 3;
}

#voiture-face-droite .aile-avant-droite a:hover {
  background: $domFaceDroite -1px -787px no-repeat;
}

#voiture-face-droite .aile-avant-droite.selected-rep, #voiture-face-droite .aile-avant-droite.selected-rep a:hover {
  background: $domFaceDroite -336px -787px no-repeat;
}

#voiture-face-droite .aile-avant-droite.selected-remp, #voiture-face-droite .aile-avant-droite.selected-remp a:hover {
  background: $domFaceDroite -668px -787px no-repeat;
}

/* SOUBASSEMENT GAUCHE */
#voiture-face-droite .soubassement-droit {
  left: 175px;
  top: 262px;
  position: absolute;
}

#voiture-face-droite .soubassement-droit a, #voiture-face-droite .soubassement-droit.selected, #voiture-face-droite ul li.soubassement-droit {
  width: 177px;
  height: 57px;
  position: absolute;
  z-index: 2;
}

#voiture-face-droite .soubassement-droit a:hover {
  background: $domFaceDroite -1px -906px no-repeat;
}

#voiture-face-droite .soubassement-droit.selected-rep, #voiture-face-droite .soubassement-droit.selected-rep a:hover {
  background: $domFaceDroite -335px -906px no-repeat;
}

#voiture-face-droite .soubassement-droit.selected-remp, #voiture-face-droite .soubassement-droit.selected-remp a:hover {
  background: $domFaceDroite -667px -906px no-repeat;
}

/* PHARE AVANT GAUCHE */
#voiture-face-droite .phare-avant-droit {
  left: 447px;
  top: 216px;
  position: absolute;
}

#voiture-face-droite .phare-avant-droit a, #voiture-face-droite .phare-avant-droit.selected, #voiture-face-droite ul li.phare-avant-droit {
  width: 95px;
  height: 45px;
  position: absolute;
  z-index: 3;
}

#voiture-face-droite .phare-avant-droit a:hover {
  background: $domFaceDroite -1px -966px no-repeat;
}

#voiture-face-droite .phare-avant-droit.selected-rep, #voiture-face-droite .phare-avant-droit.selected-rep a:hover {
  background: $domFaceDroite -336px -966px no-repeat;
}

#voiture-face-droite .phare-avant-droit.selected-remp, #voiture-face-droite .phare-avant-droit.selected-remp a:hover {
  background: $domFaceDroite -668px -966px no-repeat;
}

/* ANTI BROUILLARD AVANT GAUCHE */
#voiture-face-droite .anti-brouillard-avant-droit {
  left: 475px;
  top: 298px;
  position: absolute;
}

#voiture-face-droite .anti-brouillard-avant-droit a, #voiture-face-droite .anti-brouillard-avant-droit.selected, #voiture-face-droite ul li.anti-brouillard-avant-droit {
  width: 23px;
  height: 26px;
  position: absolute;
  z-index: 2;
}

#voiture-face-droite .anti-brouillard-avant-droit a:hover {
  background: $domFaceDroite -0px -1014px no-repeat;
}

#voiture-face-droite .anti-brouillard-avant-droit.selected-rep, #voiture-face-droite .anti-brouillard-avant-droit.selected-rep a:hover {
  background: $domFaceDroite -335px -1014px no-repeat;
}

#voiture-face-droite .anti-brouillard-avant-droit.selected-remp, #voiture-face-droite .anti-brouillard-avant-droit.selected-remp a:hover {
  background: $domFaceDroite -667px -1014px no-repeat;
}

/* CAPOT */
#voiture-face-droite .capot {
  left: 349px;
  top: 176px;
  position: absolute;
}

#voiture-face-droite .capot a, #voiture-face-droite .capot.selected, #voiture-face-droite ul li.capot {
  width: 300px;
  height: 56px;
  position: absolute;
  z-index: 1;
}

#voiture-face-droite .capot a:hover {
  background: $domFaceDroite -0px -1044px no-repeat;
}

#voiture-face-droite .capot.selected-rep, #voiture-face-droite .capot.selected-rep a:hover {
  background: $domFaceDroite -335px -1044px no-repeat;
}

#voiture-face-droite .capot.selected-remp, #voiture-face-droite .capot.selected-remp a:hover {
  background: $domFaceDroite -667px -1044px no-repeat;
}

/* PARE BRISE */
#voiture-face-droite .pare-brise {
  left: 321px;
  top: 114px;
  position: absolute;
}

#voiture-face-droite .pare-brise a, #voiture-face-droite .pare-brise.selected, #voiture-face-droite ul li.pare-brise {
  width: 226px;
  height: 68px;
  position: absolute;
  z-index: 1;
}

#voiture-face-droite .pare-brise a:hover {
  background: $domFaceDroite -5px -1103px no-repeat;
}

#voiture-face-droite .pare-brise.selected-rep, #voiture-face-droite .pare-brise.selected-rep a:hover {
  background: $domFaceDroite -339px -1103px no-repeat;
}

#voiture-face-droite .pare-brise.selected-remp, #voiture-face-droite .pare-brise.selected-remp a:hover {
  background: $domFaceDroite -671px -1103px no-repeat;
}

/* ESSUIE GLACE AVANT */
#voiture-face-droite .essuie-glace-avant {
  left: 380px;
  top: 172px;
  position: absolute;
}

#voiture-face-droite .essuie-glace-avant a, #voiture-face-droite .essuie-glace-avant.selected, #voiture-face-droite ul li.essuie-glace-avant {
  width: 160px;
  height: 10px;
  position: absolute;
  z-index: 1;
}

#voiture-face-droite .essuie-glace-avant a:hover {
  background: $domFaceDroite -0px -1172px no-repeat;
}

#voiture-face-droite .essuie-glace-avant.selected-rep, #voiture-face-droite .essuie-glace-avant.selected-rep a:hover {
  background: $domFaceDroite -334px -1172px no-repeat;
}

#voiture-face-droite .essuie-glace-avant.selected-remp, #voiture-face-droite .essuie-glace-avant.selected-remp a:hover {
  background: $domFaceDroite -666px -1172px no-repeat;
}

/* RETROVISEUR GAUCHE */
#voiture-face-droite .retro-droit {
  left: 291px;
  top: 157px;
  position: absolute;
}

#voiture-face-droite .retro-droit a, #voiture-face-droite .retro-droit.selected, #voiture-face-droite ul li.retro-droit {
  width: 52px;
  height: 31px;
  position: absolute;
  z-index: 1;
}

#voiture-face-droite .retro-droit a:hover {
  background: $domFaceDroite -0px -1183px no-repeat;
}

#voiture-face-droite .retro-droit.selected-rep, #voiture-face-droite .retro-droit.selected-rep a:hover {
  background: $domFaceDroite -335px -1183px no-repeat;
}

#voiture-face-droite .retro-droit.selected-remp, #voiture-face-droite .retro-droit.selected-remp a:hover {
  background: $domFaceDroite -667px -1183px no-repeat;
}

/* RETROVISEUR DROIT */
#voiture-face-droite .retro-gauche {
  left: 515px;
  top: 156px;
  position: absolute;
}

#voiture-face-droite .retro-gauche a, #voiture-face-droite .retro-gauche.selected, #voiture-face-droite ul li.retro-gauche {
  width: 20px;
  height: 12px;
  position: absolute;
  z-index: 1;
}

#voiture-face-droite .retro-gauche a:hover {
  background: $domFaceDroite -0px -1217px no-repeat;
}

#voiture-face-droite .retro-gauche.selected-rep, #voiture-face-droite .retro-gauche.selected-rep a:hover {
  background: $domFaceDroite -335px -1217px no-repeat;
}

#voiture-face-droite .retro-gauche.selected-remp, #voiture-face-droite .retro-gauche.selected-remp a:hover {
  background: $domFaceDroite -667px -1217px no-repeat;
}

/* PAVILLON */
#voiture-face-droite .pavillon {
  left: 253px;
  top: 102px;
  position: absolute;
}

#voiture-face-droite .pavillon a, #voiture-face-droite .pavillon.selected, #voiture-face-droite ul li.pavillon {
  width: 196px;
  height: 18px;
  position: absolute;
  z-index: 1;
}

#voiture-face-droite .pavillon a:hover {
  background: $domFaceDroite -5px -1234px no-repeat;
}

#voiture-face-droite .pavillon.selected-rep, #voiture-face-droite .pavillon.selected-rep a:hover {
  background: $domFaceDroite -339px -1234px no-repeat;
}

#voiture-face-droite .pavillon.selected-remp, #voiture-face-droite .pavillon.selected-remp a:hover {
  background: $domFaceDroite -671px -1234px no-repeat;
}

/* AILE ARRIERE GAUCHE */
#voiture-face-droite .aile-arriere-droite {
  left: 132px;
  top: 103px;
  position: absolute;
}

#voiture-face-droite .aile-arriere-droite a, #voiture-face-droite .aile-arriere-droite.selected, #voiture-face-droite ul li.aile-arriere-droite {
  width: 236px;
  height: 162px;
  position: absolute;
  z-index: 0;
}

#voiture-face-droite .aile-arriere-droite a:hover {
  background: $domFaceDroite -1px -1261px no-repeat;
}

#voiture-face-droite .aile-arriere-droite.selected-rep, #voiture-face-droite .aile-arriere-droite.selected-rep a:hover {
  background: $domFaceDroite -336px -1261px no-repeat;
}

#voiture-face-droite .aile-arriere-droite.selected-remp, #voiture-face-droite .aile-arriere-droite.selected-remp a:hover {
  background: $domFaceDroite -668px -1261px no-repeat;
}

/* PARE CHOC ARRIERE */
#voiture-face-droite .pare-choc-arriere {
  left: 123px;
  top: 189px;
  position: absolute;
}

#voiture-face-droite .pare-choc-arriere a, #voiture-face-droite .pare-choc-arriere.selected, #voiture-face-droite ul li.pare-choc-arriere {
  width: 12px;
  height: 50px;
  position: absolute;
  z-index: 1;
}

#voiture-face-droite .pare-choc-arriere a:hover {
  background: $domFaceDroite -1px -1427px no-repeat;
}

#voiture-face-droite .pare-choc-arriere.selected-rep, #voiture-face-droite .pare-choc-arriere.selected-rep a:hover {
  background: $domFaceDroite -335px -1427px no-repeat;
}

#voiture-face-droite .pare-choc-arriere.selected-remp, #voiture-face-droite .pare-choc-arriere.selected-remp a:hover {
  background: $domFaceDroite -667px -1427px no-repeat;
}

/* PORTE AVANT GAUCHE */
#voiture-face-droite .porte-avant-droite {
  left: 237px;
  top: 108px;
  position: absolute;
}

#voiture-face-droite .porte-avant-droite a, #voiture-face-droite .porte-avant-droite.selected, #voiture-face-droite ul li.porte-avant-droite {
  width: 116px;
  height: 197px;
  position: absolute;
  z-index: 0;
}

#voiture-face-droite .porte-avant-droite a:hover {
  background: $domFaceDroite -1px -1479px no-repeat;
}

#voiture-face-droite .porte-avant-droite.selected-rep, #voiture-face-droite .porte-avant-droite.selected-rep a:hover {
  background: $domFaceDroite -336px -1479px no-repeat;
}

#voiture-face-droite .porte-avant-droite.selected-remp, #voiture-face-droite .porte-avant-droite.selected-remp a:hover {
  background: $domFaceDroite -668px -1479px no-repeat;
}

/* PORTE ARRIERE GAUCHE */
#voiture-face-droite .porte-arriere-droite {
  left: 166px;
  top: 108px;
  position: absolute;
}

#voiture-face-droite .porte-arriere-droite a, #voiture-face-droite .porte-arriere-droite.selected, #voiture-face-droite ul li.porte-arriere-droite {
  width: 97px;
  height: 178px;
  position: absolute;
  z-index: 0;
}

#voiture-face-droite .porte-arriere-droite a:hover {
  background: $domFaceDroite -2px -1678px no-repeat;
}

#voiture-face-droite .porte-arriere-droite.selected-rep, #voiture-face-droite .porte-arriere-droite.selected-rep a:hover {
  background: $domFaceDroite -337px -1678px no-repeat;
}

#voiture-face-droite .porte-arriere-droite.selected-remp, #voiture-face-droite .porte-arriere-droite.selected-remp a:hover {
  background: $domFaceDroite -669px -1678px no-repeat;
}

/* VITRE AVANT GAUCHE */
#voiture-face-droite .vitre-avant-droite {
  left: 248px;
  top: 115px;
  position: absolute;
}

#voiture-face-droite .vitre-avant-droite a, #voiture-face-droite .vitre-avant-droite.selected, #voiture-face-droite ul li.vitre-avant-droite {
  width: 87px;
  height: 65px;
  position: absolute;
  z-index: 0;
}

#voiture-face-droite .vitre-avant-droite a:hover {
  background: $domFaceDroite -0px -1861px no-repeat;
}

#voiture-face-droite .vitre-avant-droite.selected-rep, #voiture-face-droite .vitre-avant-droite.selected-rep a:hover {
  background: $domFaceDroite -335px -1861px no-repeat;
}

#voiture-face-droite .vitre-avant-droite.selected-remp, #voiture-face-droite .vitre-avant-droite.selected-remp a:hover {
  background: $domFaceDroite -667px -1861px no-repeat;
}

/* VITRE ARRIERE GAUCHE */
#voiture-face-droite .vitre-arriere-droite {
  left: 177px;
  top: 114px;
  position: absolute;
}

#voiture-face-droite .vitre-arriere-droite a, #voiture-face-droite .vitre-arriere-droite.selected, #voiture-face-droite ul li.vitre-arriere-droite {
  width: 77px;
  height: 57px;
  position: absolute;
  z-index: 0;
}

#voiture-face-droite .vitre-arriere-droite a:hover {
  background: $domFaceDroite -0px -1931px no-repeat;
}

#voiture-face-droite .vitre-arriere-droite.selected-rep, #voiture-face-droite .vitre-arriere-droite.selected-rep a:hover {
  background: $domFaceDroite -336px -1931px no-repeat;
}

#voiture-face-droite .vitre-arriere-droite.selected-remp, #voiture-face-droite .vitre-arriere-droite.selected-remp a:hover {
  background: $domFaceDroite -668px -1931px no-repeat;
}

/* PHARE ARRIERE GAUCHE */
#voiture-face-droite .phare-arriere-droit {
  left: 128px;
  top: 166px;
  position: absolute;
}

#voiture-face-droite .phare-arriere-droit a, #voiture-face-droite .phare-arriere-droit.selected, #voiture-face-droite ul li.phare-arriere-droit {
  width: 16px;
  height: 26px;
  position: absolute;
  z-index: 0;
}

#voiture-face-droite .phare-arriere-droit a:hover {
  background: $domFaceDroite -0px -1990px no-repeat;
}

#voiture-face-droite .phare-arriere-droit.selected-rep, #voiture-face-droite .phare-arriere-droit.selected-rep a:hover {
  background: $domFaceDroite -336px -1990px no-repeat;
}

#voiture-face-droite .phare-arriere-droit.selected-remp, #voiture-face-droite .phare-arriere-droit.selected-remp a:hover {
  background: $domFaceDroite -668px -1990px no-repeat;
}

/* PLAQUE IMMATRICULATION */
#voiture-face-droite .plaque-immatriculation {
  left: 585px;
  top: 267px;
  position: absolute;
}

#voiture-face-droite .plaque-immatriculation a {
  width: 60px;
  height: 25px;
  position: absolute;
  z-index: 4;
  cursor: default;
}


.carViewbutton{
  width: 40px;
  height: 40px;
  overflow: auto;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  @include shadowBox(0.3);
  cursor : pointer;
  font-family: EM-icomoon;
  &.previousCarView{
    left: 0;
  }
  &.nextCarView {
    right: 0;
  }
  i {
    font-size: 2em;
    line-height: 40px;
    text-align: center;
    display: block;
    color: $gris1;
  }
}
