#vmapTarifsLivraison{
  height: 400px;
  margin-bottom: 1em;
  @media only screen {
    @media(max-width: $screen-xs-max) {
      height: 300px;
    }
  }
}

.regionSelectedNoResults, .regionSelectedResults{
  display: none;
}
.warrantieServices{
  padding: 15px;
    p.serviceVo{
        margin: 0;
    }
}

.serviceBanner{
  margin-bottom: 1em;
  padding: 0;
  img{
    max-width : 100%;
  }
}

.serviceBannerCenter {
  margin-bottom: 1em;
  padding: 0;
}

.serviceUl ul li{
  list-style: disc;
  margin-left: 30px;
}

.serviceVignette{
  text-align: center;
  margin-bottom: 0.5em;
  .servicesThumbnails{
    margin:1em;
  }
  .servicesThumbnailsLarge{
    margin: 2em;
  }
}

.serviceVn a, .serviceVo a{
  text-decoration: underline;
  font-weight: bold;
}
.serviceVn a:hover, .serviceVo a:hover{
  text-decoration: none;
  font-weight: normal;
}

.serviceVo a{
    color: $bleu;
}

.mapFrance {
  width: 100%;

  @media(max-width: $screen-xs-max) {
    width: auto;
  }
}

.padding-right-2 {
  @media(min-width: $screen-sm-max) {
    padding-right: 2em;
  }
}

.tabColorEm.nav-tabs.nav-justified {
  li {
    font-size: 0.85em;
    text-transform: capitalize;
    display: table-cell;
    width: 1%;

    @media(max-width: $screen-md-max) {
      font-size: 0.70em;
    }

    a {
      border-radius: 0;
      margin-bottom: 0;
    }

    &.active {
      a {
        background: $bleuEm!important;
        color: $blanc;
        border: 0;
      }
    }

    span {
      display: block;
      font-size: 0.90em;
    }
  }
}

.labelDeliveryChoices {
  @media(max-width: $screen-xs-max) {
    text-align: center;
  }
}

.horizontalDashedStep {
  border-bottom: 2px dashed $bleuEm;
  margin-top: 17px;

  &:before, &:after {
    content: "\e903";
    font-family: EM-icomoon-next, monospace;
    font-size: 8px;
    position: absolute;
    top: 12px;
    color: $bleuEm;
  }

  &:before {
    left: 0;
  }

  &:after {
    right: 0;
  }
}

.verticalDashedStep {
  border-left: 2px dashed $bleuEm;
  height: 50px;
  width: 2px;
  left: 50%;
  position: relative;
  margin-bottom: 5px;
  margin-left: -1px;

  &:before, &:after {
    content: "\e903";
    font-family: EM-icomoon-next, monospace;
    font-size: 8px;
    position: absolute;
    color: $bleuEm;
    left: -5px;
  }

  &:before {
    top: -5px;
  }

  &:after {
    bottom: -5px;
  }
}

#contentDeliverySteps {
  margin-bottom: 15px;

  .titleForm {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 10px;
  }

  label {
    font-size: 13px;
  }

  input {
    margin-bottom: 20px;
  }

  .additionalOptions {
    background: $grisDisabled;
    margin: -10px;
    padding: 15px 20px;

    label {
      font-size: 0.9em;
    }
  }
}

.safety{
  .media-left{
    padding-right: 25px;
    .media-object{
      max-width: 200px;
    }
  }
}
