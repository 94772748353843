.lazy {

  .containerArticleCard {
    .articleCard.articleCardHome {
      @media screen and (min-width: 992px) and (max-width: 1299px) {
        min-height: 540px;
      }

      @media screen and (max-width: 991px) {
        min-height: 320px;
      }

      @media screen and (max-width: 576px) {
        min-height: 365px;
      }
    }
  }

  .slick-next {
    right: -50px;
  }

  .slick-prev {
    left: -50px;
  }

  .slick-next, .slick-prev {
    margin-top: -20px;
  }
}