.badge{
  font-weight: 900;
}

@each $couleurClass in $tabCouleurs {

  .#{nth($couleurClass, 1)} {

    &.badge{
      .list-group-item.active & {
        color: $blanc;
        background-color: nth($couleurClass, 2) !important;
      }
      color: $blanc;
      background-color: nth($couleurClass, 2);
    }
  }
}