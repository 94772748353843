.noPadding {
  @media only screen {
    @media(max-width: $screen-sm-min) {
      padding: 0;
    }
  }
}

.floatRight{
  float: right;
  @media only screen {
    @media(max-width: $screen-sm-max){
      float: left;
    }
  }
}

.row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.img100, .w-100{
  width: 100%;
}

#carouselSelectionOffres{
  .carousel-inner{
    height: 100%;
    overflow: initial;
    .carousel-control{
      position: absolute;
      bottom: 5%;
      top: auto;
      //transform: translateY(-50%);
      width: 40px;
      height: 40px;
      opacity: 1;
      background: none;
      display: none;
      @media(max-width: $screen-md-min) {
        display: block;
      }
      @media(max-width: $screen-sm-min) {
        bottom: auto;
        top: -12px;
      }

      span{
        text-shadow: none;
        box-shadow: none;
        &:before{
          font-size: 40px;
          color: #abafb1;

        }
      }
    }
    a.left{
      left: 25px;
      @media(max-width: $screen-xs-min) {
        left: 10px;
      }
    }
    a.right{
      right: 25px;
      @media(max-width: $screen-xs-min) {
        right: 10px;
      }
    }
  }
}

.containHalfButton {
  text-align: center;
  width: 100%;
  margin-top: -2.3em;
  height: 60px;
  &.halfAbsolute{
    position: absolute;
    left: 0;
    bottom: 0;
  }
  >* {
    white-space: nowrap;
    @include shadowBox(0.3);
  }
  >.btn{
    display: inline-block;
    position: initial;
    margin: 0 0 -22px;
    box-shadow: none;
  }
}


.vcenter{
  display: inline-block;
  vertical-align: middle;
  float: none;
}

.rounded-bottom {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
}

.rounded-bottom-left {
    border-bottom-left-radius: 16px;
}
.rounded-bottom-right {
    border-bottom-right-radius: 16px;
}

.relativePosition{
  position: relative;
}

.shadow {
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
}

.padding-left-20 {
    padding-left: 20px;
}
