.modal {
  .modal-content {
    border-radius: 0;
    border-width: 0;

    .modal-header {
      border-bottom-width: 0;
    }

    .modal-footer {
      border-top-width: 0;
    }
    .h4{
      margin: 0;
    }
  }
}

.modal.in {
    > .modal-dialog {
        min-height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
        > .modal-content {
            margin: 30px auto;
        }
    }
}

.modal-backdrop {
  background-color: $bleuEm;
}

.modal-xxl {
    width: 90%;
}

.modal-fullscreen {
  background: transparent;

  .popupBox {
    position: absolute;
    display: block;
    width:100%;
    margin:auto;
    top:50%;
    transform: translateY(-50%);
  }
  .modal-dialog {
    margin: auto;
    width: 50%;
    background-color: #fff;

    .modal-content {
      background: transparent;
      border: 0;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }
}
  #modalMap, #modalRappel{
    .modal-dialog {
      background-color: #fff;
      max-width: 600px;
    }
  }


  #modalRappel {
    .modal-dialog{
      width: 600px;

      @media screen and (max-width: 767px){
        width: 96%;
        text-align:center;
      }
    }
    .icon-rappel{
      width: 169px;
      height: 169px;
    }

     .modal-body p > span{
        display: block;
        font-size: 1.2em;
        margin-bottom: 5px;
    }
  }
