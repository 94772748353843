.containEnteteResultatsRecherche{
  @media only screen {
    @media(max-width: $screen-sm-max) {
      text-align: center;
    }
  }
  margin-top: 1em;
  margin-bottom: 1em;

  h1.carListTitle {
    font-size: 2.5rem;

    @media(max-width: $screen-sm-max) {
      font-size: 2rem;
    }
  }

  #resultSatisfactionQuestion .btn{
    position: relative;
    z-index: 2;
  }
}
