// Créé les classes small-1 à small-5 (small-1 = 0.9em et small-5 = 0.5em)
$i: 5;
@while ($i>0) {
  .small-#{$i} {
    font-size: 1em - (0.1 * $i) !important;
  }
  $i: $i - 1;
}

// Créé les classes big-1 à big-5 (big-1 =1.1em et big-5 = 1.5em)
$i: 10;
@while ($i>0) {
  .big-#{$i} {
    font-size: 1em + (0.1 * $i);
  }
  $i: $i - 1;
}

.light {
  font-weight: 300;
}

.regular {
  font-weight: 400;
}

.text-uppercase {
    text-transform: uppercase !important;
}
.text-transform-none {
    text-transform: none;
}

.bold {
  font-weight: 700;
}

.italic{
  font-style: italic;
}

.underline{
  text-decoration: underline;
  &:hover{
    text-decoration: underline;
  }
}
.text-bigger{
  font-size: 1.2em;
}
.text-xs-left { text-align: left; }
.text-xs-right { text-align: right; }
.text-xs-center { text-align: center; }
.text-xs-justify { text-align: justify; }

@media (min-width: $screen-sm-min) {
  .text-sm-left { text-align: left; }
  .text-sm-right { text-align: right; }
  .text-sm-center { text-align: center; }
  .text-sm-justify { text-align: justify; }
}

@media (min-width: $screen-md-min) {
  .text-md-left { text-align: left; }
  .text-md-right { text-align: right; }
  .text-md-center { text-align: center; }
  .text-md-justify { text-align: justify; }
}

@media (min-width: $screen-lg-min) {
  .text-lg-left { text-align: left; }
  .text-lg-right { text-align: right; }
  .text-lg-center { text-align: center; }
  .text-lg-justify { text-align: justify; }
}

.line-height-normal {
    line-height: normal;
}
