@each $couleurContain in $tabCouleurs {
  $nomVariable : str-ucfirst(nth($couleurContain, 1));
  .contain#{nth($nomVariable, 1)} {
    .btn {
      //@extend .btn-#{nth($couleurContain, 1)};
      @include button-variant($blanc, nth($couleurContain, 2), nth($couleurContain, 2));
      &.noHover {
        &:hover, &:focus {
          background-color: nth($couleurContain, 2);
          border-color: nth($couleurContain, 2);
        }
      }
    }
    a:hover, a:focus {
      color: nth($couleurContain, 2);
    }
    .titreCarte{
      background-color: nth($couleurContain, 2);
    }
  }
  .contain#{nth($nomVariable, 1)}.titreCarte{
    background-color: nth($couleurContain, 2);
  }
}

.bg-gray {
    background-color: $gris1 !important;
}

.bg-white {
    background-color: $blanc !important;
}

.bg-orange {
    background-color: $orange;
}

.containBlanc {
  .btn {
    color: $bleuEm !important;
    //@include button-variant($gris1, $blanc, $blanc);
    &:hover, &:focus {
      color: $bleuEm !important;
      text-shadow: none;
    }
  }
  a:hover, a:focus {
    color: $bleuEm;
  }

}


.bg-gris7 {
    background-color: $grisDisabled;
}
