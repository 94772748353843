$lignetimeline : 4px;

.timeline {
  list-style: none;
  padding: 20px 0 20px;
  position: relative;
  &:before {
    top: 0;
    bottom: 0;
    position: absolute;
    content: " ";
    width: $lignetimeline;
    background-color: $gris2;
    left: 50%;
    margin-left: -$lignetimeline/2;
  }
  > li {
    margin-bottom: 2em;
    position: relative;
    &:before,
    &:after {
      content: " ";
      display: table;
    }
    &:after {
      clear: both;
    }
    &.timeline-inverted{
      > .timeline-panel {
        float: right;
        &:before {
          border-left-width: 0;
          border-right-width: 15px;
          left: -15px;
          right: auto;
        }
        &:after {
          border-left-width: 0;
          border-right-width: 14px;
          left: -14px;
          right: auto;
        }
      }
    }
    > .timeline-panel {
      width: 46%;
      float: left;
      padding: 1em;
      position: relative;
      @include whiteCard;
      font-size: 1.1em;
      &:before {
        position: absolute;
        top: 26px;
        right: -15px;
        display: inline-block;
        border-top: 15px solid transparent;
        border-left: 15px solid #ccc;
        border-right: 0 solid #ccc;
        border-bottom: 15px solid transparent;
        content: " ";
      }
      &:after {
        position: absolute;
        top: 27px;
        right: -14px;
        display: inline-block;
        border-top: 14px solid transparent;
        border-left: 14px solid #fff;
        border-right: 0 solid #fff;
        border-bottom: 14px solid transparent;
        content: " ";
      }
      .timeline-heading{
        margin-bottom: 1em;
      }
      img{
        @include shadowBox(0.2);
        width: 100%;
        margin-bottom: 1em;
        &:last-child{
          margin-bottom: 0em;
        }
      }
      p:last-child{
        margin-bottom: 0;
      }
      a{
        margin-top: 1em;
        display: inline-block;
      }
    }
    > .timeline-badge {
      color: #fff;
      width: 50px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      font-weight: 700;
      position: absolute;
      top: 16px;
      left: 50%;
      margin-left: -25px;
      background-color: $bleuEm;
      z-index: 100;
      //@include border-radius(50%); //since webpack
      border-radius: 50%;
      @include shadowBox(0.3);
    }

  }
}
@media (max-width: $screen-xs-max) {
  ul.timeline:before {
    left: 40px;
  }
  ul.timeline > li > .timeline-panel {
    width: calc(100% - 90px);
    width: -moz-calc(100% - 90px);
    width: -webkit-calc(100% - 90px);
  }

  ul.timeline > li > .timeline-badge {
    left: 15px;
    margin-left: 0;
    top: 16px;
  }

  ul.timeline > li > .timeline-panel {
    float: right;
  }

  ul.timeline > li > .timeline-panel:before {
    border-left-width: 0;
    border-right-width: 15px;
    left: -15px;
    right: auto;
  }

  ul.timeline > li > .timeline-panel:after {
    border-left-width: 0;
    border-right-width: 14px;
    left: -14px;
    right: auto;
  }
}

.timeline-horizontal {
    $heightCircle: 5px;
    $heightLine: 1px;
    position: relative;
    ul {
        display: flex;
        width: 100%;
        color: $vert;
        margin: 0;
        padding: 0;
        justify-content: space-between;
        li {
            list-style: none;
            position: relative;
            text-align: center;
            text-transform: uppercase;
            flex: 1 1 0;
            padding-bottom: 7px;
            margin-bottom: 5px;
            > span  {
                font-size: .9rem;
                text-transform: none;
            }
            > .tooltip {
                width: 200px !important;
                max-width: 200px !important;
                min-width: unset;
                .tooltip-inner {
                    width: 200px !important;
                    max-width: 200px !important;
                    min-width: unset;
                }
            }
            &:before{
                content: '';
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                width: $heightCircle;
                height: $heightCircle;
                background-color: $vert;
                border-radius: 50%;
                display: block;
                text-align: center;
                margin: auto 0;
                color: #000;
                transition: all ease-in-out .3s;
                z-index: 2;
            }
            &:after {
                content: "";
                position: absolute;
                width: 100%;
                height: $heightLine;
                background-color: $vert;
                bottom: ($heightCircle - $heightLine)/2;
                left: 0;
                z-index: 1;
                transition: all ease-in-out .3s;
            }
            &:first-child:after {
                left: 50%;
            }
            &:first-child, &:last-child{
                &:after{
                    width: 50%;
                }
            }
            &.active {
                &:before{
                    background: $vert;
                    color: $blanc;
                }
                &:after {
                    background: $vert;
                }
                ~ li {
                    &:after {
                        background-color: $gris1;
                    }
                    &:before {
                        background-color: $gris1;
                    }
                    color: $gris1;
                }
            }
        }
        &.disabled {
            li {
                &:after {
                    background-color: $gris1;
                }
                &:before {
                    background-color: $gris1;
                }
                color: $gris1;
            }
        }
    }
}
