body > header {
  @include shadowBox(0.3);
  background-color: $blanc;
  border-bottom: 3px solid $bleuEm;
  position: fixed;
  z-index: 205;
  width: 100%;
  top: 0;

  .container {
    //padding-top: 0.5rem;
    //padding-bottom: 0.5rem;
    position: relative;
    @media (max-width: $screen-md-max){
        padding: 1em 0;
    }
    @media (max-width: $screen-xs-max){
        padding: 1em 0.5rem;
    }
    .logo {
        padding-left: 0;
        padding-top: 2px;
        @media (max-width: $screen-lg-max) {
            text-align: center;
        }
        @media (max-width: $screen-xs-max) {
            padding-right: 0;
            text-align: right;
            &.logo-home{
                text-align: center;
            }
        }
      img {
        max-width: 130px;
      }
    }
  }

  nav {
    background-color: $blanc;
    position: fixed !important;
    z-index: 201;
    @media(max-width: $screen-md-max) {
      max-width: 500px !important;
      @include transition-duration(0.5s);
      @include easeInOut;
    }
    left: -100%;
    padding: 0 !important;
    font-size: 1.4em;
    overflow: auto;
    @include shadowBoxInset(0.3);
    &.is-active {
      @include easeInOut;
      left: 0;
        .contactNav, .helpNav{
            padding: 0.5em 1em;
        }
        .contactNav{
            border-bottom: 1px solid #d6d6d6;
            border-top: 1px solid #d6d6d6;
        }
    }
    @media(min-width: $screen-lg-min) {
      position: relative !important;
      overflow: hidden;
      left: 0;
      text-align: center;
      @include shadowBoxInset(0);
      padding: inherit;
    }
    > ul {
      @include liststyle();
      margin-bottom: 0;
      .btnMenuFinancement {
        margin-top: 1em;
      }
      @media(min-width: $screen-lg-min) {
        display: inline-block;
        padding: 0 10px;
        position: relative;
      }
      > li {
        font-weight: 700;
        @media(min-width: $screen-lg-min) {
          list-style: none;
          display: inline-block;
          text-align: center;
          margin-right: 10px;
          position: relative;
          z-index: 2;
          &.panel {
            border-bottom-width: 0;
            box-shadow: none;
            line-height: 55px;
            font-size: 0.9em;
          }
          &:last-child {
            margin-right: 0;
          }
        }
        > a {
          width: 100%;
          display: block;
          padding: 0.5em 1em;
          @media(min-width: $screen-lg-min) {
            display: inline-block;
            width: 100%;
            text-align: center;
            padding: 0;
          }
          &:after {
            content: "\f107";
            right: 1em;
            position: absolute;
            font-family: EM-icomoon;
            font-size: 1.2em;
            color: $bleuEm;
            @include transition-duration(0.25s);
            @include easeInOut();
          }
          @media(min-width: $screen-lg-min) {
            &:after {
              content: '';
            }
          }
          &[aria-expanded=true]:after {
            transform: rotate(180deg);
            @include transition-duration(0.25s);
            @include easeInOut();
          }
        }
        > ul {
          list-style: none;
          text-align: left;
          @media(min-width: $screen-lg-min) {
            display: none;
          }
          @include shadowBoxInsetTopBottom(0.3);
          > li {

            > div {
              padding: 1em;
              &.allVehicules {
                padding-top: 0.5em;
                padding-bottom: 0;
                h6, .h6 {
                  padding-bottom: 0.5em;
                }
              }
              &.byBrand {
                padding-top: 0;
                padding-bottom: 0;
                h6, .h6 {
                  padding-bottom: 0.5em;
                }
              }
              &.brandCatalog {
                padding-top: 0;
                padding-bottom: 0.5em;
                hr {
                  margin-bottom: 0;
                }

              }
              @media(min-width: $screen-lg-min) {
                padding-bottom: 0;
              }
            }
            > ul {
              padding: 0px;
              &.textColonne {
                column-count: 2;
                @media(min-width: $screen-lg-min) {
                  column-count: 3;
                }
                padding: 0 1em;
              }
              &:not(.textColonne) {
                > li {
                  padding: 0.5em 1em;
                  border-bottom: 1px solid $gris2;
                }
              }
              > li {
                font-size: 0.8em;
                line-height: 1.75em;
                &.divider{
                    padding-bottom: 27px;
                    position: relative;
                    &:after{
                        content: '';
                        display: inline-block;
                        width: 100%;
                        height : 7px;
                        zoom: 0.5;
                        position: absolute;
                        bottom: 19px;
                        left: 0;
                        right: 0;
                        background : url('../../../../resources/front/images/css/dots.svg') 0 0 repeat-x;
                    }
                }
                &.needHelpMenu .btn {
                  margin-top: 0.5em;
                  margin-bottom: 0.5em;
                }
              }
            }
          }
          &#menuUtilitaire{
            ul li{
                &.divider{
                    @media (max-width: $screen-lg-max){
                        padding-bottom: 0;
                        &:after{
                            display: none;
                        }
                    }
                }
            }
          }
        }
      }
      &:last-child {
        > li {
          > a {
            &:after {
              content: "";
            }
          }
        }
      }
    }
    .panel {
      border-radius: 0;
      margin-bottom: 0;
      border-bottom: 1px solid $gris3;
    }
    /** {
      color: $bleuEm;
    }*/

    .regroupeBSTourCompte a {
      padding: 0.5em 1em;
    }
    hr.small {
      margin: 1.25rem 0 1.25rem;
    }
    #menuLoa li{
     line-height: 1.4;
    }
  }
  &.desktop {
    nav {
      overflow: visible;
    }
    .containSousSection {
      @include liststyle();
      position: absolute;
      background-color: #fff;
      top: 55px;
      border-bottom: 3px solid $bleuEm;
      min-width: 380px;
      line-height: 1.4;
      &.AgenciesSousSection {
        min-width: 280px;
      }
    }
    .menuCompte{
        top: 56px;
        text-align: left;
    }
  }

  &.mobile {
    .lienAdditionnelsHeader {
      font-size: 0.8em;
      padding: 0.5em 1.3em;
    }

    .containSousSection{
      @include liststyle();
      max-height: 350px;
      overflow-y: scroll;
    }

  }
  .lienAdditionnelsHeader {
    text-align: right;
    padding-left: 0;
    padding-right: 0;
    a:not(.btn-bleuEM), a:not(.btn-bleuEM):hover {
      color: $bleuEm;
    }
    color: $bleuEm;
  }

  #headerHamburger {
      margin-top: 8px;
    .bar{
        display: block;
        width: 30px;
        height: 5px;
        background: $gris1;
        border-radius: 18%;
        position: absolute;
        left: 15px;
        &.top{
            top: 0;
            transition: all 0.5s ease;
            &.active{
                top: 9px;
                transform: rotate(140deg);
            }
        }
        &.middle{
            top: 9px;
            opacity: 1;
            transition: all 0.3s ease;
            &.active{
                opacity: 0;
            }
        }
        &.bottom{
            top: 18px;
            transition: all 0.5s ease;
            &.active{
                top: 9px;
                transform: rotate(39deg);
            }
        }
    }
  }

  #headerSearch {
    text-align: right;
    cursor: pointer;
    position: relative;
    height: 100%;
      @media (max-width: $screen-xs-max){
          margin-right: 0.5rem;
      }
    .shape{
        display: block;
        margin: 0 auto;
        position: absolute;
        transition: all 0.3s ease;
        &.round{
            width: 22px;
            height: 22px;
            border: 4px solid $gris1;
            border-radius: 50%;
            background: none;
            top: 3px;
            left: 18px;
            &.active{
                width: 4px;
                height: 25px;
                border: none;
                border-radius: 20px;
                background: $gris1;
                transform: rotate(50deg);
                top: 4px;
                left: 32px;
            }
        }
        &.bar{
            width: 4px;
            height: 15px;
            background-color: $gris1;
            border-radius: 20px;
            top: 17px;
            left: 39px;
            transform: rotate(-46deg);
            &.active{
                width: 4px;
                height: 25px;
                background-color: $gris1;
                top: 4px;
                left: 33px;
            }
        }
    }
  }
  .telHeader {
    //text-align: right;
      width: 100%;
      @media (max-width: $screen-lg-max){
          text-align: center;
      }
    a, a:hover {
      color: $orange;
      font-weight: 700;
      font-size: 1.3em;
      letter-spacing: 1.4px;
    }
  }
    .container-info-comp{
        padding: 0;
    }
    .containerAgencies{
        width: 100%;
    }
    .container-tel-agencies{
        //padding-left: 5rem;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        @media (max-width: $screen-lg-max){
            width: 60%;
            padding-left: 0;
            .input-group .jcf-select-drop .jcf-list .jcf-option:first-child{
                border: none !important;
            }
        }
        @media (max-width: $screen-md-max){
            margin: 1.5rem auto 4rem;
        }
        @media (max-width: $screen-xs-max) {
            width: 70%;
        }
        .agency{
            background: $bleuEm;
            color: #ffffff;
            font-size: 1.2rem;
            border-radius: 0.5rem;
            padding: 0.5rem;
            text-align: center;
            margin-bottom: 0;
            line-height: 1.2;
        }
    }
    .input-group{
        .jcf-select{
            width: 100%;
            background: $bleuEm;
            color: #ffffff;
            margin: 0;
            height: auto;
            padding: 0.5rem;
            border: none;
            border-radius: 0.5rem;
            box-shadow: none;
            text-align: center;
            &:before{
                color: #ffffff;
                line-height: 1.2;
                right: 6px;
                top: 2px;
            }
            &.jcf-drop-active{
                border-radius: 0.5rem 0.5rem 0 0;
                box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
            }
            .jcf-select-text{
                margin: 0;
                font-size: 1.2rem;
                line-height: 1.2;

            }
        }
        &.btn-language{
            float: left;
            border-left: 1px solid $bleuEm;
            @media (max-width: $screen-md-max){
                border: 1px solid $bleuEm;
                border-radius: 0.5rem;
                padding: 0.5rem 0.25rem;
            }
            @media (max-width: $screen-xs-max){
                float: left;
                display: inline-block;
                padding: 0.5rem 0.25rem;
            }
            .jcf-select{
                background: none;
                box-shadow: none;
                color: $bleuEm;
                padding: 0;
                width: auto;
                text-align: left;
                @media (max-width: $screen-xs-max){
                    width: 80%;
                    &:before{
                        top: 0;
                    }
                }
                &:before{
                    right: 0;
                    color: $bleuEm;
                }
                .jcf-select-text{
                    @media (max-width: $screen-xs-max){
                        line-height: 0.8;
                    }
                    .jcf-option-icon-lang{
                        font-size: 0;
                        line-height: 25px;
                        @media (max-width: $screen-xs-max) {
                            padding: 0;
                            line-height: 1;
                            &:before{
                                position: initial;
                                left: initial;
                                top: initial;
                                transform: none;
                            }
                        }
                    }
                }
            }
            .jcf-option-icon-lang{
                position: relative;
                line-height: 2;
                padding: 1rem 1rem 1rem 4rem;
                &:before{
                    content: '';
                    display: inline-block;
                    width: 25px;
                    height: 25px;
                    position: absolute;
                    left: 1rem;
                    top: 50%;
                    transform: translateY(-50%);
                    @media (max-width: $screen-xs-max){
                        width: 20px;
                        height: 20px;
                    }
                }
            }
            .jcf-option-icon-eng{
                &:before{
                    background: url('../../../../resources/front/images/languages/icon-eng.png') no-repeat;
                    background-size: contain;
                }
            }
            .jcf-option-icon-fra{
                &:before{
                    background: url('../../../../resources/front/images/languages/icon-fra.png') no-repeat;
                    background-size: contain;
                }
            }
            .jcf-option-icon-por{
                &:before{
                    background: url('../../../../resources/front/images/languages/icon-por.png') no-repeat;
                    background-size: contain;
                }
            }
            .jcf-option-icon-deu{
                &:before{
                    background: url('../../../../resources/front/images/languages/icon-deu.png') no-repeat;
                    background-size: contain;
                }
            }
            .jcf-select-drop{
                width: 110px !important;
                margin-top: 0.5rem;
                @media (max-width: $screen-lg-max){
                    left: initial;
                }
                @media (max-width: $screen-md-max) {
                    left: 0;
                }
                @media (max-width: $screen-xs-max){
                    left: initial;
                }
            }
        }
        .jcf-select-drop{
            border-radius: 0 0 0.5rem 0.5rem;
            overflow: hidden;
            left: 0;
            right: 0;
            &.jcf-select-form-control{
                .jcf-select-drop-content{
                    border: none;
                }
            }
            .jcf-list{
                background: #ffffff;
                .jcf-option{
                    border-bottom: 1px solid $bleuEm !important;
                    cursor: pointer;
                    &:first-child{
                        font-size: 1.2rem;
                    }
                }
            }
            .jcf-list-content{
                .jcf-hover{
                    background: $bleuEm;
                    color: #ffffff;
                }
            }

            li:last-child {
                .jcf-option{
                    border-bottom: none !important;
                }
            }
        }
    }
.container-btn{
    display: flex;
    align-items: center;
    @media (max-width: $screen-lg-max){
        margin: 0;
        justify-content: flex-end;
    }
    @media (max-width: $screen-md-max){
        align-items: stretch;
    }
}
}
#coverMenu {
display: none;
position: fixed;
top: 0;
width: 100%;
height: 100%;
z-index: 190;
background-color: $bleuEm;
opacity: 0.75;
@include easeInOut;
}

.menuAccordionHeader{
.tooltip{
    .tooltip-inner{
        min-width: auto;
    }
}
}

.input-group{
    .jcf-focus, .jcf-focus * {
        border-color: transparent !important;
    }
}

.header-pro {
    position: fixed;
    width: 100%;
    top: 56px;
    background-color: $rose;
    z-index: 204;
    text-align: center;
    color: #fff;
    @include shadowBox(0.3);
    > p {
        margin: 5px;
        font-size: 1.6rem;
    }
}
