.blackFridayContainer {
  background: url("../../../../resources/front/images/headband/black-friday.jpg") no-repeat center center;
  color: #fff;
  margin-top: 2em;
  padding: 25px;

  @media screen and (max-width: 767px) {
    margin-left: 10px;
    margin-right: 10px;
  }

  .title {
    font-size: 4.6em;

    @media screen and (max-width: 767px) {
      font-size: 2.2em;
    }
  }

  .subtitle {
    font-size: 2.3em;
    font-weight: 100;

    @media screen and (max-width: 991px) {
      font-size: 1.8em;
    }

    @media screen and (max-width: 767px) {
      font-size: 1.4em;
    }
  }

  .countDown  {
    border: 5px solid #f9b718;
    color: #f9b718;
    margin: 25px auto;
    padding: 12px 15px 8px 15px;
    display: inline-block;

    div {
      display: inline-block;
      margin: 0 10px;

      @media screen and (max-width: 767px) {
        margin: 0 5px;
      }

      .time {
        font-size: 60px;
        line-height: 43px;

        @media screen and (max-width: 767px) {
          font-size: 37px;
          line-height: 37px;
        }
      }

      .pointTime {
        display: inline-block;
      }

      span {
        font-size: 18px;
        display: block;
        font-weight: 100;

        @media screen and (max-width: 767px) {
          font-size: 14px;
        }
      }
    }
  }
}
