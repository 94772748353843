.containIllustration {
  color: $blanc;
  position: relative;
  padding: 3em 0;
  background: linear-gradient(top, rgba(34, 40, 109, 0) 0%, rgba(8, 8, 17, 0.75) 100%);
  //@include background(linear-gradient(top, rgba(34, 40, 109, 0) 0%, rgba(8, 8, 17, 0.75) 100%));// since webpack
  background-repeat: no-repeat;
  background-size: cover;
  //@include background-size(cover);// since webpack
  background-position: center center;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    background-color: $bleuEm;
    opacity: 0.7;
  }
  * {
    position: relative;
    z-index: 1;
    @include shadowText(1);
  }
}
