@mixin shadowText($opacity) {
  -webkit-text-shadow: 0px 0px 10px rgba(0, 0, 0, $opacity);
  -moz-text-shadow: 0px 0px 10px rgba(0, 0, 0, $opacity);
  text-shadow: 0px 0px 10px rgba(0, 0, 0, $opacity);
}

@mixin shadowBox($opacity) {
  -moz-box-shadow: 0px 0px 10px rgba(0, 0, 0, $opacity);
  -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, $opacity);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, $opacity);
}

@mixin shadowBoxInset($opacity) {
  -webkit-text-shadow: inset 1px 8px 9px -6px rgba(0, 0, 0, $opacity);
  -moz-text-shadow: inset 1px 8px 9px -6px rgba(0, 0, 0, $opacity);
  box-shadow: inset 1px 8px 9px -6px rgba(0, 0, 0, $opacity);
}

@mixin shadowBoxInsetTopBottom($opacity) {
  -webkit-text-shadow: inset 1px 8px 9px -6px rgba(0, 0, 0, $opacity), inset 1px -8px 9px -6px rgba(0, 0, 0, $opacity);
  -moz-text-shadow: inset 1px 8px 9px -6px rgba(0, 0, 0, $opacity), inset 1px -8px 9px -6px rgba(0, 0, 0, $opacity);
  box-shadow: inset 1px 8px 9px -6px rgba(0, 0, 0, $opacity), inset 1px -8px 9px -6px rgba(0, 0, 0, $opacity);

}

@mixin shadowBoxInsetTop($opacity) {
  -webkit-text-shadow: inset 0 5px 10px -5px rgba(0, 0, 0, $opacity);
  -moz-text-shadow: inset 0 5px 10px -5px rgba(0, 0, 0, $opacity);
  box-shadow: inset 0 5px 10px -5px rgba(0, 0, 0, $opacity);
}