@import "../variables/variables";

$paddingInHeight: 10px;
.cookie-consent-container {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: $blanc;
    box-shadow: 0 0 10px $gris5;
    z-index: 9999;
    .cookie-consent {
        width: 100%;
        padding: $paddingInHeight 10px;

        &__title {
            margin-top: 0;
            font-size: 2.5rem;
        }

        &__form {
            display: flex;
            flex-direction: column;
            max-height: calc(100vh - 20px);
            max-height: calc(var(--vh, 1vh) * 100 - 20px);
        }

        &__scrollable {
            overflow-y: auto;
        }

        &__category {
            border-bottom: 1px solid $bleuEm;
            padding: 10px 0;
            display: flex;
            flex-direction: column;

            &-group {
                display: none;
                margin-bottom: 20px;
                //overflow: auto;
            }

            &:first-child {
                border-top: 1px solid $bleuEm;
            }


            &-toggle {
                $toggleHeight: 30px;
                $toggleWidth: 100px;
                $toggleBorder: 2px;
                $toggleSwitchWith: $toggleWidth / 2 - $toggleBorder;
                $toggleSwitchHeight: $toggleHeight - 2 * $toggleBorder;
                $speedTransition: .3s;
                display: flex;
                align-items: center;

                &-title {
                    font-size: 2rem;
                    margin: 0;
                }

                /* Hide default HTML checkbox */
                > input {
                    opacity: 0;
                    width: 0;
                    height: 0;
                    &:checked + label {
                        background-color: $promo-color;
                        &:before {
                            transform: translateX($toggleSwitchWith);
                            content: attr(data-checked);
                            color: $promo-color;
                        }
                        &:after {
                            position: absolute;
                            content: attr(data-unchecked);
                            left: $toggleBorder;
                            right: unset;
                        }
                    }
                    &:disabled + label {
                        opacity: .6;
                    }
                    + label {
                        margin-left: auto;
                        position: relative;
                        cursor: pointer;
                        width: $toggleWidth;
                        height: $toggleHeight;
                        border-radius: 2px;
                        background-color: $gris6;
                        transition: $speedTransition;
                        font-weight: normal;
                        font-size: 1.3rem;
                        line-height: $toggleHeight - $toggleBorder;
                        text-align: center;
                        flex: 0 0 $toggleWidth;
                        &:before {
                            position: absolute;
                            content: attr(data-unchecked);
                            height: $toggleSwitchHeight;
                            width: $toggleSwitchWith;
                            left: $toggleBorder;
                            top: $toggleBorder;
                            background-color: white;
                            border-radius: 2px;
                            transition: $speedTransition;
                            box-shadow: 0 0 3px $gris5;
                            text-transform: uppercase;
                            color: $gris6;
                            z-index: 2;
                        }
                        &:after {
                            position: absolute;
                            height: $toggleSwitchHeight;
                            width: $toggleSwitchWith;
                            content: attr(data-checked);
                            transform: translateY(-50%);
                            text-transform: uppercase;
                            top: 50%;
                            right: $toggleBorder;
                            color: $blanc
                        }
                    }
                }
            }
            &-description {
                margin: 0;
            }
        }

        &__btn-group {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: flex-end;
            flex: 0 0;

            .cookie-consent__btn {
                margin-right: 30px;
            }

            .cookie-consent__toggle-details {
                cursor: pointer;

                &-hide {
                    display: none;
                }
            }
        }

        &__btn-wrapper {
            @media screen and (max-width: 861px) {
                width: 100%;
                margin-bottom: 10px;
                &:first-child {
                    margin-top: 10px;
                }
            }
        }

        &__btn {
            background: $bleuEm;
            color: $blanc;
            font-weight: bold;
            padding: 10px 20px;
            display: inline-block;
            text-align: center;
            border-radius: 6px;
            box-shadow: 0 26px 10px -21px rgba(#000, .28);
            @media screen and (max-width: 861px) {
                width: 100%;
            }
            &--secondary {
                background: $blanc;
                color: $bleuEm;
                border: 2px solid $bleuEm;
            }
        }
    }
}
