.containRecapitulatifCommande {
  .titreCollapse {
    margin-bottom: 0.5em;
    @media (max-width: $screen-xs-max){
        font-size: 1.2em;
    }
  }
  .collapse {
    margin-bottom: 1em;
  }

  .bleuEMCard {
    label {
      display: block;
      margin-bottom: 0em;
      p {
        display: inline-table;
        vertical-align: middle;
        margin-bottom: 0;
        width: calc(100% - 40px);
        padding: 0 0.5em;
        @media (max-width: $screen-md-max){
            text-align: left;
        }
      }
    }
      &.containerCgv{
          display: flex;
          justify-content: space-between;
          align-items: center;
           label{
               p{
                   display: block;
                   width: 100%;
                   padding: 0;
               }
           }

      }
  }



  .containFactureCommande {
    >div:last-child{
      margin-top: 1em;
    }


    .tabFacture {
      width: 100%;
      @include shadowBox(0.3);
      font-size: 14px;
      @media only screen {
        @media(min-width: $screen-sm-min) {
          font-size: 16px;
        }
      }
      th, td{
        text-align: right;
      }
      thead {
        th{
          width: 20%;
          @media (max-width: $screen-md-max){
              font-size: 0.875em;
          }
          &:first-child{
            width: 40%;
            @include box-shadow(inset 0px -8px 8px -8px rgba(0, 0, 0, 0.3));
          }
        }
      }
      tbody{
        tr{
          &:hover {
            td{
              background-color: $blanc;
            }
          }
          td{

            &:first-child{
              border-left-width: 0;
              background-color: $blanc;
              box-shadow: none;

            }
            &:last-child{
              font-weight: 700;
              border-right-width: 0;
            }
            border: 2px solid $gris3;
          }
          &:first-child{
            td{
              border-top-width: 0;
              box-shadow: none;
            }
          }
          &:last-child{
            td{
              border-bottom-width: 0;
            }
          }
        }
      }
    }
  }

    .collapseParrainage{
        @media (min-width: $screen-xs-min){
            cursor: default;
        }
        @media(max-width: $screen-xs-max){
            position: relative;
            display: block;
            &:after{
                content: '\f106';
                font-family: EM-icomoon;
                position: absolute;
                font-size: 3rem;
                line-height: 2rem;
                right: 0;
                color: $bleuEm;
                -webkit-transition-duration: 0.25s;
                transition-duration: 0.25s;
                -webkit-transition-timing-function: ease-in-out;
                -moz-transition-timing-function: ease-in-out;
                transition-timing-function: ease-in-out;
            }
        }
        &[aria-expanded='false']:after{
            transform: rotate(180deg);
        }
    }

  .containParrainageCommande{
     @media (min-width: $screen-xs-min){
         display: block;
         margin-bottom: 0;
         &.collapsing{
             height: auto;
             overflow: visible;
         }
     }
    .gris1{
      margin-top: 1em;
    }

    .btn{
      position: absolute;
      bottom: 3px;
      @media only screen {
        @media(max-width: $screen-sm-max) {
          margin-top: 1.5em;
          position: inherit;
          width: 100%;
        }
      }
    }
    #loaderSponsor{
      position: absolute;
      bottom: -6px;
      @media only screen {
        @media(max-width: $screen-xs-max) {
          margin-top: 1.5em;
          position: inherit;
          width: 100%;
        }
      }
    }

    #messageSponsor {
        margin-top: 1em;
        i, span {
            display: inline-block;
            vertical-align: middle;
        }
    }
    .demandeParrainageValide{
      color: $vert;
    }
    .demandeParrainageInValide{
      color: $rouge;
    }

    .demandeParrainageValide.demandeParrainageInValide{
      bottom: 5px;
      position: absolute;
      i{
        font-size: 2em;
      }
      @media only screen {
        @media(max-width: $screen-sm-max) {
          margin-top: 1.5em;
          position: inherit;
          width: 100%;
          display: block;
        }
      }
    }
    label{
      margin-top: 1em;
    }
  }

  ul:not(.leaders){
    font-weight: 700;
    font-size: 16px;
  }
}
