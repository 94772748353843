.asideMenu {
  &.asideMenuCompte{
    @media only screen {
        @media(max-width: $screen-md-max) {
        position: fixed;
        left: 0;
        z-index: 200;
      }
    }
  }
  @media only screen {
    @media(max-width: $screen-sm-max) {
      position: fixed;
      top: 65px;
      left: 0;
      z-index: 200;
    }
  }
  .subHeaderMenu {
    display: none;
    &.subHeaderMenuCompte{
      @media only screen {
        @media(max-width: $screen-md-max) {
          display: block;
        }
      }
    }
    @media only screen {
      @media(max-width: $screen-sm-max) {
        display: block;
      }
    }

    position: relative;
    left: 0;
    background-color: $bleuEm;
    @include box-shadow(0px 3px 10px rgba(0, 0, 0, 0.3));
    z-index: 2;
    font-size: 1.2em;
    font-weight: bold;
    color: $blanc;
    width: 100%;
    cursor: pointer;
    padding: 0.25em 1em 0.5em;
    &:after {
      font-family: EM-icomoon;
      content: '\f106';
      @include transition-duration(0.25s);
      @include easeInOut();
      position: absolute;
      right: 1em;
    }
    &.collapsed {
      &:after {
        transform: rotate(180deg);
        //@include transform(rotate(180deg)); // since webpack
      }
    }
  }

  #menuInAside {
    padding: 0;
    @media only screen {
      @media(max-width: $screen-sm-max) {
        background-color: $blanc;
        width: 100%;
        left: 0;
        z-index: 1;
        @include shadowBox(0.3);
        margin-top: 0;
        border-width: 0;
      }
    }
    &.asideMenuCompte{
      @media only screen {
        @media(max-width: $screen-md-max) {
          background-color: $blanc;
          width: 100%;
          left: 0;
          z-index: 1;
          @include shadowBox(0.3);
          margin-top: 0;
          border-width: 0;
        }
      }
    }
    &.in{
      height: auto !important;
      top: auto !important;
    }
    .list-group {
      margin-bottom: 0;
      //border-top: 3px solid $blanc;
      .list-group-item {
        color: $bleuEm;
        font-size: 1.2em;
        font-weight: 600;
        border: 1px solid transparent;
        border-top-color: $gris2;
        &:first-child, &:last-child {
          border-radius: 0;
        }
        &:first-child {
          border-top-color: transparent;
        }
        &.active {
          background-color: $bleuEm;
          color: $blanc;
          .badge {
            color: inherit;
            background-color: inherit;
          }
        }
      }
      .list-group-item-ul {
        background-color: #f5f5f5;
        padding: 0;
        border: 0;

        .titleList {
          padding: 10px 15px;
          display: block;
          background-color: #fff;

          &.active {
            background-color: $bleuEm;
            color: #fff;

            &:hover {
              background-color: $bleuEm;
            }
          }
          &:hover {
            background-color: #f5f5f5;
          }
        }

        li {
          padding: 3px 15px;

          a {
            opacity: 0.5;
            display: block;

            &.active,
            &:hover{
              opacity: 1;
            }
          }
        }

        &.active {
          background-color: $bleuEm;

          li {
            background-color: #f5f5f5;
          }
        }
      }
      hr {
        margin: 0;
      }
    }

  }
  .card{
    margin-top: 1.5em;
  }
    #collapseMenuPartner {
        > a.list-group-item {
            background-color: transparentize($bleuEm, 0.7);
            font-weight: normal !important;
            font-size: 1.2rem !important;
            padding: 5px 10px 5px 35px;
        }
    }
}
