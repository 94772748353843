$boxShadow: 0 0 10px rgba(0, 0, 0, 0.2) , inset 0 0 10px rgba(0, 0, 0, 0);
$boxShadow2: 0 0 10px rgba(0, 0, 0, 0), inset 0 0 10px rgba(0, 0, 0, 0.2);

input[type="text"], input[type="email"], input[type="password"], input[type="tel"], input[type="number"], textarea, textarea.form-control{
  @include shadowBox(0.2);
  //@include box-shadow(0 0 10px rgba(0, 0, 0, 0.2) , inset 0 0 10px rgba(0, 0, 0, 0));
  @include box-shadow($boxShadow);
  border: 1px solid $gris3;
  color: $bleuEm;
  font-weight: 600;
  font-size: 16px;
  outline: none;
  @include transition(all 0.4s ease);
  &:focus{
    @include box-shadow($boxShadow2);
    border-color: $gris3;
  }
}

.form-control::-webkit-input-placeholder , ::-webkit-input-placeholder {
  color: $gris1;
}

.form-control:-moz-placeholder,:-moz-placeholder {
  color: $gris1;
}

.form-control::-moz-placeholder,::-moz-placeholder {
  color: $gris1;
}

.form-control:-ms-input-placeholder, :-ms-input-placeholder {
  color: $gris1;
}
