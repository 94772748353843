.titreCollapse{
  background-color: $bleuEm;
  color: $blanc;
  font-size: 2rem;
  line-height: 2rem;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  position: relative;
  width: 100%;
  display: block;
  padding: 1rem;
  &:after{
    content: '\f106';
    font-family: EM-icomoon;
    position: absolute;
    font-size: 3rem;
    line-height: 2rem;
    right: 1rem;
    @include transition-duration(0.25s);
    @include easeInOut();
  }
  &[aria-expanded='false']:after {
    transform: rotate(180deg);
    //@include transform(rotate(180deg)); //since webpack
  }
  &:hover, &:focus{
    color : $blanc;
  }
}
