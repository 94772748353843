@each $couleurClass in $tabCouleurs {
  .#{nth($couleurClass, 1)} {
    color: nth($couleurClass, 2);
    a {
      color: $bleuEm;
      &:hover, &:focus {
        color: nth($couleurClass, 2);
      }
    }
  }

  a.#{nth($couleurClass, 1)}{
    &:hover, &:focus {
      color: darken(nth($couleurClass, 2), 10);
    }
  }
}


.gris1 {
  a {
    color: $gris1 !important;
  }
}

.gris2 {
  a {
    color: $gris2 !important;
  }
}

.gris3 {
  a {
    color: $gris3 !important;
  }
}

.color-white {
    color: #ffffff !important;
}

.orange {
  a {
    color: $orange !important;
  }
}

.bg-bleuEM {
    background-color: $bleuEm;
}
