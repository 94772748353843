.containEnteteFicheTexteDescription {
  margin-bottom: .5rem;
  p, span:not(#readMoreDesc) {
    font-weight: 300;
    text-align: justify;
    margin-bottom: 0;
  }
  span.big-1, p.big-1, .collapse.in {
    display: inline;
  }
  @media only screen {
    @media(max-width: $screen-sm-max) {
      p {
        text-align: center;
      }

    }
  }
}

.introPageVehicule {
  .containEnteteFicheTitreBoutons {
    @media only screen {
      @media(max-width: $screen-sm-max) {
        .disponibiliteVehicule {
          display: none;
        }
        h2, h1 {
          font-size: 1.5rem;
          margin-bottom: 0.5em;
        }
      }
    }
  }
  .containEnteteFicheTexteDescription {
    @media only screen {
      @media(max-width: $screen-sm-max) {
        display: none;
      }
    }
  }
}

.infosPageVehiculeMobile {
  .containEnteteFicheTitreBoutons {
    h2 {
      display: none;
    }
    .disponibiliteVehicule{
      *{
        font-size: 0.8em;
        text-align: center;
      }
    }
  }
  .containEnteteFicheTexteDescription{
    display: none;
  }
}

.descriptionTypeCar{
    @media (max-width: $screen-xs-max){
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-bottom: 0;
        position: relative;
        z-index: 2;
    }
    &.active{
        white-space: initial;
    }
}
#readMoreDesc{
    .less{
        display: none;
    }
}
