.containMandataire{
  @media(max-width: $screen-xs-max){
    margin-top: 0;
    padding-top: 0;
  }
  .readMoreContainer {
    padding-bottom: 2em;
  }


  @media only screen {
    @media(max-width: $screen-sm-max) {
      .noPadding{
        margin-bottom: 1em;
      }
      h6{
        cursor: pointer;
        font-size: 0.95em;
        padding: 1em 0;
        &:after{
          content: "\f107";
          font-family: EM-icomoon;
          position: absolute;
          right: 10px;
        }
      }
    }
  }

  .sublineLink {
    font-weight: bold;
    text-decoration: underline;
  }

  .sublineLink:hover {
    color: #73a6c0;
  }
    @media (max-width: $screen-sm-max){
        .mandataireTitre{
            position: relative;
            &:after {
                content: '';
                display: inline-block;
                border: solid $bleuEm;
                border-width: 0 3px 3px 0;
                display: inline-block;
                padding: 3px;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%) rotate(45deg);
                transition: all 0.3s ease-out;
            }
        }
        h3[aria-expanded="true"].mandataireTitre{
            &:after {
                transform: translateY(-50%) rotate(-135deg);
            }
        }
    }
}
