.twinTablesGroup{
  .tableGreyWhite{
    float: left;
    @media only screen {
      @media(max-width: $screen-md-max) {
        width: 100%;
      }
    }
    &:last-child {
      > tbody {
        > tr {
          @media only screen {
            @media(max-width: $screen-md-max) {
              &:first-child {
                display: none;
              }
            }
          }
          > td, th {
            &:first-child {
              @media only screen {
                @media(min-width: $screen-lg-min) {
                  $boxShadow: inset -8px 0px 8px -8px rgba(0, 0, 0, 0.3), inset 8px 0px 8px -8px rgba(0, 0, 0, 0.3);
                  @include box-shadow($boxShadow);
                }
              }
            }
          }
        }
      }
    }
  }
}



.tableGreyWhite{
  tr{
    td, th{
      position: relative;
      padding: 0.5em 0.5em;
      text-align: center;
      @media only screen {
        @media(max-width: $screen-xs-max) {
          padding: 0.5em 0.2em;
          font-size: x-small;
        }
      }
    }
  }
  th, tfoot tr td{
    padding: 0.5em 0.5em;
    text-transform: uppercase;
    font-weight: 700;
    @media only screen {
      @media(max-width: $screen-xs-max) {
        padding: 0.5em 0.2em;
        font-size: x-small;
      }
    }
  }

  >thead{
    tr{
      &:last-child{
        th{
          &:not(:first-child) {
            @include box-shadow(inset 0px -8px 8px -8px rgba(0, 0, 0, 0.3));
          }
        }
      }
      th{
        background-color: $grisFond;
      }
    }
  }

  > tbody {
    > tr {
      &:hover {
        td {
          background-color: $gris3;
          &:first-child {
            background-color: $gris2;
            &:empty {
              background-color: $grisFond;
            }
          }
        }
      }
      > td, th {
        &:first-child {
          background-color: $grisFond;
          @include box-shadow(inset -8px 0px 8px -8px rgba(0, 0, 0, 0.3));
          font-weight: bold;
          text-align: left;
          padding-left: 1em;
        }
      }
    }
  }
  >tfoot{
    &.tfootBleuEm{
      tr{
        &:first-child{
          td{
            @include box-shadow(inset 0px 8px 8px -8px rgba(0, 0, 0, 0.3));
          }
        }
        td{
          background-color: $bleuEm;
          color: $blanc;
        }
      }
    }
    tr{
      &:first-child{
        td{
          &:not(:first-child) {
            @include box-shadow(inset 0px 8px 8px -8px rgba(0, 0, 0, 0.3));
          }
        }
      }
      td{
        background-color: $grisFond;
        &:first-child {
          box-shadow: none;
        }
      }
    }
  }
}
