/*#prime-md-lg-screen, #prime-xs-ms-screen*/
.container-prime {
    background-color: $greenPrime;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .5rem;

    > img {
        width: 40px;
    }

    > p {
        line-height: $font-size-base;
        margin: 0;
    }

    @media (max-width: $screen-xs-max) {
        padding: .25rem;
        > img {
            width: 30px;
        }
        > p {
            font-size: 0.875rem;
        }
    }
}
