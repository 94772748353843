h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  text-transform: uppercase;
  font-weight: 300;
  margin-top: 0.25em;
  margin-bottom: 0.25em;
  &.titreSection {
    margin: 0.5em 0 1em 0;
    text-align: center;
    @media (min-width: $screen-md-min) {
      text-align: left;
    }
  }
}

h1,.h1, .popover-title h1, .popover-title .h1 {
  font-size: 2.5rem;
  font-weight: 300;
  @media (min-width: $screen-sm-min) {
    font-size: 4rem;
  }
  @media (min-width: $screen-md-min) {
    font-size: 4.5rem;
  }
}

h1.titleCarlist, .titleCarlist h1 {
  font-size: 1.5rem;
  font-weight: 300;
  @media (min-width: $screen-sm-min) {
    font-size: 2.8rem;
  }

  @media (max-width: $screen-sm-min) {
    margin-top: 0;
    margin-bottom: 0.3rem;
    text-align: center;
  }
}

h2, .h2 {
  font-size: 2.5rem;
  @media (min-width: $screen-sm-min) {
    font-size: 3.5rem;
  }
  @media (min-width: $screen-md-min) {
    font-size: 4rem;
  }
}
h2.subtitleCarList, .textCarList h2 {
    font-size: 0.8rem;
    @media (min-width: $screen-sm-min) {
        font-size: 1.8rem;
        margin-bottom: 0.7rem;
    }
    @media (max-width: $screen-sm-max) {
        margin-bottom: 10px;
        text-align: center;
    }
}
.textCarList{
    li{
        a{
            text-decoration: none;
        }
    }
    a{
        text-decoration: underline;
    }
}


h2.normalSubtitleCarList {
  font-size: 1.5rem;
  @media (min-width: $screen-sm-min) {
    font-size: 1.8rem;
    margin-bottom: 0.7rem;
  }
}

h3, .h3 {
  font-size: 2.5rem;
  @media (min-width: $screen-sm-min) {
    font-size: 3rem;
  }
  @media (min-width: $screen-md-min) {
    font-size: 3.5rem;
  }
}

h4, .h4 {
  font-size: 2rem;
  @media (min-width: $screen-sm-min) {
    font-size: 2.5rem;
  }
  @media (min-width: $screen-md-min) {
    font-size: 3rem;
  }
}

h5, .h5 {
  font-size: 2rem;
  @media (min-width: $screen-sm-min) {
    font-size: 2rem;
  }
  @media (min-width: $screen-md-min) {
    font-size: 2.5rem;
  }
}

h6, .h6 {
  font-size: 2rem;
  @media (min-width: $screen-sm-min) {
    font-size: 2rem;
  }
  @media (min-width: $screen-md-min) {
    font-size: 2rem;
  }
;
}
