$bleuEm: #22286d !default;

$rose: #e0055a !default;
$vert: #bed62f !default;
$bleu: #14b0e6 !default;
$jaune: #fdb813 !default;
$orange: #e95a0c !default;
$rouge: #c10000 !default;

$gris1: #abafb1 !default;
$gris2: #d6d6d6 !default;
$gris3: #cccccc !default;
$gris4: #ececec !default;
$gris5: #7c7c7c !default;
$gris6: #919191 !default;
$grisFond: #f5f5f5  !default;
$grisDisabled: #eaeaea  !default;
$blanc: #fff !default;
$promo-color: #13ac42 !default;
$greenPrime: #5eb67c;

$tabCouleurs: ("bleuEM":$bleuEm, "rose" : $rose, "vert": $vert, "bleu" : $bleu, "jaune" : $jaune, "orange" : $orange, "gris1" : $gris1, "gris2" : $gris2, "gris3" : $gris3, "gris4" : $gris4, "gris5" : $gris5, "gris6" : $gris6, "blanc" : $blanc, "grisFond" : $grisFond, "rouge": $rouge);
