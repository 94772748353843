.containReassurance {

  font-weight: 700;
  text-align: center;
  margin-bottom: 1em;
  margin-top: 1em;

  .col-sm-12 {
    text-align: center;
    margin-top: 0.3em;
  }
  i {
    font-size: 3em;
  }
  .col-xs-12 {
    display: inline;
  }
  .reassuranceTexte{
    p{
      margin-bottom: 0;
    }
  }
  h2.titreSection{
    @media(max-width: ($screen-xs-max)){
      font-size: 2rem;
    }
  }
  @media only screen {
    @media(max-width: ($screen-xs-max)) {
      * {
        vertical-align: middle;
      }
      .reassuranceTexte {
        text-align: left;
        margin-top: 1em;
        margin-bottom: 1.5em;

      }
    }
  }
  a:hover {
    color: $bleu;
  }
}
