$slick-font-family: "EM-icomoon";
$slick-prev-character: "\f104";
$slick-next-character: "\f105";
$slick-arrow-color: $gris1;

.slick-slider {
  margin-bottom: 0;
}

.slick-prev,
.slick-next {
  width: 40px;
  height: 40px;
  background-color: $blanc;
  @include whiteCard;
  &:hover, &:focus {
    outline: none;
    background-color: $blanc;
    color: $slick-arrow-color;
    &:before {
      opacity: $slick-opacity-on-hover;
    }
  }
}

.slick-prev:before, .slick-next:before {
  font-family: $slick-font-family;
  font-size: 25px;
  line-height: 1;
  color: $slick-arrow-color;
  opacity: $slick-opacity-default;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

.slick-prev {
  left: 10px;
  z-index: 2;
  [dir="rtl"] & {
    left: auto;
    right: 10px;
  }
  &:before {
    content: $slick-prev-character;
    [dir="rtl"] & {
      content: $slick-next-character;
    }
  }
}

.slick-next {
  z-index: 2;
  right: 10px;
  [dir="rtl"] & {
    left: 0px;
    right: auto;
  }
  &:before {
    content: $slick-next-character;
    [dir="rtl"] & {
      content: $slick-prev-character;
    }
  }
}



