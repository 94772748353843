.containPrix {
  color: $blanc;
  text-align: center;
  margin-top: 1em;
  margin-bottom: 3em;
    .tooltip{
        .tooltip-inner{
            max-width: initial;
        }
    }
    .priceContent{
        .tooltip{
            .tooltip-inner{
                min-width: auto;
            }
        }
        .icon-infos{
            position: absolute;
            top: 38px;
            @media (max-width: $screen-xs-max){
                margin: 0 0 0 2px;
            }
        }
    }
  .info-price
  {
      .tooltip{
          z-index: 1041;
          .tooltip-inner{
              min-width: 850px;
              p {
                  font-weight: 400;
                  span {
                      font-weight: 700;
                      color: $rose;
                      font-size: 1.3em;
                      &.blanc {
                          color: $blanc;
                          font-size: 1.3em;
                      }
                  }
              }
              .infosCredit {
                  strong {
                      font-size: 1.3em;
                      text-decoration: underline;
                  }
                  span {
                      font-size: 1em;
                  }
              }
              @media (max-width: $screen-sm-max ) {
                  min-width: 450px;
              }
              @media (max-width: $screen-xs-max ) {
                  min-width: 250px;
              }
          }
      }
  }


  @media only screen {
    @media(min-width: $screen-md-min) {
      margin-top: 1.5em;
    }
  }
  @include shadowBox(0.3);
    .badge-partner {
        font-weight: normal;
        margin-top: 10px;
    }
  &.vert {
    background-color: $vert;
      .badge-partner {
          color: $vert;
          background-color: #fff;
      }
  }
  &.bleu {
    background-color: $bleu;
      .badge-partner {
          color: $bleu;
          background-color: #fff;
      }
  }
  &.jaune {
    background-color: $jaune;
      .badge-partner {
          color: $jaune;
          background-color: #fff;
      }
  }
  &.rose {
    background-color: $rose;
      .badge-partner {
          color: $rose;
          background-color: #fff;
      }
  }
  p {
    margin: 10px 0 0 0;
    font-weight: 600;
    @include shadowText(0.3);
    @media only screen {
      @media(max-width: $screen-sm-max) {
        margin-top: 3.5em;
        &:first-child{
          margin-top: 10px;
        }
      }
    }
  }
  h3, h5, h6 {
    position: relative;
    display: inline-block;
    font-weight: 700;
    @include shadowText(0.3);
    margin-bottom: 0;
    span {
      text-transform: lowercase;
      vertical-align: middle;
    }
  }
  h3 {
    @media only screen {
      @media(min-width: $screen-md-min) and (max-width: $screen-md-max) {
        font-size: 2em;
      }
    }
  }
    .price-promo-yellow {
        color: #f3ca72;
    }

  h5.price-promo {
    position: relative;
  }
  .strikethrough {
    position: absolute;
    top: 50%;
    height: 1px;
    left: 0;
    right: 0;
    background-color: white;
    //background-color: $bleuEm;
  }

  .last-line-enhancement{
    color:$gris6;
  }

  .title-card-price
  {
    color:$gris5;
  }

  .strike{
    text-decoration:line-through;
  }
  .detailPrixCollapse {
    margin-top: 1em;
    background-color: $blanc;
    color: $bleuEm;
    text-shadow: none;
    @include shadowBox(0.2);
    &:after {
      content: '\f107';
      font-family: EM-icomoon;
      position: absolute;
      font-size: 1em;
      right: 0.5rem;
    }
  }
  #detailPrixContain {
    background-color: $blanc;
    @include shadowBoxInsetTopBottom(0.3);
    color: $gris1;
    text-align: left;
    padding: 0.5em;
    line-height: 1.25em;

    .detailIntitules {
      table {
        @media only screen {
          @media(min-width: $screen-md-min) and (max-width: $screen-md-max) {
            font-size: 0.8em;
          }
        }
        width: 100%;
        tr {
          td {
            vertical-align: bottom;
            &:last-child:not([colspan='2']){
              text-align: right;
              //&:after {
              //  text-align: right;
              //  content: ' €';
              //}
            }
          }
        }
      }
      ul {
        margin-bottom: 0;
        li {
          position: relative;
          span {
            position: absolute;
            right: 0;
            &:after {
              content: ' €';
            }
          }
        }
      }

    }
  }
  .btnGoFinancement {
    font-weight: 600;
    position: relative;
    top: 15px;
    @include shadowBox(0.2);
  }

  .remiseCarteVehicule {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: $bleuEm;
    position: absolute;
    border-radius: 50%;
    border: 2px solid $blanc;
    text-align: center;
    @include shadowBox(0.3);
    @include shadowText(0.3);
    left: calc(0% - 1.5em);
    width: 3em;
    height: 3em;
    top: 0.5em;
    //line-height: 3em;
    font-size: 1.5em;
    font-weight: 300;
    z-index: 1;
    small {
      font-size: 0.5em;
    }
    .promotion {
      color: $vert;
    }
    @media only screen {
      @media(max-width: $screen-sm-max) {
        left: calc(50% - 1.5em);
        top: 0.25em;
      }
    }
    &.hasPromo {
      @media only screen {
        @media(max-width: $screen-sm-max) {
          top: 1em;
        }
      }
    }
  }
  .loader-svg {
    stroke:$blanc;
  }
  .containPromo {
    padding: 0.5em;
    text-transform: uppercase;
    font-weight: bold;
  }
  @media screen and (max-width: $screen-sm-max) {
    .priceContent {
      display: flex;
      align-items: center;

    }
  }
  .promoBackground {
    background-size: 100%;
    //background-image: url('../../../../../resources/front/images/fiche-vehicule/promotions/promo-noel.gif');
    background-image: url('../../../../../resources/front/images/fiche-vehicule/promotions/promo-bf-card.png');
    background-repeat: no-repeat;
  }

  .promotionColor{
    background-color: $promo-color;
  }
    .partner-price {
        .price-promo {
            color: $bleuEm;
            .strikethrough {
                background-color: $bleuEm;
            }
        }
        h3 {
            font-weight: 800;
            color: #ffffff !important;
        }
    }

    .offerChristmas{
        @media (max-width: $screen-xs-max){
            margin-bottom: 4rem;
        }
        .container-price{
            @media (max-width: $screen-xs-max){
                position: initial;
            }
        }
        .container-props{
            padding: 1.5rem 0;
            position: relative;
            @media (max-width: $screen-xs-max){
                padding: 2rem 0;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                z-index: 200;
                white-space: nowrap;
            }
            .props-card{
                padding: 0.5rem 2rem;
                border: 1px solid #ffffff;
                font-size: 1.2em;
                background: #EC1940;
                position: relative;
                @media (max-width: $screen-md-max){
                    font-size: 0.875em;
                    padding: 0.5rem 1rem;
                }
                @media (max-width: $screen-xs-max){
                    padding: 0.5rem 2rem;
                }
                &:before{
                    content: '';
                    display: inline-block;
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    background-image: url('../../../../../resources/front/images/fiche-vehicule/promo-page/motif_snow.png');
                    background-size: cover;
                    background-repeat: repeat;
                    opacity: 0.6;
                }
            }
            .icon-infos{
                top: 0;
                @media (max-width: $screen-xs-max){
                    top: 8px;
                }
            }
            .tooltip{
                z-index: 1050;
                .tooltip-inner{
                    min-width: 450px;
                    text-align: left;
                    @media (max-width: $screen-xs-max){
                        min-width: 200px;
                    }
                    p{
                        margin: 1rem 0;
                    }
                }
            }
        }
    }
}
