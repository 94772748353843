
.panel-group{
  .panel {
    @include whiteCard;
    margin-bottom: 1em;
    border: 0px solid transparent;
    border-radius: 0;
    .panel-heading {
      a{
        p {
          margin-bottom: 0;
          &:after {
            position: absolute;
            right: 1em;
            content: '\f106';
            font-family: EM-icomoon;
            transition-duration: 0.5s;
          }
        }
        &.collapsed{
          p:after{
            transform: rotate(180deg);
          }
        }
      }
      color: $bleuEm;
      background-color: $blanc;
      padding: $panel-heading-padding;
      border-bottom: 1px solid transparent;
      border-radius: 0;
      > .dropdown .dropdown-toggle {
        color: inherit;
      }
    }
    .panel-body{
      @include shadowBoxInset(0.2);
    }
  }
}


