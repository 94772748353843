
.bsTourSearch{
    input {
        height: 43px;
        font-weight: normal;
    }
    .form-group {
        margin: 0;
    }
    .input-group {
        display: flex;
        margin-bottom: 0.5em;
        border-collapse: initial;
        input[type=text] {
            width: 60%;
            @media (max-width: $screen-xs-max){
                font-size: 0.8em;
                width: 85%;
            }
        }
        .input-group-btn {
            width: 30%;
            text-transform: initial;
            &.w-auto{
                width: auto;
            }
            .text-initial {
                text-transform: initial;
            }
            @media (max-width: $screen-md-max){
                width: 8%;
            }
            @media (max-width: $screen-xs-max){
                width: 15%;
            }
            button[type='submit']{
                width: 100%;
            }
        }
        .loader-bar-panel {
            margin: 0;
            padding: 0;
            height: 43px;
            text-align: center;
            @extend .btn.btn-vert;
            @extend .input-group-btn;
            > svg {
                margin-top: -1px;
            }
        }
        >div{
            z-index: 10;
            overflow-y: auto;
            max-height: 200px;
            max-width: 960px;
            .list-group{
                margin-bottom: 0;
            }
            .list-group-item{
                &:hover{
                    color: #fff;
                    background-color: $bleuEm;
                }
                &:first-child, &:last-child{
                    border-radius: initial;
                }
                &.active{
                    background-color: $bleuEm;
                }
            }
        }
        select.form-control{
            width: 10%;
            height: 43px;
            @media (max-width: $screen-xs-max){
                display: none;
            }
        }
        .jcf-select {
            width: 17%;
            color: rgba(128, 128, 128, 0.7);
            height: 43px;
            display: table-cell;

            &:before{
                font-size: 25px;
                line-height: 40px;
            }
            .jcf-select-text {
                font-weight: normal;
                font-size: 1em;
                line-height: 40px;
                vertical-align: middle;
            }
            @media ($screen-md-max){
                width: 20%;
            }
            @media (max-width: $screen-xs-max){
                display: none !important;
            }
        }
        .jcf-list .jcf-option{
            &:first-child{
                font-size: 0.926em;
            }
        }
    }

    .btn.search {
        color: $bleuEm;
        font-size: 16px;
        @extend .btn-vert;
        i:before {
           font-size: 1.2em;
         }
    }
    input[name="search"] + [data-role="search-result"] {
        mark {
            background: none;
            font-weight: bold;
            padding: 0;
            color: inherit;
            cursor: pointer;
        }
    }
}
.jcf-scrollbar-horizontal .jcf-scrollbar-handle, .jcf-list .jcf-disabled{
    display: none !important;
}
.jcf-list .jcf-option:first-child {
    font-size: .926em;
}

.searchComm{
    padding-top: 10px;
    .bsTourSearch{
        .form-group{
            >.col-xs-12{
                padding: 0;
                .input-group{
                    justify-content: center;
                }
                .input-group-btn{
                    width: auto;
                }
            }
        }
    }
}
