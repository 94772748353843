.containParametresCompte{
  .jcf-select {
    margin-bottom: 0;
  }

  .containProfilParametres{
    input[type="submit"]{
      margin-top: 1.5em;
    }
  }


  .containMdpParametres {
    margin-bottom: 2em;
    button{
      margin-top: 2.7em;
    }
  }

  .containFinParametres {
    .containEnableNewsletter {
      margin-top: 3em;
      margin-bottom: 2em;
    }
  }

  .containAdressesParametres{
    .containAdresseCompte{
      margin: 1em auto;

    }
    button{
      margin-top: 1.5em;
    }
    p{
      font-size: 1.15em;
    }
  }
}

#sendChangeAdvisor {
  &[disabled]{
    color: transparent;
    .loader-svg{
      display:inline-block;
      height: 33px;
      width: 33px;
      stroke: $blanc;
      margin: -10px;
    }
  }
  .loader-svg {
    display:none;
  }

}

