.alert{
  border-width: 0;
  margin: 1em 0;
  font-weight: bold;
  border-radius: 0;
  padding: 1em;
  i{
    font-size: 3em;
  }
}

.alert-warning{
  background-color: $orange;
  color: $blanc;
}

.alert-success {
  background-color: $vert;
  color: $blanc;
}

.alert-info {
  background-color: $bleuEm;
  color: $blanc;
}

.alert-danger {
  background-color: $orange;
  color: $blanc;
}