.banner-virus{
    background-color: #f2ba44;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.8);
    padding: 1.2rem 0 0.8rem 0;
    font-size: 2rem;
    font-weight: 900;
    position: absolute;
    top: -5px;
    left: 0;
    right: 0;
    z-index: 5;
    @media (max-width: $screen-lg-max){
        top: 0;
        font-size: 1.5rem;
    }
    .link{
        color: #e0055a;
        //text-decoration: underline;
    }
    .text-info{
        margin-bottom: 0;
    }
}
