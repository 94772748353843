.containTunnel {
  margin-top: 2em;
  margin-bottom: 2em;
  > div {
    background-color: $grisFond;
    ul.tunnelSteps {
    display: flex;
        justify-content: center;
      $line_height: 1em;
      $padding: 1em;
      $arrow_size: ($line_height + 2 * $padding)/2;
      $max_li: 10;
      $margin_left: (0.5em + 2 * $arrow_size);
      $counter: li;
      &[data-nb-steps="3"] {
        $total_margin_left: ($margin_left)*2;
        li {
          width: calc(33.33333% - #{$margin_left});
          &:first-child {
            width: 33.33333%;
          }
        }
      }
      &[data-nb-steps="4"] {
        $total_margin_left: ($margin_left)*3;
        li {
          width: calc(25% - #{$margin_left});
          &:first-child {
            width: 25%;
          }
        }
      }
      &[data-nb-steps="5"] {
        $total_margin_left: ($margin_left)*4;
        li {
          width: calc(20% - #{$margin_left});
          &:first-child {
            width: 20%;
          }
        }
      }
      font-weight: 700;
      font-size: $line_height;
      list-style: none;
      margin: 0;
      padding: 0;
      line-height: $line_height;
      counter-reset: li;
      li {
        list-style-image: none;
        list-style-type: none;
        margin-left: 0;
        text-align: center;
        background: $blanc;
        position: relative;

        -webkit-filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.2));
        filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.2));
        -ms-filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=0, OffY=0, Color='#CCC')";
        filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=0, OffY=0, Color='#CCC')";
        //margin-left: 0.5em + 2 * $arrow_size;

        margin-left: $margin_left;
        // Appended arrow.
        &:after {
          position: absolute;
          top: 50%;
          left: 100%;
          content: " ";
          height: 0;
          width: 0;
          pointer-events: none;
          border: solid transparent;
          border-left-color: $blanc;
          border-width: $arrow_size;
          margin-top: -$arrow_size;
        }

        // No prepended arrow on first item.
        &:first-child {
          margin-left: 0;

          > span {
            padding: $padding $padding/2;
            &:after {
              border: none;
            }
          }
        }

        // No appended arrow on last item.
        &:last-child {
          &:after {
            border-width: 0;
          }
        }

        > span {
          display: block;
          padding: $padding $padding/2;

          // Prepended arrow inset.
          &:after {
            position: absolute;
            top: 50%;
            right: 100%;
            content: " ";
            height: 0;
            width: 0;
            pointer-events: none;
            border: solid $blanc;
            border-left-color: transparent;
            border-width: $arrow_size;
            margin-top: -$arrow_size;

          }

          // "X)" numbering
          &:before {
            @media only screen {
              @media(max-width: $screen-xs-max) {
                content: counter(li);
                counter-increment: li;
              }
            }
          }
        }
      }

      & > li {
        float: left;
      }

      li.current {
        color: $blanc;
        background: $bleuEm;
        @include shadowBox(0.3);
        font-weight: 900;

        // Appended arrow.
        &:after {
          border-left-color: $bleuEm;
        }

        > span {
          // Prepended arrow.
          &:after {
            border-color: $bleuEm;
            border-left-color: transparent;
          }
        }
      }

      // For the overlapping to work, later LIs must have a lower z-index.
      @for $i from 1 through $max_li {
        li.tunnelStep#{$i} {
          z-index: $max_li - $i;
        }
      }
    }
  }

}

.containButtonBottomTunnel {
  margin-top: $grid-gutter-width;
  .btn {
    @include shadowBox(0.3)
  }
  div > :first-child {
    text-align: right;
  }
  div > :last-child {
    text-align: left;
  }
  @media only screen {
    @media(max-width: $screen-xs-max) {
      div > :last-child, div > :first-child {
        text-align: center;
        * {
          width: 75%;
          margin-bottom: 1em;
        }
      }
    }
  }
}

#goToOrderConfirmation{
    position: relative;
    > .loader {
        position: absolute;
        top:0;
        left: 50%;
        transform: translateX(-50%);
    }
}
