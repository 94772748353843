.containTrierRecherche {
  margin-bottom: 0.7em;

  @media (max-width: $screen-md-max) {
    margin: 0;
    padding: 0 10px;
    >div.row{
      background-color: $grisFond;
    }
  }

  .jcf-select {
    margin: -0.5em 0 0 0;
    @media (max-width: $screen-md-max) {
      margin: 1.25em 0 1em 0;
    }
  }
  @media (min-width: $screen-lg-min) {
    #trierResultats {
      display: block;
      height: auto !important;
    }
    .toggleCarte{
      padding: 0 .3em 0 0.4em;
    }
    .toggleCarteListe{
      border-left: 1px solid $gris2;
    }
  }

}

.toggleCarte {
  cursor: pointer;
  &.active {
    color: $bleuEm;
  }
}


