#containComparateurOnglet {
  @media only screen {
    @media(max-width: $screen-xs-max) {
      display: none;
    }
  }
  #accordionComparateur {
    position: fixed;
    bottom: 0;
    z-index: 190;
    margin-bottom: 0;
    width: 100%;
    .comparateurOnglet {
      position: absolute;
      z-index: -1;
      padding: 0.5em 2em 0.25em 0.5em;
      left: 1em;
      top: -2em;
      background-color: $bleuEm;
      color: $blanc;
      @include shadowBox(0.3);
      white-space: nowrap;
      text-transform: uppercase;
      font-weight: 700;
      cursor: pointer;
      &:after {
        font-family: EM-icomoon;
        content: '\f107';
        position: absolute;
        right: 0.5em;
        top: 0;
        line-height: 2em;
        @include transition-duration(0.25s);
        @include easeInOut();
      }
      &.collapsed{
        &:after {
          transform: rotate(180deg);
          //@include transform(rotate(180deg)); // since webpack

        }
      }
    }
    #collapseComparateur {
      padding: 1em 0;
      background-color: $blanc;
      color: $bleuEm;
      border-top: 3px solid $bleuEm;
      @include shadowBox(0.3);
      .vehiculeComparateur {
        @media only screen {
          @media(max-width: $screen-xs-max) {
            margin-bottom: 0.5em;
          }
        }
        .deleteCard {
          $tailleBadge: 0.85em;
          @include deleteCard($tailleBadge);
          left: 0;
          top: - $tailleBadge;
        }
        img {
          @include shadowBox(0.3);
        }
      }
      .btnComparer {
        margin: 1em 0 0;
      }

    }
  }
}

#modalComparateur {
  .h4{
    margin: 0;
  }
  .deleteCard {
    $tailleBadge: 0.85em;
    @include deleteCard($tailleBadge);
    right: calc(50% - #{$tailleBadge} / 2);
    top: - $tailleBadge;
  }
  #tabComparateur {
    @include shadowBox(0.3);
    > thead{
      tr{
        &:first-child {
          th {
            padding-top: 1.5em;
          }
        }
      }
    }
    > tbody {
      > tr {
        &:hover {
          td {
            &:first-child {
              &:empty {
                background-color: $grisFond;
              }
            }
          }
        }

        > td {
          width: 25%;
          text-align: left;
          &[colspan='3'] {
            background-color: $blanc;
            &.sousSectionComparateur {
              background-color: $blanc;
              color: $bleuEm;
              border-width: 0;
            }
          }
          &:first-child {
            width: 25%;
            text-align: right;
            padding-right: 1em;
          }
        }
      }
    }
    tfoot{
      tr{
        td{
          padding: 0.5em;
        }
      }
    }
  }
}

