.containFiltrerRecherche{
  .whiteCard{
    margin-top: 0.5em;
    margin-bottom: 1em;
    @media (max-width: $screen-md-max) {
      margin-bottom: 0;
    }
    h6, .h6{

      i{
        float: right;
      }
      margin: 0.5em 0;
    }
    .slider{
      margin-top: 3em;
      margin-bottom: 0.5em;
      width: 90%;
      left: 5%;

    }
    .tab-pane {
      text-align: center;
      .slider {
        left: 0%;
      }
      margin-bottom: 10px
    }

    .label15 {
      font-size: 0.97em;
      white-space: nowrap;

      i {
        font-size: 2.1em;
      }
    }
  }

  .jcf-checkbox {
    height: 16px;
    width: 16px;
  }
}

.colorSwatch{
    position: relative;
    cursor: pointer;
    margin-right: 0.82em;
  &.disabled{
    cursor: not-allowed;
  }
  input, .jcf-radio{
    display: none;
  }
  .couleurSwatch{
    width: 2em;
    height: 2em;
    //width: 100%;
    padding: 0;
    margin: 0;
    display: inline-block;
    vertical-align: middle;
    svg{
      width: 2em;
      height: 2em;
      border: 2px solid $blanc;
      border-radius: 5px;
      @include shadowBox(0.3);
    }
  }
  i{
    display: none;
    position: absolute;
    top: 8px;
    left: 8px;
    color: #fff;
    @include shadowText(0.3);
  }
  input:checked + i, &.jcf-label-active i{
    display: block;
  }
}

@media (min-width: 1299px) {
  .filtreStyle {
    .colorSwatch:nth-child(6n+6) {
      margin-right: 0;
    }
  }
}

.styleSearch{
  ul{
    li{
      width: 100%;

      input.select2-search__field{
        width: 100%!important;
      }
    }
  }
}

.selectFiltersSearch {
  .select2 {
    font-size: 14px;
  }

  .select2-container{
    .select2-selection--multiple {
      min-height: 28px;
    }
  }
}

.aircrit-filter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    label {
        background: white;
        z-index: 1;
        border-radius: 50rem;
        margin: 0;
    }
    label > img {
        opacity: 1;
    }
    &:has(input[type="radio"]:checked) {
        > label {
            outline: solid 2px $bleuEm;
        };
    }

    input[type="radio"] {
        display: none;
        &:checked ~ label {
            outline: none;
        }
        &:checked + label {
            outline: solid 2px $bleuEm;
        }
    }

    img {
        width: 3rem;
    }
    &::before {
        content: '';
        height: 2px;
        position: absolute;
        background: $bleuEm;
        top: 50%;
        left: 10px;
        right: 10px;
        transform: translateY(-50%);
    }
}
