// Source du css : Mit licence : http://www.minimit.com/articles/solutions-tutorials/bootstrap-3-responsive-columns-of-same-height

.inside {
  //margin-top: 20px;
  //margin-bottom: 20px;
  /*  background: #ededed;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #f4f4f4), color-stop(100%, #ededed));
    background: -moz-linear-gradient(top, #f4f4f4 0%, #ededed 100%);
    background: -ms-linear-gradient(top, #f4f4f4 0%, #ededed 100%);*/
}

.inside-full-height {
  /*
  // if you want to give content full height give him height: 100%;
  // with content full height you can't apply margins to the content
  // content full height does not work in ie http://stackoverflow.com/questions/27384433/ie-display-table-cell-child-ignores-height-100
  */
  /*height: 100%;
  margin-top: 0;
  margin-bottom: 0;*/
}


/* columns of same height styles */

/*.row-height {
  display: table;
  table-layout: fixed;
  height: 100%;
  width: 100%;
}

.col-height {
  display: table-cell;
  float: none;
  height: 100%;
}

.col-top {
  vertical-align: top;
}

.col-middle {
  vertical-align: middle;
}

.col-bottom {
  vertical-align: bottom;
}

@media (min-width: 480px) {
  .row-xs-height {
    display: table;
    table-layout: fixed;
    height: 100%;
    width: 100%;
  }
  .col-xs-height {
    display: table-cell;
    float: none;
    height: 100%;
  }
  .col-xs-top {
    vertical-align: top;
  }
  .col-xs-middle {
    vertical-align: middle;
  }
  .col-xs-bottom {
    vertical-align: bottom;
  }
}

@media (min-width: 768px) {
  .row-sm-height {
    display: table;
    table-layout: fixed;
    height: 100%;
    width: 100%;
  }
  .col-sm-height {
    display: table-cell;
    float: none;
    height: 100%;
  }
  .col-sm-top {
    vertical-align: top;
  }
  .col-sm-middle {
    vertical-align: middle;
  }
  .col-sm-bottom {
    vertical-align: bottom;
  }
}

@media (min-width: 992px) {
  .row-md-height {
    display: table;
    table-layout: fixed;
    height: 100%;
    width: 100%;
  }
  .col-md-height {
    display: table-cell;
    float: none;
    height: 100%;
  }
  .col-md-top {
    vertical-align: top;
  }
  .col-md-middle {
    vertical-align: middle;
  }
  .col-md-bottom {
    vertical-align: bottom;
  }
}

@media (min-width: 1200px) {
  .row-lg-height {
    display: table;
    table-layout: fixed;
    height: 100%;
    width: 100%;
  }
  .col-lg-height {
    display: table-cell;
    float: none;
    height: 100%;
  }
  .col-lg-top {
    vertical-align: top;
  }
  .col-lg-middle {
    vertical-align: middle;
  }
  .col-lg-bottom {
    vertical-align: bottom;
  }
}*/



.row-flex, .row-flex > *[class*='col-'] {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-flex: 1 1 auto;
  -moz-box-flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.row-flex-wrap {
  -webkit-flex-flow: row wrap;
  align-content: flex-start;
  -webkit-box-flex: 0;
  -moz-box-flex:  0;
  -webkit-flex:  0;
  -ms-flex:  0;
  flex:  0;
}

.row-flex > *[class*='col-'], .container-flex > *[class*='col-'] {
  margin: -.3px; /* hack adjust for wrapping */
}

.container-flex > *[class*='col-'] > *:not([class*='col-']), .row-flex > *[class*='col-'] > *:not([class*='col-']) {
  width: 100%;
}

.flex-col {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-flex: 1 100%;
  -moz-box-flex: 1 100%;
  -webkit-flex: 1 100%;
  -ms-flex: 1 100%;
  flex: 1 100%;
  -webkit-flex-flow: column nowrap;
  flex-flow: column nowrap;
}

.flex-grow {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-flex: 2;
  -moz-box-flex: 2;
  -webkit-flex: 2;
  -ms-flex: 2;
  flex: 2;
}

.flex-centered{
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
}

.flex-width-auto {
    flex: 0 1 auto !important;
}
