@import "../../variables/couleurs";

#detailsCar {
    .modal-body{
        display: flex;
        align-items: stretch;
        justify-content: space-evenly;
        flex-wrap: wrap;
        padding: 0;
        > div {
            display: flex;
            margin: 1rem;
            flex-direction: column;
            border-radius: 18px;
            border: solid 1px $bleuEm;
            align-items: center;
            padding: 2rem 0 1rem 0;
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
            background-color: lighten($bleuEm, 68);
            > i {
                font-size: 30px;
                margin-bottom: 2rem;
                color: $bleuEm;
            }
            width: 170px;
            height: 130px;
        }
    }
}
