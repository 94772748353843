@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

.carteVehicule.carteCommandesVehicule{
  margin-bottom: 0;
  .whiteCard{
    margin-bottom: 0;
    position: relative;
    z-index: 2;
    .carteInformations {
        padding: 1rem;

      fieldset {
        @media(max-width: $screen-sm-max) {
          width: 100%;
        }
      }

      .inProgress {
        margin-top: 0.5rem;
      }

      .signMandat {
        .sign {
          width: 79%;
          margin-bottom: 5px;
          i.icon-signing-contract {
            margin-left: 6px;
          }
          &.ico_print {
            width: 20%;
            float: right;
          }
        }
        .linkprint {
          margin-bottom: 7px;
          display: block;
          text-align: center;
        }
      }
    }
    .carteMmv{
      .h4{
        margin-top: -1px;
      }
    }
    .fieldSetDocumentsManquants {
      fieldset {
        @media only screen {
          @media(max-width: $screen-sm-max) {
            width: 100%;
            display: inline-block;
            float: left;
          }
        }
        padding: 5px 10px;
        &:first-child{
          margin-bottom: 1em;
        }
        min-width: 0;
        legend {
          width: auto;
          text-align: center;
          font-size: 1em;
          font-weight: 700;
          padding: 0 5px;
          margin-bottom: 0;
          border: 0;
        }
        ul {
          margin-bottom: 0;
        }
      }
      p.stepInformations{
        font-size: 12px;
        margin-bottom: 2px;
      }
    }
      + .tabs-below{
          margin-top: 0;
          margin-bottom: 2em;
      }
  }
  .tabs-below{
    margin-top: 2em;
  }

    .nav-tabs > li > a {
        box-shadow: inset 0 0 0 0 rgba(0, 0, 0, 0.4), 0px 0px 10px -1px rgba(0, 0, 0, 0.4),;
    }
  .nav-tabs > li.active > a,
  .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
      box-shadow: inset 0 0 0 0 rgba(0, 0, 0, 0.4), 0px 0px 10px -1px rgba(0, 0, 0, 0.4),;
  }
}

.container-order {
    display: grid;
    //grid-template-columns: 50px 50px 50px 50px;
    //grid-template-rows: auto;
    justify-content: stretch;
    grid-template-columns: 40% 1fr;
    grid-template-areas: "customerFilesContainer customerFilesContainer "
                         "carContainer carContainer"
                         ". timelineContainer";

    $paddingSize: 10px;
    .car-container {
        display: grid;
        grid-area: carContainer;
        grid-template-columns: 40% 1fr;
        grid-template-areas: "img description"
                             "img action";
        position: relative;
        background-color: #ffffff;
        z-index: 1;
        > picture {
            grid-area: img;
            > img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
                align-self: stretch;
            }
        }
        .description {
            grid-area: description;
            padding: $paddingSize $paddingSize 0 $paddingSize;
        }
        //.info-car {
        //    grid-area: infoCar;
        //    padding-right: $paddingSize;
        //}
        .action {
            grid-area: action;
            display: grid;
            gap: $paddingSize;
            grid-template-columns: 1fr 1fr;
            padding: $paddingSize;
            align-self: end;
        }
    }
    .timeline-container {
        grid-area: timelineContainer;
        display: grid;
        grid-template-columns: 1fr 140px;
        position: relative;
        align-items: stretch;
        background-color: $orange;
        &.no-appointment {
            grid-template-columns: 1fr;
            background-color: unset;
        }
        > div:nth-child(2) {
            display: flex;
            justify-content: center;
            > button {
                > i {
                    font-size: 1.8rem;
                }
            }
        }
    }
    .tab-customer-files {
        grid-area: customerFilesContainer;
        > div:nth-child(2) {
            display: none;
        }
        > div:nth-child(1) {
            padding: 10px 30px;
            .icon-upload, .icon-arrow-down {
                position: relative;
                &:before {
                    transform: translateY(-50%);
                    position: absolute;
                    top: 50%;
                    transition: transform .5s;
                }
            }
            .icon-upload:before {
                right: 0;
            }
            .icon-arrow-down:before {
                left: 0;
            }
        }
        &.open {
            > div:nth-child(1) {
                .icon-arrow-down:before {
                    transform: translateY(-50%) rotate(180deg);
                }
            }
            > div:nth-child(2) {
                display: block;
            }
        }
    }
    .tooltip {
        width: 200px !important;
        max-width: 200px !important;
        z-index: 10;
        min-width: unset;
        .tooltip-inner {
            width: 200px !important;
            max-width: 200px !important;
            min-width: unset;
            z-index: 10;
        }
    }
    .btn-scheduler {
        display: flex;
        align-content: center;
        > button {
            background:none;
            border:none;
            &:focus{
                border: none;
                outline: none;
            }
        }
    }

    &.partner {
        position: relative;
        padding: 5px 0 !important;
        .car-container {
            z-index: 3;
            grid-template-columns: 150px 1fr 200px;
            grid-template-areas:
                "img description action"
            ;
            grid-column-gap: 5px;
            > picture > img {
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
            .description {
                display: grid;
                padding: 5px 0;
                align-items: center;
                align-self: center;
                grid-template-rows: auto;
                grid-template-columns: 1fr 150px;
                grid-template-areas:
                    "order price"
                    "brand-model price"
                    "version price"
            ;
                .grid-order {
                    grid-area: order;
                }
                .grid-brand-model {
                    grid-area: brand-model;
                }
                .grid-version {
                    grid-area: version;
                }
                .grid-price {
                    grid-area: price;
                    text-align: right;
                }
            }
            button, a {
                font-size: 1.1rem !important;
                line-height: 1.1rem !important;
                text-wrap: nowrap;
                padding: 5px 5px;
                > span:has( > i) {
                    display: none;
                }
            }
            .action {
                align-self: center;
                display: flex;
                flex-direction: column;
                padding: 0 5px 0 0;
                gap: 5px;
                > .flex > *:nth-child(2) {
                    margin-left: 5px;
                }
            }
        }
        .tab-customer-files {
            > div:nth-child(1) {
                font-size: 1.2rem;
                padding: 5px;
            }
        }
        .timeline-container {
            .timeline-horizontal ul li {
                padding-bottom: 2px;
                margin-bottom: 2px;
            }
        }
    }

    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        grid-template-columns: 1fr;
        grid-template-areas: "customerFilesContainer"
                         "carContainer"
                         "timelineContainer";
        .car-container {
            grid-template-areas: "img description infoCar"
                             "action action action";
        }
    }

    @media (max-width: $screen-xs-max) {
        grid-template-columns: 1fr;
        grid-template-areas: "customerFilesContainer"
                         "carContainer"
                         "timelineContainer";
        .car-container {
            grid-template-columns: 1fr;
            grid-template-areas: "img"
            "description "
            "infoCar"
            "action";
            .info-car {
                padding: $paddingSize $paddingSize 0 $paddingSize;
            }
            .action {
                display: flex;
                flex-direction: column;
            }
        }
        .timeline-container {
            display: flex;
            flex-direction: column;
            background-color: unset;
            box-shadow: unset;
            > div:nth-child(1) {
                @include shadowBox(0.3);
            }
            > div:nth-child(2) {
                margin-top: 1.5rem;
                display: flex;
                flex-direction: column;
                > .btn-scheduler > button {
                    display: inline-block;
                    width: 100%;
                    background-color: $orange;
                    border-radius: 10px;
                    padding: 1rem 0;
                    margin: 0 2rem;
                    @include shadowBox(0.3);
                }
            }
        }
    }
}

.search-order {
    margin-top: 2.1rem;
    margin-bottom: 2.5rem;
    padding: 10px;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
    form {
        display: grid;
        grid-template-columns: 1fr 50px 150px 150px 150px;
        justify-content: center;
        align-items: center;
        > div {
            .jcf-select {
                margin: 0;
                height: 34px;
            }
            .jcf-select-text {
                height: 34px;
            }
        }
    }
}

.pagination-container {
    .pagination:not(.no-margin) {
        li {
            display: none;
            &.active, &.active + li, &:nth-child(-n + 3), &:nth-last-child(-n + 3), &:has( + .active) {
                display: inline;
            }
            &.active + li,  &:nth-child(-3n) {
                > a {
                    margin-right: 15px;
                }
            }
            &:nth-last-child(-3n), &:has( + .active) {
                > a {
                    margin-left: 15px;
                }
            }
            &:nth-child(-n + 3) {
                > a {
                    margin-left: -1px;
                }
            }
            &:nth-last-child(-n + 3) {
                > a {
                    margin-right: -1px !important;
                }
            }
        }
    }
}
