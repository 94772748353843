.containAlerteSurMesure{
  margin-top: 1.5em;
  margin-bottom: 0.5em;
  text-align: center;
  .whiteCard{
    padding: 10px;
  }
}

#modalAlerteSurMesure{
  .headerAlerteSurMesureStep2, .headerAlerteSurMesureStep3, .alerteSurMesureStep2, .alerteSurMesureStep3, .footerAlerteSurMesureStep2, .footerAlerteSurMesureStep3 {
    display: none;
  }
}

.onDemandFilter{
  position: relative;
  margin : auto 1em 1em auto
}

.removeFilter{
  $tailleBadge : 0.7em;
  @include deleteCard($tailleBadge);
  right: -($tailleBadge);
  top: -($tailleBadge);
  font-weight: normal;
  font-size: xx-small;
}


