.bandeauBrand{
    .brandList{
        > .row {
            display: flex;
            flex-wrap: wrap;
        }
    }
    a{
        font-size: 1.143em;
        display: block;
        &.link{
            display: none;
            text-transform: initial;
            font-size: 85%;
            margin-top: 5px;
            text-decoration: underline;
            @media (max-width: $screen-xs-max) {
                span{
                    display: none;
                }
            }
        }
    }
    .brand{
        width: 14%;
        display: none;
        &:before{
            content: '';
            display: inline-block;
            width: 1px;
            height: 90%;
            position: absolute;
            left: 0;
            top: 5px;
        }
        &:nth-child(-n+7){
            display: block;
        }
        &:nth-child(7){
            a{
                &:nth-of-type(3){
                    display: none;
                }
                &.link{
                    display: block;
                    @media(max-width: $screen-md-max){
                        display: none;
                    }
                }
            }
        }
        &:nth-child(-n+3){
            a:not(.link){
                font-weight: bold;
            }
        }
        &:nth-child(8n+0){
            &:before{
                display: none;
                @media(max-width: $screen-md-max){
                    display: inline-block;
                }
            }
        }
        @media (max-width: $screen-md-max){
            width: 24%;

            &:nth-child(4n+1){
                &:before{
                    display: none;
                }
            }
            &:nth-child(-n+7){
                &:before{
                    height: 60%;
                }
            }
            &:nth-of-type(4){
                margin-bottom: 30px;
                &:before{
                    height: 88%;
                }
                a:nth-of-type(3){
                    display: none;
                }
                a.link{
                    display: inline-block;
                }
            }
            &:first-child{
                &:before{
                    display: inline-block;
                }
            }

        }
        @media (max-width: $screen-xs-max){
            width: 32.33%;
            &:nth-child(3n+1){
                &:before{
                    display: none;
                }
            }
            &:nth-of-type(3){
                margin-bottom: 30px;
                &:before{
                    height: 75%;
                }
                a:nth-of-type(3){
                    display: none;
                }
                a.link{
                    display: inline-block;
                }
            }
            &:nth-of-type(4){
                margin-bottom: 0;
                a:nth-of-type(3){
                    display: block;
                }
                a.link{
                    display: none;
                }
            }
            &:nth-of-type(5), &:nth-of-type(9){
                &:before{
                    display: inline-block;
                }
            }
        }
    }
    &.active{
        .brand{
            display: block;
            &:before{
                height: 85%;
            }
            &:first-child{
                &:before{
                    height: 70%;
                }
            }
            &:nth-of-type(7){
                a{
                    &:nth-of-type(3){
                        display: inline-block;

                    }
                    &.link{
                        display: none;
                    }
                }
            }
            &:nth-of-type(-n + 7){
                &:after{
                    content: '';
                    display: inline-block;
                    width: 100%;
                    height: 15px;
                    position: absolute;
                    left: 0;
                    bottom: -4px;
                    background-color: $bleuEm;
                }
                a{
                    &:nth-of-type(3){
                        padding-bottom: 10px;
                        &:before{
                            content: '';
                            display: inline-block;
                            height: 1px;
                            width: 102%;
                            background-color: #fff;
                            position: absolute;
                            bottom: 3px;
                            left: 50%;
                            transform: translateX(-50%);
                            z-index: 9;
                        }
                    }
                }
            }
            &:first-child{
                a{
                    &:nth-of-type(3){
                        &:before{
                            width: 85%;
                            left: 26px;
                            transform: none;
                        }
                    }
                }
            }
            @media (max-width: $screen-md-max){
                &:nth-of-type(-n +7){

                    &:after{
                        display: none;
                    }
                    a{
                        &:nth-of-type(3){
                            padding-bottom: 0;
                            &:before{
                                display: none;
                            }
                        }
                    }
                }
                &:nth-of-type(-n +4){
                    &:after{
                        content: '';
                        display: inline-block;
                        width: 100%;
                        height: 15px;
                        position: absolute;
                        left: 0;
                        bottom: -4px;
                        background-color: $bleuEm;
                    }
                    a{
                        &:nth-of-type(3){
                            padding-bottom: 10px;
                            &:before{
                                content: '';
                                display: inline-block;
                                height: 1px;
                                width: 102%;
                                background-color: #fff;
                                position: absolute;
                                bottom: 3px;
                                left: 50%;
                                transform: translateX(-50%);
                                z-index: 9;
                            }
                        }
                    }
                }
                &:first-child{
                    order: 1;
                    a{
                        &:nth-of-type(3){
                            &:before{
                                width: 85%;
                                left: 31px;
                                transform: none;
                                @media (max-width: $screen-xs-max){
                                    left: 30px;
                                }
                            }
                        }
                    }
                }
                &:nth-of-type(4){
                    order: 4;
                    margin-bottom: 0;
                    a{
                        &:nth-of-type(3){
                            display: inline-block;
                            &:before{
                                width: 85%;
                                left: 82px;

                            }
                        }
                        &.link{
                            display: none;
                        }
                    }
                }
                &:nth-of-type(2){
                    order: 2;
                }
                &:nth-of-type(3){
                    order: 3;
                }
                &:nth-of-type(5){
                    order: 5;
                }
                &:nth-of-type(6){
                    order: 10;
                    &:before{
                        display: none;
                    }
                }
                &:nth-of-type(7){
                    order: 6;
                    &:before{
                        display: inline-block;
                        height: 100%;
                    }
                }
                &:nth-of-type(8){
                    order: 11;
                    &:before{
                        height: 100%;
                    }
                }
                &:nth-of-type(9){
                    order: 7;
                    &:before{
                        display: inline-block;
                        height: 100%;
                    }
                }
                &:nth-of-type(10){
                    order: 11;
                    &:before{
                        display: inline-block;
                        height: 100%;
                    }
                    &:after{
                        content: '';
                        display: inline-block;
                        width: 1px;
                        height: 85%;
                        position: absolute;
                        right: -1px;
                        top: 0;
                        background-color: #fff;
                    }
                }
                &:nth-of-type(11){
                    order: 7;
                    &:before{
                        height: 100%;
                    }
                }
                &:nth-of-type(12){
                    order: 11;
                    &:before{
                        height: 100%;
                    }
                }
                &:nth-of-type(13){
                    order: 8;
                    &:before{
                        display: inline-block;
                        height: 100%;
                    }
                }
                &:nth-of-type(14){
                    order: 12;
                }
            }
            @media (max-width: $screen-sm-max){
                &:nth-of-type(4){
                    a{
                        &:nth-of-type(3){
                            &:before{
                                left: 55px;
                            }
                        }
                    }
                }
            }
            @media (max-width: $screen-xs-max){
                &:nth-of-type(-n + 6){
                    a{
                        &:nth-of-type(3){
                            padding-bottom: 0;
                        }
                    }
                }
                &:nth-of-type(-n + 3){
                    a{
                        &:nth-of-type(3){
                            padding-bottom: 10px;
                        }
                    }
                }
                &:nth-of-type(3){
                    margin-bottom: 0;
                    a{
                        &:nth-of-type(3){
                            display: inline-block;
                            &:before{
                                left: -24px;
                                transform: none;
                            }
                        }
                        &.link{
                            display: none;
                        }
                    }
                }
                &:nth-of-type(4){
                    &:after{
                        display: none;
                    }
                    a{
                        &:nth-of-type(3){
                            &:before{
                                display: none;
                            }
                        }
                    }
                }
                &:nth-of-type(5){
                    order: 7;
                }
                &:nth-of-type(6){
                    order: 10;
                }
                &:nth-of-type(7){
                    order: 5;
                }
                &:nth-of-type(8){
                    order: 8;
                }
                &:nth-of-type(9){
                    order: 11;
                }
                &:nth-of-type(10){
                    order: 6;
                    &:before{
                        display: inline-block;
                        height: 100%;
                    }
                    &:after{
                        display: none;
                    }
                }
                &:nth-of-type(11){
                    order: 9;

                    //&:after{
                    //    content: '';
                    //    display: inline-block;
                    //    width: 1px;
                    //    height: 85%;
                    //    position: absolute;
                    //    right: -1px;
                    //    top: 5px;
                    //    background-color: #fff;
                    //}
                }
                &:nth-of-type(12){
                    order: 12;
                    &:before{
                        height: 100%;
                    }
                }
                //&:nth-of-type(13){
                //    order: 9;
                //}
                //&:nth-of-type(14){
                //    order: 12;
                //    &:before{
                //        height: 100%;
                //    }
                //}
            }
        }
    }
}
