.containArgumentaire {
  @include liststyle();
  .sliderMobile {
    //font-size: 1.35em;
    text-align: center;
    margin: 1em auto;
    i {
      font-size: 5em;
      line-height: 1.5em;
      color: $bleu;
    }
    p, h3 {
      font-weight: 700;
      line-height: 26px;
    }
    .slick-prev,
    .slick-next {
      top: 30%;
    }
  }
}
