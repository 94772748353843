.containConfirmationParrainage{
  h1{
    margin: 0em auto 0.5em;
  }
  img{
    margin: 2em;
  }
  .btn{
    margin: 0.5em;
  }
}