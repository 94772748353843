.containError{
  padding: 2em 10px;
  hr{
    margin: 5rem 0 4rem 0;
  }
  .btn-bleuEM{
    margin-top: 2em;
  }
  .btn-gris1{
    margin-top: 0.75em;
  }
}