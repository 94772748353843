/***********************************/
/************ CHECKBOXES ***********/
/***********************************/
:root .jcf-checkbox span {
  margin: -5px 0 0 -5px;
}
.jcf-checkbox, .containCheckBoxFakeRadio label label .jcf-checkbox {
  cursor: pointer;
  vertical-align: middle;
  display: inline-block;
  position: relative;
  overflow: hidden;
  background: #fff;
  border: 1px solid $gris3;
  border-radius: 2px;
  &:not(.jcf-disabled){
    box-shadow: 0 0 5px rgba(0,0,0,0.35)
  }
  margin: 0 3px 3px 0;
  height: 22px;
  width: 22px;

  span {
    position: absolute;
    display: none;
    height: 7px;
    width: 11px;
    top: 50%;
    left: 50%;
    margin: -7px 0 0 -6px;
    border: 3px solid #777;
    border-width: 0 0 3px 3px;
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -ms-filter: "progid:DXImageTransform.Microsoft.Matrix(M11=0.7071067811865483, M12=0.7071067811865467, M21=-0.7071067811865467, M22=0.7071067811865483, SizingMethod='auto expand')";


    .formBleuEm & {
      border-color: $bleuEm;
    }
    .formBleu & {
      border-color: $bleu;
    }
    .formVert & {
      border-color: $vert;
    }
    .formJaune & {
      border-color: $jaune;
    }
    .formRose & {
      border-color: $rose;
    }
    .formOrange & {
      border-color: $orange;
    }
  }

  input[type="checkbox"] {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 0;
    margin: 0;
    left: 0;
    top: 0;
    &:not(:disabled){
    cursor: pointer;
    }
  }

  &.jcf-checked span {
    display: block;
  }
  &.jcf-disabled span {
    border-color: $gris1;
  }
}

/***********************************/
/************** RADIOS *************/
/***********************************/

.jcf-radio, .containCheckBoxFakeRadio .jcf-checkbox {
  cursor: pointer;
  vertical-align: middle;
  display: inline-block;
  position: relative;
  overflow: hidden;
  background: #fff;
  border: 1px solid $gris3;
  border-radius: 12px;
  margin: 0 3px 0 0;
  height: 26px;
  width: 26px;
  &:not(.jcf-disabled) {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2)
  }
  span {
    display: none;
    position: absolute;
    top: 7px;
    left: 7px;
    right: 7px;
    bottom: 7px;
    background: $bleuEm;
    border-radius: 100%;
    transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    border-width: 0;
    height: auto;
    width: auto;
    margin: 0;
    .formBleuEm & {
      background: $bleuEm;
    }
    .formVert & {
      background: $vert;
    }
    .formBleu & {
      background: $bleu;
    }
    .formJaune & {
      background: $jaune;
    }
    .formRose & {
      background: $rose;
    }
    .formOrange & {
      background: $orange;
    }
  }
  input[type="radio"] {
    position: absolute;
    height: 100%;
    width: 100%;
    border: 0;
    margin: 0;
    left: 0;
    top: 0;
    &:not(:disabled) {
      cursor: pointer;
    }
  }
  &.jcf-checked{
    span {
      display: block;
    }
  }
  &.jcf-disabled{
    span {
      background: $gris1;
    }
  }
}


/***********************************/
/************* SELECTS *************/
/***********************************/

.jcf-select {
  display: inline-block;
  vertical-align: top;
  position: relative;
  border: 1px solid $gris3;
  background: #fff;
  margin: 0 0 12px;
  width: 100%;
  height: 34px;
  cursor: pointer;

  .select2-selection--multiple{
    border: none;
  }

  &:not(.jcf-disabled) {
    //@include shadowBox(0.1);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);

  }

  &:before{
    content: "\f107";
    font-family: EM-icomoon;
    font-size: large;
    position: absolute;
    top: 0px;
    right: 6px;
    line-height: 34px;
    color: $gris1;
  }

  select {
    z-index: 1;
    left: 0;
    top: 0;
  }

  .jcf-select-text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer;
    display: block;
    font-size: 16px;
    font-weight: 600;
    line-height: 34px;
    margin: 0 35px 0 8px;
  }

  .jcf-select .jcf-select-opener {
    position: absolute;
    text-align: center;
    background: none;
    width: 26px;
    bottom: 0;
    right: 0;
    top: 0;
    background-color: transparent;
  }

  .jcf-select-drop {
    position: absolute;
    margin-top: 0px;
    z-index: 9999;
    top: 100%;
    left: -1px;
    right: -1px;
  }

  .jcf-drop-flipped {
    bottom: 100%;
    top: auto;
  }
}

.input-group {
  .jcf-select {
    width: 50%;
  }
}

.filterCheckbox{
  height: 16px;
  width: 16px;
}

.filterSelect{
  height: 34px !important;
  width: 100%;
  color: $blanc;
  overflow: hidden;
}

.jcf-select.jcf-select-input-lg {
  margin-bottom: 0;
  height: 44px;
  &:before {
    line-height: 44px;
  }
  .jcf-select-text {
    line-height: 44px;
    //font-size: 18px;
    color: $gris1;
    >span {
      display: block;
      width: 10px;
      overflow: visible;
    }
  }
}



body > .jcf-select-drop {
  position: absolute;
  margin: -1px 0 0;
  z-index: 9999;
  &.jcf-drop-flipped {
    margin: 1px 0 0;
  }
}

.jcf-select.jcf-compact-multiple {
  max-width: 100%;
  width: 100%;
}

.jcf-select-drop {
  .jcf-select-drop-content {
    border: 1px solid $gris3;
  }
  &.jcf-compact-multiple {
    .jcf-option{
      &:before {
        display: inline-block;
        content: '\f096 ';
        font-family: EM-icomoon;
        transform: none;
        margin-right: 5px !important;
        border: navajowhite;
        top: 1px;
        font-size: 1em;
        position: relative;
        font-weight: 400;
      }
      &.jcf-hover {
        background: $gris3;
        color: $bleuEm;
      }
      &.jcf-selected {
        background: $bleuEm;
        color: $blanc;

        &:before {
          content: '\f046 ';
        }
      }
    }

  }
}


/* multiple select styles */
.jcf-list-box {
  overflow: hidden;
  display: inline-block;
  border: 1px solid #b8c3c9;
  min-width: 200px;
  width: 100%;
  margin: 0 15px;
}

/* select options styles */
.jcf-list {
  display: inline-block;
  vertical-align: top;
  position: relative;
  background-color: $grisFond;
  line-height: 14px;
  font-size: 14px;
  font-weight: 600;
  width: 100%;
  color: $bleuEm;
  @include shadowBoxInset(0.15);
  .jcf-list-content {
    vertical-align: top;
    display: inline-block;
    overflow: auto;
    width: 100%;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      overflow: hidden;
      display: block;
    }
  }
  .jcf-overflow {
    overflow: auto;
  }
  .jcf-option {
    white-space: normal;
    overflow: hidden;
    cursor: default;
    display: block;
    padding: 5px 9px;
    color: $bleuEm;
    min-height: 14px;
    height: 1%;
  }
  .jcf-disabled {
    background: #fff !important;
    color: #aaa !important;
  }
}


.jcf-select-drop .jcf-hover{
  background: $gris3;
  color: $bleuEm;
}
.jcf-list-box .jcf-selected {
  background: $bleuEm;
  color: $blanc;
}

.jcf-list .jcf-optgroup-caption {
  white-space: nowrap;
  font-weight: bold;
  display: block;
  padding: 5px 9px;
  cursor: default;
  color: $bleuEm;
}

.jcf-list .jcf-optgroup .jcf-option {
  padding-left: 30px;
}

/***********************************/
/************ SCROLLBAR ************/
/***********************************/

/* scrollbars */
.jcf-scrollable-wrapper {
  box-sizing: content-box;
  position: relative;
}

.jcf-scrollbar-vertical {
  position: absolute;
  cursor: default;
  background: transparent;
  width: 6px;
  bottom: 0px;
  right: 0px;
  top: 0px;
  border-radius: 3px;



  .jcf-scrollbar-dec, .jcf-scrollbar-inc {
    background: transparent;
    height: 0px;
    width: 6px;
    left: 0;
    top: 0;
  }
  .jcf-scrollbar-inc {
    top: auto;
    bottom: 0;
  }
  .jcf-scrollbar-handle {
    background: $gris1;
    height: 1px;
    width: 15px;
    right: 0;
    border-radius: 3px;
  }
}

.jcf-scrollbar-horizontal {
  position: absolute;
  background: transparent;
  right: auto;
  top: auto;
  left: 0;
  bottom: 0;
  width: 1px;
  height: 6px;
  border-radius: 3px;
  .jcf-scrollbar-dec, .jcf-scrollbar-inc {
    display: inline-block;
    vertical-align: top;
    overflow: hidden;
    background: transparent;
    height: 6px;
    width: 0px;
  }
  .jcf-scrollbar-inc {
    left: auto;
    right: 0;
  }
  .jcf-scrollbar-slider {
    display: inline-block;
    position: relative;
    height: 6px;

  }
  .jcf-scrollbar-handle {
    position: absolute;
    background: $gris1;
    height: 6px;
    border-radius: 3px;
  }
  &.jcf-inactive {
    .jcf-scrollbar-handle {
      visibility: hidden;
    }
    .jcf-scrollbar-dec, .jcf-scrollbar-inc {
      background: transparent;
    }
  }
}

/***********************************/
/************* GLOBAL **************/
/***********************************/

.jcf-focus{
  border-color: transparent !important;
}

.jcf-disabled {
  background: $grisDisabled !important;
}

.formBleuEm .jcf-focus *{
  border-color: $bleuEm !important;
}

.formVert .jcf-focus * {
  border-color: $vert !important;
}

.formBleu .jcf-focus * {
  border-color: $bleu !important;
}

.formJaune .jcf-focus * {
  border-color: $jaune !important;
}

.formRose .jcf-focus * {
  border-color: $rose !important;
}

.formOrange .jcf-focus * {
  border-color: $orange !important;
}

.jcf-select-drop .jcf-option-hideme{
  display: none;
}

label{
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;

}
