.containFaq{
  .containTabsFaq {
    ul{
      li{
        @media only screen {
          @media(min-width: $screen-md-min) {
            i {
              display: block;
              font-size: 2em;
              margin-bottom: 0.5em;
            }
          }
        }
      }
    }

    .tab-pane{
      padding: 1em;
    }



    .tab-pane, .panel-collapse{

      //.btn-group{
      //  .btn{
      //    margin-left: 0.5em;
      //    margin-bottom: 0.5em;
      //    text-transform: none;
      //    font-weight: 600;
      //    background-color: $grisFond;
      //    border: 2px solid $gris3;
      //    color: $gris1;
      //    text-shadow: none;
      //    font-size: 1.1em;
      //    &.btn.active{
      //      background-color: $bleuEm;
      //      border-color: $bleuEm;
      //      color: $blanc;
      //      /*&:after{
      //        font-family: EM-icomoon;
      //        content: '\f00d';
      //        line-height: 1em;
      //      }*/
      //    }
      //  }
      //}
      .panel-group{
        padding: 1em 1em 0;
        .panel{
          box-shadow: none;

          margin-bottom: 0;
          &:not(:last-child){
            border-bottom: 1px solid $gris3;
          }
          .panel-heading{
            padding: 15px 0;
          }
          .panel-collapse{
            .panel-body{
              padding: 0 .5em 15px;
              box-shadow: none;
              border-top-color: $blanc;
            }
          }
        }
      }
    }
  }
}

.force-style-ul {
    padding-left: 40px !important;
    li {
        list-style: unset !important;
    }
}
