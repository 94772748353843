.containModalitesCommande{
  .whiteCard {
    .containCartePaiement{
      .whiteCard{
        margin-bottom: 2.5em;
      }
    }
    .containRadioModePaiement{
      .whiteCard{
        padding-bottom: 1.5em;
        .form-group{
          text-align: left;
        }
      }
    }
    .whiteCard{
      @media only screen {
        @media(max-width: $screen-xs-max) {
          margin-bottom: 2.5em;
        }
      }

      &:hover{
        background-color: $grisFond;
      }
      label{
        font-weight: 500;
        @media (max-width: $screen-md-max){
            font-size: 0.75em;
        }
      }
    }
    .infosComplementairesLivraison{
        margin-bottom: 0;
    }
  }
  .bleuEMCard{
    p{
      margin-bottom: 0;
    }
  }
  .containRadioModePaiement{
    >label {
      display: block;
      //margin-bottom: 1em;
    }
    .containFormInfosCredit{
      display: none;
    }
  }
  .containCartePaiement,.containRadioModePaiement{
    .jcf-radio {
      position: absolute;
      bottom: calc(0% - 13px);
      left: calc(50% - 13px);
    }
    label{
      p{
        font-weight: normal;
      }
    }
  }

  #montantPaiementAcompte{
    width: 75px;
  }
  @media (max-width: $screen-xs-max){
      #montantPaiementAcompteValidationButton{
          display: block;
          margin: 1em auto 0;
          width: 70%;
      }
  }
}
