.tab-feu-vert{
    padding: 0 6em 2.5em;
    @media (max-width: $screen-xs-max){
        padding: 0 0 2.5em;
    }
    li{
        font-size: 1.125em;
        @media (max-width: $screen-sm-max){
            width: 50%;
            display: inline-block;
            font-size: 14px;
            a{
                border-radius: initial;
            }
        }
        a{
            color: #22286d;
            box-shadow: none;
        }
    }
    .nav-tabs.nav-justified > .active > a {
        color: $blanc;
        border: none;
        background-color: $bleuEm !important;
        box-shadow: none;
    }
    .tab-content{
        margin-top: 2px;
        .tab-pane{
            box-shadow: 0 10px 10px -4px rgba(0, 0, 0, 0.3);
            padding: 0 10px;
            .modelCars{
                color: $blanc;
                background-color: $bleuEm;
                padding: 0.6em 0;
                .title{
                    border-right: 1px solid $blanc;
                    &:last-child{
                        border-right: none;
                    }
                }
                p{
                    margin: 0;
                }
                .priceCar{
                    p{
                        display: inline-block;
                        font-size: 22px;
                    }
                    .month{
                        font-size: 9px;
                        padding-left: 2px;
                        span{
                            display: block;
                        }
                    }
                }
            }
            .container-fluid{
                padding: 10px 20px;
                .col-md-6{
                    padding: 0 20px 10px 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    p{
                        strong{
                            display: inline-block;
                            margin: 0 0 5px;
                            font-size: 14px;
                        }
                    }
                    p{
                        font-size: 11px;
                    }
                    .icon {
                        width: 20%;
                        text-align: center;
                    }
                    .text{
                        display: inline-block;
                        width: 80%;
                        vertical-align: middle;
                    }
                }

            }
        }
    }
}
