@mixin handleFab($fabFontSize) {

  $fabDimension: $fabFontSize * 2;
  $fabPosition: $fabFontSize / 4;
  display: block;
  position: fixed;
  background-color: $bleuEm;
  width: $fabDimension;
  height: $fabDimension;
  border-radius: 50%;
  z-index: 100;
  text-align: center;
  cursor: pointer;
  @include shadowBox(0.3);
  @include transition-duration(0.5s);
  @include transition-timing-function(ease-in-out);
  transform: translate3d(0,0,0);
  //@include transform(translate3d(0, 0, 0)); //since webpack
  i {
    color: $blanc;
    @include shadowText(0.5);
    line-height: 2em;
    font-size: $fabFontSize;
  }
}

#fab {
  @include handleFab(1.5em);
  @media only screen {
    @media(min-width: $screen-lg-min) {
      display: none;
    }
  }
  right: (1em/4)*1;
}


#scrolltop{
  @include handleFab(1em);
  right: (1em/4)*3.5;
}
