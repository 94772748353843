.containGuideEspaceClient {
  .introGuide {
    margin: 1em auto 0;
  }
  .btnGuideCompte {
    position: absolute;
    bottom: 10px;
    left: 10px;
    width: calc(100% - 20px)
  }
  .carteGuideCompte{
    margin-top: 2em;
  }

  .texteCarteGuide{
    padding-bottom: 50px;
  }
  margin-bottom: 2em;
}

.containGuidePageCompte{
  h6{
    margin: 1em 0;
  }
  .stepGuideCompte{
    margin-bottom: 2em;
    >.row{
      margin-bottom: 2em;
      a.tel{
        @media (max-width: $screen-xs){
          display: block;
        }
      }
    }

  }
  .stepNumber{
    $dimensions: 1.5em;
    width: $dimensions;
    height: $dimensions;
    line-height: $dimensions - 0.1em;
    color: $blanc;
    display: block;
    border-radius: 50%;
    text-align: center;
    font-weight: 900;
    background-color: $bleuEm;
    margin: auto;
  }

  div.btn{
    margin-top: 1em;

  }
  hr{
    margin: 0;
  }
}
