.star-rating{
  display: inline-block;

  .ratingStars {
    &.rating-container {
      color: $gris3;
      cursor: pointer;
      .rating-stars {
        color: $bleuEm
      }
    }
  }
}
