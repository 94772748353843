.containActusAutomobiles {
  overflow: initial;
  padding: 0 0 40px;
  @media only screen {
    @media(max-width: $screen-sm-max) {
      overflow: hidden;
      padding: 0;
      .flex {
        display: block;
      }
    }
  }
  @media (max-width: $screen-xs-max) {
    margin: 0;
  }
  .titleActus{
    font-size: 17px;
  }
  .carouselMd{
    width: 100%;
    margin: 0;
    height: 100%;
    .carousel-inner{
      height: 100%;
      padding: 0;
      overflow: initial;
      .item-md{
        min-height: 330px;
          @media (max-width: $screen-xs-max){
              min-height: 380px;
          }
        &:first-child{
          padding-left: 0;
        }
        &:nth-child(3){
          padding-right: 0;
        }
        .carNewsCard{
          height: 100%;
        }
        .containHalfButton{
            position: absolute;
            bottom: -37px;
            left: 50%;
            transform: translateX(-50%);
            a.btn{
              margin: 0;
            }
        }
        @media (min-width: 992px){
          position: relative;
        }
      }
      @media (max-width: 768px){
        padding: 0 10px;
      }
      @media (max-width: 320px){
        padding: 15px 10px;
      }
      @media (min-width: 992px){
        display: flex;
        flex-flow: row nowrap;
        align-items: stretch;
      }
      .carousel-control {
        position: absolute;
        top: 40%;
        transform: translateY(-50%);
        width: 40px;
        height: 40px;
        opacity: 1;
        background: #ffffff;
        border: solid 1px #d6d6d6;
        display: none;
        @media(max-width: $screen-xs-max){
          width: 30px;
        }
        span {
          text-shadow: none;
          box-shadow: none;
          line-height: 40px;
          font-size: 16px;
          @media(max-width: $screen-xs-max){
            font-size: 14px;
          }
          &:before{
            color: #abafb1;
          }
        }
        @media (max-width: $screen-sm-max){
          display: block;
        }
      }
      a.left {
        left: 8px;
        span{
          &:before {
            content: "〈";
            padding-right: 8px;
          }
        }
      }
      a.right {
        right: 8px;
        span {
          &:before{
            content: "〉";
            padding-left: 8px;
          }
        }
      }
    }
  }


  .carNewsCard.vert {
    border: 2px $vert solid;
  }
  .carNewsCard.bleu {
    border: 2px $bleu solid;
  }
  .carNewsCard.jaune {
    border: 2px $jaune solid;
  }

  .sliderMobileActu {
    margin-bottom: 1em;
    .whiteCard {
      margin-top: 0.5em;
      margin-bottom: 1em;
    }

    a:hover {
      @include shadowBox(0.40);
    }
    a.carousel-control{
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 40px;
      height: 40px;
      background-color: #fff;
      background-image: none;
      opacity: 1;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
      border: 1px solid #d6d6d6;
      span.arrow{
        color: #abafb1;
        &:before{
          line-height: 40px;
          font-size: 16px;
          text-shadow: initial;
        }
      }
      span.arrow-left{

        &::before{
          content: '〈';
          padding-right: 8px;
        }
      }
      span.arrow-right{
        &::before{
          content: '〉';
          padding-left: 8px;
        }
      }
    }
    a.left{
      left: 10px;
    }
    a.right{
      right: 10px;
    }
    @media(min-width: $screen-md-min) {
      a.carousel-control{
        display: none;
      }
    }
  }

  .thumbnailActus {
    overflow: hidden;
    padding:0;
    img {
      height: 166px;
      width: 100%;
      object-position: center center;
      object-fit: cover;
    }
    img.portrait {
      width: 100%;
      height: auto;
    }
  }

  .resumeActu {
    position: relative;
    padding: 1em;
    //height: 160px;
    .readMore {
      left: 0;
    }
  }

  .txtActus{
    color: $bleuEm;
    font-size : 13px;
  }

  .slick-prev,
  .slick-next {
    top: 33%;
  }
}
