.containSliderPromo {
  @media(max-width: $screen-xs-max){
    margin: 0;
  }
  .carousel-inner{
    padding: 0 !important;
  }
  @include shadowBox(0.40);
  border: solid 1px rgba(83, 174, 225, 0.52);
  .slickSlider {
    .slidePromo {
      display: none !important;
      //height: 450px;
      padding: 0;
      text-align: center;
      color: $blanc;
      position: relative;
      //padding-top: 60px;
      //margin-bottom: 2em;
      .contentPromo {
        height: 100%;
        //background: url('../../images/slider-promo/twingo.png');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        display: table-cell;
        vertical-align: middle;
        p {
          @include shadowText(0.6);
          font-size: 1.65em;
          margin-bottom: 0;
        }
        .sliderImageContainer {
          overflow: hidden;
          height: 240px;
          width: 100%;
          background-color: $grisFond;
          img{
            width: 100%;
            height:100%;
          }
        }
      }
      h4 {
        background-color: $jaune;
        @include shadowBox(0.3);
        font-weight: 700;
        padding: 0.5em;
        display: inline-block;
        position: absolute;
        z-index: 199;
        //display: inline-block;
        top: 0;
        left: 50%;
        transform: rotate(-3deg) translate(-50%);
        //@include transform(rotate(-3deg) translate(-50%)); // since webpack
        @media only screen {
          @media(max-width: $screen-xs-max) {
            font-size: 2.5rem;
            width: 80%;
          }
        }

      }
      .cadreSliderPromo {
        background-color: $jaune;
        @include shadowBox(0.3);
        font-weight: 700;
        padding: 0.5em;
        display: inline-block;
        position: absolute;
        z-index: 199;
        bottom: -10px;
        left: 50%;
        transform: rotate(-3deg) translate(-50%);
        //@include transform(rotate(-3deg) translate(-50%)); since webpack
      }
    }
    .slidePromo:first-child {
      display: table !important;
    }
    &.slick-initialized .slidePromo {
      display: table !important;
    }

  }

  .carousel-inner{
    padding: 0;
    .carousel-control{
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 40px;
      height: 40px;
      opacity: 1;
      background: #ffffff;
      border: solid 1px #d6d6d6;
      @media(max-width: $screen-xs-max){
        width: 30px;
      }
      span{
        text-shadow: none;
        box-shadow: none;
        line-height: 40px;
        font-size: 16px;
        @media(max-width: $screen-xs-max){
          font-size: 14px;
        }
        &:before{
          color: #abafb1;
        }
      }
    }
    a.left{
      left: 9px;
      span{
        &:before{
          content: "〈";
          padding-right: 8px;
        }
      }
    }
    a.right{
      right: 9px;
      span{
        &:before{
          content: "〉";
          padding-left: 8px;
        }
      }
    }
  }
  .containSliderPadding {
    @media(max-width: $screen-sm-max) {
      margin-left: 0;
      margin-right: 0;
    }
  }
  a.carousel-control{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 40px;
    height: 40px;
    background-color: #fff;
    background-image: none;
    opacity: 1;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    border: 1px solid #d6d6d6;
    span.arrow{
      color: #abafb1;
      &:before{
        line-height: 40px;
        font-size: 16px;
        text-shadow: initial;
      }
    }
    span.arrow-left{

      &::before{
        content: '〈';
        padding-right: 8px;
      }
    }
    span.arrow-right{
      &::before{
        content: '〉';
        padding-left: 8px;
      }
    }
  }
  a.left{
    left: 10px;
  }
  a.right{
    right: 10px;
  }
}

.slick-slider {
  .slick-prev, .slick-next {
    box-shadow: none;
  }
  @media only screen {
    @media(max-width: $screen-sm-min) {

      .slick-prev {
        left: 0px;
      }
      .slick-next {
        right: 0px;
      }
    }
  }

}

