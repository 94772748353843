@import url(~leaflet/dist/leaflet.css);
@import url(~leaflet-routing-machine/dist/leaflet-routing-machine.css);

@import "./tab-feu-vert";

.containEquipePresentation{

  h1 {
    font-size: 2.5em;
    margin: 0.3em 0 0.7em 0;
  }

  a {
    color: $bleu;
  }

  .slider {
    margin: 0;
  }

  .slider-nav {
    .slick-slide {
      padding: 10px;

      @media only screen and (max-width: 1300px) {
        padding: 5px;
      }

      img {
        cursor: pointer;
      }
    }
  }

  #map {
    height: 264px;
    z-index: 1;

    .leaflet-routing-container {
      display: none;
    }

    @media only screen and (max-width: 1300px) {
      height: 334px;
    }
  }

  .whiteCard {
    padding: 0;
    margin-bottom: 1.5em;

    .nomPersonne {
      text-align: center;
      font-size: 1.1em;
      text-transform: uppercase;
      line-height: 2em;
      margin-bottom: 0;
      font-weight: 600;
      white-space: nowrap;
    }
    .telAgency {
      white-space: nowrap;
    }

    &.secondDesc {
      margin-bottom: 1.5em;

      @media only screen and (max-width: 767px) {
        margin-bottom: 1.5em;
      }

      .reviewClient {
        font-size: 1.2em;
        margin-bottom: 30px;

        .starsReview {
          color: $jaune;
          margin-bottom: 10px;
        }
      }
    }
  }

  .contactInformations {
    h3 {
      font-size: 1.6rem;
      margin: 1em 0;
    }

    .btn {
      font-size: 1.5rem;
    }

    .infosAgency {
      padding: 15px;
      margin-bottom: 20px;

      .y-separator {
        height: 205px;
        border-left: 1px solid $bleuEm;
        margin-top: 20px;
      }

      .callDemandeDeRappel {
        @media only screen and (max-width: 767px) {
          margin-bottom: 15px;
        }
      }
    }

    .titleWhitecard {
      font-size: 1.5em;
      margin-bottom: 15px;

      @media only screen and (max-width: 767px) {
        margin-bottom: 0;
        margin-top: 20px;
      }
    }

    ul {
      @media only screen and (max-width: 767px) {
        margin-bottom: 25px;
      }

      li {
        background-color: $gris4;
        margin: 4px 0;
        padding: 1px 10px;
        font-size: 1.15em;
      }

      .active {
        background-color: $bleuEm;
        color: $blanc;
      }
    }

    .btn {
      @media only screen and (max-width: 1300px) {
        font-size: 0.85em;
        font-weight: bold;
      }
    }

    @media only screen and (max-width: 992px) {
      margin-top: 0;
      margin-bottom: 0;
    }

    .carteInfos {
      p {
        padding: 0;
      }
    }
  }
}

.containPresentationPresentation{
    padding: 0 20px;
    @media (max-width: $screen-sm-max){
        padding: 0 10px;
    }
  .whiteCard{
    margin-bottom: 1.5em;
    h6{
      margin-bottom: 1em;
    }
    &.services{
        font-size: 1.1em;
        padding: 10px;
        h2{
            font-size: 2rem;
            margin-bottom: 1em;
        }
        ul{
            padding-left: 20px;
            li{
                list-style-type: disc;
            }
        }
        h3, h4{
            font-size: 1.1em;
            text-transform: inherit;
        }
        h3{
            margin: 1em 0 0.3em;
        }
    }
    &.hotelPartner{
        p:last-child{
            padding: 10px;
            img{
                width: 100%;
            }
        }
    }
    &.premiumConversion{
      ul{
          li{
              a{
                  text-decoration: underline;
              }
          }
      }
      .btn{
          &.btn-bleuEM{
              @media (max-width: $screen-sm-max) {
                  width: 100%;
                  margin-bottom: 10px;
              }
          }
      }
    }
    &.warrantieServices{
        h2, h1{
            margin: 0.875em 0;
            font-size: 2rem;
        }
        .container-img{
            margin: 1em 0;
            img{
                display: inline-block;
                margin: 0 10px;
            }
        }
        p{
            &:last-child{
                font-size: 0.8em;
                color: $gris1;
            }
        }
        ul{
            &:nth-of-type(1){
                margin: 1em 0;
                text-align: center;
                li{
                    list-style-type: none;
                    display: inline-block;
                    margin: 0 10px;
                    @media (max-width: $screen-xs-max){
                        margin: 10px;
                    }
                    img{
                       display: inline-block;
                    }
                }
            }

        }
        &.packs{
            a{
                text-decoration: underline;
            }
            h2:nth-of-type(2), p:nth-of-type(2), p:nth-of-type(3){
                color: $bleu;
            }
            p:nth-of-type(2){
                margin: 0;
                a{
                    color: $bleu;
                }
            }
            p:nth-of-type(3){
                text-decoration: underline;
                margin: 0;
            }
            ul{
                &:nth-of-type(1){
                    li{
                        margin: 0 20px;
                        @media (max-width: $screen-xs-max){
                            margin: 10px 20px;
                        }
                    }
                }
            }
            ul{
                &:nth-of-type(3){
                    color: $bleu;
                    a{
                        color: $bleu;
                    }
                }
            }
        }
    }
    &.deliveryMode{
      h2, h1{
          margin: 0.875em 0;
          font-size: 2rem;
      }
      h3{
          margin: 1em 0;
          font-size: 2rem;
      }
      .map{
          img{
              width: 100%;
          }
      }
      .labelDeliveryChoices{
          display: flex;
          .containerStep{
              align-self: center;
          }
      }
    }
    &.distribution{
        h1{
            margin: 0.875em 0;
            font-size: 2rem;
        }
        a{
            display: inline-block;
            color: #fff;
            background-color: $bleuEm;
            padding: 10px;
            margin-left: 10px;
            @media (max-width: $screen-xs-max){
                margin-left: 0;
                margin-top: 6px;
                display: inline-block;
                padding: 5px 20px;
            }
        }
        .map{
            img{
                width: 75%;
            }
        }
        .text{
            strong{
                display: inline-block;
            }
        }
        p{
            margin-bottom: 20px;
        }
    }
      &.offer-feu-vert{
          a{
              display: inline-block;
          }
      }
      &.exportPackage{
          h3{
              font-size: 2rem;
              margin: 1.5em 0;
          }
          a{
              text-decoration: underline;
          }
          p:nth-of-type(2){
              font-size: 0.8em;
              font-style: italic;
              color: $gris1;
          }
      }
      &.utilityKit{
          em{
              font-size: 0.8em;
              font-style: italic;
              color: $gris1;
          }
      }
      &.bonusesGodfather{
          a{
              text-decoration: underline;
          }
      }
  }
  .bodyCarte{
    font-size: 1.1em;
    padding-top: 0.5em;
      @media (max-width: $screen-xs-max){
          font-size: 0.9em;
          strong{
              text-align:left;
              display: block;
          }
          .map{
              padding-bottom: 10px;
          }
      }
    h6:not(:first-child){
      margin-top: 1.5em;
    }
    .text{
        margin-bottom: 20px;
    }
    a.basicLink {
      text-decoration: underline;
      color: #1c3ef2;
    }
    a.meetLink{
        color: #fff;
        background-color: $bleuEm;
        padding: 10px;
        margin-left: 10px;
        @media (max-width: $screen-xs-max){
            margin-left: 0;
            margin-top: 6px;
            display: inline-block;
            padding: 5px 20px;
        }
    }
    .spacing{
        padding-bottom: 15px;
    }
  }
    .introduction{
        .bodyCarte{
            @media (max-width: $screen-xs-max){
                strong{
                    text-align:left;
                    display: initial;
                }
            }
        }
    }
  .maintenance{
    .whiteCard{
      padding: 1em 10px 1.7em;
        ul{
            padding: 0;
        }
        a.basicLink{
            text-decoration: underline;
            color: #1c3ef2;
        }
    }
  }
}
.offer-feu-vert{
    .whiteCard{
        @media (max-width: $screen-xs-max){
            background: none;
            box-shadow: initial;
            border: none;
            margin-bottom: 2.1em;
        }
    }
}
.thumbnail-img {
  //width: 200px;
  //height: 200px;
  overflow: hidden;
  position: relative;
  img {
    position: absolute;
    top: -9999px;
    bottom: -9999px;
    left: -9999px;
    right: -9999px;
    margin: auto;
    height: 100%;
  }
}

#lightgallery{
  >div{
    margin-bottom: 1.5em;
  }
}

.containVisite360{
  padding: 0;
  margin-bottom: 15px;

  iframe{
    width: 100%;
    @include whiteCard
  }
}

.containGaleriePhoto{
  padding: 1.5em 1.5em 0;
}
