@for $i from 1 through 9 {
    $margin : $i*10;
    // margins - all sides
    .margin-top-#{$i} {
        margin-top: #{$i}em;
    }
}
.ml-1 {
    margin-left: .8rem;
}

.p-10 {
    padding: 10px;
}

.margin-top-037 {
    margin-top: 0.37em;
}

.margin-top-x-0 {
    @media only screen and (max-width: 767px) {
        margin-top: 0!important;
    }
}

.p-0 {
    padding: 0 !important;
}

.margin-y-3 {
    margin-top: 3em;
    margin-bottom: 3em;
}

.margin-bottom-0 {
    margin-bottom: 0!important;
}

.margin-bottom-1{
    margin-bottom: 1em;
}


.padding-top-0 {
    padding-top: 0!important;
}

.padding-bottom-1{
    margin-bottom: 1em;
}

.padding-bottom-2{
    margin-bottom: 2em;
}

.padding-x-10 {
    padding: 0 10px;
}

@for $i from 0 through 20 {
    .py-#{$i} {
        padding-top: $i * 0.1rem;
        padding-bottom: $i * 0.1rem;
    }
    .pt-#{$i} {
        padding-top: $i * 0.1rem;
    }
    .pr-#{$i} {
        padding-right: $i * 0.1rem;
    }
    .pb-#{$i} {
        padding-bottom: $i * 0.1rem;
    }
    .px-#{$i} {
        padding-right: $i * 0.1rem;
        padding-left: $i * 0.1rem;
    }
    .m-#{$i} {
        margin: $i * 0.1rem;
    }
    .mr-#{$i} {
        margin-right: $i * 0.1rem;
    }
    .mx-#{$i} {
        margin-right: $i * 0.1rem;
        margin-left: $i * 0.1rem;
    }
    .mb-#{$i} {
        margin-bottom: $i * 0.1rem !important;
    }
}


.padding-x-20 {
    padding: 0 20px;
}

.ml-10px {
    margin-left: 10px;
}
.mr-10px {
    margin-right: 10px;
}

.ml-1 {
    margin-left: 1rem;
}

.mr-1 {
    margin-right: 1rem;
}

.padding-x-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.padding-15 {
    padding: 15px;
}

.padding-x-1 {
    padding: 0 5px;
}

.padding-right-0 {
    padding: 0 0 0 10px;
}

.padding-left-0 {
    padding: 0 10px 0 0;
}

.mt-0 {
    margin-top: 0 !important;
}
