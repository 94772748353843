.containIntro{
  margin-top: 56px;
  @media(max-width: $screen-md-max) {
      margin-top: 65px;
  }
    @media(max-width: $screen-sm-max){
        &.asideShow{
            margin-top: 123px;
        }
    }
}
.header-pro ~ .containIntro {
    margin-top: 88px;
    @media(max-width: $screen-md-max) {
        margin-top: 97px;
    }
    @media(max-width: $screen-sm-max){
        &.asideShow{
            margin-top: 123px;
        }
    }
}
