$tooltip-border-color: #fff;
$tooltip-border-width: 2px;
$tooltip-arrow-border-width: $tooltip-arrow-width + $tooltip-border-width;

.tooltip {
  z-index: 1;
}

.tooltip, .jqvmap-label {
  font-size: $font-size-base;
  font-weight: 700;
  box-sizing: border-box;
}

.tooltip-inner, .jqvmap-label {
  border: $tooltip-border-width solid $tooltip-border-color;
  @include shadowBox(0.3);
  box-sizing: border-box;
}

.jqvmap-label:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  @include shadowText(0.3);
}
.containInformationsFicheVehicule .tooltip-inner {
  max-width: 500px;
}
.tooltip, .jqvmap-label {
  &.top .tooltip-arrow {
    bottom: $tooltip-border-width
  }
  &.bottom .tooltip-arrow {
    top: $tooltip-border-width;
  }
  &.left .tooltip-arrow {
    right: $tooltip-border-width;
  }
  &.right .tooltip-arrow {
    left: $tooltip-border-width;
  }
}


.tooltip-reset-size {
    .tooltip {
        width: 200px !important;
        max-width: 200px !important;
        min-width: unset;
        height: unset !important;
        .tooltip-inner {
            width: 200px !important;
            max-width: 200px !important;
            min-width: unset;
            height: unset !important;
        }
    }
}
