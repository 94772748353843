
@font-face {
  // latoth
  font-family: 'Lato';
  src: url('../../../fonts/lato/latoth.eot?euw0km'),
  url('../../../fonts/lato/latoth.eot?euw0km#iefix') format('embedded-opentype'),
  url('../../../fonts/lato/latoth.woff2?euw0km') format('woff2'),
  url('../../../fonts/lato/latoth.woff?euw0km') format('woff'),
  url('../../../fonts/lato/latoth.ttf?euw0km') format('truetype'),
  url('../../../fonts/lato/latoth.svg?euw0km#EM-icomoon') format('svg');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  // latolt
  font-family: 'Lato';
  src: url('../../../fonts/lato/latolt.eot?euw0km'),
  url('../../../fonts/lato/latolt.eot?euw0km#iefix') format('embedded-opentype'),
  url('../../../fonts/lato/latolt.woff2?euw0km') format('woff2'),
  url('../../../fonts/lato/latolt.woff?euw0km') format('woff'),
  url('../../../fonts/lato/latolt.ttf?euw0km') format('truetype'),
  url('../../../fonts/lato/latolt.svg?euw0km#EM-icomoon') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  // latorg
  font-family: 'Lato';
  src: url('../../../fonts/lato/latorg.eot?euw0km'),
  url('../../../fonts/lato/latorg.eot?euw0km#iefix') format('embedded-opentype'),
  url('../../../fonts/lato/latorg.woff2?euw0km') format('woff2'),
  url('../../../fonts/lato/latorg.woff?euw0km') format('woff'),
  url('../../../fonts/lato/latorg.ttf?euw0km') format('truetype'),
  url('../../../fonts/lato/latorg.svg?euw0km#EM-icomoon') format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  //latobl
  font-family: 'Lato';
  src: url('../../../fonts/lato/latobl.eot?euw0km'),
  url('../../../fonts/lato/latobl.eot?euw0km#iefix') format('embedded-opentype'),
  url('../../../fonts/lato/latobl.woff2?euw0km') format('woff2'),
  url('../../../fonts/lato/latobl.woff?euw0km') format('woff'),
  url('../../../fonts/lato/latobl.ttf?euw0km') format('truetype'),
  url('../../../fonts/lato/latobl.svg?euw0km#EM-icomoon') format('svg');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  //latobk
  font-family: 'Lato';
  src: url('../../../fonts/lato/latobk.eot?euw0km'),
  url('../../../fonts/lato/latobk.eot?euw0km#iefix') format('embedded-opentype'),
  url('../../../fonts/lato/latobk.woff2?euw0km') format('woff2'),
  url('../../../fonts/lato/latobk.woff?euw0km') format('woff'),
  url('../../../fonts/lato/latobk.ttf?euw0km') format('truetype'),
  url('../../../fonts/lato/latobk.svg?euw0km#EM-icomoon') format('svg');
  font-weight: 900;
  font-style: normal;
}
