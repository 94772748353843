#modal360 {
    height: 100vh;

    .modal-header {
        position: absolute;
        top: 10px;
        right: 17px;
        padding: 4px 7px;
        z-index: 10500;
    }

    .modal-body {
        height: 100vh;
        padding: 0;
        width: 100vw;
        margin: auto;

    }

    .modal-dialog {
        width: 100vw;
        height: 100vh;
        overflow: hidden;

        .modal-content {
            background-color: rgb(62, 62, 62);
            height: 100vh;
            @media (max-width: $screen-md-max) {
                background-color: #000000;
            }
        }

    }

    body {
        background: #3f3f3f;
    }

    .player-wrapper {
        width: 100%;
        height: 100vh;
        overflow: hidden;
        position: relative;
    }

    #threesixty-360 {
        @media (max-width: $screen-md-max) {
            height: 100%;
        }
        @media (max-width: $screen-md-max) and (orientation: portrait) {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            height: auto;
            &:before {
                content: url('/../../build/resources/front/images/360/btn_white_360.svg');
                display: inline-block;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                opacity: 1;
                animation: flicker 3s linear 1s infinite backwards;
            }
            &.active {
                &:before {
                    display: none;
                }
            }
        }
        @media (max-width: $screen-xs-max) {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            height: auto;
        }
        @media (max-width: $screen-xs-max) and (orientation: portrait) {
            &:before {
                content: '';
                display: inline-block;
                width: 150px;
                height: 70px;
                background: url('/../../build/resources/front/images/360/btn_white_360.svg') no-repeat;
                background-size: contain;
            }
            &.active {
                &:before {
                    display: none;
                }
            }
        }

        img {
            @media (max-width: $screen-md-max) {
                object-fit: contain;
                width: auto;
                height: 100%;
            }
            @media (max-width: $screen-md-max) and (orientation: portrait) {
                width: 100%;
                height: auto;

            }
            @media (max-width: $screen-xs-max) {
                width: 100%;
                height: auto;
            }
        }
    }

    body .threesixty-wrapper {
        width: auto;
        max-width: 100%;
        height: 100%;
        position: relative;
        transition: all ease .4s;
    }

    body .threesixty-wrapper .threesixty {
        display: block;
        width: 100%;
        height: auto;
        cursor: ew-resize;
    }

    .threesixty-wrapper .threesixty.zoom_active {
        cursor: ns-resize;
    }

    .threesixty img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
        opacity: 0;
        display: block !important;
    }

    .threesixty-wrapper .threesixty img.active {
        transition: transform .5s ease-out;
    }

    .threesixty-wrapper .zoom {
        position: absolute;
        top: 105px;
        right: 17px;
        padding: 4px 7px;
        z-index: 998;
        cursor: pointer;
        background: rgba(255, 255, 255, .7);
        font-size: 18px;
        opacity: .7;
    }

    .threesixty-wrapper .zoom:hover {
        background: rgba(255, 255, 255, 1);
    }

    .threesixty-wrapper.zoom1 .threesixty img {
        transform: scale(2.5);
    }

    .threesixty-wrapper.zoom1 .hotspots {
        display: none !important;
    }

    .threesixty img.active {
        opacity: 1;
    }

    .threesixty img:first-child {
        position: relative;
    }

    .hotspots {
        position: absolute;
        top: 150px;
        right: 0;
        padding: 5px;
        width: 80px;
        display: none;
        transition: all .4s;
        height: calc(100% - 155px);
        overflow: auto;
        background: rgba(0, 0, 0, .1);
    }

    .hotspots:hover {
        opacity: 1;
    }

    .hotspots img {
        margin-bottom: 5px;
        display: block;
        width: 100%;
        cursor: pointer;
        border: 3px solid #333;
    }

    .hotspots img:hover {
        opacity: .8;
        border-color: rgba(255, 255, 255, .1);
    }

    #player_carlab_zoom_modale {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .9);
        opacity: 0;
        z-index: 10000;
        height: 0;
        overflow: hidden;
        transition: transform .5s, opacity .5s;
    }

    #player_carlab_zoom_modale .close {
        position: absolute;
        top: 10px;
        right: 10px;
        color: #fff;
        text-shadow: 1px 1px 1px #333;
        background: #000;
        border-radius: 100px;
        cursor: pointer;
        opacity: 1;
        font-size: 30px;
        height: 31px;
        width: 30px;
        z-index: 99;
    }

    #player_carlab_zoom_modale.active {
        opacity: 1;
        transform: translateY(0);
        height: 100%;
    }

    #player_carlab_zoom_modale img {
        display: block;
        height: auto;
        width: auto;
        margin: 0 auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
    }

    #hotspots_edit_tools {
        position: absolute;
        top: 85px;
        right: 34px;
        z-index: 10001;
        color: #fff;
        @media (max-width: $screen-xs-max) and (orientation: portrait) {
            top: 110px;
        }
        @media (max-width: $screen-xs-max) and (orientation: landscape) {
            top: 70px;
        }
    }

    #hotspots_edit_tools_menu .btn {
        margin: 0;
        display: inline-block;
        border-radius: 0;
        height: 70px;
        width: 70px;
        z-index: 1;
        padding: 0;
        text-align: center;
        transition: all ease .2s;
        position: relative;
        box-shadow: none;
        background-color: #22286d;
        opacity: 0.5;
        color: #ffffff;

        span {
            &:not(.caption) {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                top: 10px;
                font-size: 12px;

                &:after {
                    content: '';
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    top: 12px;
                    width: 48px;
                    height: 27px;
                    background: url('/../../build/resources/front/images/360/btn_white_360.svg') no-repeat;
                    background-size: contain;
                    @media (max-width: $screen-xs-max) and (orientation: landscape) {
                        width: 32px;
                        height: 27px;
                    }
                }
            }
        }

        &.active {
            opacity: 1;
        }

        @media (max-width: $screen-md-max) and (orientation: portrait) {
            background-color: #ffffff;
            color: $bleuEm;
            span {
                &:not(.caption) {
                    &:after {
                        background: url('/../../build/resources/front/images/360/btn_blue_360.svg') no-repeat;
                    }
                }
            }
        }

    }

    #hotspots_edit_tools_menu .btn .caption {
        color: #ffffff;
        font-size: 9px;
        position: absolute;
        bottom: 4px;
        left: 50%;
        transform: translateX(-50%);
        @media (max-width: $screen-md-max) and (orientation: portrait) {
            color: $bleuEm;
        }
    }

    #hotspots_edit_tools_menu .btn:first-child {
        margin: 0 20px 0 0;
    }

    #hotspots_edit_tools_menu .btn img {
        width: 70%;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 23px;
    }

    #modale_zoom_slider {
        position: absolute;
        bottom: 0;
        right: 0;
        padding: 15px 20px;
        z-index: 100;
        width: 350px;
        @media (max-width: $screen-md-max) {
            position: absolute;
            bottom: 100px;
            right: 15px;
            padding: 0;
            width: 250px;
        }
        @media (max-width: $screen-md-max) and (orientation: portrait) {
            bottom: 430px;
            right: -70px;
            width: auto;
            transform: rotate(-90deg);
            &:before {
                content: url('/../../build/resources/front/images/360/loupe_icon.svg');
                display: inline-block;
                position: absolute;
                bottom: 0;
                left: -48px;
                transform: rotate(90deg);
            }
        }
        @media (max-width: $screen-xs-max) and (orientation: portrait) {
            bottom: 50vh;
            right: -40px;
            width: 129px;
            &:before {
                transform: rotate(90deg) scale(0.5);
                left: -38px;
                top: -5px;
            }
            .btn-zoom {
                display: none;
            }
        }
        @media (max-width: $screen-xs-max) and (orientation: landscape) {
            bottom: 15px;
            width: auto;
        }
    }

    .hotspots_modale {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 9999;
        display: none
    }

    .hotspots_modale.active {
        display: block
    }

    .hotspots_modale img {
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
    }

    input[type=range] {
        -webkit-appearance: none;
        width: 100%;
        height: 3px;
        margin: 8px 0;
    }

    input[type=range]:focus {
        outline: 0;
    }

    input[type=range]::-webkit-slider-runnable-track {
        width: 100%;
        height: 5px;
        cursor: pointer;
        box-shadow: 0 0 0 #000, 0 0 0 #0d0d0d;
        background: rgba(255, 255, 255, .78);
        border-radius: 0;
        border: .4px solid #fff;
        @media (max-width: $screen-md-max) and (orientation: portrait) {
            background: rgba(255, 255, 255, 1);
            height: 3px;
        }
    }

    input[type=range]::-webkit-slider-thumb {
        box-shadow: .5px .5px 3.5px #333, 0 0 .5px #404040;
        border: 2px solid #fff;
        height: 21px;
        width: 21px;
        border-radius: 21px;
        background: #22286d;
        cursor: pointer;
        -webkit-appearance: none;
        margin-top: -8.4px;
        @media (max-width: $screen-md-max) and (orientation: portrait) {
            box-shadow: 0.5px 0.5px 3.5px #333, 0 0 0.5px #404040;
            border: 2px solid #000000;
            background: #ffffff;
        }
        @media (max-width: $screen-xs-max) and (orientation: portrait) {
            height: 18px;
            width: 18px;
        }
    }

    input[type=range]::-moz-range-thumb {
        box-shadow: .5px .5px 3.5px #333, 0 0 .5px #404040;
        border: 2px solid #fff;
        height: 21px;
        width: 21px;
        border-radius: 21px;
        background: #22286d;
        cursor: pointer;
        //-webkit-appearance:none;
        margin-top: -8.4px;
        @media (max-width: $screen-md-max) and (orientation: portrait) {
            box-shadow: 0.5px 0.5px 3.5px #333, 0 0 0.5px #404040;
            border: 2px solid #000000;
            background: #ffffff;
        }
        @media (max-width: $screen-xs-max) and (orientation: portrait) {
            height: 18px;
            width: 18px;
        }
    }

    input[type=range]:focus::-webkit-slider-runnable-track {
        background: rgba(255, 255, 255, .78);
    }

    input[type=range]:focus::-moz-range-track {
        background: rgba(255, 255, 255, .78);
    }

    input[type=range]::-moz-range-track {
        width: 100%;
        height: 3px;
        cursor: pointer;
        box-shadow: 0 0 0 #000, 0 0 0 #0d0d0d;
        background: rgba(255, 255, 255, .78);
        border-radius: 0;
        border: .4px solid #fff;
    }

    input[type=range]::-ms-track {
        width: 100%;
        height: 5px;
        cursor: pointer;
        background: 0 0;
        border-color: transparent;
        color: transparent
    }

    input[type=range]::-ms-fill-lower {
        background: rgba(242, 242, 242, .78);
        border: .4px solid #fff;
        border-radius: 0;
        box-shadow: 0 0 0 #000, 0 0 0 #0d0d0d;
    }

    input[type=range]::-ms-fill-upper {
        background: rgba(255, 255, 255, .78);
        border: .4px solid #fff;
        border-radius: 0;
        box-shadow: 0 0 0 #000, 0 0 0 #0d0d0d;
    }

    input[type=range]::-ms-thumb {
        box-shadow: .5px .5px 3.5px #333, 0 0 .5px #404040;
        border: 4.6px solid #fff;
        width: 21px;
        border-radius: 21px;
        background: #68aa13;
        cursor: pointer;
        height: 5px;
    }

    input[type=range]:focus::-ms-fill-lower {
        background: rgba(255, 255, 255, .78);
    }

    input[type=range]:focus::-ms-fill-upper {
        background: rgba(255, 255, 255, .78);
    }

    #interior-360-player-canvas {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 9999;
        cursor: move;
    }

    #interior-360-player {
        width: 100%;
        height: 100%;
        position: fixed;
        z-index: 9999;
        top: -9999px;
        left: 0;
    }

    #orientation_change {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #68aa13;
        color: #fff;
        text-align: center;
        z-index: 10010;
    }

    #orientation_change p {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        font-size: 18px;
    }

    #orientation_change .glyphicon {
        display: block;
        margin-bottom: 20px;
        font-size: 60px;
    }

    #player-logo {
        position: fixed;
        top: 30px;
        left: 30px;
        width: 125px;
        z-index: 1;

        .logo-portrait {
            display: none;
            @media (max-width: $screen-md-max) and (orientation: portrait) {
                display: block;
            }
        }

        .logo-landscape {
            display: block;
            @media (max-width: $screen-md-max) and (orientation: portrait) {
                display: none;
            }
        }
    }

    #player-logo img {
        width: 100%;
    }

    @media (orientation: portrait) {
        #orientation_change {
            display: block !important;
        }
    }
    @media (max-height: 600px) and (orientation: landscape) {
        #hotspots_edit_tools_menu {
            width: 240px;
            height: 45px;
            right: 0;
            left: auto !important;
            position: fixed;
            text-align: right;
        }
        #hotspots_edit_tools_menu .btn {
            height: 45px;
            width: 45px;
            display: inline-block;
            float: none;
        }
        .threesixty-wrapper .zoom img {
            width: 50px;
        }
        #player_carlab_zoom_modale .close {
            font-size: 50px;
            height: 50px;
            width: 50px;
        }
        #modale_zoom_slider {
            width: 300px;
            padding: 15px 0 15px 20px;
        }
        #modale_zoom_slider img {
            width: 35px;
        }
        #modale_zoom_slider input {
            margin-top: 15px;
        }
        #player-logo {
            position: fixed;
            top: 0;
            left: 0;
            height: 40px;
            z-index: 1;
        }
        #next-picture-button, #previous-picture-button {
            width: 40px;
            height: 45px;
        }
    }

    #hotspots_edit_tools_menu .btn {
        @media (max-width: $screen-xs-max) and (orientation: landscape) {
            height: 60px;
            width: 60px;
            &#go-to-360-interior-player {
                margin-right: 9px;
            }
        }

    }

    #hotspots_edit_tools_menu {
        @media (max-width: $screen-xs-max) and (orientation: landscape) {
            width: auto;
            height: auto;
            right: 25px;
        }
    }

    #player-logo {
        top: 20px;
        left: 30px;
    }

    button.btn.btn-blanc {
        @media (max-width: $screen-md-max) and (orientation: portrait) {
            background: none;
            border: none;
            color: #ffffff;
            font-size: 1.5rem;
            &:after {
                content: 'X';
                display: inline-block;
                vertical-align: middle;
                background-color: #ffffff;
                border-radius: 50%;
                padding: 5px 13px;
                font-size: 2rem;
                font-weight: bold;
                color: #22286d;
                margin-left: 10px;
            }
        }
    }

    .arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        padding: 15px 10px;
        background-color: #22286d;
        text-align: center;
        cursor: pointer;
        -webkit-transition: all ease .4s;
        transition: all ease .4s;
        z-index: 1;
        @media (max-width: $screen-md-max) and (orientation: portrait) {
            display: none;
        }
    }

    .arrow-left {
        left: 0;
    }

    .arrow-right {
        right: 0;
    }

    .arrow::before {
        content: '';
        display: inline-block;
        border: solid #fff;
        border-width: 0 2px 2px 0;
        padding: 8px;
        vertical-align: middle;
    }

    .arrow-left::before {
        transform: rotate(135deg);
        margin-left: 20px;
        @media (max-width: $screen-xs-max) and (orientation: landscape) {
            margin-left: 5px;
        }
    }

    .arrow-right::before {
        transform: rotate(-45deg);
        margin-right: 20px;
        @media (max-width: $screen-xs-max) and (orientation: landscape) {
            margin-right: 5px;
        }
    }

    .arrow:hover {
        opacity: .5;
    }

    label[for=zoom-slider] {
        width: 100%;
        display: flex;
        align-items: center;
    }

    .btn-zoom {
        border: 3px solid #fff;
        border-radius: 50%;
        padding: 15px 10px;
        line-height: 0;
        position: relative;
        background: #22286d;
        @media (max-width: $screen-md-max) and (orientation: portrait) {
            padding: 10px 6px;
            background: rgba(0, 0, 0, 0.3);
        }
    }

    .btn-zoom::before, .zoom-in::after {
        content: '';
        display: inline-block;
        width: 15px;
        height: 3px;
        background-color: #fff;
        @media (max-width: $screen-md-max) and (orientation: portrait) {
            width: 12px;
            transform: translateY(-1px);
        }
    }

    .btn-zoom {
        &.zoom-out {
            &::before {
                @media (max-width: $screen-md-max) and (orientation: portrait) {
                    transform: translateY(-1px) rotate(90deg);
                }
            }
        }
    }

    .zoom-in::after {
        position: absolute;
        top: 17px;
        left: 10px;
        transform: rotate(90deg);
        @media (max-width: $screen-md-max) and (orientation: portrait) {
            top: 12px;
            left: 6px;
            transform: translateY(-1px) rotate(90deg);
        }
    }

    :focus {
        outline-color: #22286d;
    }

    .container-picture-change {
        display: none;
        z-index: 999;
        @media (max-width: $screen-md-max) and (orientation: portrait) {
            display: flex;
            align-items: center;
            position: absolute;
            bottom: 200px;
            left: 50%;
            transform: translateX(-50%);
            width: 50%;
            input[type=range]::-webkit-slider-thumb {
                transform: scale(1.5);
            }
            .btn {
                border-radius: 50%;
                background: #ffffff;
                padding: 13px;

                &:before {
                    content: '';
                    display: inline-block;
                    border: solid $bleuEm;
                    border-width: 0 2px 2px 0;
                    padding: 8px;
                    vertical-align: middle;
                }
            }
            #change-prev {
                margin-right: 10px;

                &:before {
                    transform: rotate(135deg);
                    margin-left: 5px;
                }
            }
            #change-next {
                margin-left: 10px;

                &:before {
                    transform: rotate(-45deg);
                    margin-right: 5px;
                }
            }
        }
        @media (max-width: $screen-xs-max) and (orientation: portrait) {
            width: 80%;
            bottom: 20vh;
            .btn {
                padding: 9px;

                &:before {
                    padding: 5px;
                }
            }
            input[type=range]::-webkit-slider-thumb {
                transform: scale(1.2);
            }
        }

    }
}

.view-360 {
    position: relative;
    height: 100%;

    .btn360 {
        position: absolute;
        left: 10px;
        bottom: 10px;
    }
}

@keyframes flicker {
    0% {
        opacity: 0;
    }
    30% {
        opacity: 0.8;
    }
    50% {
        opacity: 0.8;
    }
    70% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}
