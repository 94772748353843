.containFormulaireConnexionInscription {
  .divIllustration{
    background: url('../../../../resources/front/images/illustrations/fotolia_94789433.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
  @media(max-width: $screen-xs-max){
    > .row{
      display: flex;
      flex-direction: column-reverse;
    }
  }

  font-size: 1em;
    input {
      margin-bottom: 1em;
    }

  .small-1 {
    margin-top: 0.5em;
  }
  ul {
    margin-bottom: 1em;
    font-weight: 700;
    li {
      margin-bottom: 0.5em;
      line-height: normal;
    }
  }
  .btn-block.disabled{
    visibility: visible !important;
  }

  .form-group{
    margin-bottom: 0;
  }
}

.btnMenuCompte {
  @media screen {
    @media (min-width: $screen-lg-min) {
        //display: inline-block;
        padding: 0.25rem 0.5rem;
        width: 100%;
        background: none;
        position: relative;
        text-shadow: none;
        //font-size: 0;
        border: 1px solid $bleuEm;
        border-radius: 0.5rem;
        text-align: left;
        &:focus{
            outline: none;
        }
    }
  }
  i {
    color: $bleuEm;
      font-size: 2rem;
      @media (max-width: $screen-md-max){
          color: #ffffff;
      }
      &.icon-account{
          display: inline-block;
          line-height: 1;
      }
      &.icon-arrow-down{
          //position: absolute;
          //top: 50%;
          //transform: translateY(-50%);
          //right: 5px;
          @media (max-width: $screen-md-max){
              color: #ffffff;
          }
      }
  }
}

#modalForgotPassword{
  h6{
    margin-bottom: 0.5em;

  }
  .h4{
    margin-bottom: 0;
    @media (max-width: $screen-xs-max){
        font-size: 1.4rem;
    }
  }
  .h6{
      @media (max-width: $screen-xs-max){
          font-size: 1.5rem;
      }
  }
  p{
    margin-bottom: 1.5em;
  }
}
