@import "accueil-compte/accueil-compte";
@import "accueil-compte/guide-espace_client";
@import "parametres-compte/parametres-compte";
@import "avantages-compte/avantages-compte";
@import "avantages-compte/confirmation-parrainage";


.titreCompte {
  margin-top: 0;
  margin-bottom: 0.5em;
}