.containAsideReprise {
  .whiteCard{
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .containEnteteAsideReprise {
    padding: 0.5em;
    p{
      margin-bottom: 0;
    }
  }
  .containDetailsAsideReprise {
    padding : 0.5em;
  }
  .containTarifAsideReprise {
    background-color: $bleuEm;
    color: $blanc;
    padding: 1em;
    @include shadowText(0.2);
    * {
      margin: 0;
    }
  }
}