.containEnteteMarque{
  .containIllustrationMarque{
    margin-bottom: 1em;
    h1 {
      margin-bottom: 0.5em;
    }
  }
  .containDescriptionMarque{
    margin-top: 2em;
    font-weight: 300;

    h2 {
      font-size: 1.8em;
      font-weight: 700;
      margin-top: 1.3em;
      margin-bottom: 0.7em;

      @media(max-width: $screen-sm-max) {
        font-size: 1em;
        line-height: 1.4em;
      }
    }

    p {
      font-weight: 400;
      text-align: justify;
    }
    @media only screen {
      @media(max-width: $screen-sm-max) {
        p {
          text-align: left;
        }

      }
    }


  }

}
#readMoreDesc{
  cursor: pointer;
  font-size: 0.8em;
  &:hover{
    color: darken($bleuEm, 10%);
  }
  @media (max-width: $screen-xs-max){
      text-align: center;
      position: relative;
      z-index: 2;
      .icon-arrow-down, .icon-arrow-up{
          margin-left: 0.25rem;
      }
  }

}


.carteMarqueVehicule{

  .whiteCard {
    margin-bottom: 3em;
    @media only screen {
      @media(min-width: $screen-sm-min) {
        height: 507px;
        .containTarifsMarque {
          position: absolute;
          bottom: 0;
        }
      }
      @media(min-width: $screen-md-min) {
        height: 584px;
      }
      @media(min-width: $screen-lg-min) {
        height: 472px;

      }
    }
  }

  .carteMmv{
    margin: 0.75em 0;
  }
  .carteImg{
    margin-top: 2em;
  }
  .tarifsCarteMarque{
    margin: 0.75em 0;
    p{
      margin-bottom: 0;
    }
    h6{
      margin-top: 0;
    }
  }
}
