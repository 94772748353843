.containEquipementsReprise{

  .twinTablesGroup{
    @include shadowBox(0.3);
    padding: 0;
    table {
      float: left;
      @media only screen {
        @media(max-width: $screen-md-max) {
          width: 100%;
        }
      }
      &:last-child{
        >tbody{
          >tr{
            @media only screen{
              @media(max-width: $screen-md-max){
                &:first-child{
                  display: none;
                }
              }
            }
            >td,th{
              &:first-child{
                @media only screen {
                  @media(min-width: $screen-lg-min) {
                    @include box-shadow(inset -8px 0px 8px -8px rgba(0, 0, 0, 0.3), inset 8px 0px 8px -8px rgba(0, 0, 0, 0.3));
                  }
                }
              }
            }
          }
        }
      }
      > tbody {
        > tr {
          &:hover {
            td {
              background-color: $gris3;
              &:first-child {
                background-color: $gris2;
                &:empty {
                  background-color: $grisFond;
                }
              }
            }
          }
          > td, th {
            position: relative;
            padding: 0.5em 0.5em;
            text-align: center;
            width: 20%;
            @media only screen{
              @media(max-width: $screen-sm-max){
                width: 25%;
              }
            }
            &:first-child {
              width: 60%;
              @media only screen {
                @media(max-width: $screen-sm-max) {
                  width: 50%;
                }
              }
              background-color: $grisFond;
              @include box-shadow(inset -8px 0px 8px -8px rgba(0, 0, 0, 0.3));
              font-weight: bold;
              text-align: left;
              padding-left: 1em;
            }
          }
          th{
            padding: 0.5em 0;
            text-transform: uppercase;
            //font-size: 1.2em;
            font-weight: 700;
          }
        }
      }
    }
  }

  .containOptionsAdditionnelles{
    margin-top: 1.5em;
  }

}

