.containEstimationReprise {
  .img100.whiteCard:first-child{
    padding: 0;
  }
  .bleuEMCard {
    p, label {
      margin-bottom: 0;
    }
  }
}
