section.recruitmentPage, section.topJob {

  @media(max-width: $screen-xs-max) {
    padding: 30px 0;
  }

    &.recruitmentPage {
      padding: 20px 0;
      margin-bottom: 0;
        @media (max-width: $screen-sm-max){
            padding: 20px;
        }
    &.bgBlanc {
      background-color: $blanc;
    }

    h1, h2, h3, h4 {
      text-transform: inherit;
    }

    h1 {
      font-size: 5em;

      @media(max-width: $screen-xs-max) {
        font-size: 2em;
      }
    }

    h2 {
      font-size: 4em;
      margin-bottom: 60px;

      @media(max-width: $screen-xs-max) {
        font-size: 2em;
        margin-bottom: 20px;
      }
    }

    h3 {
      font-size: 2.5em;

      @media(max-width: $screen-xs-max) {
        font-size: 1.4em;
      }
    }

    h4 {
      font-size: 2em;
    }

    h5 {
      font-size: 1.5em;
    }

    .jobTitleH3 {
      font-size: 4em;

      @media(max-width: $screen-xs-max) {
        font-size: 1.4em;
      }
    }

    .intro {
      p {
        font-size: 1.3em;

        @media(max-width: $screen-xs-min) {
          font-size: 1.1em;
        }
      }

      span {
        font-size: 1em;
        margin-bottom: 30px;
        display: block;
      }
    }

    .illustration-emploi {
      width: 590px;
      float: right;

      @media(max-width: $screen-lg-min) {
        width: 445px;
        margin-top: 170px;
      }
    }

    .postLink {
      position: relative;
      border: 1px solid $bleuEm;
      margin-bottom: 10px;
      display: block;
      padding: 20px 80px;
      transition: all 0.1s ease;

      @media(max-width: $screen-xs-max) {
          padding: 15px 25px 15px 15px;
      }

        &:hover {
        border: 1px solid $vert;
        color: $vert;
        box-shadow: inset 0 0 0 3px #bed62f;
      }

      span:first-child {
        font-size: 1.4em;
        display: block;
        font-weight: bold;
      }

      span:last-child {
        font-size: 1em;
      }

      i {
        font-weight: bold;
        font-size: 1.8em;
        position: absolute;
        right: 60px;
        top: 33px;

        @media(max-width: $screen-xs-max) {
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }

    .step-job {
      @media(min-width: $screen-md-min) {
        margin-bottom: 80px;
      }

      &.odd {
        @media(min-width: $screen-lg-min) {
          padding-right: 90px;
        }
      }

      &.even {
        @media(min-width: $screen-lg-min) {
          padding-left: 90px;
        }
      }

      .numList {
        color: $vert;
        font-size: 1.43em;
      }

      p {
        font-size: 1.3em;

        @media(max-width: $screen-xs-min) {
          font-size: 1.1em;
        }
      }
    }
  }

  .img-bottom {
    width: 100%;
    margin: 0 0 50px 0;

    @media(max-width: $screen-md-min) {
      margin: 4em 0 0 0;
    }
  }

  &.jobsList {
    padding-top: 0;
    @media (max-width: $screen-xs-max){
        padding: 0 10px 20px;
    }
    .container-text{
        a{
            font-weight: bold;
        }
    }
  }

  &.othersJobs {
    p {
      font-size: 1.3em;
      margin: 20px 0;
    }
  }

  &.formContactJobs {
    input, textarea {
      border: 1px solid $bleuEm;
      padding: 10px 15px;
      box-shadow: none;
      font-weight: 400;
    }

    input {
      display: block;
      width: 100%;
      margin-bottom: 50px;

      @media(max-width: $screen-sm-min) {
        margin-bottom: 20px;
      }
    }

    textarea {
      min-height: 190px;
      min-width: 100%;
      max-width: 100%;
    }

    .cvUpload {
      border: 2px dashed $bleuEm;
      padding: 40px;
      margin-bottom: 50px;

      @media(max-width: $screen-sm-min) {
        padding: 20px;
        margin-bottom: 20px;
      }

      i {
        font-size: 1.6em;
      }
    }

    button {
      margin-top: 30px;

      @media(max-width: $screen-sm-min) {
        margin-top: 20px;
      }
    }
      @media (max-width: $screen-xs-max){
          .container{
              padding: 0;
          }
      }
  }

  &.topJob {
    position: relative;
    padding: 0;
    margin-top: 70px;
    overflow: hidden;

    .bgTop {
      height: 350px;
      background-position: center;
      background-size: cover;

      @media(max-width: $screen-md-max) {
        height:250px;
      }

      @media(max-width: $screen-xs-max) {
        height:100px;
      }
    }

    img {
      width: 100%;
    }


  }

  .topJob {
    @media(max-width: 1300px) {
      margin-top: 70px;
    }

    .homeJob {
      padding: 45px 115px;

      @media(max-width: $screen-sm-max) {
        padding: 15px;
      }
    }
  }

  .jobDescription {
    //margin-bottom: 60px;

    @media(max-width: $screen-sm-max) {
      margin-bottom: 30px;
    }

    //  ul {
    //  list-style: none;
    //}
  }
}

.containerTitle {
  margin-top: -50px;
  .titleJob {
    background: $blanc;
    padding: 10px 30px;
    text-align: center;
    //margin: -50px auto 30px auto;
    border-bottom: 5px solid $bleuEm;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.16);

    /*@media(max-width: 1300px) {
      width: 60%;
    }

    @media(max-width: $screen-sm-max) {
      padding: 15px 15px 0 15px;
      margin-top: -30px;
      width: 95%;
    }*/

    h1 {
      font-size: 3.35em;
      text-transform: inherit;

      @media(max-width: $screen-sm-max) {
        font-size: 1.8em;
      }
    }

    h2 {
      font-size: 1.9em;
      text-transform: inherit;

      @media(max-width: $screen-sm-max) {
        font-size: 1.2em;
      }
    }

    h3 {
      font-size: 3em;

      @media(max-width: $screen-xs-max) {
        font-size: 1.4em;
      }
    }

    h4 {
      font-size: 2em;

      @media(max-width: $screen-xs-max) {
        font-size: 1em;
      }
    }

    h5 {
      font-size: 1.5em;

      @media(max-width: $screen-xs-max) {
        font-size: 0.8em;
      }
    }

    .date {
      color: $gris1;
      font-size:1.5em;

      @media(max-width: $screen-md-max) {
        font-size: 1.2em;
      }
    }

    .category {
      position: relative;
      bottom: -30px;
      border-radius: 30px;
      background: $bleuEm;
      padding: 12px 30px;
      color: $blanc;
      margin: auto;
      display: inline-block;
      border: 3px solid $blanc;
      font-size: 1.5em;

      @media(max-width: $screen-sm-max) {
        font-size: 1em;
        padding: 5px 30px;
        bottom: -11px;
        display: inline;
      }
    }
  }
  }

.recruitmentErrorsFields{
  color:red;
}

.apply-letter{
  color: red;
  width: 600px;
  word-break: break-word;
}

#recruitmentButton{

  @media(min-width: $screen-sm-max) {
    margin-top: -10%;
  }

  @media(max-width: $screen-sm-max) {
    margin-top: 10%;
  }
}
