.containBreadcrumb{
  padding-top: 1em;
  @media only screen {
    @media(max-width: $screen-md-max) {
      font-size: 0.71em;
    }

    @media(max-width: $screen-xs-max) {
      padding-bottom: 1rem;
    }
  }

  .breadcrumb{
    padding: 0;
    margin-bottom: 0;
    background: none;

    @media only screen {
      @media(max-width: $screen-xs-max) {
        margin-bottom: 0.2em;
      }
    }

    > li {
      + li:before {
        padding: 0 0 0 3px;
      }
        a, span {
            display: inline-block;
        }
    }
  }
}
