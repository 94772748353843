#modalTunnelDevis, #modalTunnelDevisDynamique{
  .headerTunnelDevisStep2, .headerTunnelDevisStep3, .headerTunnelDevisStep4, .tunnelDevisStep2, .tunnelDevisStep3, .tunnelDevisStep4, .footerTunnelDevisStep2, .footerTunnelDevisStep3, .footerTunnelDevisStep4 {
    display: none;
  }

  .containTunnel{
    margin-top: 0;
    > div{
      background-color: transparent;
    }
  }

  .callConnexionTunnelDevis{
    margin-top: 1em;
  }

  @media only screen {
    @media(max-width: $screen-sm-max) {
      button{
        margin-top: 1em;
      }
    }
    @media(max-width: $screen-xs-max) {
      a[type="button"]{
        margin-top: 1em;
      }
    }
  }

  #adressePostaleEnvoiDevis{
    display: none;
  }

    .tunnelDevisStep0, .tunnelDevisStep1, .tunnelDevisStep2{
    .containDivIllustration{
      padding: 0;
      .divIllustration{
        //height: calc(100% - 70px);
      }
    }
    .divIllustration{
      background: url('../../../../resources/front/images/illustrations/votre-devis-en-un-clic.jpg');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    }

  }

  .containPreviousStepTunnelDevisStep1{
    position: absolute;
    bottom:0;
    left:0;
    width: 100%;
  }

  .btn-block.disabled{
    visibility: visible !important;
  }

  .inscriptionAjax, #connexionAjax{
    position: absolute;
    bottom: 0;
    right: 10px ;
    width: calc(100% - 20px);
  }

  #modalFormulaireInscription form, #modalFormulaireConnexion form{
    margin-bottom: 70px;
    .small-4{
      font-size: 1em;
    }
  }

  .form-group {
    margin-bottom: 0;
    input {
      margin-bottom: 1em;
    }
  }
  .small-1 {
    margin-top: 0.5em;
  }
}

#choosePostalQuotationAddress{
  .btn-adresse-devis{
    margin-top: 1em;
  }
}

.modalDevisSuccessButtons{
  @media(max-width: $screen-sm-max) {
    button, a[type="button"]{
      margin-bottom: 1em;
    }
  }
}
