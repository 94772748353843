.slickSliderAvis{
  @media only screen {
    min-height: inherit;
    @media(min-width: $screen-lg-min) {
      .whiteCard {
        min-height: 48.5em;
      }
    }
    @media(max-width: $screen-lg-min) {
      .whiteCard {
        min-height: 40em;
      }
    }
  }
  //.item{
  //  display: none;
  //}
  //.item:first-child{
  //  display: block;
  //}
  &.slick-initialized .slide {
    display: block;
  }
  .carousel-inner{
    .item{
      padding: 0;
    }
    a.carousel-control{
      position: absolute;
      top: 25%;
      width: 40px;
      height: 40px;
      background-color: #fff;
      background-image: none;
      opacity: 1;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
      border: 1px solid #d6d6d6;
      span.arrow{
        color: #abafb1;
        &:before{
          line-height: 40px;
          font-size: 16px;
          text-shadow: initial;
        }
      }
      span.arrow-left{

        &::before{
          content: '〈';
          padding-right: 8px;
        }
      }
      span.arrow-right{
        &::before{
          content: '〉';
          padding-left: 8px;
        }
      }
    }
    a.left{
      left: 0;
    }
    a.right{
      right: 0;
    }
  }
}

#carouselAvis{
  padding-right: 10px;
  padding-top: 8px;
}

@media(max-width: $screen-sm-max) {
  #carouselAvis{
    padding: 0 10px;
  }
}
.containAvisNoteClient {
  @media(max-width: $screen-xs-max){
    margin-top: 2em;
  }
  @include shadowBox(0.40);
  @media (max-width: $screen-sm-max){
    box-shadow: none;
  }
  > .row{
    height: 342px;
    @media (max-width: $screen-sm-max){
      height: auto;
      background-color: #fff;
      @include shadowBox(0.40);
    }
  }
}

.containAverageClientNote {
  position: relative;
  padding: 64px 0 0;
  margin:auto;
  height:342px;
  text-align: center;
  background: white no-repeat center url('../../../../resources/front/images/illustrations/note.png');
  background-size: contain;
  @media (max-width: $screen-sm-max){
    height: auto;
    padding: 64px 0;

  }
  p{
    span{
      &:nth-of-type(2){
        font-size: 38px;
      }
    }
  }
  a.jaune{
    display: inline-block;
    width: auto;
    .avisButton{
      width: auto;
      padding: 8px 17px;
    }
  }
}

.stars {
  font-size: 25px;
  margin-bottom: 20px;
}

.avisTitle{
  font-size: 20px;
}

.avisNote {
  font-size: 58px;
}

.avisButton{
  color:#ffffff;
  background-color:$jaune;
  padding: 2%;
  width: 194px;
  margin:auto;
  font-size: 14px;
}

.containAvisClient {
  height: 342px;
  padding: 0;
  @media (max-width: $screen-sm-max){
    height: auto;
    margin-top: 1em;
  }
  .carousel-inner{
    height: 100%;
    margin: 0;
    .carousel-control {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 40px;
      height: 40px;
      opacity: 1;
      background: #ffffff;
      border: solid 1px #d6d6d6;
      @media(max-width: 320px){
        width: 30px;
      }
      span {
        text-shadow: none;
        box-shadow: none;
        line-height: 40px;
        font-size: 16px;
        @media(max-width: 320px){
          font-size: 14px;
        }
        &:before{
          color: #abafb1;
        }
      }
    }
    a.left{
      left: 0;
      span{
        &:before{
          content: "〈";
          padding-right: 8px;
        }
      }
    }
    a.right{
      right: -1px;
      span{
        &:before{
          content: "〉";
          padding-left: 8px;
        }
      }
    }
  }
  @media only screen {
    min-height: inherit;
    @media(max-width: $screen-sm-max) {
      .row-flex {
        display: block;
      }
    }
  }

  .reviewsMobile {
    background-color: #ffffff;
    @include shadowBox(0.40);
    @media (max-width: $screen-sm-max){
      flex-direction: initial;
    }
  }

  .whiteCard {
    margin-bottom: 1.5em;
  }

  .slick-slide {
    margin-bottom: 0.5em;
    margin-top: 0.5em;
  }
  .slick-next, .slick-prev {
    top: 50%;
    box-shadow: none;
  }

  .slick-prev {
    left:1px;
  }

  .slick-next {
    right:-1px;
  }


  .reviewsCard {
    height:342px;
    margin-top: -10px;
    overflow: hidden;
    @media (max-width: $screen-sm-max){
      height: auto;
    }
  }

  @media(min-width: $screen-md-min) {
    .reviewsPipe {
      position: absolute;
      height: 225px;
      top: 15%;
      right: -1px;
      border-right: 3px #d8d8d8 solid;
    }
  }

  .reviewsComment {
    height: 60px;
    overflow: hidden;
  }

  .reviewsInfos {
    margin-top:-35px;
  }

  .photoAvisClient {
    //width: 100%;
    display: inline-block;
    border-width: 0 0.25em 0.25em 0.25em;
    border-style: solid;
    border-color: $blanc;
    height: 182px;
    width: calc(182px * 2);
    border-bottom-left-radius: calc(182px * 2);
    border-bottom-right-radius: calc(182px * 2);
    @include shadowBox(0.3);
    overflow: hidden;
    position: relative;
    &:before{
      content: '';
      width: 100%;
      height: 5px;
      display: inline-block;
      position: absolute;
      left: 0;
      top: 0;
      background-color: white;
    }
    @media (min-width: 992px) and (max-width: 1280px){
      height: 120px;
      width: calc(120px * 2);
      border-bottom-left-radius: calc(120px * 2);
      border-bottom-right-radius: calc(120px * 2);
      &:before{
        height: 10px;
        top: 4px;
      }
    }
    @media (max-width: $screen-sm-max){
      &:before{
        height: 5px;
      }
    }
    @media (max-width: $screen-xs-max){
      height: 91px;
      width: calc(91px * 2);
      border-bottom-left-radius: calc(91px * 2);
      border-bottom-right-radius: calc(91px * 2);
      &:before{
        height: 5px;
      }
    }
    img{
      width: 100%;
    }

  }

  .homeRating {
    position: relative;
    width: 149px;
    height: 49px;
    border-radius: 20px;
    background-color:  #f5f5f5;
    min-height: 2em;
    left:50%;
    top:40%;
    transform: translate(-50%, -60%);

    i {
      position: absolute;
      top: 25%;
      transform: translateX(-75%);
      font-size: 1.4em;
      color: $gris2;
      position: relative;

      &.icon-star-full {
        color: $jaune;
      }
    }
  }


  .rating {
    margin-top: 1em;
    min-height: 2em;
    i {
      font-size: 2em;
      color: $gris2;
      position: relative;
      margin: auto 0.1em;
      &.icon-star-full {
        color: $jaune;
      }
      &:nth-child(1), &:last-child {
        bottom: 0.5em
      }
      &:nth-child(3) {
        top: 0.25em;
      }
    }
  }
  .modeleAvis {
    background-color: $jaune;
    color: $blanc;
    font-weight: 700;
    padding: 1em 4em;
    margin: 1.5em auto;
    p {
      margin: 0;
    }
  }

  @media only screen {
    @media(max-width: $screen-sm-max) {
      .photoAvisClient {
       margin-top:10px;
      }
      .reviewsInfos {
        margin-top:-25px;
      }
    }
  }

  .noteGlobaleAvisClient{
    margin-bottom: 0;
    min-height: 0;
    >div h6, >div h4{
      text-align: center
    }
    >div:nth-child(2) h6{
      @media only screen {
        @media(min-width: $screen-md-min) {
          line-height: 2em;
          text-align: left;
        }
      }
    }
    >div:last-child h4{
      @media only screen {
        @media(min-width: $screen-md-min) {
          text-align: right;
        }
      }
    }
  }
}

