.containInformationsFicheVehicule {
  ul:not(.leaders) {
    font-weight: 700;
    font-size: 16px;
    li {
      margin: 0.5em 0;
    }
  }

  #infosFicheVehiculeTabs {
    li {
      white-space: nowrap;
      @media only screen {
        @media(min-width: $screen-md-min) {
          i {
            display: block;
            font-size: 2em;
            margin-bottom: 0.5em;
          }
        }
      }
    }
  }

  /*.containOffresGaranties {
    .greyCard {
      margin-bottom: 2em;
      label {
        color: $vert;
        font-size: 2em;
        font-weight: bold;
        margin-bottom: 0.5em;
        .jcf-radio {
          position: absolute;
          bottom: calc(0% - 13px);
          left: calc(50% - 13px);
        }
      }
    }
  }*/

  .containOffresGaranties {
    label {
      font-size: 2em;
      min-height: 155px;

        .warranty-link {
            font-size: 1rem;
            font-weight: normal;
            > a {
                text-decoration: underline;
            }
        }
      span {
        span:first-child {
          font-size: 1.65em;
          line-height: 1.3em;
          display: block;
        }

        span:last-child {
          @media(max-width: $screen-xs-max) {
            font-size: 1.2rem;
          }
        }

        &.lineHeightXs {
          line-height: 1.2em;
        }
      }

      &[for$="Disable"] {
        font-size: 1.2em;
        span.jcf-checked ~ span {
          color: $rouge;
        }
        span.jcf-checked > span {
          background: $rouge;
        }
        small {
          font-family: 'latoth';
          font-size: 14px;
        }
      }

      font-weight: bold;
      padding-bottom: 0.5em;
      margin: 20px 0;
      .jcf-radio {
        position: absolute;
        bottom: -34px;
        left: calc(50% - 19px);
      }
      &:not(:last-child) {
        border-right: solid 1px #d6d6d6 ;
      }
    }
    .greyCard {
      margin-bottom: 2em;

    }
  }

  .containOffresServices {
    text-align: left;
    .greyCard {
      margin-left: 0.5em;
      margin-bottom: 2em;
      padding-left: 25px;
      label {
        margin-bottom: 0;
        width: 100%;
        > span, div {
          display: inline-block;
        }
        div {
          padding: 0.5em 3.5em 0.5em 0;
          width: calc(100% - 4em);
          line-height: 1em;
        }
        > span {
          font-weight: bold;
          font-size: 2em;
          position: absolute;
          right: 0.5em;
          top: calc(50% - 0.5em);
          line-height: 1em;
        }

        .jcf-checkbox {
          position: absolute;
          left: calc(0% - 13px);
          top: calc(50% - 13px);
        }
      }
    }

  }
  .slider {
    margin-top: 3em;
  }
  .slider.fundingSlider {
      margin-top: 0em;

  }

  .recommandationFinancement {
    margin-top: 1em;
  }

  .titreCollapse {
    margin-bottom: 0.5em;
  }

  .collapse {
    margin-bottom: 1em;
  }

  .btn {
    margin: 1em 0.5em 2em 0.5em;
  }

  .containEnteteFicheTitreBoutons {
    margin-bottom: 20px;
  }

  .bleuEMCard {
    > div > div {
      display: inline-flex;
      align-items: center;
      ul {
        margin-bottom: 0;
        li {
          font-size: smaller;
        }
      }
      i {
        font-size: 3em
      }
      &:first-child {
        justify-content: center;
        text-align: center;
      }
    }
  }

}

.containOffresFinancement {

  &.margin0 {
    margin-top: 0;
  }

  .greyCard {
    margin-top: 1em;
    margin-bottom: 1em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    h6{
      margin-bottom: 7px;
    }
  }
  .bleuEMCard {
    font-size: 1.4em;
    line-height: 1.3em;
  }
  .containImageFinancement {
    margin-bottom: 1em;
    height: 150px;
    overflow: hidden;
    img {
      width: 100%;
    }
  }
  .financementAnnualMileAgeBloc{
   min-height: 48px;
  }
  .fundingParameters{
    padding: 0em 1em;

    .fundingParameter{
      margin: 1em 0em;
      h7{
        text-transform: none !important;
        font-weight: bold;
        font-size: 1.5rem;
      }
      h7.fundingParameterValueLabel{
        color: #e0055a;
        text-align: right;
      }
      h7.fundingParameterLabel{
        text-align: left;
      }
    }
  }

  .fundingParameters.funding-parameters-loan_without_repurchase_commitment{
    background-color: #8cff8e2b;
  }
  .fundingParameters.funding-parameters-long_term_leasing{
    background-color: #FDE1DF;
  }
  .fundingParameters.funding-parameters-leasing_without_repurchase_commitment {
    background-color: #FBF6DB;
  }
  .fundingParameters.funding-parameters-leasing_with_repurchase_commitment{
    background-color: #FBF6DB;
  }



  .funding-bloc-container {
    h6 {
      //margin-bottom: 1em;
      i {
        font-size: 0.75em;
      }

    }
    ul {
      @media only screen {
        @media(min-width: $screen-md-min) and (max-width: $screen-md-max) {
          font-size: 0.7em !important;
        }
      }
      &.gris1 {
        li {
          font-weight: 400;
          margin: 0;
        }
      }
    }
    .prixFinancement {
      margin: 0.5em 1.1em;
      font-size: 2.3rem;
      color: $rose;

        > .bloc-monthyPayment {
            line-height: 15px;
        }

      @media only screen {
        @media(min-width: $screen-md-min) and (max-width: $screen-md-max) {
          font-size: 1.7em !important;
        }
      }
      span:not(.monthyPayment) {
        font-size: 0.7em;
        vertical-align: middle;
      }

    }
    .info-error{
      display: none;
      line-height: 18px;
      span{
        color: #e0055a;
        font-size: 0.6em !important;
      }
    }
    .mentionsLegalesFinancement {
      margin-top: 1em;
      //position: absolute;
      //bottom: -12px;
      left: 0;
      width: 100%;
      font-size: 1.5em;
      line-height: 1em;
      padding-left: 1em;
      padding-right: 1em;
      min-height: 4em;

      @media(max-width: 1300px) {
        padding-left: 0;
        padding-right: 0;
      }

      @media(min-width: $screen-md-min) and (max-width: $screen-md-max) {
        font-size: 1.5em;
      }

      .resultCredit {
        display: block;
        margin-top: 2em;
      }
    }
  }
  //
  .whiteCard {
    //padding-top: 0.5em;
    bottom: 0.5em;
    margin-top: 0.5em;
    margin-bottom: 1.5em;
    .funding-bloc-container {
      height: 327px;
    }
    &:hover {
      background-color: $grisFond;
    }
    .jcf-radio {
      position: absolute;
      bottom: calc(0% - 13px);
      left: calc(50% - 13px);
    }

  }
}

.containPersonnalisationApparenceFiche {
  ul.leaders {
    li {
      .colorSwatch {
        margin-right: 0;
        .couleurSwatch {
          margin-left: 0.5em;
          margin-right: 0.5em;
        }
        i {
          left: 17px;
          top: 1px;
        }
      }
      .jcf-checkbox {
        margin-left: 0.5em;
      }
    }
  }
}

.containPersonnalisationOptionsFiche {
  ul.leaders {
    padding: 0 0 0 5px;

  }
}
.containOffresFinancement{
  .tooltip.fade {
    z-index: 1071;
    .tooltip-inner {
      line-height: 1.5em;
      min-width: 800px;
      text-align: justify;
    font-size-adjust: 0.5;
      .infosCredit {
        strong {
          font-size: 1.3em;
          text-decoration: underline;
        }
        span {
          font-size: 1em;
        }
      }
      @media (max-width: $screen-sm-max ) {
        min-width: 450px;
      }
      @media (max-width: $screen-xs-max ) {
        min-width: 250px;
      }
    }
  }
}


#infoCondition {
  text-align: center;
  position: relative;
  #defaults-title {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  img {
    width: 100%;
    max-width: 270px;
  }
  #legend .default {
    margin: 20px 0;
    text-align: left;
  }
  #other-defaults {
    margin-bottom: 32px;
    .default {
      button {
        background-color: $bleu;
        border-radius: 100%;
        fill: $bleu;
        margin: 0;
        padding: 3px 0 0 0;
        width: 24px;
        svg {
          width: 14px;
        }
        &:hover, &:focus {
          background-color: darken($bleu, 10);
          fill: darken($bleu, 10);
        }
      }
    }
  }
  #legend {
    min-width: 170px;
    h6 {
      margin-bottom: 30px;
    }
    td {
      vertical-align: top;
    }
    .whiteCard {
      padding: 12px 20px 5px;
    }
  }
  #support-pictures {
    width: 100%;
    svg {
      max-width: 346px;
      .st0:hover, .private {
        fill: #ffffff;
      }
    }
    .default {
      cursor: pointer;
      border-radius: 50%;
      color: white;
      font-size: 0.7em;
      height: 20px;
      line-height: 20px;
      position: absolute;
      text-align: center;
      width: 20px;
      @media (max-width: $screen-sm-max){
        line-height: 25px;
        width: 25px !important;
        height: 25px !important;
      }
      &:hover{
        filter: brightness(85%);
      }
    }
  }
  .support-picture {
    position: relative;

    &.exterior {
      margin: 30px 0 30px 45px;
    }
    &.interior {
      margin: 30px 0 30px 15px;
    }
  }
  .icon {
    width: 24px;
    height: 24px;
    border-radius: 12px;
    padding-top: 2px;
    padding-left: 1px;
    color: $blanc;
    text-align: center;
  }
  .legend-text {
    padding-top: 2px;
    padding-left: 10px;
    color: $gris1;
  }
  #default-picture-container {
    box-sizing: content-box !important;
    position: absolute;
    z-index: 2;
  }
  #default-picture {
    background-color: white;
    border-radius: 20px;
    border-style: solid;
    position: relative;
    .navigation {
      &.right {
        right: 25px;
      }
      svg {
        height: 25px;
        width: 25px;
      }
    }
  }
  #default-picture-img {
    border-radius: 14px;
    cursor: pointer;
  }
  #default-picture-loading {
    height: 100%;
    padding-top: 39px;
  }
  #default-picture-loading-error {
    height: 100%;
    padding: 10px 6px;
  }
  .navigation {
    bottom: 0;
    cursor: pointer;
    position: absolute;
    top: 0;
    &.left {
      left: 0;
    }
    &.right {
      right: 42px;
    }
    svg {
      bottom: 0;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
      margin-bottom: auto;
      margin-top: auto;
      position: absolute;
      top: 0;
    }
    :hover #Rectangle_909, :hover #Rectangle_910 {
      fill: #abafb1;
    }
  }
  .arrow-down {
    border-top: solid;
    border-right: solid transparent;
    border-left: solid transparent;
    height: 0;
    position: relative;
    width: 0;
  }
  @media screen and (max-width: $screen-xs-max) {
    .table-condition {
      width: 100%;
      margin: 0 auto;
      .tr-condition td {
        &#legend {
          display: block;
          padding-top: 1em;
          width: 170px !important;
          left: 0;
          right: 0;
          margin: auto;
        }
        &#support-pictures {
          display: block;
          padding-top: 1em;
          width: 100% !important;
        }
      }
    }
  }
  .tyre-defaults-container {
    text-align: left;
    .title {
      font-weight: bold;
      margin-bottom: 5px;
    }
  }
  .quality-certificate {
    text-transform: none;
    margin: 20px 0 0;
    i {
      margin: 0 10px 0 0;
    }
  }

}

#modal-default-pictures-full-screen .modal-dialog {
  width: fit-content;

  .modal-content {
    margin-top: 50px;
  }
}

#selectedLegalInformation{
  padding: 40px;
  text-align: justify;
}

#carouselVehiculesSimilaires{
    .carousel-inner{
        overflow: visible;
        @media (max-width: $screen-sm-max){
            overflow: hidden;
        }
    }
}
#infosCaracteristiquesTechniques {
    .leaders{
        .tooltip {
            z-index: 1080;
        }
    }
    img.critair {
        height: 3rem
    }
    .energy-class {
        margin-left: 5px;
      cursor: pointer;
      + .energy-class {
        margin-left: -20px;
        margin-right: 12px;
      }
    }
}

#view-all-defaults {
    margin: 0;
    text-transform: initial;

    svg {
        fill: $blanc;
        margin-right: 4px;
        vertical-align: middle;
        width: 15px;
    }
}
