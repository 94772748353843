/*
 * Slider for Bootstrap
 *
 * Copyright 2012 Stefan Petre
 * Licensed under the Apache License v2.0
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * SCSS File by Detlef Beyer
 */
//@import "compass";

@mixin slider_background-image($colorstart:#f5f5f5, $colorend:#f9f9f9, $backcolor: #f7f7f7) {
  background-color: $backcolor;
  background-image: -moz-linear-gradient(top, $colorstart, $colorend);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from($colorstart), to($colorend));
  background-image: -webkit-linear-gradient(top, $colorstart, $colorend);
  background-image: -o-linear-gradient(top, $colorstart, $colorend);
  background-image: linear-gradient(to bottom, $colorstart, $colorend);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$colorstart}', endColorstr='#{$colorend}', GradientType=0);
}

/* Variables should be defined outside of this file     */
/* doing it here just to keep things together in github */
$baseLineHeight: 25px;
$baseBorderRadius: 4px;

.slider {
  margin-top: 2em;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  &.slider-horizontal {
    width: 100%;
    height: $baseLineHeight;
    .slider-track {
      height: $baseLineHeight/4;
      width: 100%;
      margin-top: -$baseLineHeight/4;
      top: 50%;
      left: 0;
    }
    .slider-selection, .slider-track-low, .slider-track-high {
      height: 150%;
      top: -25%;
      bottom: 0;
    }
    .slider-tick,
    .slider-handle {
      margin-left: -$baseLineHeight/2;
      margin-top: -$baseLineHeight/3;

      &.triangle {
        border-width: 0 $baseLineHeight/2 $baseLineHeight/2 $baseLineHeight/2;
        width: 0;
        height: 0;
        border-bottom-color: #0480be;
        margin-top: 0;
      }
    }
    .slider-tick{
      &:not(.in-selection) {
        margin-left: -$baseLineHeight/4;
        margin-top: -$baseLineHeight/8;
      }
    }
    .slider-tick-label-container {
      white-space: nowrap;

      .slider-tick-label {
        margin-top: $baseLineHeight * 1.2;
        display: inline-block;
        text-align: center;
      }
    }
  }
  &.slider-vertical {
    height: 210px;
    width: $baseLineHeight;
    .slider-track {
      width: $baseLineHeight/2;
      height: 100%;
      margin-left: -$baseLineHeight/4;
      left: 50%;
      top: 0;
    }
    .slider-selection {
      width: 100%;
      left: 0;
      top: 0;
      bottom: 0;
    }
    .slider-track-low, .slider-track-high {
      width: 100%;
      left: 0;
      right: 0;
    }
    .slider-tick,
    .slider-handle {
      margin-left: -$baseLineHeight/4;
      margin-top: -$baseLineHeight/2;
      &.triangle {
        border-width: $baseLineHeight/2 0 $baseLineHeight/2 $baseLineHeight/2;
        width: 1px;
        height: 1px;
        border-left-color: #0480be;
        margin-left: 0;
      }
      .slider-tick-label-container {
        white-space: nowrap;

        .slider-tick-label {
          padding-left: $baseLineHeight * .2;
        }
      }
    }
  }
  &.slider-disabled {
    .slider-handle {
      @include slider_background-image(#dfdfdf, #bebebe, #f7f7f7);
    }
    .slider-track {
      @include slider_background-image(#e5e5e5, #e9e9e9, #f7f7f7);
      cursor: not-allowed;
    }
  }
  input {
    display: none;
  }
  .tooltip-inner {
    white-space: nowrap;
  }
  .tooltip {
    &.top {
      margin-top: -45px;
    }
  }

  .hide {
    display: none;
  }
}

.slider-track {
  position: absolute;
  cursor: pointer;
  border-radius: $baseBorderRadius ;
  //@include border-radius($baseBorderRadius); // since webpack
  background-color: $gris1;
}

.slider-selection {
  position: absolute;
  @include box-sizing(border-box);
  border-radius: $baseBorderRadius ;
  //@include border-radius($baseBorderRadius); // since webpack
  background-color: $rose;
  .formBleuEm &{
    background-color: $bleuEm;
  }
  .formOrange & {
    background-color: $orange;
  }
}

.slider-selection.tick-slider-selection {
  //@include slider_background-image(#89cdef, #81bfde, #f7f7f7);
  background-color: $rose;
  .formBleuEm & {
    background-color: $bleuEm;
  }
  .formOrange & {
    background-color: $orange;
  }
}

.slider-track-low, .slider-track-high {
  position: absolute;
  background: transparent;
  @include box-sizing(border-box);
  border-radius: $baseBorderRadius ;
  //@include border-radius($baseBorderRadius); // since webpack
}

.slider-handle {
  position: absolute;
  width: $baseLineHeight;
  height: $baseLineHeight;
  background-color: $rose;
  .formBleuEm & {
    background-color: $bleuEm;
  }
  .formOrange & {
    background-color: $orange;
  }
  //@include slider_background-image(#149bdf, #0480be, #0e90d2);

  @include box-shadow(inset 0px 0px 0px 1px $gris3,inset 0px 0px 0px 8px #fff, 1px 1px 5px rgba(0,0,0,0.3));


  &.round {
    //border-radius: 10px;
    border-radius: 50%;
    //@include border-radius(50%); // since webpack
    //@include shadowBox(0.3);
    //border-collapse: collapse;
  }
  &.triangle {
    background: transparent none;
  }
  &.custom {
    background: transparent none;
    &::before {
      line-height: $baseLineHeight;
      font-size: 20px;
      content: '\2605'; //unicode star character
      color: #726204;
    }
  }
}

.slider-tick {
  position: absolute;
  width: $baseLineHeight/2;
  height: $baseLineHeight/2;
  //@include slider_background-image(#f9f9f9, #f5f5f5, #f7f7f7);
  background-color: $gris1;
  @include box-shadow(inset 0 -1px 0 rgba(0, 0, 0, 0.15));
  @include box-sizing(border-box);
  filter: none;
  opacity: 1;
  border: 0px solid transparent;
  &.round {
    border-radius: 50%;
  }
  &.triangle {
    background: transparent none;
  }
  &.custom {
    background: transparent none;
    &::before {
      line-height: $baseLineHeight;
      font-size: 20px;
      content: '\2605'; //unicode star character
      color: #726204;
    }
  }

  &.in-selection {
    width: $baseLineHeight;
    height: $baseLineHeight;

    //@include slider_background-image(#89cdef, #81bfde, #f7f7f7);
    background-color: $rose;
    .formBleuEm & {
      background-color: $bleuEm;
    }
    .formOrange & {
      background-color: $orange;
    }

    opacity: 1;
  }
}

.sliderCo2, .puissanceCV {
  padding: 0 35px;

  .slider-horizontal{
    margin-top: 1.3em;

    .slider-handle {
      width: 60px;
      height: 25px;
      margin-top: -10px;

      @include box-shadow(inset 0px 0px 0px 1px $gris3,inset 0px 0px 0px 4px #fff, 1px 1px 5px rgba(0,0,0,0.3));

      &.round {
        border-radius: 5%;
        color: $blanc;
        text-align: center;
        padding-top: 4px;
        font-size: 0.8em;
      }

      &.max-slider-handle {
        margin-left: -25px;
      }

      &.min-slider-handle {
        margin-left: -35px;
      }
    }
  }
}

.containFiltrerRecherche {
  .whiteCard {
    .sliderCo2, .puissanceCV {
      .slider {
        margin-top: 1em;
      }
    }
  }
}