.icon-pdf {
  height: 1.2em;
  width: 1.2em;
  display: inline-block;
  margin-left: 0.5em;
  margin-top: -1px;
  background: url('../../../fonts/svg/icon-pdf-file.svg');
}

.icon-external-link {
  height: 1em;
  width: 1em;
  display: inline-block;
  margin-left: 0.5em;
  margin-top: -1px;
  background: url('../../../fonts/svg/icon-external-link.svg');
}

.icon-rappel {
  height: 100px;
  width:100px;
  display: inline-block;
  background: url("../../../fonts/svg/icon-rappel.svg");
}
