@import '../../global/energy-class';
.carteVehicule {
  &.containVert {
    .prixCarteVehicule .prixCarteVehiculeContainer, .prixCarteVehicule .row {
      background-color: $vert;
    }
    .rubanRemise{
      @include rubanRemise($vert);
    }
    p.stepInformations{
      color: $vert;
    }
  }
  &.containBleu {
    .prixCarteVehicule .prixCarteVehiculeContainer, .prixCarteVehicule .row {
      background-color: $bleu;
    }
    .rubanRemise {
      @include rubanRemise($bleu);
    }
    p.stepInformations{
      color: $bleu;
    }
  }
  &.containJaune {
    .prixCarteVehicule .prixCarteVehiculeContainer, .prixCarteVehicule .row {
      background-color: $jaune;
    }
    .rubanRemise {
      @include rubanRemise($jaune);
    }
    p.stepInformations{
      color: $jaune;
    }
  }
  &.containRose {
    .prixCarteVehicule .prixCarteVehiculeContainer, .prixCarteVehicule .row {
      background-color: $rose;
    }
    .rubanRemise {
      @include rubanRemise($rose);
    }
    p.stepInformations{
      color: $rose;
    }
  }
  &.containGris1 {
    .prixCarteVehicule .prixCarteVehiculeContainer, .prixCarteVehicule .row {
      background-color: $gris1;
    }
    .rubanRemise {
      @include rubanRemise($gris1);
    }
    p.stepInformations{
      color: $gris1;
    }
  }

  &.modelList {
    .promo {
      display: inline !important;
    }
    .promotion {
      line-height: 1em;
    }

    &.col-sm-6, &.col-lg-4 {
      width: 100%;
    }
    .whiteCard {
      padding-top: 0;
      padding-bottom: 0;
      @media only screen {
        @media(max-width: $screen-md-max) {
          margin-bottom: 0;
        }
      }

    }
    img {
      width: 100%;
    }
    .carteImg {
      width: 20%;
      padding-top: 10px;
      padding-bottom: 10px;

      @media only screen {
        @media(max-width: $screen-sm-max) {
          width: 50%;

          @media(max-width: 630px) {
            width: 35%;
          }
        }
      }
    }
    .carteMmv {
      width: 30%;
      padding-top: 5px;
      padding-bottom: 10px;
      @media only screen {
        @media(max-width: $screen-sm-max) {
          width: 100%;
          @media(max-width: 630px) {
            width: 65%;
          }
        }
      }
    }

    .carteInfos {
      width: 33%;
      padding-top: 10px;
      padding-bottom: 10px;
      @media only screen {
        @media(max-width: $screen-sm-max) {
          width: 50%;
          padding-top: 0;
          padding-bottom: 0;
          @media(max-width: 630px) {
            width: 65%;
            @media(max-width: 500px) {
              width: 100%;

            }
          }
        }
      }

      .infosCarteVehicule{
        .typeVehiculeCarte {
          width: 100%;
          padding-top: 0;
            margin:0;
          @media(max-width: 500px) {
            width: 35%;
            display: inline-block;
            margin-right: 0;
          }
        }
        .caracteristiquesRapides {
          width: 100%;
          margin-top: 0;
        }
        .equipementsCarte {
          width: 100%;
          text-align: left;
          height: 1em;
          i {
            font-size: 2.7rem;
            //display: inline-block;
          }
            img {
                padding-right: .3rem;
                height: 2.7rem;
            }
        }

      }
    }
    @media only screen {

      @media(max-width: $screen-xs-max) {
        .prixCarteVehicule {
          .row{
            margin-top: 1.5em;
          }
        }
      }
      @media(min-width: $screen-md-min) {
        .prixCarteVehicule {
          width: 17%;
          position: absolute;
          top: 0;
          right: 0;
          height: 100%;
          .row {
            height: 100%;
            margin-top: 0;
            //padding: 1em;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .col-xs-5 {
              width: 100%;
              left: 0;
            }
          }
          .remiseCarteVehicule {
            left: -1.5em;
          }
        }

      }
    }
    @media only screen {
      @media(max-width: $screen-sm-min) {
        h6 {
          font-size: 1.5rem;
        }
      }
    }
  }
}

.carteModel {
    margin-top:0.6em;
    @media (max-width: $screen-sm-max){
        width: 49%;
    }
    @media (max-width: $screen-xs-max){
        width: 100%;
    }
    &:not(.modelList) {

      .carteImg {
        padding-top: 10px;
        padding-bottom: 5px;
      }

      .carteInfos {
        padding-left: 0px;
      }

      .modelFunding {
        margin-top: 25px;
      }

      .versionModelsButton {
        position: absolute;
        bottom: -22px;
        display: block;
      }

    }

    &.modelList {
      @media(min-width: $screen-sm-min) {
        width: 100%;
        margin-bottom: 2em;
        min-width: 938px;

        .whiteCardModels {
          height: 115px;
        }

        .carteImg {
          padding-top: 0.75em;

          img {
            width: 100%;
          }
        }

        .carteMmv {

          padding-top: 2em;
          padding-left: 2em;

          .carteVehiculeModele{

            font-size: 1em;
          }
        }

        .modelDescription {
          padding-top: 1em;
          line-height: 10px;
          font-size: x-small;
          padding-left: 2em;
        }

        .modelFunding {
          height:113px;
          padding-right: 2em;
          position: relative;

            .textStatus{
              position: absolute;
              top: 50%;
              transform: translateY(-50%);

              .modelTextPrice {
                text-align: center;
              }
            }

        }

        .btn {
          height: 113px;
          width: 163px;
          padding-top: 1.8em;
          margin-left: -15px;
        }
      }

      @media(max-width: $screen-sm-max) {

        .carteImg {
          padding-top: 10px;
          padding-bottom: 5px;
        }

        .carteInfos {
          padding-left: 0px;
        }

        .modelFunding {
          margin-top: 25px;
        }

        .versionModelsButton {
          position: absolute;
          bottom: -22px;
          display: block;
        }
      }
    }
    &:not(.listeCarte) {

      .whiteCard {
        img {
          @media (max-width: $screen-sm-min ) {
            min-height: inherit;
            width: 100%;
          }
        }
      }
    }
}

.carteVehicule.listeCarte{
  .prixCarteVehicule{
    padding: 0;
    .prixCarteVehiculeContainer{
      height: 100%;
      padding: 20px 0;
      display: block;
      @media(max-width: $screen-sm-max){
        display: flex;
        padding: 10px 0;
      }
      .encartPrixCarte{
        left: auto;
        width: 100%;
        display: block;
        float: none;
        margin-left: 10px;
        @media (max-width: $screen-xs-max){
          &:first-child{
            text-align: left;
          }
          &:nth-of-type(2){
            text-align: right;
            padding-right: 10px;
          }
        }
        span{
          font-size: 8px;
        }
      }
    }
  }
}

.badge-promo {
    color: $blanc;
    background: $promo-color;
    border-radius: 1em;
    border: 1px $blanc solid;
    padding: 3px 10px;
    font-size: 10px;
    line-height: 10px;
    font-weight: bold;
    white-space: nowrap;
}

#secTopDesc{
    display:none;
}

.tooltip{
    z-index: 1071;
    .tooltip-inner{
        line-height: 1.5em;
        min-width: 800px;
        p {
            font-weight: 400;
            span {
                font-weight: 700;
                color: $rose;
                font-size: 1.3em;
                &.blanc {
                    color: $blanc;
                    font-size: 1.3em;
                }
            }
        }
        .infosCredit {
            strong {
                font-size: 1.3em;
                text-decoration: underline;
            }
        }
        @media (max-width: $screen-sm-max ) {
            min-width: 700px;
        }
        @media (max-width: $screen-xs-max ) {
            min-width: 250px;
        }
    }
}

.carteVehicule, .carteModel{
    .badge-partner {
        font-weight: normal;
    }
    &.containVert {
        .containRubanDispo {
            @include rubanDispo($vert)
        }
        .badge-partner {
            background: #ffffff;
            color: $vert;
        }
    }
    &.containBleu {
        .containRubanDispo {
            @include rubanDispo($bleu)
        }
        .badge-partner {
            background: #ffffff;
            color: $bleu;
        }
    }
    &.containJaune {
        .containRubanDispo {
            @include rubanDispo($jaune)
        }
        .badge-partner {
            background: #ffffff;
            color: $jaune;
        }
    }
    &.containRose {
        .containRubanDispo {
            @include rubanDispo($rose)
        }
        .badge-partner {
            background: #ffffff;
            color: $rose;
        }
    }
    &.containGris1 {
        .containRubanDispo {
            @include rubanDispo($gris1)
        }
        .badge-partner {
            background: #ffffff;
            color: $gris1;
        }
    }
}

#containerCarList {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    width: 100%;
    margin: 0;
    @media (max-width: $screen-xs-max){
        overflow: hidden;
    }
}

.wrapperCarList{
    padding-top: 1rem;
    padding-right: 0;
}

.carteVehicule{
    margin-bottom: 3.5rem;
    @media (max-width: $screen-sm-max){
      padding: 0 10px;
    }
    .containerCard{
        padding: 0;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .carteImg{
        display: block;
        padding: 1rem;
        position: relative;
        .promo {
            display: none;
        }
        img {
            object-fit: cover;
            width: 100%;
            height: 180px;
        }
        .critair {
            position: absolute;
            right: 1.3rem;
            bottom: 1.3rem;
            width: 40px !important;
            height: 40px !important;
        }
        .containerAvailability{
            display: none;
        }

        .tooltip{
            .tooltip-inner{
                min-width: auto;
            }
        }
    }
    .container-specs{
        padding: 1rem;
        height: 55%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .carteMmv{
            padding-top: 0.25rem;
            display: block;
            span{
                display: block;
                font-size: 1.8rem;
                &.infosBrand{
                    display: none;
                }
            }
            .version{
                font-size: 1.3rem;
                color: $gris1;
            }
        }
        .infosCarteVehicule{
            display: flex;
            flex-direction: column;
            gap: .5rem;
            padding-top: 1rem;
            position: relative;
            .critair {
                display: none;
            }
            .energy-class {
              max-width: 120px;
            }
            .caracteristiquesRapides {
                font-size: 1em;
                height: 23px;
                .color-list {
                    display: inline-block;
                    vertical-align: middle;
                    margin-right: 0.5em;
                    .couleurSwatch {
                        width: 2rem;
                        height: 2rem;
                        display: inline-block;
                        line-height: 1em;
                        margin-right: 0.5rem;
                        vertical-align: middle;
                        svg{
                          border-radius: 0.5rem;
                          border: 1px solid $gris2;
                          box-sizing: border-box;
                          width: 2rem;
                          height: 2rem;
                          line-height: 1em;
                        }
                    }

                    i {
                        font-size: 1.3em;
                        margin-right: 0.2em;
                        color: $gris1;
                        line-height: 0;
                    }
                }
            }
            .equipementsCarte{
                display: block;
                @media (max-width: $screen-sm-max){
                    display: none;
                }
                i {
                    font-size: 3rem;
                }
                img {
                    height: 3rem;
                    padding-right: .3rem;
                }
            }
            .tooltip{
                .tooltip-inner{
                    min-width: auto;
                }
            }
        }
    }
    .containerPrice{
        color: #ffffff;
        padding: .5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        margin-top: 5px;
        min-height: 53px;
        left: 0;
        right: 0;
        bottom: 0;
        .price{
            color: #ffffff;
            font-size: 1.4em;
            display: flex;
            flex-direction: column;
            width: 50%;
            padding-right: 3.5rem;
            text-shadow: 0 0 10px rgba(0,0,0,.3);
            align-items: center;

            .price-promo {
                font-size: 1.3rem;
                position: relative;
                &::after {
                    content: "";
                    position: absolute;
                    top: 49%;
                    left: 0;
                    height: 1px;
                    width: 100%;
                    background: #ffffff;
                    transform: translateY(-50%);
                }
            }
        }
        .containerFundingPrice{
            width: 50%;
            padding-left: 25px;
            font-size: 1.4rem;
            line-height: 0.8;
            text-shadow: 0 0 10px rgba(0,0,0,.3);
            color: #ffffff;
            .icon-infos{
                font-size: 1rem;
                color: #ffffff;
                position: absolute;
            }
            .small{
                &:first-child {
                    display: inline-block !important;
                    margin-top: 0 !important;
                }
                font-size: 1rem;
                line-height: 1.1rem;
                &:not(.month){
                    display: block;
                    margin-top: 0.2rem;
                }
            }
            &:hover{
                color: #ffffff;
            }
            p {
              margin: 0 0 3px;
            }
        }
        &.partner-price {
            .price {
                flex: 1 1 auto;
                display: grid;
                grid-template-rows: auto 1fr;
                grid-template-columns: 1fr auto;
                grid-template: "price-promo badge" "promotion badge";
                justify-items: center;
                padding-right: unset;
                .price-promo {
                    grid-area: price-promo;
                    color: $bleuEm;
                    font-size: 1.4rem;
                    font-weight: 600;
                    &::after {
                        background: $bleuEm;
                    }
                }
                .promotion {
                    grid-area: promotion;
                    font-size: 3rem;
                    font-weight: 800;
                    line-height: 3rem;
                }
                .badge {
                    grid-area: badge;
                }
            }
            .remiseCarteVehicule {
                right: 0;
                top: 0;
                left: unset;
                transform: translate(-10%, -80%);
            }
        }
        .financementIndispo{
            font-size: 1.1rem;
        }
        &.promoBackground {
            background-size: 100% 175px;
            //background-image: url('../../../../resources/front/images/fiche-vehicule/promotions/promo-noel.gif');
            background-image: url('../../../../resources/front/images/fiche-vehicule/promotions/promo-bf-card.png');
            background-repeat: no-repeat;
            .promotion{
                display: block;
                //color: $bleuEm;
                color: #f3ca72;
            }
        }
        .remiseCarteVehicule{
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: #22286d;
            color: #ffffff;
            width: 63px;
            height: 63px;
            border-radius: 50%;
            vertical-align: middle;
            line-height: 56px;
            border: 2px solid #ffffff;
            font-size: 1.8rem;
            font-weight: 300;
            box-shadow: 0 0 10px rgba(0,0,0,.3);
        }
    }
    &.item .promoBackground {
      background-size: 100% 110px;
    }
    &.containVert{
        .containerPrice{
            background-color: $vert;
        }
    }
    &.containBleu{
        .containerPrice{
            background-color: $bleu;
        }
    }
    &.containJaune{
        .containerPrice{
            background-color: $jaune;
        }
    }
    &.containRose{
        .containerPrice{
            background-color: $rose;
        }
    }
    &.containGris1{
        .containerPrice{
            background-color: $gris1;
        }
    }
    .containFastLinksVehiculeCards{
        display: none;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
        position: absolute;
        bottom: -24px;
        left: 0;
        right: 0;
        > span{
            padding: 0.5rem;
            background-color: $gris1;
            color: #fff;
            cursor: pointer;
            font-size: 1.1rem;
            @include shadowBoxInsetTop(0.3);
            &:hover{
                background-color : darken($gris1, 10);
            }
            &.followingButton{
                background-color: $bleuEm;
            }
            i{
                font-size: 1.2rem;
                margin-right: 0.25rem;
            }
            @media (max-width: $screen-xs-max){
                &.comparerVehicule{
                    display: none;
                }
            }
        }
        @media (max-width: $screen-md-max){
            display: flex;
            justify-content: space-between;
            opacity: 1;
        }
    }
    &:hover{
        .containFastLinksVehiculeCards{
            display: flex;
            justify-content: space-between;
            opacity: 1;
        }
    }
    .deleteCard{
        $tailleBadge : 0.85em;
        @include deleteCard($tailleBadge);
        right: - $tailleBadge;
        top: - $tailleBadge;
    }
}
.inlineCard{
    .carteVehicule{
        margin-bottom: 2rem;
        padding-right: 0;
        display: flex;
        width: 100%;
        .containerCard{
            flex-direction: row;
            justify-content: flex-start;
        }
        .carteImg{
            width: 25%;
            @media (max-width: $screen-md-max) {
                align-self: center;
            }
            img{
                height: 121px;
            }
        }
        .container-specs{
            display: flex;
            width: 55%;
            flex-direction: row;
            height: auto;
            @media (max-width: $screen-md-max) {
                width: 50%;
            }
            .carteMmv{
                width: 50%;
                padding-top: 0;
            }
            .infosCarteVehicule{
                display: flex;
                flex-direction: column;
                gap: .5rem;
                width: 50%;
                text-align: left;
                padding-top: 0;
                .equipementsCarte{
                    margin-top: auto;
                    i{
                        font-size: 2.5rem;
                    }

                    img {
                        height: 2.5rem;
                        padding-right: .3rem;
                    }
                }
            }
        }
        .containerPrice{
            flex-direction: column;
            justify-content: center;
            padding: 0 1rem 0 2rem;
            position: relative;
            width: 20%;
            margin-top: 0;
            @media (max-width: $screen-md-max) {
                width: 25%;
            }
            .price{
                width: 100%;
                padding: 0;
                margin-bottom: 1rem;
                font-size: 1.6em;
              &.loaIsBestProposition {
                .span-price {
                  font-size: 0.7em;
                }
              }
            }
            .containerFundingPrice{
                width: 100%;
                padding: 0;
                font-size: 1.5em;
                .small{
                    font-size: 1rem;

                }
                &.financementIndispo{
                    font-size: 1.4rem;
                }
                p{
                    font-size: 1.4rem;
                }
            }
            .remiseCarteVehicule{
                left: 0;
                right: unset;
                top: 50%;
                transform: translate(-50%, -50%);
            }
            &.partner-price {
                .price {
                    display: flex;
                    flex: unset;
                    font-size: 3rem !important;
                    font-weight: 800;
                }
                .promotion {
                    font-size: 3rem;
                }
                .badge-partner {
                    margin-top: 10px;
                }
            }
        }
        .containFastLinksVehiculeCards{
            width: 3%;
            padding: 0;
            display: flex;
            opacity: 1;
            flex-direction: column;
            justify-content: flex-start;
            position: relative;
            bottom: initial;
            left: initial;
            right: initial;
            > span{
                font-size: 0;
                text-align: center;
                i{
                    font-size: 1.5rem;
                    line-height: 1.4;
                    margin-right: 0;
                }
            }
        }
    }
}
.commercial{
    .carteVehicule{
        margin-bottom: 1rem;
        .carteImg{
            display: flex;
            width: 20%;
            align-items: center;
            padding: 0.5rem 1rem;
            img{
                height: 50px;
            }
            .critair {
                display: none;
            }
            .containerAvailability{
                display: block;
                color: #ffffff;
                padding-left: 1.5rem;
                font-size: 1rem;
                span{
                    display: block;
                }
            }
            .containRubanDispo{
                display: none;
            }
            &.couleurDispo1{
                background-color: #54D600;
            }
            &.couleurDispo2 {
                background-color: $jaune;
            }
            &.couleurDispo3{
                background-color: #4A73B5;
            }
            &.couleurDispo4, &.couleurDispo5{
                background-color: #FD950E;
            }
            &.couleurDispoTypeCoulRose{
                background-color: $orange;
            }
        }
        .container-specs{
            flex-direction: column;
            width: 60%;
            padding: 0.5rem 1rem;
            align-self: center;
            .carteMmv{
                width: 100%;
                min-height: auto;
                span{
                    display: inline-block;
                    font-size: 1.2rem;
                    &.brand, &.model, &.version{
                        display: none;
                    }
                    &.infosBrand{
                        display: block;
                        padding-right: 0.5rem;
                    }
                    &::after{
                        content: '-';
                        display: inline-block;
                        margin: 0 0.25rem;
                    }
                    &:last-child{
                        &::after{
                            display: none;
                        }
                    }
                }
            }
            .infosCarteVehicule{
                width: 100%;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                align-items: center;
                text-align: left;
                padding-top: 0.25rem;
                .typeVehiculeCarte {
                    display: none;
                }
                .equipementsCarte{
                    width: 50%;
                    margin-top: 0;
                }
            }
        }
        .containerPrice{
            padding: 0.5rem 2rem 0.5rem 2.5rem;
            width: 20%;
            .price{
                margin-bottom: 0.5rem;
                font-size: 1.1em;
            }
            .containerFundingPrice{
                font-size: 1.1em;
                line-height: 1;
                &.financementIndispo{
                    font-size: 1.1rem;
                }
                .small{
                    font-size: 1rem;
                }
            }
            .remiseCarteVehicule{
                width: 45px;
                height: 45px;
                line-height: 39px;
                font-size: 1.3rem;
            }
        }
        .containFastLinksVehiculeCards{
            display: flex;
            flex-direction: column;
            span{
                height: 50%;
                i{
                    line-height: 21px;
                }
                &:last-child{
                    height: auto;
                }
            }
        }
    }
}

.containVehiculesSimilaires{
    .carousel-inner{
        display: flex;
        @media (max-width: $screen-md-max) {
            flex-wrap: wrap;
        }
        @media (max-width: $screen-sm-max) {
            display: block;
            padding: 1rem;
        }
        .item.carteVehicule {
            @media(max-width: $screen-md-min) {
                margin: 0 auto;
                float: none;
                left: 0;
            }
            @media all and (transform-3d), (-webkit-transform-3d) {
                @include transition-transform(0.6s ease);
                &.next,
                &.active.right {
                    @include translate3d(200%, 0, 0);
                    left: 0;
                }
                &.prev,
                &.active.left {
                    @include translate3d(-200%, 0, 0);
                    left: 0;
                }
                &.next.left,
                &.prev.right{
                    @include translate3d(0, 0, 0);
                    left: 0;
                }
            }
        }
        > .next,
        > .prev {
            @media(max-width: $screen-md-min) {
                width: 66.66667%;
                float: none;
                left: 0;
                margin: 0 auto;
            }
            @media(max-width: $screen-sm-min) {
                width: 70%;
                padding: 0 2.5rem;
            }
            @media(max-width: $screen-xs-min) {
                width: 100%;
            }
        }

        a.carousel-control{
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 40px;
            height: 40px;
            background-color: #fff;
            background-image: none;
            opacity: 1;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
            border: 1px solid #d6d6d6;
            span.arrow{
                color: #abafb1;
                &:before{
                    line-height: 40px;
                    font-size: 16px;
                    text-shadow: initial;
                }
            }
            span.arrow-left{

                &::before{
                    content: '〈';
                    padding-right: 8px;
                }
            }
            span.arrow-right{
                &::before{
                    content: '〉';
                    padding-left: 8px;
                }
            }
            @media(min-width: $screen-md-min) {
                display: none;
            }
            @media(max-width: $screen-sm-min) {
                top: 37%;
            }
        }
        a.left{
            left: 10px;
        }
        a.right{
            right: 10px;
        }
    }
    .carteVehicule{
        @media (max-width: $screen-md-max){
            min-height: 480px;
        }
        @media (max-width: $screen-xs-max){
            padding: 0 2.5rem;
        }
        .containerPrice{
            position: relative;
        }
        .containFastLinksVehiculeCards{
            @media (max-width: $screen-md-max){
                position: relative;
                bottom: initial;
                padding: 0;
            }
        }
    }
}

.animateCard{
    opacity: 0;
    &:nth-child(-n+8){
        animation: fadeslideUp 0.2s ease-out forwards 0.75s ;
    }
    &:nth-child(n+8){
        animation: fade 0.5s ease-out forwards;
    }
    &.carteVehiculeCompact{
        &:nth-child(-n+12){
            animation: fadeslideUp 0.2s ease-out forwards 0.75s ;
        }
        &:nth-child(n+13){
            animation: fade 0.5s ease-out forwards;
        }
    }
}
@keyframes fadeslideUp {
    0%{
        transform: translateY(50%);
        opacity: 0;
    }
    20%{
        opacity: 0.2;
    }
    100%{
        transform: translateY(0);
        opacity: 1;
    }
}
@keyframes fade {
   0% {
       opacity: 0;
   }
   100%{
       opacity: 1;
   }
}

.funding-tooltip-container {
    .tooltip-inner {
        text-align: justify;
        p {
            line-height: normal;
            font-size: 14px !important;
        }
        b {
            font-size: 19px !important;
            color: $rose;
        }
    }
}
