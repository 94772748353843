.containCallDevis {
  .btn {
    @include shadowBox(0.3);
  }
  @media only screen {
    @media(max-width: $screen-sm-max) {
      .whiteCard {
        display: none;
      }
      .btn {
        position: fixed;
        bottom: 0;
        z-index: 200;
        padding-left:0;
        padding-right:0;
        &:first-child {
          left: 0;
        }
        &:last-child {
          right: 0;
        }
        &.btn-sm {
          @include button-size($padding-large-vertical, $padding-large-horizontal, $font-size-large, $line-height-large, $btn-border-radius-large);
        }
      }

    }
    @media(min-width: $screen-sm-max) {
      #boutonCommande {
        margin-top: 10px;
      }
    }
  }
}

.containReservedVehicule{

  .btn{
    margin-bottom: 0.5em;
  }

  .colorSwatch{
    margin-right: 0;
    margin-bottom: 10px;

    .couleurSwatch{
      svg {
        margin: 0.4em 0.18em;
      }
    }
    span{
      font-weight: bold;
      &.nbColors{
        position: absolute;
        top: -3px;
        left: 3px;
        height: 28px;
        width: 28px;
        text-align: center;
        line-height: 28px;
        color: #fff;
        @include shadowText(0.5)
      }
    }
  }
  .colorSwatchCarSheet{
    display: inline-block;
    a{
      position: relative;

    }
  }

  @media only screen {
    @media(max-width: $screen-sm-max) {
      .whiteCard{
        margin-bottom: 2em;
      }

      .colorSwatchCarSheet{
        width: 33%;
        display: inline-block;
        @media(max-width: $screen-xs-max) {
          width: 49%;
        }

      }
    }
  }
}
