.containHandBand{
  margin-top: 35px;

  @media (max-width: $screen-sm-max){
      margin-top: 0;
  }

  @media only screen {
    @media(max-width: $screen-xs-max) {
      margin-left: 0;
      margin-right: 0;
    }
  }
}
