.flash {
  margin-top: 1em;
  text-align: center;
}

.hideFlash {
  display: none;
}

.flashError {
  color : $orange;
  .icon-problem{
    font-size: 3.5rem;
    display:block;
    margin-top: 0.25em;
    margin-bottom: 0.25em;
  }
}

.flashSuccess {
  color : $vert;
}