.containConfirmationCommande{
  .containIllustration {
    background-image: url("../../../../resources/front/images/illustrations/confirmation-commande.jpg");
    padding: 6em 0;
    @include shadowBox(0.3);
  }
  .whiteCard{
    padding : 0;
    margin-bottom: $grid-gutter-width/2;

    .containDetailsConfirmation {
      padding: .5em 0;
    }
  }
  .bleuEMCard {
    p {
      margin-bottom: 0;
    }
  }


}
