.tableReglement{
  width: 100%;
  margin-bottom: 1em;
  @include shadowBox(0.3);
  th{
    font-size: 0.75em;
    background-color: $grisFond;
    @include box-shadow(inset 0px -8px 8px -8px rgba(0, 0, 0, 0.3));
  }
  td{
    font-weight: 700;
  }
  th, td{
    text-align: center;
    padding: 0.5em 0;
  }
}
