.hideOnMobile {
  display: none;
  @media only screen {
    @media(min-width: $screen-lg-min) {
      display: inline;
    }
  }
}

$i:9;
@while ($i>=0) {
    .z-index-#{$i} {
        z-index: #{$i} ;
    }
    $i: $i - 1;
}

.readMore {
  position: absolute;
  bottom: 0;
}

.flex {
  display: flex;
}

.align-items-center {
    align-items: center;
}

.row.equal {
    display: flex;
    flex-wrap: wrap;
}

.flex-fill {
    flex: 1 1 auto!important;
}
.flex-wrap {
    flex-wrap: wrap;
}

.align-items-baseline {
    align-items: baseline;
}
.align-items-end {
    align-items: flex-end;
}

.position-relative {
    position: relative;
}

.margin-top {
    margin-top: auto;
}
.justify-content-center {
    justify-content: center;
}
.tableCell{
  display: table-cell;
}

.verticalAlignMiddle{
  vertical-align: middle;
}

.overflowHidden{
  overflow: hidden;
}

.noScroll {
      overflow: hidden;
}

.hidden{
  visibility: hidden;
}

.noneBlock{
  display: none;
}

.flex-column {
    flex-direction: column;
}
