// Source du css : Mit licence : http://www.minimit.com/articles/solutions-tutorials/bootstrap-3-responsive-centered-columns

/* centered columns styles */
.row-centered {
  text-align: center;
}

.col-centered {
  display: inline-block;
  float: none;
  /* reset the text-align */
  text-align: left;
  /* inline-block space fix */
  margin-right: -4px;
  @media only screen{
    @media(max-width: $screen-xs-max){
      margin-right: 0px;
    }
  }
}

.col-fixed {
  /* custom width */
  width: 320px;
}

.col-min {
  /* custom min width */
  min-width: 320px;
}

.col-max {
  /* custom max width */
  max-width: 320px;
}