ul.leaders {
  max-width: 100%;
  padding: 0;
  overflow-x: hidden;
  list-style: none;
  margin-bottom: 0;
  font-size: 16px;
  line-height: 1.5em;
  &.warranty {
      li {
          display: grid;
          grid-template-columns: auto auto;
          &.with-extension {
              grid-template-columns: 220px auto;
          }
          @media(max-width: $screen-sm-max) {
              grid-template-columns: auto;
          }
          > span {
              text-align: right;
              &:first-child {
                  white-space: nowrap;
                  text-align: left;
                  overflow: hidden;
                  &:after {
                      width: 0;
                      white-space: nowrap;
                      content: ". . . . . . . . . . . . . . . . . . . . " ". . . . . . . . . . . . . . . . . . . . " ". . . . . . . . . . . . . . . . . . . . " ". . . . . . . . . . . . . . . . . . . . " ". . . . . . . . . . . . . . . . . . . . " ". . . . . . . . . . . . . . . . . . . . " ". . . . . . . . . . . . . . . . . . . . " ". . . . . . . . . . . . . . . . . . . . " ". . . . . . . . . . . . . . . . . . . . " ". . . . . . . . . . . . . . . . . . . . " ". . . . . . . . . . . . . . . . . . . . " ". . . . . . . . . . . . . . . . . . . . " ". . . . . . . . . . . . . . . . . . . . " ". . . . . . . . . . . . . . . . . . . . " ". . . . . . . . . . . . . . . . . . . . " ". . . . . . . . . . . . . . . . . . . . " ". . . . . . . . . . . . . . . . . . . . " ". . . . . . . . . . . . . . . . . . . . " ". . . . . . . . . . . . . . . . . . . . " ". . . . . . . . . . . . . . . . . . . . " ". . . . . . . . . . . . . . . . . . . . ";
                      font-weight: 300;
                      font-size: 0.5em;
                      line-height: 4em;
                      padding-left: 10px;
                  }
              }
          }
          &:after {
              content: none;
          }
      }
  }
  li{
    margin: 0.5em 0;
    clear: both;
    &:after {
      float: left;
      width: 0;
      white-space: nowrap;
      content: ". . . . . . . . . . . . . . . . . . . . " ". . . . . . . . . . . . . . . . . . . . " ". . . . . . . . . . . . . . . . . . . . " ". . . . . . . . . . . . . . . . . . . . " ". . . . . . . . . . . . . . . . . . . . " ". . . . . . . . . . . . . . . . . . . . " ". . . . . . . . . . . . . . . . . . . . " ". . . . . . . . . . . . . . . . . . . . " ". . . . . . . . . . . . . . . . . . . . " ". . . . . . . . . . . . . . . . . . . . " ". . . . . . . . . . . . . . . . . . . . " ". . . . . . . . . . . . . . . . . . . . " ". . . . . . . . . . . . . . . . . . . . " ". . . . . . . . . . . . . . . . . . . . " ". . . . . . . . . . . . . . . . . . . . " ". . . . . . . . . . . . . . . . . . . . " ". . . . . . . . . . . . . . . . . . . . " ". . . . . . . . . . . . . . . . . . . . " ". . . . . . . . . . . . . . . . . . . . " ". . . . . . . . . . . . . . . . . . . . " ". . . . . . . . . . . . . . . . . . . . ";
      font-weight: 300;
      font-size: 0.5em;
      line-height: 4em;
    }
    span:first-child {
      padding-right: 0.33em;
      background: white;
      font-weight: 700;

    }
    span + span {
      float: right;
      padding-left: 0.33em;
      background: white;
      position: relative;
      z-index: 1
    }
    label {
      display: inline;
      //margin: 10em !important;
    }

  }
  li.noDots{
    &:after{
      content: "";
    }
  }
}
