$font-family-sans-serif: "Lato", Helvetica Neue, Helvetica, Arial, sans-serif !default;
$headings-font-family : "Lato", Helvetica Neue, Helvetica, Arial, sans-serif !default;

$body-bg : $grisFond  !default;
$text-color: $bleuEm !default;

$btn-border-radius-large : 0;
$btn-border-radius-small : 0;
$input-border-radius : 0;
$input-border-radius-large : 0;
$input-border-radius-small : 0;

//** Tooltip text color
$tooltip-color: #fff !default;
//** Tooltip background color
$tooltip-bg: $bleuEm !default;
$tooltip-opacity: 1 !default;

$border-radius-base : 8px;

$grid-gutter-width: 20px !default;
$container-large-desktop: (1250px + $grid-gutter-width) !default;

$screen-lg : 1300px;
$screen-lg-max: 1440px;

//== Navs
//
//##

//=== Shared nav styles
$nav-link-padding: 10px 15px !default;
$nav-link-hover-bg: $gris3 !default;


$nav-disabled-link-color: $blanc !default;
$nav-disabled-link-hover-color: $blanc !default;

//== Tabs
$nav-tabs-border-color: $blanc !default;



$nav-tabs-justified-link-border-color: $blanc !default;
$nav-tabs-justified-active-link-border-color: $blanc !default;

//== Pills
$nav-pills-border-radius: 0 !default;
$nav-pills-active-link-hover-bg: $blanc !default;
$nav-pills-active-link-hover-color: $blanc !default;



//== Form states and alerts
//
//## Define colors for form feedback states and, by default, alerts.

$state-success-text:             $vert !default;
$state-success-bg:               #dff0d8 !default;
$state-success-border:           darken(adjust-hue($state-success-bg, -10), 5%) !default;

$state-info-text:                $bleuEm !default;
$state-info-bg:                  #d9edf7 !default;
$state-info-border:              darken(adjust-hue($state-info-bg, -10), 7%) !default;

$state-warning-text:             $bleuEm !default;
$state-warning-bg:               #fcf8e3 !default;
$state-warning-border:           darken(adjust-hue($state-warning-bg, -10), 5%) !default;

$state-danger-text:              $orange !default;
$state-danger-bg:                #f2dede !default;
$state-danger-border:            darken(adjust-hue($state-danger-bg, -10), 5%) !default;


.help-block{
  font-size: 12px;
  font-weight: bold;
  margin-top: 0;
}
