.containSearchBar{

  padding-top: 1.5em;

  button{
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    border: 1px solid $gris3;
  }

  .select-marques, .select-modeles{
    width: 50%;
    height: 48px !important;
    color: $blanc;
    overflow: hidden;

    .select2-search--inline{
      display: inherit;
    }
  }

  .jcf-select {
    width: 50%;
  }
  .jcf-select.jcf-compact-multiple {
    max-width: 50%;
    width: 50%;
    display: table-cell;
  }
}
