@import "../variables/variables";

$height: 24px;
.energy-class {
    position: relative;
    font-weight: normal;
    background: var(--energy-class-color);
    text-align: left;
    line-height: 1;
    display: flex;
    align-items: center;
    color: #000;
    padding: 0 1rem 0 .5rem;
    height: $height;
    margin-right: $height/2 + 5px;
    &::before {
        content: attr(data-energy-class);
        padding-right: .5rem;
        font-weight: bold;
    }
    &.energy-class-a, &.energy-class-g {
        color: white;
    }
    &::after {
        content: '';
        position: absolute;
        right: 0;
        transform: translateX(100%);
        top:0;
        width: 0;
        height: 0;
        border-left: solid $height/2 var(--energy-class-color);
        border-top: solid $height/2 transparent;
        border-bottom: solid $height/2 transparent;
    }
}
$width: 180px;
$step: 20px;
.energy-class-list {
    padding-left: 0;
    > li {
        display: flex;
        gap: 3rem;
    }
    .energy-class-a {
        min-width: $width;
    }
    .energy-class-b {
        min-width: $width + $step;
    }
    .energy-class-c {
        min-width: $width + 2*$step;
    }
    .energy-class-d {
        min-width: $width + 3*$step;
    }
    .energy-class-e {
        min-width: $width + 4*$step;
    }
    .energy-class-f {
        min-width: $width + 5*$step;
    }
    .energy-class-g {
        min-width: $width + 6*$step;
    }
    .energy-class {
        height: 30px;
    }
    .energy-class {
        margin-left: 0 !important;
        &::before {
            position: absolute;
            right: 5px;
            border-radius: 3px;
            background: rgb(255,255,255);
            color: var(--energy-class-color);
            text-align: center;
            padding-right: 0;
            border: solid 1px #ffffff;
            width: 20px;
        }
        &::after {
            border-left: solid 15px var(--energy-class-color);
            border-top: solid 15px transparent;
            border-bottom: solid 15px transparent;
        }
    }
    .energy-class.current {
        padding-left: 25px;
        padding-right: 10px;
        background: #fff;
        color: #000;
        filter: drop-shadow(0 0 2px rgba(0,0,0,.3));
        &::after {
            right: unset;
            left: 0;
            transform: translateX(-100%) rotate(180deg);
            border-left: solid 15px #ffffff;
            border-top: solid 15px transparent;
            border-bottom: solid 15px transparent;
        }
        &::before {
            left: 0;
            right: unset;
            background: var(--energy-class-color);
            color: #ffffff;
        }
    }
}
