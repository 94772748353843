.containAdvancePayment{
    .containerPayment{
        display: flex;
        align-items: stretch;
        @media (max-width: $screen-sm-max){
            flex-direction: column;
        }
    }
    .container-card{
        margin: 0;
        height: 100%;
        @media (max-width: $screen-md-max){
            margin: 0 0 1em;
        }
    }
    .card{
        margin: 0;
        padding: 20px;
        height: 100%;
        @media (max-width: $screen-xs-max){
            padding: 10px;
        }
    }
    .mt-2{
        margin-top: 1em;
    }
    .light{
        font-weight: 300;
    }
    h2{
        font-size: 1.8em;
    }
    .titleCar{
        font-size: 1.5em;
        margin: 0;
    }
    .versionCar{
        font-size: 1.3em;
        color: $gris6;
        @media (max-width: $screen-xs-max){
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    .container-title{
        margin-top: 0.8em;
        @media (max-width: $screen-xs-max){
            margin-top: 0;
        }
    }
    table{
        width: 100%;
        font-size: 1.14em;
        @media (max-width: $screen-xs-max){
            font-size: 0.9em;
            th{
                font-size: 0.9em;
            }
        }
        tr{
            border: 1px solid $gris3;
        }
        tbody{
            tr{
                &:last-child{
                    background: $bleuEm;
                    color: #ffffff;
                }
            }
        }
        td, th{
            text-align: center;
            padding: 0.5em 0;
        }
    }
    .paymentTitle{
        font-weight: normal;
    }
    .modePaymentTitle{
        font-size: 1.14em;
    }
    @media (max-width: $screen-xs-max){
        .mt-xs-0{
            margin-top: 0;
        }
        .btn span{
            margin: 0;
        }
        .containButtonBottomTunnel > .btn[type="submit"] {
            width: 100%;
        }
    }
}


