@import "pages-reprise/index-reprise";
@import "pages-reprise/equipements-reprise";
@import "pages-reprise/etat-reprise";
@import "pages-reprise/coordonnees-reprise";
@import "pages-reprise/estimation-reprise";
@import "aside-reprise";

.containPagesReprise{
  .whiteCard:first-child{
    margin-bottom: 1em;
    padding: 1em;
    h6{
      margin-bottom: 1em;
    }
  }
}