#containerCarList{
  //display: flex;
  //flex-flow: row wrap;
  //align-items: stretch;
  //width: 100%;
  //margin: 0;

    /* INLINE CARD */
  //&.inlineCard{
  //    .carteVehicule{
  //      //display: flex;
  //      //width: 100%;
  //      //max-height: initial;
  //      //  @media (max-width: $screen-md-max){
  //      //      flex-basis: 100%;
  //      //      &:nth-child(odd), &:nth-child(even){
  //      //          padding: 0;
  //      //      }
  //      //  }
  //      //  .whiteCard{
  //      //      //padding: 0;
  //      //      //width: 97%;
  //      //      .containerCard{
  //      //          display: flex;
  //      //          width: 100%;
  //      //          margin: 0;
  //      //          a:nth-of-type(2){
  //      //              width: 25%;
  //      //              padding: 10px 0 10px 10px;
  //      //          }
  //      //          a:nth-of-type(3){
  //      //              width: 35%;
  //      //              padding: 10px 0;
  //      //          }
  //      //          a:nth-of-type(4){
  //      //              width: 20%;
  //      //              @media (max-width: $screen-sm-max){
  //      //                  width: 25%;
  //      //              }
  //      //
  //      //          }
  //      //          .carteInfos{
  //      //              a:nth-of-type(2){
  //      //                  display: inline-block;
  //      //                  width: auto;
  //      //                  padding: 0;
  //      //              }
  //      //          }
  //      //      }
  //      //  }
  //        //.containFastLinksVehiculeCards{
  //        //  padding: 0;
  //        //  height: initial;
  //        //  width: 3%;
  //        //  span{
  //        //      float: none;
  //        //      opacity: 1;
  //        //      font-size: 0;
  //        //      i{
  //        //          font-size: 15px;
  //        //      }
  //        //      span{
  //        //          display: none;
  //        //      }
  //        //    &.followingButton{
  //        //      background-color: $bleuEm;
  //        //    }
  //        //  }
  //        //}
  //        //.carteImg{
  //        //    //padding-left: 0;
  //        //    //@media (max-width: $screen-sm-max){
  //        //    //    padding-right: 5px;
  //        //    //}
  //        //    //img{
  //        //    //    height: 121px;
  //        //    //}
  //        //}
  //        .carteMmv{
  //            @media (max-width: $screen-sm-max){
  //                padding: 0 5px;
  //                margin-top: 0;
  //            }
  //            .mmvCarteVehicule{
  //                .h6{
  //                    min-height: initial;
  //                }
  //            }
  //        }
  //
  //        .carteInfos{
  //            width: 32%;
  //            text-align: center;
  //            padding: 10px;
  //            @media (max-width: $screen-sm-max){
  //                padding: 0 5px;
  //            }
  //            .caracteristiquesRapides{
  //                display: flex;
  //                flex-direction: column;
  //                height: initial;
  //                margin: 0;
  //                .color-list {
  //                    margin-right: 0;
  //                }
  //                .couleurSwatch{
  //                    margin-right: 5px;
  //                }
  //            }
  //            .equipementsCarte{
  //                margin-top: 35px;
  //                height: initial;
  //                >span{
  //                    display: flex;
  //                    justify-content: center;
  //                }
  //            }
  //            .typeVehiculeCarte{
  //                padding-top: 0;
  //            }
  //
  //        }
  //        .prixCarteVehicule{
  //            height: 100%;
  //            .prixCarteVehiculeContainer{
  //                height: 100%;
  //                margin-top: 0;
  //                flex-direction: column;
  //                justify-content: center;
  //                padding: 0 10px;
  //                line-height: 1.4;
  //                .encartPrixCarte{
  //                    &:first-child{
  //                        margin-bottom: 10px;
  //                    }
  //                    width: 100%;
  //                    padding: 0 0 0 21px;
  //                    left: initial;
  //                    line-height: 1;
  //                    span .icon-infos{
  //                        top: 1px;
  //                        right: 0;
  //                        @media (max-width: $screen-md-max){
  //                            top: -10px;
  //                            right: -8px;
  //                        }
  //                    }
  //                }
  //                .remiseCarteVehicule{
  //                    left: -34px;
  //                }
  //            }
  //        }
  //    }
  //}
}

.carteGrilleVehicule{
  &.carteModel {
      padding-bottom: 3rem;
      > div {
          height: 100%;
            padding-bottom: 0 !important;
            margin-bottom: 0 !important;
  }
.carteImg{
  margin-bottom: 35px;
}

.carteMmv {
  min-height: 50px;
  line-height: 70px;

  @media (max-width: $screen-xs-max) {
    margin-top: 0;
    height: auto;
  }
}

.blocTitleCarte {
  vertical-align:middle;
  display:inline-block;
  line-height:1.2;
}

.modelDescription{
  @media (min-width: $screen-sm-min) {
    min-height: 165px;
  }

  ul {
    list-style-type: none;

    li {
      margin-bottom: 10px;
      padding-left: 0.7em;
      text-indent: -0.7em;

      &:before {
        content: '- ';
      }
    }
  }
}

.containRubanDispo {
  &.rubanVert {
    @include rubanDispo($vert);
  }

  &.rubanBleu {
    @include rubanDispo($bleu);
  }

  &.rubanJaune {
    @include rubanDispo($jaune);
    color: #535252;
  }
}

.btn {
  &.btnVert {
    @include button-variant($blanc, $vert, $vert);
  }

  &.btnBleu {
    @include button-variant($blanc, $bleu, $bleu);
  }

  &.btnJaune {
    @include button-variant($blanc, $jaune, $jaune);
    color: #535252;
  }
}
}

&:not(.listeCarte){
.whiteCardModels{
  padding-bottom: 2em;
  margin-bottom: 50px;
  padding-top: 10px;
  .carPictureModel{
    height: 190px;
    img{
      height: 190px;
      object-fit: contain;
    }
  }

  &:hover {
    @include shadowBox(0.5);
  }

  a.linkJaune {
    color: #535252;
  }
}

.whiteCard{
  .carteImg{
    //overflow: hidden;
    //text-align: center;
    //img{
    //  object-fit: cover;
    //  width: 100%;
    //  height: 180px;
    //}
  }
  .prixCarteVehicule{
    padding: 0;
    .prixCarteVehiculeContainer{
      margin-top: 0.5em;
      height: 55px;
      line-height: 0.6;
      padding:0;
      .encartPrixCarte span .icon-infos{
        color: white;
        position: absolute;
        top: -5px;
        right: 30px;
          font-size: 1rem;
          @media (max-width: $screen-md-max) {
              right: -20px;
          }
          @media (max-width: $screen-sm-max){
              font-size: 1.2rem;
          }
          @media (max-width: $screen-xs-max){
              right: -10px;
          }
      }
    }
  }
}

//.containFastLinksVehiculeCards{
//  display: inline-block;
//
//  >span{
//    float: left;
//    &:not(:first-child){
//      float: right;
//      text-align: right;
//      margin-left: 0px;
//      @media (max-width: $screen-xs-max ) {
//        display: none !important;
//      }
//    }
//  }
//}
}

.infosCarteVehicule {
//position: relative;

//.typeVehiculeCarte {
//  padding-top: 1em;
//}

.equipementsCarte {
  //display: block;
  //text-align: center;
  //height: 2.25em;
  //i {
  //  font-size: 3rem;
  //}
}
}
}




.logoBrandTop {
position: relative;
width: 100%;
text-align: center;
margin-bottom: 5px;

img {
position: absolute;
top: 50%;
left: 50%;
height: 80%;
width: 80%;
margin: -40% 0 0 -40%;
}
}

.logoBrandTop:after {
content: "";
display: block;
padding-bottom: 100%;
background: #fff;
border-radius: 12px;
}

.logoBrandContainer {
width: 7%;
display: inline-block;

@media (max-width: $screen-xs-max ) {
width: 18%;
}
}

.descBrand {
color: $gris6;
}

.previewDesc {
color: $gris6;
width: 100%;
float: right;
&.modelDescriptionType{
    @media (max-width: $screen-xs-max){
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-bottom: 0;
        position: relative;
        z-index: 2;
    }
    &.active{
        white-space: initial;
    }
}
}

.pricesCar {
padding: 6px 0 3px 0;
color: $blanc;

&.bgVert {
background: $vert;
}

&.bgBleu {
background: $bleu;
}

&.bgJaune {
background: $jaune;
color: #535252;
}

.titlePrice {
font-size: 0.7em;
}

.withoutFunding {
margin:5%;
}

.priceCar {
font-size: 1.35em;
font-weight: 600;
span {
  font-size: 0.8em;
  font-weight: 400;
}
  > sup > i {
      font-size: 1rem;
      color: #ffffff;

  }
}

 .legal-mentions {
     display: block;
    font-size: .9rem;
    line-height: 1rem;
    font-weight: 400;
}

.reduction {
position: absolute;
border-radius: 50%;
border: 3px solid $blanc;
text-align: center;
@include shadowBox(0.3);
@include shadowText(0.3);
left: 50%;
width: 2.8em;
height: 2.8em;
top: calc(50% - .8em);
font-size: 3.3em;
font-weight: 300;
z-index: 1;
transform: translateX(-50%) scale(0.5);
background: $bleuEm;
color: $vert;
padding-top: 30px;
div {
  color: $blanc;
  font-size: 0.45em;
  line-height: 0.5em;
}
}

.lineSeparator {
border-left: 1px solid #535252;
height: calc(100% - 12px);
display: inline-block;
position: absolute;
left: 50%;
}

&.bgBleu {
.lineSeparator {
  border-left: 1px solid #fff;
}
}

&.bgJaune {
.lineSeparator {
  border-left: 1px solid #535252;
}
}
}

.container-description {
z-index: 1;
}

