.menuCompte {
  font-size: 1em;
  padding: 1em;
  width: 100%;
    z-index: 300;
    @include whiteCard;
    @include shadowBoxInsetTopBottom(0.3);
    @media only screen {
    @media(min-width: $screen-lg-min) {
      position: absolute;
      right: 0;
      width: 345px;
      border-bottom: 3px solid $bleuEm;
      font-size: 1.5em;
    }
  }
  .initialHidden{
    display: none;
  }
  .btn-block.disabled{
    visibility: visible !important;
  }
}

.formCompte{
  .initialHidden{
    display: none;
  }

  .form-group {
    margin-bottom: 0;
    input {
      margin-bottom: 1em;
    }
  }
  .small-1 {
    margin-top: 0.5em;
    font-size: 0.5em;
  }
  ul {
    margin-bottom: 0;
    font-weight: 700;
    @include liststyle();
    li {
      margin-bottom: 0.5em;
      font-size: 0.7em;
      line-height: normal;
    }
  }
}

.containFormeJuridiqueInscription{
  display: none;
}

.btnMenuCompte {
    overflow: hidden;
    display: flex;
    line-height: 14px;
    align-items: center;
    > span {
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 0 0 0 4px;
    }
  i {
    color: $blanc;
  }
}
