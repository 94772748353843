.conditionsGenerales {
    padding: 2rem 1rem;
  h3, h2{
      font-size: 1em;
      font-weight: 500;
      margin: 2rem 0;
    }
    ul{
        li{
            list-style: disc;
            margin-left: 1.5em;
        }
    }
  div {
    margin-bottom: 2em;
    h6 {
      margin-bottom: 0.5em !important;
    }

  }
  a{
    text-decoration: underline;
  }
    &.cgvArticles{
        h2{
            font-size: 2.5rem;
            font-weight: 300;
            margin: 0 0 1rem;
        }
    }
}
