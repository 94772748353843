.carteVehicule.carteVehiculeCompact {
  margin-top: 0.5em;

  .whiteCard {
    a {
      > div {
        padding: 0;
      }
      &:hover {
        color: $bleuEm;
      }
    }
    .whiteCardComLink{
      padding: 0 10px;
    }
    h6 {
      font-size: 1em;
      .regular {
        font-size: 0.8em;
      }
      text-transform: none;
    }

    .couleurSwatch {
      width: 2rem;
      height: 2rem;
      display: inline-block;
      line-height: 1em;
      border-radius: 0.5rem;
      margin-right: 0.5rem;
      svg{
        border: 1px solid $gris2;
        width: 2rem;
        height: 2rem;
        line-height: 1em;
      }
    }

    .containPrixCarteCompact {
      font-weight: bold;
      font-size: 1.25em;
      line-height: 1.5em;
      padding-right: 40px;
      color: $blanc;
      @include shadowText(0.5);
      span {
        font-size: .8em;
      }
      .financementIndispo {
        font-size: 0.7em;
      }
      .containFastLinksCompact {
        width: 20px;
        font-size: 1em;
      }
      &.vert {
        background-color: $vert;
      }
      &.bleu {
        background-color: $bleu;
      }
      &.jaune {
        background-color: $jaune;
      }
      &.rose {
        background-color: $rose;
      }

    }
    .containDispoCompact {
      font-size: 0.8em;
      font-weight: bold;
      line-height: 2.4em;
      color: $blanc;
    }

    .containImageCompact {
      padding: 10px;
      min-height: 52px;
    }

    .infosCarteVehicule {
      //display: none;
      i{
        font-size: 1.5em;
      }

    }
    &:hover {
      .infosCarteVehicule {
        display: block;
      }

    }

    .remiseCarteVehicule {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: $bleuEm;
      position: absolute;
      border-radius: 50%;
      border: 2px solid $blanc;
      text-align: center;
      @include shadowBox(0.3);
      @include shadowText(0.3);
      left: -1.5em;
      width: 3em;
      height: 3em;
      top: calc(50% - 1.5em);
      //line-height: 2.75em;
      font-size: 1.5em;
      font-weight: 300;
      z-index: 1;
      zoom: 0.5;
      small {
        font-size: 0.5em;
      }
      .promotion {
        color: $vert;
      }
    }

  }
  .containFastLinksVehiculeCardsCompact {
    display: block !important;
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    z-index: 99;
    height: 100%;
    span {
      display: block;
      text-align: center;
      height: 50%;
      background-color: $gris1;
      color: $blanc;
      line-height: 2em;
      cursor: pointer;
      &:hover {
        background-color: darken($gris1, 10%);
      }
      &.followingButton{
        background-color: $bleuEm;
      }
    }

  }

  .couleurDispo1{
    background-color: #54D600;
  }
  .couleurDispo2 {
    background-color: $jaune;
  }
  .couleurDispo3{
    background-color: #4A73B5;
  }
  .couleurDispo4, .couleurDispo5{
    background-color: #FD950E;
  }
  .couleurDispoTypeCoulRose{
    background-color: $orange !important;
  }
}
