.containSearchMobile{
  position: fixed;
  z-index: 204;
  width: 100%;
  padding: 0 1em;
  right: 0;
  background-color: $blanc;
  display: none;
  border-bottom: 3px solid $bleuEm;
  @include transition-duration(0.5s);
  @include easeInOut;
  @include shadowBox(0.3);

  .input-group{
    margin: 1em 0;
  }
  .containSearchBar{
    padding: 0;
  }
  #bsTourSearch{
      .input-group{
          input[type="text"]{
              width: 100%;
          }
      }
      .jcf-select{
          display: none !important;
      }
   }

  .bandeauBrand{
      .brand{
          position: relative;
          display: none;
          a{
              &:hover{
                  text-decoration: underline;
              }
          }
          &:before{
              background-color: $bleuEm;
          }
          &:first-child{
              &:before{
                  display: none;
              }
          }
          &:nth-child(-n+4){
              display: block;
          }
       }
      &.active{
          padding-bottom: 20px;
          .brand{
              display: block;
              &:nth-child(-n+4){
                  &:after{

                      background-color: #fff;
                  }
                  a{
                      &:before{
                          background-color: $bleuEm;
                      }
                  }
              }
              &:nth-of-type(9){
                  &:before{
                      display: block;
                  }
              }
              &:nth-of-type(10){
                  &:before{
                      display: block;
                  }
                  &:after{
                      background-color: $bleuEm;
                  }
              }
          }
          .lessBrand{
              display: block;
              position: absolute;
              bottom: 3px;
              right: 15px;
          }


      }
  }
  @media (max-width: $screen-xs-max){
        .container{
            padding: 0;
        }
        .bandeauBrand{
            padding-bottom: 20px;
        }
        .brandList{
            padding: 0;
            .brand{
                &:nth-child(3n+1){
                    &:before{
                        display: none;
                    }
                }
                &:nth-of-type(3), &:nth-of-type(2){
                    &:before{
                        height: 90%;
                    }
                }
                &:nth-child(-n+3){
                    display: block;
                }
                &:nth-of-type(3){
                   margin-bottom: 0;
                }

                &:nth-of-type(5){
                    &:before{
                        height: 100%;
                    }
                }
                &:nth-of-type(4){
                    display: none;
                }
                &:nth-of-type(11){
                    &:after{
                        background-color: $bleuEm;
                        height: 100%;
                    }
                }
                &:nth-of-type(13){
                    &:before{
                        display: block;
                    }
                }
            }
        }
        .bandeauBrand.active{
            .brand{
                &:nth-of-type(4){
                    display: block;
                }
            }
        }
    }
}


