.car-slider {
    display: grid;
    justify-items: stretch;
    line-height: 0;
    width: 100%;

    grid-template-columns: 100%;
    grid-template-areas: "slider-360-access" "main" "aside";
    grid-template-rows: 1fr;

    @media (min-width: $screen-md-max) {
        grid-template-columns: calc(100% - 233px) 233px;
        grid-template-rows: 1fr 394px;
        grid-template-areas: ". slider-360-access" "main aside";
    }

    &.one-picture {
        grid-template-columns: 100%;
        grid-template-areas: "slider-360-access" "main" "aside";
        grid-template-rows: 1fr;
    }

    .slide {
        &:first-child {
            display: block;
        }

        display: none;
    }

    &.slick-initialized .slide {
        display: block;
    }

    > div {
        position: relative;

        &.slider-main {
            grid-area: main;

            > .auto-plus {
                position: absolute;
                right: 0;
                bottom: 3px;

                img {
                    width: 120px;
                    @media (max-width: $screen-md-max) {
                        width: 80px;
                    }
                    @media (max-width: $screen-xs-max) {
                        width: 50px;
                    }
                }
            }
            > .badge-satisfait {
                position: absolute;
                right: -30px;
                bottom: -30px;
                img {
                    width: 210px;
                    @media (max-width: $screen-md-max) {
                        width: 190px;
                    }
                    @media (max-width: $screen-xs-max) {
                        width: 130px;
                    }
                }
            }

            .slide {
                position: relative;
                height: 100%;

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }

                .container-btn {
                    position: absolute;
                    bottom: 10px;
                    left: 10px;
                    z-index: 2;
                    margin: 0;

                    .btn {
                        background: #ffffff;
                        box-shadow: 0 0 10px rgba(0, 0, 0, .3);
                    }
                }

                @media (max-width: $screen-xs-max) {
                    .container-btn {
                        bottom: 5px;
                        left: 5px;

                        .btn {
                            font-size: 10px;
                        }
                    }
                }
            }
        }

        &.slider-aside {
            grid-area: aside;
            display: flex;
            flex-direction: column;

            .sliderThumb {
                display: none;
                @media (min-width: $screen-md-max) {
                    display: block;
                }
                flex: auto 1 1;
            }
        }

        &.slider-360-access {
            grid-area: slider-360-access;

            > div {
                display: flex;

                button {
                    flex: auto 1 1;
                    display: flex;
                    font-size: .9rem;
                    padding: 2px;
                    justify-content: center;
                    align-items: center;
                    gap: 3px;

                    img {
                        width: 30px;
                        margin: 2px;
                    }

                    i.logo-360 {
                        width: 30px;
                        height: 16px;
                        background: url('../../../../resources/front/images/360/btn_white_360.svg') no-repeat center center;
                        background-size: contain;
                    }

                    &.active {
                        background: #ffffff !important;
                        color: $bleuEm !important;
                        //img {
                        //    filter: invert(14%) sepia(10%) saturate(7493%) hue-rotate(212deg) brightness(101%) contrast(102%);
                        //}
                        i.logo-360 {
                            background: url('../../../../resources/front/images/360/btn_white_360_bleu_em.svg') no-repeat center center;
                        }
                    }
                }
            }
        }
    }

    #sliderDiapo {
        .slick-prev, .slick-next {
            @media (max-width: $screen-md-max) {
                width: 20px;
            }
        }
    }

    @import "diaporama-fiche-vehicule/convertion-prime";
    @import "diaporama-fiche-vehicule/slider-diapo-refactory";
    @import "diaporama-fiche-vehicule/slider-thumb-fiche-vehicule";

}

.slick-h-100 {
    height: 100%;

    .slick-list, .slick-track, .slick-slide > div {
        height: 100%;
    }
}

@import "diaporama-fiche-vehicule/modale-vue-360";
