.carteVehicule.carteDevisVehicule{
    margin-bottom: 2rem;
    padding: 0;
    @media (max-width: $screen-xs-max){
        padding-bottom: 4rem;
        &.expiredQuotationCard{
            padding-bottom: 0;
            margin-bottom: 3rem;
        }
    }
    &.listeCarte {
      .whiteCard {
          padding: 0;
          display: flex;
          @media (max-width: $screen-xs-max){
              flex-wrap: wrap;
              position: relative;
          }
          .carteImg{
              width: 30%;
              @media (max-width: $screen-xs-max){
                  width: 100%;
              }
              img{
                  height: 130px;
              }
          }
          .carteMmv{
              padding: 1rem 2rem 1rem 0;
              width: 25%;
              @media (max-width: $screen-xs-max){
                  width: 100%;
                  padding: 0 1rem 1rem;
              }
              p{
                  font-size: 2rem;
                  margin: 0;
              }
              .version{
                  color: $gris1;
                  font-size: 1.4rem;
              }
          }
          .cardPrice{
              padding: 0 1rem;
              width: 20%;
              @media (max-width: $screen-xs-max){
                  order: 1;
                  width: 100%;
                  padding: 0;
              }
          }
          .containerPrice{
              margin-top: 0;
              height: 100%;
              flex-direction: column;
              justify-content: center;
              width: 100%;
              padding: 1rem;
              @media (max-width: $screen-xs-max){
                  flex-direction: row;
                  align-items: center;
              }
              .remiseCarteVehicule{
                  left: 0;
              }
              .price{
                  margin-bottom: 0.5rem;
              }
              .price, .containerFundingPrice{
                  padding: 0;
                  width: 100%;
              }
              .containerFundingPrice{
                  .month{
                      font-size: 1.1rem;
                  }
              }
              &.promoContainer{
                  padding: 1rem 1rem 1rem 2.5rem;
                  @media (max-width: $screen-sm-max){
                      padding: 1rem 1rem 1rem 3rem;
                      .price{
                          font-size: 1.2em;
                      }
                      .containerFundingPrice{
                          font-size: 1em;
                          .month{
                              font-size: 0.85rem;
                          }
                      }
                  }
                  @media (max-width: $screen-xs-max){
                      margin-top: 0.5rem;
                      padding: 1rem;
                      .price{
                          padding-right: 3.5rem;
                          font-size: 1.4em;
                      }
                      .containerFundingPrice{
                          padding-left: 3.5rem;
                          font-size: 1.4em;
                          .month{
                              font-size: 1.1rem;
                          }
                      }
                      .remiseCarteVehicule{
                          left: 50%;
                      }
                  }
              }
          }
        .deleteCard{
          $tailleBadge : 0.85em;
          @include deleteCard($tailleBadge);
          right: -10px;
          top: -10px;
        }
      }
        .btnSimilarCars{
            font-size: 1.5rem;
        }
    }

    .containButtonCarteDevis{
        width: 25%;
        padding: 1rem 1rem 1rem 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @media (max-width: $screen-xs-max){
            width: 100%;
            padding: 1rem;
            .expiredQuotation{
                display: none;
            }
        }
    }
    .containGris1{
        display: flex;
        justify-content: space-between;
        @media (max-width: $screen-xs-max){
            position: absolute;
            bottom: -36px;
            right: 0;
            width: 100%;
        }
        .btn{
            padding: 1rem 0.5rem;
            font-size: 1.2rem;
            @media (max-width: $screen-lg-max){
                font-size: 1rem;
            }
            @media (max-width: $screen-xs-max){
                padding: 1rem;
            }
            &.btnSeeCar{
                width: 65%;
                @media (max-width: $screen-xs-max){
                    order: 1;
                    width: auto;
                }
            }
            &.btnPrint{
                width: 30%;
                @media (max-width: $screen-xs-max){
                    width: auto;
                }
            }
        }
    }
}
