@mixin rubanDispo($couleur) {
  width: 85px;
  height: 88px;
  overflow: hidden;
  position: absolute;
  top: -3px;
  left: -3px;

  .rubanDispo {
    font: bold 1em Sans-Serif;
    color: #333;
    text-align: center;
    text-shadow: rgba(255, 255, 255, 0.5) 0px 1px 0px;
    transform: rotate(-45deg);
    //@include transform(rotate(-45deg)); // since webpack
    position: relative;
    padding: 7px 0;
    right: -5px;
    top: 15px;
    width: 120px;
    background-color: $couleur;
    color: $blanc;
    @include shadowText(0.3);
    float: right;
    @include shadowBox(0.3);
    span {
      font-size: 0.9em;
    }
    &:before, &:after {
      content: "";
      border-top: 3px solid darken($couleur, 10%);
      border-left: 3px solid transparent;
      border-right: 3px solid transparent;
      position: absolute;
      bottom: -3px;
    }
    &:before {
      left: 0;
    }
    &:after {
      right: 0;
    }
  }
}


@mixin rubanRemise($couleur){
  position: absolute;
  z-index: 2;
  width: calc(100% + 10px);
  left: -5px;
  top: -0.5em;
  line-height: 2em;
  background-color: $couleur;
  color: $blanc;
  @include shadowText(0.2);
  @include shadowBox(0.2);

  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  @media only screen {
    @media(max-width: $screen-xs-max) {
      width: 100%;
      left : 0;
    }
  }
  &:before {
    @media only screen {
      @media(max-width: $screen-xs-max){
        display: none;
      }
    }
    content: "\A";
    border-style: solid;
    border-width: 0px 5px 5px 0;
    border-color: transparent darken($couleur , 10%) transparent transparent;
    position: absolute;
    left: 0;
    bottom: -5px;
  }
  &:after {
    @media only screen {
      @media(max-width: $screen-xs-max) {
        display: none;
      }
    }
    content: "\A";
    border-style: solid;
    border-width: 0px 5px 5px 0;
    border-color: transparent darken($couleur, 10%) transparent transparent;
    position: absolute;
    right: 0;
    bottom: -5px;
    transform: rotate(-90deg);
  }
}

@mixin simpleRibbon($color, $rotation:-45deg) {
  transform: rotate($rotation);
  background-color: $color;
  width: 100%;
  text-align: center;
  padding: 5px 10px;
}
