/*doc
---
title: Boutons
name: buttons
category: basics
---
```html_example

    <div class='btn btn-vert'>Bouton</div>
    <div class='btn btn-bleu'>Bouton</div>
    <div class='btn btn-jaune'>Bouton</div>
    <div class='btn btn-orange'>Bouton</div>
    <div class='btn btn-rose'>Bouton</div>
    <div class='btn btn-gris1'>Bouton</div>
    <div class='btn btn-gris2'>Bouton</div>
    <div class='btn btn-gris3'>Bouton</div>
    <div class='btn btn-blanc'>Bouton</div>
    <div class='btn btn-bleuEM'>Bouton</div>

```
*/

@each $couleurBtn in $tabCouleurs {
  .btn.btn-#{nth($couleurBtn, 1)} {
    @include button-variant($blanc, nth($couleurBtn, 2), nth($couleurBtn, 2));
    &.noHover {
      &:hover, &:focus {
        background-color: nth($couleurBtn, 2);
        border-color: nth($couleurBtn, 2);
      }
    }
  }
}

@each $couleurBtn in $tabCouleurs {
    .btn.btn-revert-#{nth($couleurBtn, 1)} {
        background-color: #ffffff;
        color: nth($couleurBtn, 2);
        font-weight: 500;
        border-color: nth($couleurBtn, 2);
        border-width: 2px;
        text-shadow: unset;
        &:hover, &:focus {
            background-color: nth($couleurBtn, 2);
            color: #ffffff;
            border-color: nth($couleurBtn, 2);
        }
    }
}

.btn.btn-gris3,
.btn.btn-gris4 {
  color: $gris1;
  text-shadow: none;
}

.btn.btn-gris4 {
  border-color: $gris3;
}

.btn.btn-blanc {
  color: $bleuEm;
  text-shadow: none;
  &:hover, &:focus {
    color: $bleuEm;
  }
}


.btn.btn-vert {
  color: $blanc;
  text-shadow: none;
}

/*doc
---
title: test
name: test
category: CSS
---
```html_example

    <div class='btn btn-vert'>Bouton</div>
    <div class='btn btn-bleu'>Bouton</div>
    <div class='btn btn-jaune'>Bouton</div>
    <div class='btn btn-orange'>Bouton</div>
    <div class='btn btn-rose'>Bouton</div>
    <div class='btn btn-gris1'>Bouton</div>
    <div class='btn btn-gris2'>Bouton</div>
    <div class='btn btn-gris3'>Bouton</div>
    <div class='btn btn-blanc'>Bouton</div>
    <div class='btn btn-bleuEM'>Bouton</div>

```
*/
