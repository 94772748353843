.containPlanAcces {
  .containCoordonneesPlanAcces{
    @include box-shadow(inset 6px 0px 6px -6px rgba(0, 0, 0, 0.3));
    @media only screen {
      @media(max-width: $screen-xs-max) {
        @include shadowBoxInset(0.3);
        padding-top: 0.5em;
        text-align: center;
      }
    }
  }
  #map{
    @media only screen{
      @media(max-width: $screen-xs-max){
        height: 250px;
      }
    }
  }

  .btnFormulairePlanAcces{
    margin-top: 1em;
  }
}