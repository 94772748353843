.containIndexReprise{
  .slider.slider-horizontal {
    top: 8px;

  }

  .btn {
    white-space: nowrap;
  }

  .input-group{
    .jcf-select {
      width: 100%;
      margin: 0;
    }
  }


}


