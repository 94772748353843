.containCartesAccessoires {
    label {
      text-align: center;
      padding: 0;
      width: 100%;
      display: block;
    p {
      position: absolute;
      width: 100%;
      left: 0;
      top: auto;
      right: auto;
      text-align: center;
      bottom: 0.5em;
    }

  }
  .greyCard {
    margin-bottom: 2em;
    padding: 0.5em;
    display: table;
    img {
      vertical-align: top;
      width: 100%;
      height: auto;
      display: block;
      padding-left: 0;
    }
    .shortDesc {
      //display: inline-block;
      //@media only screen {
      //  @media(max-width: $screen-sm-max) {
      //    width: calc(100% - 11em);
      //    display: inline-block;
      //    margin-left: 1em;
      //  }
      //}
      .tooltip {
        position: fixed;
      }
      .tooltip-inner {
        z-index: 9999;
      }
    }

    .jcf-checkbox {
      position: absolute;
      top: calc(100% - 13px);
      left: calc(50% - 13px);
    }
  }
}
