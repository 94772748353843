@import "../../../../../styles/sass/variables/couleurs";

.refactory-container {
  font-size: 1.3em;
  font-weight: bold;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 9;

  #refactory-row{
    position: relative;
    color: $bleuEm;
    text-align: center;

    #refactory-title{
      background-color: $bleuEm;
      color: $blanc;
    }

    div{
      height: 50px;
      //line-height: 50px;
      padding-left: 0;
      padding-right: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: $screen-xs-max) {
        height: 15px;
        padding: 0.8rem 0;
      }
    }
  }

  @media (max-width: $screen-xs-max) {
    font-size: 0.6em;
  }
}
