@import url(~leaflet/dist/leaflet.css);
@import url(~leaflet-routing-machine/dist/leaflet-routing-machine.css);

.localisationPage {
  h2 {
    font-size: 1.6em;
    margin-top: 20px;
    margin-bottom: 20px;
    text-transform: inherit;
    font-weight: bold;
  }

  h3 {
    font-size: 1.15em;
    margin-top: 45px;
    margin-bottom: 20px;
    text-transform: inherit;
  }

  p {
    padding-left: 70px;
    padding-right: 70px;

    @media only screen and (max-width: 767px) {
      padding: 0;
    }
  }

  #map {
    height: 301px;
    z-index: 1;

    .leaflet-routing-container {
      display: none;
    }

    @media only screen and (max-width: 1300px) {
      height: 334px;
    }
  }

  .whiteCard {
    &.infosAgency {
      padding: 15px;
      margin-bottom: 20px;
      margin-top: 30px;
      @media (max-width: $screen-xs-max){
          margin-top: 0.5rem;
      }
      a.contactInfos{
          color: $bleu;
      }
      .y-separator {
        height: 205px;
        border-left: 1px solid $bleuEm;
        margin-top: 20px;
      }

      .callDemandeDeRappel {
        @media only screen and (max-width: 767px) {
            margin-bottom: 15px;
        }
      }
    }

    .titleWhitecard {
      font-size: 1.6em;
      margin-bottom: 20px;

      @media only screen and (max-width: 767px) {
        margin-bottom: 0;
        margin-top: 20px;
      }
    }

    ul {
      @media only screen and (max-width: 767px) {
        margin-bottom: 25px;
      }

      li {
        background-color: $gris4;
        margin: 4px 0;
        padding: 1px 10px;
        font-size: 1.15em;
      }

      .active {
        background-color: $bleuEm;
        color: $blanc;
      }
    }

    .btn {
      @media only screen and (max-width: 1300px) {
        font-size: 0.85em;
        font-weight: bold;
      }
    }

    @media only screen and (max-width: 992px) {
      margin-top: 15px;
      margin-bottom: 15px;
    }

    .carteInfos {
      p {
        padding: 0;
      }
    }
  }

  .topBrands {
    padding-left: 70px;
    padding-right: 70px;

    @media only screen and (max-width: 767px) {
      padding: 0;
    }

    a {
      font-size: 1.4em;
      margin: 8px 0;
      color: $bleuEm;
    }
  }

  .cardBusinessUnit {
    padding: 0 5.2em;

    @media(max-width: $screen-md-max) {
      padding: 0 2.3em;
    }

    @media only screen and (max-width: 991px) {
      margin-top: 20px;
    }

    img {
      border: 1px solid $bleuEm;
      border-radius: 10px;
      width: 100%;
      margin-bottom: 15px;
    }

    h3 {
      margin: 0 0 8px 0;
      font-size: 1.3em;
    }

    .distance {
      background-color: $bleuEm;
      color: $blanc;
      font-size: 0.8em;
      padding: 3px 11px;
      border-radius: 15px;
      margin-top: 8px;
      display: inline-block;

      &.selected {
        background-color: $vert;
        color: $bleuEm;
      }
    }
  }

  .othersAgencies {
    margin-top: 40px;
    margin-bottom: 45px;
    padding: 15px;
  }
}

@media (max-width: $screen-xs-max){
    #descriptionType{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 0;
        &.active{
            white-space: initial;
        }
    }
    #readMoreDesc{
        text-align: center;
        .icon-arrow-down, .icon-arrow-up{
            margin-left: 0.25rem;
        }
    }
}

.regionList {
  margin-top: 30px;

  h2 {
    font-size: 2.5rem;

    @media only screen and (max-width: 767px) {
        font-size: 2.2rem;
      }
  }

  a {
    font-size: 1.4em;
    margin: 8px 0;
    opacity: 0.6;

    @media only screen and (max-width: 767px) {
      font-size: 1.5rem;
    }
  }



}



.containerLocalisationCard {
  margin-top: 10px;
    @media (max-width: $screen-xs-max){
        &:first-child{
            margin-top: 25px;
        }
    }
}

.localisationCard{
  position: relative;
  min-height: 0px;
  border-radius: 10px;
  background: #fff;
  border-bottom: 0px solid #22286d;
  margin: 5px 0;
  padding-bottom: 0px;
  display: block;
  -moz-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  color: #fff;
}
.cityname{
  position: absolute;bottom:10%;
  right: 0;
  left: 0;
  text-align: center;
  border-radius: 20px;
  padding: 10px 30px;
}
.nameregion{
  font-size: 2rem
}
.cityfont{
  font-size: 2.5rem;
}

.background_1{
  height: 230px;    border-radius: 10px;
}
.background_2{
  background: linear-gradient(rgba(34,37,109,0.3), rgba(34,40,109,0.8));    height: 230px;    border-radius: 10px;  transition: 0.2s;
}
.thumbnaillocalisation{
  background-position: center;
  background-size: cover;
}
.background_2:hover {
  background: linear-gradient(rgba(255,255,255,0.3), rgba(255,255,255,0.8));    height: 230px;    border-radius: 10px; transition: 0.2s;
}

.localisationPage{
    .carteVehicule{
        .typeVehiculeCarte{
            padding: 0;
        }
        .containerCard{
            margin: 0;
        }
    }
}
