.topBlog {
  position: relative;
  padding: 0;
  margin-top: 59px;
  z-index: 2;
  overflow: hidden;
    @media(max-width: $screen-xs-max){
        margin-top: 65px;
    }
  .bgTop {
    height: 500px;
    background-position: center;
    background-size: cover;

    @media(max-width: $screen-md-max) {
      height:300px;
    }

    @media(max-width: $screen-xs-max) {
      height:100px;
    }
  }

  img {
    width: 100%;
  }

  .titleBlog {
    position: relative;
    background: $blanc;
    padding: 60px 100px 0 100px;
    width: 800px;
    margin: -50px auto 30px auto;
    border-bottom: 5px solid $bleuEm;
    @include shadowBox(0.1);

    @media(max-width: 1300px) {
      width: 60%;
    }

    @media(max-width: $screen-sm-max) {
      padding: 15px 15px 0 15px;
      margin-top: -30px;
      width: 95%;
    }


    h1 {
      font-size: 3.35em;
      text-transform: inherit;

      @media(max-width: $screen-sm-max) {
        font-size: 1.8em;
      }
    }

    h2, .articleContent {
      font-size: 1.9em;
      text-transform: inherit;

      @media(max-width: $screen-sm-max) {
        font-size: 1.2em;
      }
    }

    .date {
      color: $gris1;
      font-size:1.5em;

      @media(max-width: $screen-md-max) {
        font-size: 1.2em;
      }
    }

    .category {
      position: relative;
      bottom: -30px;
      border-radius: 30px;
      background: $bleuEm;
      padding: 12px 30px;
      color: $blanc;
      margin: auto;
      display: inline-block;
      border: 3px solid $blanc;
      font-size: 1.5em;

      @media(max-width: $screen-sm-max) {
        font-size: 1em;
        padding: 5px 30px;
        bottom: -11px;
        display: inline;
      }
    }
  }

}

.topBlog {

  .homeBlog {
    padding: 45px 115px;

    @media(max-width: $screen-sm-max) {
      padding: 15px;
    }
  }
}



.carouselActu{
  .carousel-inner{
    margin: 0;
    .item{
      padding: 0;
      .containerArticleCard{
        &:first-child{
          padding-left: 0;
          @media(max-width: 992px) {
            padding-left: 10px;
          }
        }
        &:last-child{
          padding-right: 0;
          @media(max-width: 992px) {
            padding-right: 10px;
          }
        }

      }
    }
  }
  a.carousel-control{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 40px;
    height: 40px;
    background-color: #fff;
    background-image: none;
    opacity: 1;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    border: 1px solid #d6d6d6;
    margin-top: -20px;
    @media (max-width: $screen-sm-max){
        top: 100px;
    }
    @media (max-width: $screen-xs-max){
      width: 30px;
    }
    span.arrow{
      color: #abafb1;
      @media(max-width: $screen-xs-max){
        font-size: 14px;
      }
      &:before{
        line-height: 40px;
        font-size: 16px;
        text-shadow: initial;
      }
    }
    span.arrow-left{

      &::before{
        content: '〈';
        padding-right: 8px;
      }
    }
    span.arrow-right{
      &::before{
        content: '〉';
        padding-left: 8px;
      }
    }
  }
  a.left{
    left: 0;
    margin-left: -55px;
    @media (max-width: $screen-md-max) {
      margin-left: 10px;
    }
  }
  a.right{
    right: 0;
    margin-right: -55px;
    @media (max-width: $screen-md-max) {
      margin-right: 10px;
    }
  }
}
.containArticleBlog{
    @media(max-width: $screen-md-max){
        margin-top: 2em;
    }
    .item.active{
        display: flex;
        @media (max-width: $screen-sm-max){
            display: block;
        }
    }
}


.containerArticleCard {
    margin: 10px 0 50px;
  .articleCard {
    position: relative;
    min-height: 528px;
    height: 100%;
    background: #fff;
    border-bottom: 3px solid $bleuEm;
    padding-bottom: 4rem;
    display: block;
    @include shadowBox(0.2);

    @media (max-width: $screen-md-max){
        min-height: 540px;
    }
    @media(max-width: $screen-sm-max) {
      margin: 0;
      margin-bottom: 2em;
      min-height: inherit;
    }
    .thumbnailArticle {
      height: 300px;
      width: 100%;
      object-fit: cover;
      object-position: center;

      @media(max-width: $screen-sm-max) {
        height: 150px;
      }
    }

    .category {
      border-radius: 20px;
      background: none;
      border: 2px solid $bleuEm;
      padding: 0.5rem 1rem;
      color: $bleuEm;
      transition: background 0.2s;
      font-size: 0.7em;

      @media(max-width: $screen-sm-max) {
        display: none;
      }
    }
    .containerInfos{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    h3 {
      font-size: 1.5em;
      text-transform: inherit;
      height: 54px;
      display: flex;
      align-items: center;
      margin-bottom: 1.5rem;
      @media (max-width: $screen-sm-max){
          height: auto;
      }
    }

    p.description {
      color: $gris5;
       height: 57px;
       @media (max-width: $screen-md-max){
          height: 80px;
       }
       @media (max-width: $screen-sm-max){
           height: auto;
       }
    }

    .dateArticle {
      color: $bleuEm;
      opacity: 0.7;
      font-size: 0.8em;
    }

    &.articleCardHome {
      margin-top: 0;
    }
  }
  .ctaBlog{
    background: $bleuEm;
    position: absolute;
    left: 50%;
    bottom: -12px;
    transform: translateX(-50%);
    color: #ffffff;
    border: 2px solid rgba($bleuEm, 0);
    padding: 1rem 3rem;
    text-transform: uppercase;
    font-weight: bold;
    white-space: nowrap;
  }
  &:hover {
    .ctaBlog{
        background: #ffffff;
        color: $bleuEm;
        border: 2px solid $bleuEm;
    }
    h3 {
        opacity: 0.7;
    }
  }

}

.btnMoreArticles {
  text-transform: inherit;
  margin-bottom: 20px;
  padding-left: 30px;
  padding-right: 30px;
  border: 3px solid $bleuEm;
  color: $bleuEm;
    &:hover{
        background: $bleuEm;
        color: #ffffff;
        border: 3px solid rgba($bleuEm, 0);
    }
}

.paginationBlog {
  margin: 35px 0 50px 0;

  @media(max-width: $screen-sm-max) {
    margin: 15px 0;
  }
    .arrow-prev{
        margin-right: 1rem;
    }
    .arrow-next{
        margin-left: 1rem;
    }
  a, span {
    color: $bleuEm;
    border: 1px solid $gris2;
    background: $blanc;
    width: 35px;
    height: 35px;
    display: inline-block;
    text-align: center;
    line-height: 2.3em;
    &:hover {
      color: $bleuEm;
      border: 1px solid $bleuEm;
    }
  }

  span.selected {
    background: $bleuEm;
    color: $blanc;
    cursor: default;

    &:hover {
      color: $blanc;
    }
  }
}

.articleBody {
  position: relative;

  .articlePage {
    margin-bottom: 100px;

    @media(max-width: $screen-sm-max) {
      margin-bottom: 50px;
    }

    h2 {
      padding: 15px 90px 0 90px;
      font-size: 2.15em;
      font-weight: 700;

      @media(max-width: $screen-sm-max) {
        padding: 15px 15px 0 15px;
        font-size: 1.25em;
      }
    }
    a{
        color: #1c3ef2;
        text-decoration: underline;
    }
    p {
      font-size: 1.15em;
      font-weight: 300;
      padding: 15px 90px 0 90px;

      @media(max-width: $screen-sm-max) {
        padding: 15px 15px 0 15px;
      }
    }

    img {
      width: 100%;
    }

    iframe {
      margin: 30px auto;
      display: block;
    }

    ul {
      padding-left: 150px;
      padding-right: 90px;
      font-size: 1.15em;
      font-weight: 300;

      @media(max-width: $screen-sm-max) {
        padding-left: 30px;
      }
    }
  }

  // Partage fixe
  .shareArticle {
    position: fixed;
    display: block;
    top: 330px;
    margin-left: -140px;
    display: none;

    @media(max-width: 1750px) {
      margin-left: -100px;
    }


    @media(max-width: 1600px) {
      position: relative;
      margin-left: inherit;
      top: inherit;
      z-index: 1;
    }

    @media(max-width: $screen-sm-max) {
      width: 100%;
      display: inline-block;
      z-index: -1;
    }

    .titleShare {
      font-size: 0.7em;
      display: inline-block;

      @media(max-width: 1600px) {
        font-size: 1.1em;
      }
    }

    .verticalLign {
      height: 10px;
      width: 1px;
      background: $bleuEm;
      margin: 10px auto;

      @media(max-width: 1600px) {
        height: 1px;
        width: 20px;
        margin-bottom: 0;
      }
    }

    span {
      @media(max-width: 1600px) {
        display: inline-block;
        margin: 0 10px;
      }

      i {
        display: block;
        font-size: 1.6em;
        margin: 20px 0;
        transition: opacity 0.2s;

        &:hover {
          opacity: 0.5;
        }

        @media(max-width: 1600px) {
          margin-top: 15px;
        }
      }
    }

    &.fixedShareArticle {
      position: absolute;
      bottom: 40px;
      top: inherit;
    }

    &.fixedTopShareArticle {
      position: absolute;
      top: inherit;
    }

    &.bottomShareArticle {
      display: inline-block!important;
      width: 100%;
    }
  }
}

// Page de creation d'articles
.createArticlePage {
  input, textarea {
    width: 100%;
    box-shadow: none;
    border: 1px solid $gris2;
    height: 35px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    margin-bottom: 15px;
    padding-left: 10px;
  }

  textarea {
    min-height: 100px;
  }

  .d-none {
    display: none;
  }
}

.articleBody {
  position: relative;

  .articlePage {
    margin-bottom: 100px;

    @media(max-width: $screen-sm-max) {
      margin-bottom: 50px;
    }

    h1,h2,h3,h4,h5,h6 {
      font-weight: 700;
      padding: 15px 90px 0 90px;
      @media(max-width: $screen-sm-max) {
        padding: 15px 15px 0 15px;
      }
    }

    h1 {
      font-size: 2.5em;
      @media(max-width: $screen-sm-max) {
        font-size: 1.45em;
      }
    }

    h2 {
      font-size: 2.15em;
      @media(max-width: $screen-sm-max) {
        font-size: 1.25em;
      }
    }

    h3 {
      font-size: 1.75em;
      @media(max-width: $screen-sm-max) {
        font-size: 1.15em;
      }
    }

    h4 {
      font-size: 1.5em;
      @media(max-width: $screen-sm-max) {
        font-size: 1.05em;
      }
    }

    h5 {
      font-size: 1.3em;
      @media(max-width: $screen-sm-max) {
        font-size: 0.95em;
      }
    }

    h6 {
      font-size: 1.2em;
      @media(max-width: $screen-sm-max) {
        font-size: 0.85em;
      }
    }

    p, span {
      font-size: 1.15em;
      font-weight: 300;
      padding: 15px 90px 0 90px;

      @media(max-width: $screen-sm-max) {
        padding: 15px 15px 0 15px;
      }
    }

    img {
      width: 100%;
      max-width: 100%;
      min-width: 100%;
      @include shadowBox(0.2);
      border-radius: 20px;
    }

    @media(max-width: $screen-xs-max) {
      iframe {
        max-width: 100%;
        height: auto;
      }
    }
    iframe {
      margin: 30px auto;
      display: block;
    }

    ul {
      padding-left: 150px;
      font-size: 1.15em;
      font-weight: 300;

      @media(max-width: $screen-sm-max) {
        padding-left: 30px;
      }
    }
  }

  // Partage fixe
  .shareArticle {
    position: fixed;
    display: block;
    top: 330px;
    margin-left: -140px;
    display: none;

    @media(max-width: 1750px) {
      margin-left: -100px;
    }


    @media(max-width: 1600px) {
      position: relative;
      margin-left: inherit;
      top: inherit;
      z-index: 1;
    }

    @media(max-width: $screen-sm-max) {
      width: 100%;
      display: inline-block;
      z-index: -1;
    }

    .titleShare {
      font-size: 0.7em;
      display: inline-block;

      @media(max-width: 1600px) {
        font-size: 1.1em;
      }
    }

    .verticalLign {
      height: 10px;
      width: 1px;
      background: $bleuEm;
      margin: 10px auto;

      @media(max-width: 1600px) {
        height: 1px;
        width: 20px;
        margin-bottom: 0;
      }
    }

    span {
      @media(max-width: 1600px) {
        display: inline-block;
        margin: 0 10px;
      }

      i {
        display: block;
        font-size: 1.6em;
        margin: 20px 0;
        transition: opacity 0.2s;

        &:hover {
          opacity: 0.5;
        }

        @media(max-width: 1600px) {
          margin-top: 15px;
        }
      }
    }

    &.fixedShareArticle {
      position: absolute;
      bottom: 40px;
      top: inherit;
    }

    &.fixedTopShareArticle {
      position: absolute;
      top: inherit;
    }
  }
}

// Page de creation d'articles
.createArticlePage {
  input, textarea {
    width: 100%;
    box-shadow: none;
    border: 1px solid $gris2;
    height: 35px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    margin-bottom: 15px;
    padding-left: 10px;
  }

  textarea {
    min-height: 100px;
  }

  .d-none {
    display: none;
  }

  .card-header {
    &.note-toolbar {
      .dropdown-menu {
        min-width: 190px;
      }
    }
  }
}
