.card{
  margin: $grid-gutter-width/2 0;
  padding: $grid-gutter-width / 2;
}

.whiteCard {
  @include whiteCard;
}

.greyCard {
  @include greyCard;
}

.bleuEMCard{
  @include bleuEMCard;
}