
.datepicker{
  table{
    tr{
      td{
        span{
          &.active{
            background-color: $bleuEm;
            background-image: none;
            &.active{
              &.active {
                background-color: $bleuEm;
                background-image: none;
              }
            }
          }
        }
        &.active {
          &:hover{
            &:hover, &.active{
              background-color: $bleuEm;
              background-image: none;
            }
          }
          &.active {
            background-color: $bleuEm;
            background-image: none;
          }
          background-color: $bleuEm;
          background-image: none;

        }
        &.today, &.today:hover, &.today:hover:hover{
          background-color: $gris3;
          background-image: none;
          color: $bleuEm;
        }
      }
    }
  }
}