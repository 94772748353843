#containAdminPanelOnglet, #containDevPanelOnglet {

    #accordionAdminPanel, #accordionDevPanel {
        position: fixed;
        bottom: 0;
        z-index: 1051;
        margin-bottom: 0;
        width: 100%;
        .adminPanelOnglet {
            background-color: $bleuEm;
            &:after {
                font-family: EM-icomoon;
                content: '\f106';
                position: absolute;
                right: 0.5em;
                top: 0;
                line-height: 2em;
            }
        }
        .adminPanelOnglet, .webPanelOnglet {
            position: absolute;
            z-index: -1;
            padding: 0.5em 2em 0.25em 0.5em;
            top: -2em;
            color: $blanc;
            @include shadowBox(0.3);
            white-space: nowrap;
            text-transform: uppercase;
            font-weight: 700;
            cursor: pointer;
        }
        .webPanelOnglet {
            left: 12em;
            padding-right: 0.5em;
            background-color: $orange;
        }
        #collapseAdminPanel, #collapseDevPanel {
            padding: 0 0 1em;
            background-color: $bleuEm;
            color: $bleuEm;
            border-top: 3px solid $bleuEm;
            @include shadowBox(0.3);
            .btn {
                margin: 1em 0 0;
            }
        }
        #collapseDevPanel {
            z-index: 200;
            #supportCreationPanel {
                color: $blanc;
                padding: 1em;
            }
            .jcf-select{
                margin-bottom: 0;
                color: $bleuEm;
            }
        }
    }

}
