.containAsideButtons {
    .btn {
      width: 33.33333%;
      box-sizing: border-box;
      padding: 5px 0;

      @include shadowBox(0.3);
      &:not(:last-child) {
        width: calc(33.33333% - 0.5em);
        margin-right: 0.5em;

      }
      @media only screen {
        @media(min-width: $screen-md-min) and (max-width: $screen-md-max) {
          width: 100%;
          &:not(:last-child) {
            width: 100%;
            margin-right: 0em;
            margin-bottom: 0.5em;
          }
        }
        @media(max-width: $screen-xs-max) {
          &:last-child{
            display: none;
          }
          &:not(:last-child) {
            width: calc(50% - 0.25em);
            margin-right: 0em;
          }
          &:nth-child(1){
            margin-right: 0.25em;

          }
          &:nth-child(2){
            margin-left: 0.25em;
          }

        }
      }
      @media (max-width: $screen-xs-max){
        &:not(:last-child){
          margin-right: 0.3em;
          width: calc(50% - 0.5em);
        }
        &.suivreVehicule{
          float:left;
        }

      }
      @media (max-width: $screen-xs-max){
        width: calc(32%);
      }
      &.btn-rrssb{
        width: 100%;
      }
    }
    .container-rssb{
      margin-right: 0.5em;
      width: calc(33.33333% - 0.5em);
      @media (max-width: $screen-md-max){
        width: 100%;
        margin-right: 0;
        padding: 0;
      }
      @media (max-width: $screen-sm-max){
        margin-right: 0.25em;
        width: calc(33.33333% - 0.5em);
        float: left;
      }
      @media (max-width: $screen-xs-max){
        width: 50%;
        margin-right: 0;
      }
      >.row{
        position: relative;
        @media (max-width: $screen-md-max){
          margin: 0;
        }
        .rrssb-buttons{
          display: none;
          position: absolute;
          top: 30px;
          left: 50%;
          transform: translateX(-50%);
          z-index: 1;
          justify-content: center;
          background-color: #fff;
          padding: 5px 0;
          margin: 0;
          border-radius: 35px;
          @media (max-width: $screen-xs-max){
            right: 0;
            transform: none;
            left: auto;
          }
          li{
            list-style-type: none;
            margin: 0 8px;
            border-radius: 50%;
            @media (max-width: $screen-sm-max){
              margin: 0 15px;
            }
            &.rrssb-twitter{
              background-color: $bleu;
              padding: 10px;
            }
            &.rrssb-facebook{
              background-color: $bleuEm;
              padding: 10px 12px;
            }
            &.rrssb-email{
              background-color: $gris1;
              padding: 10px;
            }
            a{
              color: #fff;
            }
            &:hover{
              opacity: 0.7;
              cursor: pointer;
            }
          }
          &.active{
            display: flex;
          }
        }
      }
    }
}
