// Chargement des variables avant boostrap pour permettre l'initialisation de valeurs non prévues
@import "variables/variables";

// Chargement des Fonts
@import "fonts/fonts";


// Chargement de bootstrap avant les mixins pour permettre l'override de certaines mixins


//@import "~bootstrap-sass/assets/stylesheets/_bootstrap-sprockets";
@import "~bootstrap-sass/assets/stylesheets/bootstrap";

// Chargement des mixins
@import "mixins/mixins";


//chargement de la bibliothèque slick-carousel
@import "~slick-carousel/slick/slick";
@import "~slick-carousel/slick/slick-theme";

//chargement des bibliotheques npm node_modules qui à l'origine étaient appelées par bower
@import "~select2/dist/css/select2.min.css";
@import "~toastr/build/toastr.min.css";
@import "~bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css";
@import "~outdated-browser/outdatedbrowser/outdatedbrowser";
//@import "~tipso/src/tipso.min.css";
@import "~lightgallery/dist/css/lightgallery.min.css";

@import "../../lib/jqvmap/dist/jqvmap.min.css";

@import "./lib/jcf.css";

// Mise en place des styles globaux
@import "global/global";


/********************************/
/******** Style des vues ********/
/********************************/

// Vues communes
@import "views/common/common";

// Vues composants
@import "views/components/components";

// Vues index
@import "views/intro/intro";
@import "views/index/index";
@import "views/liste-des-vehicules/liste-des-vehicules";
@import "views/fiche-vehicule/fiche-vehicule";
@import "views/compte/compte";
@import "views/marque/marque";
@import "views/nos-services/nos-services";
@import "views/articles/articles";
@import "views/commande/commande";
@import "views/plan-acces/plan-acces";
@import "views/contact/contact";
@import "views/faq/faq";
@import "views/localisation/localisation";
@import "views/qui-sommes-nous/qui-sommes-nous";
@import "views/reprise/reprise";
@import "views/errors/errors";
@import "views/sitemap/sitemap";
@import "views/terms-and-conditions/terms-and-conditions";
@import "views/recrutement/recrutement";
@import "views/fiche-vehicule/_promo-page";



/********************************/
/******* Style des plugins ******/
/********************************/

// Style des libraries utilisés
@import "lib/lib";


@import "style-guide/style-guide";
@import "lib/cookie_consent";

.vertical-align {
  display: flex;
  align-items: center;
}

.flex-center {
  justify-content: center;
}

.d-flex {
  display: flex;
}
