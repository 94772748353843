@mixin liststyle($padding: 0) {
  padding: $padding;
  li {
    list-style: none;
  }
}

@mixin listOlUlstyle($padding: 0) {
  ol, ul {
    list-style: none;
    padding: $padding;
  }
}
