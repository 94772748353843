//$baseBorderRadius: 0px, 0px; //since webpack

.btn {
  text-transform: uppercase;
  font-weight: 500;
  @include shadowText(0.3);
  //@include border-radius(0px, 0px);
  border-radius: 0px 0px;
  //@include border-radius($baseBorderRadius); //since webpack
  white-space: normal;
  height: auto;
  &.noHover{
    cursor: default;
  }
}

hr {
  margin: 2rem 0;
  border : 0;
  height : 7px;
  background : url('../../../resources/front/images/css/dots.svg') 0 0 repeat-x;
  &.small{
    zoom: 0.5;
  }
  //margin: 2rem 0;
  //border-width: 0.75rem;
  //border-image: url('../../../images/css/dots.svg') 20% round;
  //&.small{
  //  margin: 1rem 0;
  //  border-width: 0.4rem;
  //}
}

a {
  color: $bleuEm;
  &:hover, &:focus {
    text-decoration: none;
    color: $bleuEm;
  }
}

::selection {
  background: $bleuEm;
  color : $blanc;
}

::-moz-selection {
  background: $bleuEm;
  color: $blanc;
}

ul.listStyled{
  padding-left: 1em;
  list-style-type: disc;
  li{
    margin-bottom: 0.5em;
  }
}

.clear{
  clear: both;
}
