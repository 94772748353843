.loadingCarteGrise, .carComparedLoader{
  display: none;
}

.loadingFundingSimulation{
    display: none;
    color: $rose;
    //height: 80px;

   &.loadingBestPrice {
    height: 47px;
    padding-top: 6px;
  }
}

.loader-svg {
  stroke: $bleuEm;
}

.loaderListeVehicules{
  display: none;
  svg{
    height: 5em;
    width: 5em;
  }
  @media (max-width: $screen-md-max){
    position: fixed;
    top: 17.5vh;
    left: 0px;
    height: 100%;
    background-color: $grisFond;
    zoom: 0.75;
  }
  .h6 {
    font-size: 2.5em;
  }
}

.loaderAvisClients{
  display: none;
}



// SECOND LOADER POUR PAGES

.no-js #loader {
  display: none;
}

.js #loader {
  display: block;
  position: absolute;
  left: 100px;
  top: 0;
}

.waitingLoader {
    display: none;
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 200;
  background-color: #fff;
  //background: #fff url(http://i.stack.imgur.com/FhHRx.gif);
  //background-repeat: no-repeat;
  //background-position: center center;
}



.containerAnim {
  height: 150px;
  left: 50%;
  margin: -75px 0 0 -53px;
  position: absolute;
  top: 50%;
  width: 106px;
  text-align: center;
}

.cord {
  padding-top: 100px;
  width: 15px;
  transform: rotate(0deg);
  //@include transform(rotate(0deg)); //since webpack
  @include transform-origin(50% 50%);
  float: left;
}

.ball {
  background: #333;
  width: 15px;
  height: 15px;
  float: left;
  border-radius: 50%;
}

.shadows {
  clear: left;
  padding-top: 20px;
  margin-left: -2px;
}

.shadows div {
  float: left;
  margin-left: 2px;
  width: 13px;
  height: 3px;
  border-radius: 50%;
  box-shadow: 0px 0px 3px rgba(204, 204, 204, 0.3);
  background: rgba(204, 204, 204, 0.3);
}

.leftMove {
  @include animation(leftBall .5s ease-in-out 0s infinite alternate);
  //animation: leftBall ;
}

.rightMove {
  @include animation(rightBall .5s ease-in-out 0s infinite alternate);
}

.leftShadow {
  @include animation(leftShadowN .5s ease-in-out 0s infinite alternate);
}

.rightShadow {
  @include animation(rightShadowN .5s ease-in-out 0s infinite alternate);
}

@keyframes leftBall{

  0%{
    transform: rotate(0deg) translateY(0px);
    /*this pauses the ball at the begining*/
  }
  50% {
    transform: rotate(0deg) translateY(0px);

  }
  100% {
    transform: rotate(50deg) translateY(-20px);
  }
}

//@include keyframes(leftBall){
//  0% {
//    @include transform(rotate(0deg) translateY(0px));
//  }
//  /*this pauses the ball at the begining*/
//  50% {
//    @include transform(rotate(0deg) translateY(0px));
//
//  }
//  100% {
//    @include transform(rotate(50deg) translateY(-20px));
//  }
//} //since webpack

@keyframes rightBall{

  0%{
    transform: rotate(-50deg) translateY(-20px);
    /*this pauses the ball at the begining*/
  }
  50% {
    transform: rotate(0deg) translateY(0px);

  }
  100% {
    transform: rotate(0deg) translateY(0px) translateX(0px);
  }
}

//@include keyframes(rightBall){
//  0% {
//    @include transform(rotate(-50deg) translateY(-20px));
//  }
//  /*this pauses the ball at the begining*/
//  50% {
//    @include transform(rotate(0deg) translateY(0px));
//  }
//  100% {
//    @include transform(rotate(0deg) translateY(0px) translateX(0px));
//
//  }
//} //since webpack

@keyframes leftShadowN{

  0%{
    transform:translateX(0px);
    /*this pauses the ball at the begining*/
  }
  50% {
    transform: translateX(0px);

  }
  100% {
    transform: translateX(-25px);
  }
}

//@include keyframes(leftShadowN){
//  0% {
//    @include transform(translateX(0px));
//  }
//  /*this pauses the ball at the begining*/
//  50% {
//    @include transform(translateX(0px));
//  }
//  100% {
//    @include transform(translateX(-25px));
//  }
//} //since webpack


@keyframes rightShadowN{

  0%{
    transform:translateX(25px);
    /*this pauses the ball at the begining*/
  }
  50% {
    transform: translateY(0px);

  }
  100% {
    transform: translateY(0px);
  }
}

//@include keyframes(rightShadowN){
//  0% {
//    @include transform(translateX(25px));
//  }
//  /*this pauses the ball at the begining*/
//  50% {
//    @include transform(translateY(0px));
//  }
//
//  100% {
//    @include transform(translateY(0px));
//  }
//} //since webpack

/*colors*/

.cord:nth-of-type(1) .ball {
  background: $bleuEm;
}

.cord:nth-of-type(2) .ball {
  background: $vert;
}

.cord:nth-of-type(3) .ball {
  background: $bleu;
}

.cord:nth-of-type(4) .ball {
  background: $jaune;
}

.cord:nth-of-type(5) .ball {
  background: $orange;
}

.cord:nth-of-type(6) .ball {
  background: $rose;
}

.cord:nth-of-type(7) .ball {
  background: $bleuEm;
}
