.containOffre {
  margin-top: 2em;
  @media only screen {
    @media(min-width: $screen-md-min) {
      .divOffre {
        &:before, &:after {
          top: 5%;
          content: ' ';
          display: block;
          width: 50%;
          background-color: $gris3;
          height: 90%;
          position: absolute;
          z-index: 0;
          @include shadowBox(0.15);
        }
        &:not(:last-child):after {
          right: -25%;
        }
        &:not(:first-child):before {
          left: -25%;
        }
      }
    }

  }
  .divOffre {
    margin-bottom: 4em;

    .whiteCard {
      position: relative;
      z-index: 1;
      padding: 0.5em;
      text-align: center;
      //padding-bottom: 2.5em;
    }

    p {
      font-weight: 700;
    }

    .btn {
      position: absolute;
      left: 5%;
      width: 90%;
      @include shadowBox(0.3);
      white-space: normal;
    }
  }
}

