.containLignesAvancement{
  margin: 2em auto;
  >ul{
    padding: 1em 0;
    &.ligneReprise{
      .titreLigne{
        color: $orange;
      }
      .etapeLigne.etapePassee .ligneAvancement {
        border-color: $orange;
      }

    }
    &.ligneCommande{
      .titreLigne {
        //color: $vert;
      }
      .etapeLigne.etapePassee .ligneAvancement {
        //border-color: $vert;
      }
    }
    &.ligneFinancement{
      .titreLigne {
        color: $rose;
      }
      .etapeLigne.etapePassee .ligneAvancement {
        border-color: $rose;
      }
    }
    >li{
      display: inline-block;
      &.titreLigne{
        font-size: 1.5em;
        text-transform: uppercase;
        width: 20%;
        @media only screen {
          @media(min-width: $screen-sm-min)and (max-width: $screen-sm-max) {
            width: 15%;
          }
        }
        text-align: right;
        padding-right: 0.5em;
        &.endTitre{
          text-align: left;
          padding-left: 1.5em;
          padding-right: 0;
          width: 14%;
          color: $bleuEm !important;
        }
        @media only screen {
          @media(max-width: $screen-md-max) {
            span {
              font-size: 0.75em;
            }
            @media(max-width: $screen-sm-max) {
              span {
                font-size: 0.5em;
              }

            }
          }
        }
      }
      &.containLigne{
        width: 65%;
        @media only screen {
          @media(max-width: $screen-sm-max) {
              width: 70%;

          }
        }
        > ul.ligne{
          width: 100%;
          display: inline-block;
          white-space: nowrap;
          > li.etapeLigne {
            width: 25%;
            display: inline-block;
            //float: left;
            margin-right: -4px;
            position: relative;
            .ligneAvancement{
              content: "";
              display: inline-block;
              top: calc(50% - 2px);
              vertical-align: bottom;
              width: 100%;
              margin-right: -100%;
              margin-left: 10px;
              border-top: 4px solid $gris1;
              position: absolute;
              &.vert{
                border-color: $vert;
              }
              &.bleu{
                border-color: $bleu;
              }
              &.jaune{
                border-color: $jaune;
              }
              &.rose{
                border-color: $rose;
              }
            }
            &:last-child{
              position: absolute;
              width: auto !important;
              .ligneAvancement {
                @include transform-origin(top left)
              }
            }
            &.etapePassee {
              .etape{
                color: $bleuEm;
                border-color: $bleuEm;
              }
            }
            &.etapeCourante {
              .etape{
                background-color: $bleuEm;
                color: $blanc;
                border-color: $bleuEm;
              }
            }
            .etape {
              $dimensions: 1.5em;
              width: $dimensions;
              height: $dimensions;
              /*line-height: $dimensions - 0.1em;*/
              line-height: 1.2em;
              border: 3px solid $gris1;
              color: $gris1;
              display: block;
              border-radius: 50%;
              text-align: center;
              font-weight: 900;
              background-color: $blanc;
              position: relative;
              cursor: help;
              z-index: 1;
            }
          }
        }
        ul:nth-child(2).ligne{
          width: 30%;
        }
      }
    }
  }
}

.containCartesAvancement{
  text-align: center;
  .whiteCard{
    margin-bottom: 3em;
  }
  .titreCarte{
    padding: 0.5em;
    text-transform: uppercase;
    color: $blanc;
    @include shadowText(0.3);
    margin-top: 0;
  }

  .infosGeneralesCarte{
    margin: 1em 0;
  }

  .prixCarte{
    font-size: 1.5em;
  }
  .containHalfButton{
    margin-bottom: -3.3em;
    margin-top: auto;
  }
}
