.popover{
  border-radius: 0;
  @include whiteCard;
  *.popover-title{
    background-color: $blanc;
    border-bottom-width: 0;
    font-size: 2rem;
    @media (min-width: $screen-sm-min) {
      font-size: 2.5rem;
    }
    @media (min-width: $screen-md-min) {
      font-size: 3rem;
    }
  }
}