
.noPrime {
    margin-top: 20px;
    @media only screen {
        @media(max-width: $screen-sm-max) {
            height: 200px;
        }
    }
}

.sliderThumb {
    width: 100%;
    padding: 0 4px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
    background-color: $blanc;
    overflow: hidden;
    margin: 0;
    @include shadowBox(0.3);

    .slick-prev, .slick-next {
        left: 50%;
        transform: translateX(-50%);

        &:before {
            font-size: 15px;
        }

        height: 20px;
    }

    .slick-prev {
        top: 0;

        &:before {
            content: "\f106";
        }
    }

    .slick-next {
        bottom: 0;
        top: auto;

        &:before {
            content: "\f107";
        }
    }

    article {
        padding: 0.23em 1.5em;
    }

    img {
        padding-left: 0;
        padding-right: 0;
    }

    .thumb {
        margin: 0.25rem auto 0;
        cursor: pointer;

        &.thumb-ext, &.thumb-int {
            position: relative;

            .caption {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                background: $bleuEm;
                opacity: 0.5;
                z-index: 999;

                span {
                    color: #ffffff;
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    font-weight: bold;

                    &.icon-slider {
                        top: 35px;
                        font-size: 1.2em;
                        padding-left: 0.5em;
                        @media (max-width: $screen-md-max) {
                            top: 12px;
                        }

                        &:before {
                            content: '';
                            display: inline-block;
                            width: 72px;
                            height: 35px;
                            background: url('/../../build/resources/front/images/360/btn_white_360.svg') no-repeat;
                            background-size: contain;
                            position: absolute;
                            left: 50%;
                            transform: translate(-50%);
                            top: 10px;
                            @media (max-width: $screen-md-max) {
                                width: 62px;
                            }
                        }
                    }

                    &.text {
                        bottom: 26px;
                        @media (max-width: $screen-md-max) {
                            bottom: 23px;
                        }
                    }
                }
            }
        }
    }
}


