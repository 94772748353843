.containListeDesMarques{
  .brandCard{
    background-color: $bleuEm;
    margin-bottom: 1.5em;
    position: relative;
    .brandCardTitle{
      position: absolute;
      top: calc(50% - 0.5em);
      width: 100%;
      left: 0;
      text-align: center;
      font-size: 1.5em;
      font-weight: bold;
      color: $blanc !important;
      @include shadowText(0.5);
    }
    img{
      opacity: 0.1;
      transition: opacity 0.5s;
    }
    &:hover{
      img{
        opacity: 0.35;
      }
    }
  }
}


