@mixin whiteCard() {
  background-color: $blanc;
  @include shadowBox(0.3);
  border: 1px solid $gris2;
  ul{
    @include liststyle();
  }

}

@mixin greyCard() {
  background-color: $grisFond;
  @include shadowBox(0.3);
  border: 1px solid $gris2;
}

@mixin bleuEMCard() {
  background-color: $bleuEm;
  @include shadowBox(0.3);
  color: $blanc;
}

@mixin deleteCard($tailleBadge: 0.85em){
  background-color: $bleuEm;
  color: $blanc;
  position: absolute;
  border-radius: 50%;
  text-align: center;
  font-weight: 900;
  font-size: $tailleBadge;
  @include shadowBox(0.15);
  width: $tailleBadge*2;
  height: $tailleBadge*2;
  line-height: $tailleBadge*2;
  cursor: pointer;
}
