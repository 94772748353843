@each $couleurContain in $tabCouleurs {
  $nomVariable: str-ucfirst(nth($couleurContain, 1));
  fieldset.contain#{nth($nomVariable, 1)} {
    border: 2px solid nth($couleurContain, 2);
    legend {
      color: nth($couleurContain, 2);
    }
  }
}

fieldset {
  padding: 5px 10px;
  min-width: 0;
  legend {
    width: auto;
    text-align: center;
    font-size: 1em;
    font-weight: 700;
    padding: 0 5px;
    margin-bottom: 0;
    border: 0;
  }
  ul {
    margin-bottom: 0;
    text-align: left;
  }
}
