.lg-backdrop{

  background: $bleuEm;
  z-index: 9999;
  &.in{
    opacity: 0.75;
  }
}

.lg-actions{
  .lg-next, .lg-prev {
    width: 40px;
    height: 40px;
    background-color: $blanc;
    color: $gris1;
    font-family: EM-icomoon;
    @include whiteCard;
    &:hover, &:focus {
      background-color: $blanc;
      color: $gris1;
      &:after, &:before {
        opacity: 0.5;
      }
    }
  }
  .lg-next{
    right: 0;
      &:before{
        content: '\f105';
        margin-left: 4px;
      }
  }

  .lg-prev {
    left: 0;
    &:after {
      content: '\f104';
      margin-left: 4px;
    }
  }
}


.lg-outer{
  z-index: 10000;



  .lg-thumb-outer {
    background-color: $blanc;
    @include shadowBox(0.3);
    height: 75px !important;
  }
  .lg-toogle-thumb{
    background-color: $blanc;
    box-shadow: 0px 8px 0px 0px white, 0px 0px 10px rgba(0, 0, 0, 0.3);
    color: $gris1;
    &:hover{
      color : $gris1;
    }
  }
  .lg-thumb-item{
    border-radius: 0px;
    height: initial !important;
    &.active, &:hover{
      border-color: $bleuEm;
    }
    img{
      height: auto;
    }
  }
}

.lg-toolbar{
  background-color: $blanc;
  @include shadowBox(0.3);
  .lg-icon{
    color: $gris1;
    &:hover {
      color: $gris1;
    }
  }
  .lg-close {
    &:after {
      font-family: Lato;
      content: "x";
      font-size: 1em;
      line-height: 0em;
    }
  }
}
