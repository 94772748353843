#bandeauAccueil {
  color: $blanc;
  position: relative;
  @media(max-width: $screen-sm-max){
    padding: 0 0 25px;
  }
  @media(max-width: $screen-xs-max){
    margin-top: 60px;
    padding: 0 0 1.2em;
  }
    .header-picture-home {
        background-color: $bleuEm;
        padding: .75rem 1rem;
        text-align: center;
    }
  .containImageBandeau{
    position: relative;
    height: auto;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    //@media(min-width: 1300px) {
    //  align-items: center;
    //}
    @media(max-width: $screen-xs-max){
      height: auto;
    }
    h5, .h5{
      margin-bottom: 1em;
    }
      picture img{
          object-fit: cover;
          height: 500px;
          @media(max-width: $screen-xs-max){
              height: 130px;
          }
      }
      &.digital {
          picture img {
              //object-fit: cover;
              //margin-top: 30px;
              object-position: top;
              //height: unset;
              width: 100%;
              //max-width: 1500px;

              @media (max-width: 321px) {
                  margin-top: 50px;
              }

              @media (max-width: 450px) {
                  margin-bottom: -10px;
                  margin-top: 25px;
                  //height: 130px;
              }
              @media (max-width: 767px) and (min-width: 451px) {
                  height: 390px;
                  margin-bottom: -180px;
                  margin-top: 20px;
              }
              @media (max-width: 991px) and (min-width: 768px) {
                  margin-top: 10px;
                  margin-bottom: -40px;
                  //width: auto;
              }
              @media (max-width: 1299px) and (min-width: 992px) {
                  margin-top: 10px;
                  margin-bottom: -10px;
              }
              @media (min-width: 1300px) {
                  //height: 480px;
                  margin-top: -10px;
                  max-width: 1980px;
              }
          }
      }
  }
  .fondBleuBandeau{
    background-color: rgba(34,40,109,0.85);
    padding: 0;
    bottom:0;
    position: absolute;
    width: 100%;
    .noPadding{
      padding: 0;
    }
    .info{
      font-size: 1.2em;
      text-shadow: 0 3px 12px #000000;
      margin: 10px 0;
    }
      .logoMeilleursDistributeur{
          img{
              position: absolute;
              right: 0;
              transform: translateY(-100%);
              width: 15%;
              max-width: 330px;
          }
          @media (max-width: $screen-md-max){
              img{
                  width: 20%;
              }
          }
          @media (max-width: $screen-sm-max){
              img{
                  width: 27%;
              }
          }
          @media (max-width: $screen-xs-max){
              img{
                  width: 190px;
              }
          }
      }

    h1{
      font-size: 1.8rem;
      margin: 0.5em 0;
      padding-left: 12px;
      text-shadow: 0 3px 12px #000000;
      @media (max-width: $screen-sm-max){
        text-align: center;
        font-size: 1em;
        span{
          display: block;
        }
      }
      @media(max-width: $screen-xs-max) {
        font-size: 0;
        text-align: left;
        margin-bottom: 0.7em;
        span{
          display: inline-block;
        }
      }
    }
    @media only screen {
      @media(max-width: $screen-xs-max) {
        position: inherit;
        background-color: $bleuEm;
      }
    }
    @include shadowBox(0.8);
    .container{
      .h6{
        @media only screen {
          @media(max-width: $screen-md-max) {
            margin: 0;
          }
        }
      }
    }
  }
  .bandeauBrand{
    background: #22286d;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.8);
    padding: 15px 0 25px;
    overflow: hidden;
    @media (max-width: $screen-xs-max){
      padding: 15px 0 80px;
    }
    .container{
      position: relative;
    }
    .brandContent{
        display: flex;
        width: 100%;
        flex-flow: row wrap;
        margin: 0
    }
    h2{
      font-size: 1.3em;
      padding: 10px 70px 0 0;
      span{
        display: block;
      }
      @media (max-width: $screen-md-max){
        padding: 10px 0;
      }
    }
    a{
      color: #fff;
      &:hover{
        color: $vert;
      }
      &.link{
        &.lessBrand{
            display: block;
        }
       }
    }
    .brand{
      &:before{
        background-color: #fff;
      }
    }
    .brandList{
      background: #22286d;
      position: absolute;
      right: 10px;
      margin: 0;
      padding: 0 0 25px;
      width: 87%;
      z-index: 6;
      @media (max-width: $screen-md-max){
        width: 80%;
      }
      @media (max-width: $screen-sm-max){
        width: 75%;
      }
      @media (max-width: $screen-xs-max){
        width: 100%;
        right: 0;
      }
      a.lessBrand.hidden-lg{
        @media (max-width: $screen-md-max){
            position: absolute;
            right: 35px;
            bottom: 18px;
        }
        @media (max-width: $screen-sm-max){
          right: 15px;
          bottom: 16px;
        }
        @media (max-width: $screen-xs-max){
          bottom: 20px;
        }
      }
    }
    &.active{
      overflow: visible;
        .brandList{
            padding: 0 0 15px;
        }
    }
  }

  .homeSearch{
      @media (max-width: $screen-xs-max){
          margin-top: 10px;
      }
  }
  .bandeauBrand{
      &.active{
          .brand{
              @media (max-width: $screen-xs-max){

              }
          }
      }
  }
}



