.subFooter1299 {
  @media only screen {
    @media(max-width: $screen-md-max) {
      display: block;
      position: fixed;
      left: 0;
      bottom: 0;
      z-index: 2;
      width: 100%;
      background-color: $blanc;
      @include box-shadow(0px 3px 10px rgba(0, 0, 0, 0.3));
      margin-top: 0;
    }
  }

  @media (max-width: $screen-md-max) {
    .titreCollapse{
      font-size: 1em;
      text-align: left;
      padding: 0.5rem;
      &:after{
        font-size: 1.5rem;
      }
    }
  }




    .footerFiltresMobile {
        padding: 1em 0 0.5em;
        text-align: center;
        font-size: 1.2em;
        text-transform: uppercase;
        font-weight: bold;
        color: $gris1;
        cursor: pointer;
        &:not(:last-child){
            border-right: 1px solid $gris2;
        }
        &[aria-expanded="true"]{
            position: absolute;
            left: 0.95em;
            width: calc(100% - 2em);
            text-align: left;
            background-color: $blanc;
            z-index: 1;
            border-right-width: 0;
            &:after{
                content: '\f107';
                font-family: EM-icomoon;
                position: absolute;
                right: 0;
                top: 1em;
            }
        }
    }
    .titresSubFooter{
        @include shadowBox(0.3);
        @media only screen {
            @media(min-width: $screen-lg-min) {
                display: none;
            }
        }
        @media(max-width: $screen-lg-max) {
            z-index: 1;
            position: relative;
        }
    }
    .contenusSubFooter{
        .filtersContainer {
            @media(max-width: $screen-md-max) {
                overflow-y: scroll;
                overflow-x: hidden;
            }
        }
        @media only screen {
            @media(max-width: $screen-md-max) {
                @include shadowBoxInsetTop(0.3);
            }
        }
    }
    #filtrerResultats{
        z-index: 2;
        max-height: 100vh;
        background-color: $grisFond;
        @media only screen {
            @media(min-width: $screen-lg-min) {
                height: auto !important;
                display: block;
                max-height: none;
                overflow: auto;
            }
        }
        .tooltip{
            .tooltip-inner{
                min-width: auto;
            }
        }
    }

    .container-btn-sort{
        display: flex;
        justify-content: flex-end;
        width: 100%;
        @media (max-width: $screen-md-max){
            justify-content: center;
        }
        > p {
            align-self: center;
            margin-right: auto;
            margin-bottom: 0.7em;
        }
        .displayCard{
            display: inline-block;
            padding-right: 20px;
            button{
                padding: 7px 10px;
                margin: 0 5px;
                background-color: #fff;
                @include shadowBox(0.2);
                border: 1px solid #cccccc;
                &:hover, &.active{
                    color: #fff;
                    background-color: $bleuEm;
                }
            }
        }
        .containTrierRecherche{
            width: 35%;
            display: inline-block;
            @media (max-width: $screen-md-max){
                width: 100%;
                padding: 0 10px;
            }
            .jcf-select{
                margin: 0;
                height:auto;
            }
            #trierResultats{
                left: initial;
                width: 100%;
                @media (max-width: $screen-md-max){
                    margin: 20px 0;
                }
            }
        }
    }
}

