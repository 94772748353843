.asideConseiller{
  .image-cropper {
    width: 10em;
    height: 10em;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    margin: 1em auto;
    border : 2px solid $blanc;
    @include shadowBox(0.2)
  }
  img {
    display: inline;
    margin: 0 auto;
    height: auto;
    width: 100%;
  }
  .phoneCall{
    margin-top: 1em;
  }
}

.asideMenu .asideConseiller{
  @media only screen {
    @media(max-width: $screen-sm-max){
      display: none;
    }
  }
}

