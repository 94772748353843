footer{
  background-color: $blanc;
  padding-bottom: 2em;
  margin-top: 2em;
  ul {
    list-style: none;
    text-align: center;
    margin-bottom: 0;
    padding-left: 0;

    li {
      display: inline-block;
      &:not(:last-child):after {
        content: ' | ';
      }
        a {
            display: inline-block;
            padding: 0 5px;
        }
    }
  }

  hr{
    margin: 1rem 0;
  }

  .liensFooter {
    background-color: $bleuEm;
    font-weight: 700;
    font-size: 1.25em;
    padding: 0.5em;
    margin-bottom: 2em;
    color: $blanc;
    a{
      color: $blanc;
      @media (max-width: $screen-sm-max) {
          font-size: 1.3rem;
      }
      &:hover{
        color :darken($blanc, 10%);
      }
    }
  }
  .container-video{
      position: relative;
      cursor: pointer;
      .video-footer{
          object-fit: cover;
          width: 100%;
          height: 215px;
      }
      .triangle-play{
          background-color: #fff;
          border-radius: 50%;
          position: absolute;
          top: 50%;
          left: 50%;
          width: 90px;
          height: 90px;
          transform: translate(-50%, -50%);
          z-index: 2;
          display: inline-block;
          &:before{
              content: '';
              display: inline-block;
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 15px 0 15px 26.0px;
              border-color: transparent transparent transparent $bleuEm;
              position: absolute;
              top: 30px;
              left: 36px;
          }
          &.out{
              display: none;
          }
      }
  }

  .greyCard{
    padding-bottom: 10px;
    video{
      width: 100%;
    }
    @media only screen{
      @media (max-width: $screen-md-max){
        margin-bottom: 1.5em;
      }
    }
  }
  .btn-radius {
      border-radius: 10px;
  }
  .rappelFooter {

    .icon-rappel {
      height: 132px;
      width: 130px;
      margin: auto 0;
      margin-bottom: -19px;
      position: relative;
      z-index: 0;
    }
    .btn {
      text-align: center;
      position: relative;
      z-index: 1;
    }
  }
  .telFooter{
    font-size: 1.25em;
    text-transform: uppercase;
    text-align: center;
    margin-top: 0.55em;
    margin-bottom: 1em;
    p{
      margin-bottom: 0;
    }
    a {
      font-weight: 700;
      font-size: 1.5em;
      &:hover{
        color : $orange;
      }
    }
  }
  .inputTelFooter{
    font-size: 1.25em;
    text-transform: uppercase;
    text-align: center;
    p{
      margin-bottom: 2.4em;
    }
  }

  .socialFooter{
    font-size: 2.8em;
    text-align: center;
    margin-bottom: 0.2em;
    a{
      margin: auto 0.3em;
      .icon-facebook {
        color: #3a5795;
        &:hover{
          color: darken(#3a5795, 10%);
        }
      }
      .icon-em-instagram {
        color: #C32AA3;
        &:hover{
          color: darken(#C32AA3, 10%);
        }
      }
      .icon-twitter {
        color: #5ea8dd;
        &:hover{
          color: darken(#5ea8dd, 10%);
        }
      }
      &.icon{
          width: 39px;
          height: 39px;
          border-radius: 50%;
          display: inline-block;
          vertical-align: middle;
          position: relative;
          &:before{
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
          }
          &.icon-youtube{
              background: #dc472e;
              &::before{
                  content: url('../../../../resources/front/images/icons/youtube.svg');
              }
          }
          &.icon-instagram{
              background: rgb(254,218,119);
              background: linear-gradient(31deg, rgba(254,218,119,1) 0%, rgba(245,133,41,1) 16%, rgba(221,42,123,1) 43%, rgba(129,52,175,1) 89%, rgba(81,91,212,1) 92%);
              &::before{
                  content: url('../../../../resources/front/images/icons/instagram.svg');
              }
          }
      }
    }
  }

  .cardHoraires.greyCard{
    @media only screen {
      @media(min-width: $screen-md-max) {
        margin-top: 1.5em;
      }
    }
  }
  .horairesFooter{
    text-align: center;
    span{
      margin: auto 1em;
    }
    p{
      margin-bottom: 0px;
      &:last-child {
        margin-bottom: 20px;
      }
      &+ p {
        margin-bottom: 5px!important;
      }
    }
  }

  .liensLegaux{
    font-weight: 300;
    margin-top: 2em;
    margin-bottom: 1em;
    a:hover{
      color: $gris1;
    }
  }

  #callVisiteGuidee, #callAgencyChoice{
    margin-bottom: 1em;
  }

  #outdated {
    background-color: $orange !important;
    @include shadowBox(0.3);
  }
}
