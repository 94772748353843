@import "notif-messages-accueil-compte";
@import "placement-accessoires-compte";
@import "recapitulatif-demarches";

.containFastLinksAccountSections{
  .whiteCard{
    margin-bottom: 1.5em;
    padding-bottom: 43px;
    p{
      margin: 1em auto;
    }
    .btn{
      position: absolute;
      bottom: 10px;
      left:10px;
      width: calc(100% - 20px);
    }
  }
}