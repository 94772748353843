.containCoordonneesReprise {
  .whiteCard {
    input, .jcf-select {
      margin-bottom: 1em;
    }
    .small-4 {
      font-size: 1em;
      margin-top: 0.5em;
    }
  }
}