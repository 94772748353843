.containEnteteFicheTitreBoutons {
  .row {
    position: relative;
    > * {
      //display: inline-block;
      //vertical-align: middle;
    }
    h1 {
      @media (min-width: 992px) {
        font-size: 2.8rem;
      }

      font-size: 1.75em;
      margin-bottom: 0.5em;
    }
    h2, h1 {
      margin-top: 0;
      margin-bottom: 0;
    }
    > div {
      display: block;
      font-size: 1.8rem;
      //position: absolute;
      //right: 0;
      //top: 0;
      div {
        display: inline-block;
        //@include shadowBox(0.3);

      }
    }
    .disponibiliteVehicule {
      &.bleu {
        a {
          color: $bleu;
        }
      }
      &.rose {
        a {
          color: $rose;
        }
      }
      a {
        text-decoration: underline;
        &:hover {
          color: $bleuEm;
        }
      }
    }
    @media only screen {
      @media(max-width: $screen-sm-max) {
        h2, > div,
        h1, > div{

          text-align: center;

        }
        div {
          margin: 0 0 0.5em;
        }
      }
    }
  }

}